import { FolderType, RenameFolderType } from 'types/types';
import { checkParam, requestInstance, EditType, GetItemsType, ResponseType } from './api';

export const folderAPI = {
  async getFolderList(
    workspaceId: string,
    folderId: string | null,
    pageNumber: number | null,
    filter: string | null
  ) {
    const query = checkParam(filter);
    const folder = checkParam(folderId);

    const res = await requestInstance().get<GetItemsType<Array<FolderType>>>(
      `v1/folder/search?workspace_id=${workspaceId}&page=${pageNumber}&folder_id=${folder}&keyword=${query}`
    );

    return res.data;
  },
  async getAllFoldersList(workspaceId: string) {
    const res = await requestInstance().get<GetItemsType<Array<FolderType>>>(
      `v1/folder?workspace_id=${workspaceId}`
    );

    return res.data;
  },
  async setNewFolder(workspaceId: number, folderName: string, parentId: null | string = null) {
    const res = await requestInstance().post<
      ResponseType<FolderType>
    >('v1/folder', { name: folderName, workspace_id: workspaceId, parent_id: parentId });

    return res.data;
  },

  async pinFolder(folderId: number) {
    const res = await requestInstance().post<EditType>(`v1/folder/${folderId}/pin`);

    return res.data;
  },
  async unpinFolder(folderId: number) {
    const res = await requestInstance().post<EditType>(`v1/folder/${folderId}/unpin`);

    return res.data;
  },

  async deleteFolder(folderId: number) {
    const res = await requestInstance().delete<EditType>(`v1/folder/${folderId}`);

    return res.data;
  },

  async renameFolder(folderId: number, newFolderName: string, parent_id: number | string) {
    const res = await requestInstance().put<ResponseType<RenameFolderType>>(`v1/folder/${folderId}`, { name: newFolderName, parent_id });

    return res.data;
  },
  async getParentFolderList(folder_id: number | string) {
    const res = await requestInstance().get<ResponseType<Array<FolderType>>>(`v1/folder/${folder_id}/parents`);

    return res.data;
  },
  async getOneFolder(folder_id: number | string) {
    const res = await requestInstance().get<ResponseType<FolderType>>(`v1/folder/${folder_id}`);

    return res.data;
  },
  async moveFolder(folderId: number, workspaceId: number, parentId?: number) {
    const res = await requestInstance().post<ResponseType<FolderType>>(
      `v1/folder/${folderId}/move`,
      {
        workspace_id: workspaceId,
        folder_id: parentId

      }
    );

    return res.data;
  },
  async orderFolders(folders: number[]) {
    const res = await requestInstance().post<ResponseType<FolderType>>(
      'v1/folder/order',
      {
        folders
      }
    );

    return res.data;
  }
};
