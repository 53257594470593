import { Flex } from '@chakra-ui/react';

const KeyIcon = ({ background }: { background: string }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    boxSize="28px"
    borderRadius="4px"
    fontSize="14px"
    color="white.500"
    background={background}
  >
    <i className="fa-solid fa-key" />
  </Flex>
);

export default KeyIcon;
