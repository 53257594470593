import { SnippetType } from 'types/types';
import { checkParam, GetItemsType, requestInstance, ResponseType } from './api';

export const snippetsAPI = {
  async getSnippetsList(
    teamId: string | number | null,
    collectionId: string | number | null,
    search: string | null,
    tagsList: string | null
  ) {
    const team_id = checkParam(teamId);
    const collection_id = checkParam(collectionId);
    const keyword = checkParam(search);
    const tags = checkParam(tagsList);

    const res = await requestInstance().get<GetItemsType<Array<SnippetType>>>(
      `/v1/snippet?team_id=${team_id}&collection_id=${collection_id}&keyword=${keyword}&tags=${tags}`
    );

    return res.data;
  },

  async createSnippet(
    collection_id: string | number | null,
    title: string,
    content: string,
    team_id: string | number | null,
    tags: string[] = []
  ) {
    const res = await requestInstance().post<ResponseType<SnippetType>>('/v1/snippet', {
      collection_id,
      title,
      content,
      tags,
      team_id
    });

    return res.data;
  },

  async editSnippet(
    snippetId: string | number,
    title: string,
    content: string,
    tags: string[] = []
  ) {
    const res = await requestInstance().put<ResponseType<SnippetType>>(`/v1/snippet/${snippetId}`, {
      title,
      content,
      tags
    });

    return res.data;
  },

  async deleteSnippet(
    snippetId: string | number
  ) {
    const res = await requestInstance().delete<ResponseType<SnippetType>>(`/v1/snippet/${snippetId}`);

    return res.data;
  },

  async bulkDeleteSnippets(
    snippets: Array<string | number>
  ) {
    const res = await requestInstance().post<ResponseType<SnippetType>>('/v1/snippet/bulk-delete', { snippets });

    return res.data;
  }
};

