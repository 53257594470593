import { InviteType, PendingTeamMember, Team, TeamInvitationType, TeamMember, UploadImage } from 'types/types';
import { requestInstance, EditType, GetItemsType, ResponseType, checkParam } from './api';

export const teamAPI = {
  async getTeamList(owner = 'own') {
    const res = await requestInstance().get<ResponseType<Array<Team>>>(`v1/team?filter_by_ownership=${owner}`);

    return res.data;
  },
  async getTeamById(teamId: string | number) {
    const res = await requestInstance().get<ResponseType<Team>>(`v1/team/${teamId}`);

    return res.data;
  },
  async getTeamMembers(teamId: number, page: number, keyword?: string, excludeWorkspaceId?: number | string) {
    const res = await requestInstance()
      .get<GetItemsType<Array<TeamMember>>>(
        `v1/team/${teamId}/members?page=${page}&keyword=${checkParam(keyword)}
        &exclude_workspace_id=${checkParam(excludeWorkspaceId)}`
      );

    return res.data;
  },
  async getTeamPendingMembers(teamId: number, page: number) {
    const res = await requestInstance()
      .get<GetItemsType<Array<PendingTeamMember>>>(
        `v1/team/${teamId}/invite?page=${page}`
      );

    return res.data;
  },
  async inviteTeamMembers(teamId: number, invites: Array<InviteType>) {
    const res = await requestInstance().post<ResponseType<{ message: string }>>(`v1/team/${teamId}/invite`, {
      invites
    });

    return res.data;
  },
  async deleteTeamMember(teamId: number | string, userId: number | string) {
    const res = await requestInstance().delete<EditType>(`v1/team/${teamId}/user/${userId}`);

    return res.data;
  },
  async deleteTeamInvitation(inviteToken: string) {
    const res = await requestInstance().delete<EditType>(`v1/team/invite/${inviteToken}`);

    return res.data;
  },
  async getTeamInvitation(inviteToken: string) {
    const res = await requestInstance().get<ResponseType<TeamInvitationType>>(`v1/team/invite/${inviteToken}`);

    return res.data;
  },
  async acceptTeamInvitation(inviteToken: string) {
    const res = await requestInstance().get<ResponseType<TeamInvitationType>>(`v1/team/invite/${inviteToken}/accept`);

    return res.data;
  },
  async declineTeamInvitation(inviteToken: string) {
    const res = await requestInstance().get<ResponseType<TeamInvitationType>>(`v1/team/invite/${inviteToken}/decline`);

    return res.data;
  },
  async getJoinUrl(teamId: number | string) {
    const res = await requestInstance().get<ResponseType<{ url: string | null }>>(`v1/team/${teamId}/join/url`);

    return res.data;
  },
  async generateJoinUrl(teamId: number | string) {
    const res = await requestInstance().get<ResponseType<{ url: string }>>(`v1/team/${teamId}/join/generate`);

    return res.data;
  },
  async resetJoinUrl(teamId: number | string) {
    const res = await requestInstance().delete<EditType>(`v1/team/${teamId}/join/url`);

    return res.data;
  },
  async getTeamJoinInvitation(joinToken: string) {
    const res = await requestInstance().get<ResponseType<TeamInvitationType['team']>>(`v1/team/${joinToken}/join`);

    return res.data;
  },
  async joinTeamByToken(joinToken: string) {
    const res = await requestInstance().post<ResponseType<TeamInvitationType>>(`v1/team/${joinToken}/join`);

    return res.data;
  },

  async updateTeamName(teamId: number | string, name: string) {
    const res = await requestInstance().put<ResponseType<Team>>(`/v1/team/${teamId}`, { name });

    return res.data;
  },

  async deleteTeamImage(teamId: number | string) {
    const res = await requestInstance().delete<ResponseType<Team>>(`v1/team/${teamId}/remove-image`);

    return res.data;
  },

  async updateTeamImage(teamId: number, image: UploadImage) {
    const formData = new FormData();

    if (image?.file) {
      formData.append('image', image.file);
      formData.append('width', image.width.toString());
      formData.append('height', image.height.toString());
      formData.append('x', image.x.toString());
      formData.append('y', image.y.toString());
    }
    const res = await requestInstance().post<ResponseType<Team>>(`v1/team/${teamId}/image?_method=patch`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return res.data;
  }
};
