import OneFieldModal from 'components/common/popup/OneFieldModal';
import Popup from 'components/common/popup/Popup';
import { useState } from 'react';
import { useAppDispatch } from 'store/store';
import { addNewFolder } from 'store/wiki/folder-reducer';

type Props = {
  teamId: number | null;
  isOpen: boolean;
  onClose: () => void;
}

export const CreateFolder = ({ isOpen, onClose, teamId }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [errors, setErrors] = useState<{ [key: string]: string } | null>(null);
  const [loading, setLoading] = useState(false);

  const handleCreate = (name: string) => {
    if (errors) setErrors(null);
    setLoading(true);

    dispatch(addNewFolder(name, teamId))
      .then(() => {
        onClose();
      })
      .catch((err) => {
        setErrors(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Popup isOpen={isOpen} onClose={onClose} overflow="unset">
      <OneFieldModal
        errors={errors}
        title="New folder"
        onClose={onClose}
        setErrors={setErrors}
        handleSubmit={handleCreate}
        fieldValue=""
        placeholder="Name"
        loading={loading}
      />
    </Popup>
  );
};

