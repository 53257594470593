import { collectionsApi } from 'api/collectionsApi';
import { errorHandler } from 'utils/errorHandler';
import { CollectionType, InferActionsTypes } from '../types/types';
import { showAlert } from './app-reducer';
import { AppThunkType } from './store';

const initialState = {
  data: [] as Array<CollectionType>,
  isFetching: false,
  currentCollection: null as CollectionType | null
};

export const actionsCollections = {
  setCollectionsList: (data: Array<CollectionType>) => ({ type: 'SET_COLLECTIONS_LIST', data }) as const,
  setIsCollectionsFetching: (isFetching: boolean) => ({ type: 'SET_IS_COLLECTIONS_FETCHING', isFetching }) as const,
  addNewCollection: (data: CollectionType) => ({ type: 'ADD_NEW_COLLECTION', data }) as const,
  setCurrentCollection: (data: CollectionType | null) => ({ type: 'SET_CURRENT_COLLECTION', data }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsCollections>

export const collectionsReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_COLLECTIONS_LIST':
      return {
        ...state,
        data: action.data
      };

    case 'SET_IS_COLLECTIONS_FETCHING':
      return {
        ...state,
        isFetching: action.isFetching
      };

    case 'ADD_NEW_COLLECTION':
      return {
        ...state,
        data: [action.data, ...state.data]
      };

    case 'SET_CURRENT_COLLECTION':
      return {
        ...state,
        currentCollection: action.data
      };

    default:
      return state;
  }
};

export const getCollections = (
  teamId: string | number | null
): AppThunkType => async (dispatch) => {
  try {
    dispatch(actionsCollections.setIsCollectionsFetching(true));

    const response = await collectionsApi.getCollectionsList(teamId);
    dispatch(actionsCollections.setCollectionsList(response.data));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  } finally {
    dispatch(actionsCollections.setIsCollectionsFetching(false));
  }
};

export const createCollection = (
  name: string
): AppThunkType => async (dispatch, getState) => {
  const environment = getState().profile.currentEnvironment;
  try {
    const response = await collectionsApi.createCollection(name, environment.team_id ?? null);
    dispatch(actionsCollections.addNewCollection(response.data));
    dispatch(showAlert('Collection successfully created!', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const getCollection = (
  collectionId: string | number
): AppThunkType => async (dispatch) => {
  try {
    const response = await collectionsApi.getCollection(collectionId);
    dispatch(actionsCollections.setCurrentCollection(response.data));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const updateCollection = (
  name: string,
  collectionId: number | string
): AppThunkType => async (dispatch, getState) => {
  const collections = getState().collections.data;
  try {
    const response = await collectionsApi.updateCollection(name, collectionId);
    dispatch(actionsCollections.setCollectionsList(
      collections.map((item) => ({ ...item, ...(+item.id === +collectionId ? { name, updated_at: response.data.updated_at } : undefined) }))
    ));
    dispatch(showAlert('Collection successfully updated!', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const deleteCollection = (
  collectionId: number | string
): AppThunkType => async (dispatch, getState) => {
  const collections = getState().collections.data;
  try {
    const response = await collectionsApi.deleteCollection(collectionId);
    dispatch(actionsCollections.setCollectionsList(
      collections.filter((item) => +item.id !== +collectionId)
    ));
    dispatch(showAlert('Collection successfully deleted!', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export default collectionsReducer;

