import { Avatar, Box, Center, Circle, Flex, Image, Link, Spacer, Text } from '@chakra-ui/react';
import { TeamInvitationType } from 'types/types';
import logo from 'assets/image/home.svg';
import mobile_logo from 'assets/image/icons/mobile-logo.svg';
import AuthFooter from '../../Auth/AuthFooter';

interface AuthLayoutProps {
  children: JSX.Element;
  image: string;
  title: string;
  icon: JSX.Element;
  description?: string;
  teamInvitation?: TeamInvitationType;
  topSpace?: { base: string; md: string };
}

const AuthLayout = ({
  children,
  image,
  title,
  icon,
  description,
  teamInvitation,
  topSpace = { base: '40px', md: '75px' }
}: AuthLayoutProps) => (
  <Flex
    fontFamily="'Inter', sans-serif"
    w="100%"
    h={{ base: 'auto', lg: '100vh' }}
    minH="100vh"
    flexDir={{ base: 'column-reverse', lg: 'row' }}
    alignItems={{ base: 'center', lg: 'initial' }}
  >
    <Flex
      backgroundImage={{ base: 'none', lg: `url(${image})` }}
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      flex={1}
      direction="column"
      justifyContent="space-between"
      w={{ base: '100%', lg: 'unset' }}
    >
      <Box display={{ base: 'none', lg: 'block' }} boxSize="40px" m="32px">
        <Link href={process.env.REACT_APP_HOME_URL || '/'}>
          <Image src={logo} boxSize="40px" objectFit="contain" />
        </Link>
      </Box>
      <Spacer display={{ base: 'block', lg: 'none' }} />
      <AuthFooter />
    </Flex>

    <Flex
      direction="column"
      overflowY="auto"
      w={{ base: '100%', lg: '552px' }}
      h="100%"
      p={{ base: '56px 32px 32px 32px', lg: '48px 80px 36px' }}
      maxW={{ base: '456px', lg: 'unset' }}
    >
      <Link href={process.env.REACT_APP_HOME_URL || '/'}>
        <Image
          src={mobile_logo}
          w={{ base: '89px', md: '96px' }}
          display={{ base: 'block', lg: 'none' }}
          mx="auto"
          mb={topSpace}
        />
      </Link>

      <Flex alignItems="center">
        <Box display={{ base: 'none', lg: 'block' }} mr="16px">
          {icon}
        </Box>
        <Text as="h1" fontWeight={600} fontSize="32px" lineHeight="150%">
          {title}
        </Text>
        {teamInvitation && (
          <Center gap="8px" ml="24px">
            <Circle border="1px dashed" borderColor="light_green.300" size="30px">
              <Avatar boxSize="22px" />
            </Circle>
            <Box color="light_green.300">
              <i className="fa-solid fa-bolt" />
            </Box>
            <Circle border="1px dashed" borderColor="light_green.300" size="30px">
              <Avatar src={teamInvitation.team.image} boxSize="22px" />
            </Circle>
          </Center>
        )}
      </Flex>

      {description && (
        <Text
          fontWeight="400"
          fontSize="14px"
          lineHeight="17px"
          color="gray.500"
          mt="8px"
          mb="-16px"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {children}
    </Flex>
  </Flex>
);

export default AuthLayout;
