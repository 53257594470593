import { Button, Center, MenuItem, MenuList, ResponsiveValue } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Union } from 'types/types';

export const ContextMenuWrapper = ({ children }: { children: ReactNode }): JSX.Element => (
  <MenuList
    display="flex"
    flexDirection="column"
    minW="auto"
    border="1px solid #EEEEEE"
    bg="white.50"
    boxShadow="0px 0px 8px rgba(0, 0, 0, 0.12)"
    borderRadius="8px"
    boxSizing="border-box"
    p="12px"
    gap="8px"
  >
    {children}
  </MenuList>
);

type Props = {
  label: string | JSX.Element;
  handleClick: () => void;
  icon: JSX.Element;
  height?: ResponsiveValue<Union<string | 'px' | (string & {})>>;
  width?: ResponsiveValue<Union<string | 'px' | (string & {})>>;
}

export const ContextMenuButton = ({ label, handleClick, icon, height, width }: Props): JSX.Element => (
  <MenuItem
    as={Button}
    onClick={handleClick}
    _hover={{
      bg: '#F5F5F5'
    }}
    h={height || '26px'}
    p="6px"
    w={width || '100%'}
    borderRadius="4px"
    color="gray.600"
    gap="12px"
    bg="white.50"
    justifyContent="flex-start"
    fontWeight="600"
  >
    <Center fontSize="14px" boxSize="18px" color="#9D9EB5">
      {icon}
    </Center>
    {label}
  </MenuItem>
);
