import { useState } from 'react';
import { Box, Button, Center, Divider, Image, Text } from '@chakra-ui/react';
import Popup from 'components/common/popup/Popup';
import { BlurLoading } from 'components/common/BlurLoading';
import { useAppDispatch, useAppSelector } from 'store/store';
import stripe_logo from 'assets/image/stripe_logo.svg';
import add_payment from 'assets/image/add_payment.webp';
import { subscriptionAPI } from 'api/subscriptionApi';
import { errorHandler } from 'utils/errorHandler';

type PropsType = {
  handleClose: () => void;
  isOpen: boolean;
}

const AddPaymentMethod = ({ handleClose, isOpen }: PropsType): JSX.Element => {
  const dispatch = useAppDispatch();
  const { currentPlan } = useAppSelector((state) => state.subscription);

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    subscriptionAPI.getPaymentMethod()
    .then((response) => {
      window.open(response.data.url, '_self');
    })
   .catch((error) => errorHandler(error, dispatch))
   .finally(() => setLoading(false));
  };

return (
  <Popup onClose={handleClose} hasCloseButton isOpen={isOpen} width="480px">
    {currentPlan
        ? (
          <Center flexDirection="column" w="100%">
            <Image h="211px" w="228px" src={add_payment} mb="16px" />

            <Text mb="8px" fontSize="24px" fontWeight="700" lineHeight="150%" textAlign="center">
              {currentPlan.hasDefaultPaymentMethod ? 'Change Payment Method' : 'Add Payment Method'}
            </Text>

            <Text w="100%" mb="16px" textAlign="center" fontSize="14px" fontWeight="400" color="light_gray" lineHeight="150%">

              {currentPlan.hasDefaultPaymentMethod
                ? 'Problem occurred with charging your default payment method. Please change your payment method to continue to use'
                : 'Problem with payment. Add payment method to continue to use'}
              {' '}
              <Box as="span" color="gray.500">
                {currentPlan?.subscription_plan.plan.name}
              </Box>
              {' '}
              plan
            </Text>

            <Button
              isLoading={loading}
              onClick={handleSubmit}
              size="lg"
              colorScheme="red"
              boxShadow="0px 1px 4px rgba(87, 14, 14, 0.25)"
            >
              {currentPlan.hasDefaultPaymentMethod ? 'Change Payment Method' : 'Add Payment Method'}
            </Button>

            <Divider w="64px" my="16px" mx="auto" />

            <Image src={stripe_logo} />
          </Center>
        )
        : <BlurLoading />}
  </Popup>
  );
};

export default AddPaymentMethod;
