import { Button, useDisclosure } from '@chakra-ui/react';
import Popup from 'components/common/popup/Popup';
import { AddWorkspace } from 'components/modals/Popups/AddWorkspace';

export const CreateWorkspaceButton = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        size="icon"
        fontSize="18px"
        color="green.1000"
        bg="transparent"
        _hover={{
          bg: 'green.1000',
          color: 'white.50'
        }}
        _active={{
          bg: 'green.1000',
          color: 'white.50'
        }}
        transition="150ms"
      >
        <i className="fa-solid fa-plus" />
      </Button>

      <Popup isOpen={isOpen} onClose={onClose} width="349px">
        <AddWorkspace onClose={onClose} />
      </Popup>
    </>
  );
};
