import { Box, Divider, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';
import { SelectionIndicator } from 'components/common/SelectionIndicator';
import { PlanType } from 'types/types';
import diamond_icon from 'assets/image/diamond.svg';
import flag from 'assets/image/icons/flag.svg';
import team from 'assets/image/icons/team.svg';
import { BenefitsIcons } from './BenefitsIcons';
import { PlanBenefitsList } from './PlanBenefitsList/PlanBenefitsList';

type PlanCardProps = {
  checked: boolean;
  price: number;
  explanation: string;
  plan: PlanType,
  isCurrentPlan: boolean;
  trialDays: number | null;
  isInPopup:boolean;
  handleSelect: () => void;
};

const PlanCard = ({
  price,
  explanation,
  checked,
  plan,
  isCurrentPlan,
  trialDays,
  isInPopup,
  handleSelect
}: PlanCardProps) => {
  if (!checked) {
    return (
      <Flex
        cursor="pointer"
        onClick={handleSelect}
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        h="60px"
        p={{ base: '10px', md: '10px 16px' }}
        borderRadius="8px"
        border="1px solid"
        borderColor="gray.100"
      >
        <Flex alignItems="center">
          <Image
            mr="6px"
            src={plan.slug === 'basic' ? flag : `${plan.slug === 'pro' ? diamond_icon : team}`}
            objectFit="inherit"
            boxSize="20px"
            p={plan.slug === 'pro' ? '2px' : '0px'}
          />
          <Text
            mr="8px"
            color="light_gray"
            textTransform="capitalize"
            fontWeight="500"
            fontSize={{ base: '16px', md: '14px' }}
            lineHeight="150%"
          >
            {plan.name}
          </Text>
          <Text mr="8px" color="light_gray" pl="2px" fontWeight="400" fontSize="16px">
            /
          </Text>
          <Text fontSize="16px" fontWeight="600">
            $
            {price}
          </Text>

          <HStack ml="16px" h="40px" spacing="4px" p="12px" bg="white.600" borderRadius="6px">
            <BenefitsIcons plan={plan} />
          </HStack>
        </Flex>

        {isCurrentPlan && <SelectionIndicator fontSize="24px" color="light_green.100" />}
      </Flex>
    );
  }

  return (
    <VStack
      justifyContent="center"
      cursor="pointer"
      onClick={handleSelect}
      w="100%"
      h={{ base: 'fit-content', lg: '137px' }}
      p="16px"
      spacing="12px"
      borderRadius="8px"
      border="1px solid"
      borderColor="light_green.300"
    >
      <HStack w="100%" justifyContent="space-between" alignItems="flex-start">
        <HStack spacing="6px" w="100%">
          <Image
            src={plan.slug === 'basic' ? flag : `${plan.slug === 'pro' ? diamond_icon : team}`}
            objectFit="inherit"
            boxSize="20px"
            p={plan.slug === 'pro' ? '2px' : '0px'}
          />
          <Text textTransform="capitalize" color="light_gray" fontWeight="500" fontSize="14px" lineHeight="150%">
            {plan.name}
          </Text>
        </HStack>
        {isCurrentPlan && <SelectionIndicator fontSize="24px" color="light_green.100" />}
      </HStack>

      <HStack w="100%" justifyContent="flex-start" spacing="16px" h="40px">
        <Text fontSize="40px" fontWeight="600">
          $
          {price}
        </Text>

        <Divider display={{ base: 'none', lg: 'initial' }} orientation="vertical" />
        <HStack h="40px" spacing="4px" p="12px" bg="white.600" borderRadius="6px">
          <BenefitsIcons plan={plan} />
        </HStack>
      </HStack>

      {!(!trialDays && isCurrentPlan) && (
        <Text
          w="100%"
          color={trialDays && trialDays < 4 && isCurrentPlan ? 'red.100' : 'black'}
          textAlign="start"
          h={{ base: 'fit-content', lg: '20px' }}
          fontSize="12px"
          fontWeight="400"
        >
          {trialDays && isCurrentPlan ? `* ${trialDays} Trial Day${trialDays > 1 ? 's' : ''} left` : explanation}
        </Text>
      )}

      <Divider display={{ base: 'initial', lg: 'none' }} />

      <Box
        w="100%"
        display={{ base: 'initial', lg: 'none' }}
        h="100%"
        maxH={{ base: '100%', md: isInPopup ? '100%' : '140px', lg: '100%' }}
      >
        <PlanBenefitsList isInPopup={isInPopup} planType={plan.slug} />
      </Box>

    </VStack>
  );
};

export default PlanCard;
