import {
  Center,
  Circle,
  FormControl,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  useDisclosure
} from '@chakra-ui/react';
import { Delete } from 'components/modals/Popups/Delete';
import { LegacyRef, ReactNode, useEffect, useState } from 'react';
import { defaultImageUrls } from 'utils/defaultImageUrls';
import { ContextMenuButton } from './buttons/ContextMenuButton';
import Popup from './popup/Popup';

type UploadImageProps = {
  children: ReactNode;
  image: string | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  deleteImage: () => Promise<void>;
  imageRef?: LegacyRef<HTMLInputElement>;
  size?: string;
  iconSize?: string;
  hasBorder?: boolean;
};

const UploadImage = ({
  imageRef,
  children,
  image,
  handleChange,
  deleteImage,
  size = '117px',
  iconSize = '40px',
  hasBorder
}: UploadImageProps) => {
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const handleDelete = () => {
    setLoading(true);
    if (deleteImage) {
      deleteImage()
        .then(() => {
          onDeleteClose();
        })
        .finally(() => setLoading(false));
    }
  };

  const checkImage = () => {
    if (image && !defaultImageUrls.includes(image)) {
      setShowDeleteButton(true);
    } else {
      setShowDeleteButton(false);
    }
  };

  useEffect(() => {
    checkImage();
  }, [image]);

  return (
    <>
      <Center boxSize={`calc(${size} + ${hasBorder ? '26px' : '0px'})`} position="relative">
        <Circle
          size={`calc(${size} + 26px)`}
          border="2px dashed"
          borderColor={hasBorder ? 'yellow.50' : 'transparent'}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Circle size={size} overflow="hidden" display="flex" justifyContent="center" alignItems="center">
            {children}
          </Circle>
        </Circle>

        <Menu autoSelect={false}>
          <MenuButton
            as={Circle}
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            boxSize={iconSize}
            bottom="-4px"
            right="-4px"
            bg="white.50"
            cursor="pointer"
            color="ghost_blue"
            borderColor="ghost_blue"
            transition=".3s"
            _hover={{
              color: 'light_blue.100',
              borderColor: 'light_blue.100'
            }}
            _active={{
              color: 'light_blue.100',
              borderColor: 'light_blue.100'
            }}
            boxShadow="0px 1.2px 4.8px rgba(0, 0, 0, 0.25)"
            __css={{
              '>*': {
                boxSize: 'inherit',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderColor: 'inherit'
              }
            }}
          >
            <Circle
              size={`calc(${iconSize} - 6px)`}
              fontSize={`calc(${iconSize} / 2.5)`}
              border="1px dashed"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <i className="fa-solid fa-image" />
            </Circle>
          </MenuButton>

          <MenuList
            border="transparent"
            bg="white.500"
            boxShadow="0px 0px 16px rgba(101, 101, 101, 0.25)"
            borderRadius="8px"
            boxSizing="border-box"
            minW="164px"
          >
            <FormControl>
              <FormLabel
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
                h="40px"
                _hover={{ bg: 'white.700' }}
                color="black"
                gap="10px"
                bg="white.100"
                p="8px"
                m="0 8px 0 8px"
                minW="calc(100% - 16px)"
                w="calc(100% - 16px)"
                borderRadius="4px"
                fontSize="14px"
                cursor="pointer"
              >
                <Center boxSize="20px" color="light_green.50" fontSize="11px" bg="light_green.400" borderRadius="4px">
                  <i className="fas fa-image" />
                </Center>
                Upload Image
                <Input ref={imageRef} onChange={handleChange} accept="image/png, image/jpeg" type="file" hidden />
              </FormLabel>
            </FormControl>

            {showDeleteButton && (
            <>
              <MenuDivider borderColor="gray.100" opacity="1" />

              <ContextMenuButton handleClick={onDeleteOpen}>
                <Center background="red.50" borderRadius="4px" fontSize="12px" boxSize="20px" color="red.100">
                  <i className="fa-solid fa-trash-can" />
                </Center>
                Remove Image
              </ContextMenuButton>
            </>
            )}
          </MenuList>
        </Menu>
      </Center>

      <Popup isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <Delete
          title="Delete Image"
          description="Are you sure, you want to delete this Image?"
          handleDelete={handleDelete}
          onClose={onDeleteClose}
          isLoading={loading}
        />
      </Popup>
    </>
  );
};

export default UploadImage;
