import { Center } from '@chakra-ui/react';

type PropsType = {
  fontSize: string
  color?: string
}

export const SelectionIndicator = ({ fontSize, color = 'light_green.50' }: PropsType): JSX.Element => (
  <Center as="span" boxSize={fontSize} fontSize={fontSize} color={color}>
    <i className="fa-solid fa-circle-check" />
  </Center>
);
