import { Button, Divider, Flex, HStack, IconButton, Image, Square, Text, VStack } from '@chakra-ui/react';
import { showAlert } from 'store/app-reducer';
import { actionsPopup } from 'store/popup-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import { useDeviceParams } from 'hooks/useDeviceParams';
import google_chrome_logo from 'assets/image/google_chrome_logo.svg';
import install_desktop from 'assets/image/icons/install_desktop.svg';
import logo from 'assets/image/logo.svg';
import InstructionList from './InstructionList';

const SuggestInstallApp = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { deferredPromptEvent } = useAppSelector((state) => state.popup);

  const { deviceType, browserType, os, isInstalled } = useDeviceParams();

  const needChangeBrowser = ((browserType !== 'chrome' && deviceType === 'desktop')
    || (browserType !== 'safari' && deviceType === 'mobile' && os === 'iOS'));

  const handleInstallPwa = () => {
    if (deferredPromptEvent) {
      deferredPromptEvent.prompt();

      deferredPromptEvent.userChoice.then(() => {
        dispatch(actionsPopup.setShowInstallApp(false));
      });
    }
  };

  const handleOpen = () => {
    window.open('web+getlinkerpwa://b', '_blank');
    dispatch(actionsPopup.setShowInstallApp(false));
  };

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.origin);
    dispatch(showAlert('Link successfully copied!', true));
    dispatch(actionsPopup.setShowInstallApp(false));
  };

  const handleClick = () => {
    if (needChangeBrowser) {
      copyLink();
    } else if (deferredPromptEvent) {
      handleInstallPwa();
    } else if (!deferredPromptEvent) {
      handleOpen();
    }
  };

  const onCancelClick = () => {
    dispatch(actionsPopup.setShowInstallApp(false));
  };

  return (
    <Flex
      zIndex={6}
      position="fixed"
      direction="column"
      bottom={{ base: 0, md: '32px' }}
      right={{ base: '50%', md: '32px' }}
      transform={{ base: 'translateX(50%)', md: 'unset' }}
      p="24px"
      w={{ base: '100%', md: '401px' }}
      maxW="100%"
      bg="white"
      boxShadow="0px 0px 41px rgba(101, 101, 101, 0.35)"
      borderRadius={{ base: '32px 32px 0 0', md: '12px' }}
      gap={{ base: '24px', md: '16px' }}
    >
      <IconButton
        position="absolute"
        top="24px"
        right="24px"
        onClick={onCancelClick}
        aria-label="Close"
        boxSize="38px"
        bg="transparent"
        color="light_gray"
        _hover={{
          bg: 'transparent'
        }}
        _active={{
          bg: 'transparent'
        }}
        fontSize={{ base: '36px', md: '26px' }}
        icon={<i className="fa-solid fa-xmark" />}
      />

      {needChangeBrowser && (
        <Text fontWeight="600" fontSize="16px" lineHeight="38px">
          Browser change required
        </Text>
      )}

      <HStack spacing="16px">
        <Square p="10px" bg="#F4F4F4" borderRadius="7px">
          <Image boxSize="36px" fit="cover" src={needChangeBrowser ? google_chrome_logo : logo} />
        </Square>

        <VStack justifyContent="flex-start" alignItems="flex-start" spacing="4px">
          <Text fontWeight="600" fontSize="16px" lineHeight="17px">
            Linker
          </Text>

          <Text fontWeight="400" fontSize="14px" lineHeight="17px" mb="16px" color="gray.500">
            www.getlinker.app
          </Text>
        </VStack>
      </HStack>

      <Divider />

      {needChangeBrowser && (
        <Text fontWeight="400" fontSize="14px" color="gray.500">
          Your current browser is not compatible with this functionality. Open this page with
          {' '}
          <b>{os === 'iOS' ? 'Safari' : 'Chrome'}</b>
          {' '}
          to continue.
        </Text>
      )}

      {needChangeBrowser || !!deferredPromptEvent || isInstalled || (!isInstalled && !deferredPromptEvent && deviceType === 'desktop')
        ? (
          <Button
            size="sm"
            colorScheme="green"
            filter="drop-shadow(0px 1px 4px rgba(14, 87, 72, 0.25))"
            lineHeight="150%"
            fontSize={{ base: '16px', ml: '14px' }}
            onClick={handleClick}
            leftIcon={
              deferredPromptEvent
                ? <Image boxSize="20px" src={install_desktop} />
                : (
                  <div>
                    {needChangeBrowser
                      ? <i className="fa-solid fa-clone" />
                      : <i className="fa-solid fa-up-right-from-square" />}
                  </div>
                )
            }
            iconSpacing="10px"
          >
            {deferredPromptEvent ? 'Install' : `${needChangeBrowser ? 'Copy link' : 'Launch the App'}`}
          </Button>
        )
        : <InstructionList deviceType={deviceType} os={os} />}
    </Flex>
  );
};

export default SuggestInstallApp;
