import { Reminder, ReminderOptions } from 'types/types';
import { requestInstance, EditType, GetItemsType } from './api';

export const reminderAPI = {
  async getRemindersList(page: number) {
    const res = await requestInstance().get<GetItemsType<Array<Reminder>>>(`v1/bookmark/reminder/get?page=${page}`);

    return res.data;
  },
  async createReminder(bookmarkId: number | string, reminderOptions: ReminderOptions) {
    const res = await requestInstance().post<GetItemsType<Reminder>>(`v1/bookmark/reminder/${bookmarkId}`, reminderOptions);

    return res.data;
  },
  async updateReminder(reminderId: number | string, reminderOptions: ReminderOptions) {
    const res = await requestInstance().patch<GetItemsType<Reminder>>(`v1/bookmark/reminder/${reminderId}`, reminderOptions);

    return res.data;
  },
  async deleteReminder(reminderId: number | string) {
    const res = await requestInstance().delete<EditType>(`v1/bookmark/reminder/${reminderId}`);

    return res.data;
  }
};
