import React from 'react';

const CommandIcon = ({ size = 12 }: { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d={`M2.31421 0.0343332C1.17151 0.207863 0.23679
       1.11069 0.0359809 2.23394C-0.0046591 2.47078
        -0.0118309 2.91633 0.0240279 3.13207C0.165073
         3.98799 0.717298 4.7337 1.46794 5.08545C1.91976
          5.29416 2.29509 5.36685 3.05529 5.38327L3.63382
           5.39499V6V6.60501L3.05529 6.61673C2.30226 6.63315
            1.91498 6.70584 1.46794 6.91455C0.822484 7.21705
             0.31807 7.81268 0.107699 8.51852C0.0240279
              8.80696 0.00012207 8.97345 0.00012207
               9.28534C0.00012207 10.3054 0.552348 
               11.2106 1.46555 11.6866C2.79711 12.3807 
               4.49682 11.8742 5.15662 10.5845C5.36699 
               10.1764 5.47218 9.6535 5.4913 8.92655L5.50564 
               8.41534H6.0005H6.49535L6.50969 8.92421C6.5336 
               9.82 6.67942 10.3781 7.01171 10.8659C7.41094 
               11.4498 8.08748 11.8578 8.8453 11.9704C10.3155 
               12.1861 11.7092 11.2012 11.965 9.76606C12.0057 
               9.52922 12.0128 9.08367 11.977 8.86793C11.8359 
               8.01201 11.2837 7.2663 10.5331 6.91455C10.086
                6.70584 9.69874 6.63315 8.94809 6.61673L8.36718 
                6.60501V6V5.39499L8.94809 5.38327C9.38079 
                5.37389 9.58638 5.35747 9.76567 5.32464C10.3729 
                5.20739 10.8438 4.97524 11.2168 4.60707C11.6136
                 4.21546 11.8837 3.68783 11.977 3.13207C12.0128
                  2.91633 12.0057 2.47078 11.965 2.23394C11.7092 
                  0.798801 10.3155 -0.186096 8.8453 0.0296431C8.08748
                   0.142202 7.41094 0.550232 7.01171 1.13414C6.65791
                    1.65003 6.50252 2.30663 6.50252 3.27043V3.58465H6.0005H5.49847V3.27043C5.49847
                     2.30663 5.34309 1.65003 4.98928 1.13414C4.42988 0.315732 3.35651
                      -0.125127 2.31421 0.0343332ZM3.04812 1.66645C3.30152
                       1.72976 3.56927 1.93378 3.68163 2.15421C3.80355 
                       2.3934 3.83223 2.57865 3.84419 3.19539L3.85375
                        3.75349L3.25849 3.74177C2.61542 3.7277 2.41462 
                        3.69956 2.1899 3.59638C1.97714 3.49789 1.79067 
                        3.26808 1.707 3.00075C1.66636 2.86943 1.66158
                         2.56927 1.69744 2.43326C1.85522 1.85874 2.45047 1.51871 3.04812
                          1.66645ZM9.52661 1.66645C9.8852 1.75556 10.2031 2.06744 10.3036 
                          2.43326C10.3394 2.56927 10.3346 2.86943 10.294 3.00075C10.2103
                           3.26808 10.0239 3.49789 9.81109 3.59638C9.58638 3.69956 9.38557
                            3.7277 8.7425 3.74177L8.14724 3.75349L8.15681 3.19539C8.17115
                             2.4145 8.24048 2.15655 8.50822 1.91267C8.77836 1.6641 9.15368 
                             1.575 9.52661 1.66645ZM6.90892 6V6.8911H6.0005H5.09207V6V5.1089H6.0005H6.90892V6ZM3.84419
                              8.80696C3.82984 9.5855 3.76052 9.84345 3.49277 10.0873C2.9023 10.629
                               1.9102 10.3429 1.69744 9.56674C1.6831 9.5128 1.67114 9.38617 1.67114 
                               9.28768C1.67353 9.00394 1.75481 8.79523 1.94845 8.58418C2.07993
                                8.44114 2.2401 8.36141 2.49829 8.31217C2.76603 8.26058 2.79711
                                 8.25823 3.35412 8.25589L3.85375 8.2512L3.84419 8.80696ZM9.50749
                                  8.30748C9.91867 8.39189 10.1745 8.61467 10.294 8.99925C10.3346
                                   9.13057 10.3394 9.43073 10.3036 9.56674C10.0908 10.3429 9.0987
                                    10.629 8.50822 10.0873C8.24048 9.84345 8.17115 9.5855 8.15681 
                                    8.80461L8.14724 8.24651L8.7425 8.25823C9.13217 8.26761 9.39513
                                     8.28402 9.50749 8.30748Z`}
      fill="white"
    />
  </svg>
);

export default CommandIcon;
