import { Flex, Spacer, Stack, Text } from '@chakra-ui/react';
import { ButtonGroupPopup } from 'components/common/buttons/ButtonGroupPopup';
import FormInput from 'components/common/inputs/FormInput';
import { useFormik } from 'formik';
import { ReactNode, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

type PropsType = {
  fieldValue: string;
  errors: null | { [key: string]: string };
  title: string;
  onClose: () => void;
  setErrors: (value: PropsType['errors']) => void;
  handleSubmit: (value: string) => void;
  placeholder?: string;
  loading?: boolean;
  children?: ReactNode;
  titleIcon?: JSX.Element;
  hotKeys?: JSX.Element;
};

const OneFieldModal = ({
  fieldValue, title, placeholder, errors,
  loading, titleIcon, children, hotKeys,
  setErrors, handleSubmit, onClose }: PropsType): JSX.Element => {
  const formik = useFormik({
    initialValues: {
      name: fieldValue
    },
    onSubmit: (value) => {
      handleSubmit(value.name);
    }
  });

  useEffect(() => () => {
    setErrors(null);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errors) setErrors(null);
    formik.handleChange(e);
  };

  useHotkeys(
    'enter',
    (e) => {
      formik.handleSubmit();
      e.preventDefault();
    },
    []
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex direction="column">
        <Stack spacing="24px">
          <Flex>
            <Flex alignItems="center" gap="12px">
              {titleIcon}
              <Text fontWeight="600" fontSize="16px" lineHeight="19px">
                {title}
              </Text>
            </Flex>

            <Spacer />
            {hotKeys}
          </Flex>

          <FormInput
            name="name"
            placeholder={placeholder || fieldValue}
            handleChange={handleChange}
            value={formik.values.name}
            autoComplete="off"
            error={errors?.name ?? errors?.title}
            height="40px"
          />
        </Stack>

        {children}

        <ButtonGroupPopup isLoading={loading} label="Save" handleCancelClick={onClose} />
      </Flex>
    </form>
  );
};

export default OneFieldModal;
