import { OutputData } from '@editorjs/editorjs';
import { WikiDocumentType } from 'types/wiki';
import { checkParam, EditType, GetItemsType, requestInstance, ResponseType } from '../api';

export const documentAPI = {
  async getDocuments(
    folderId: string | null,
    pageNumber: number,
    keyword: string | null,
    teamId: number | null
  ) {
    const params = {
      page: pageNumber,
      query: checkParam(keyword),
      wiki_folder_id: checkParam(folderId),
      team_id: teamId
    };

    const res = await requestInstance().get<GetItemsType<Array<WikiDocumentType>>>('v1/wiki', { params });

    return res.data;
  },

  async addDocument(
    title: string,
    teamId: number | null,
    wiki_folder_id: string | null,
    content: OutputData | null
  ) {
    const res = await requestInstance().post<ResponseType<WikiDocumentType>>(
      'v1/wiki',
      { title, wiki_folder_id, team_id: teamId, content }
    );

    return res.data;
  },

  async deleteDocument(documentId: number) {
    const res = await requestInstance().delete<EditType>(`v1/wiki/${documentId}`);

    return res.data;
  },

  async renameDocument(documentId: number, title: string, content: OutputData | null, folderId: number | null) {
    const res = await requestInstance().patch<ResponseType<WikiDocumentType>>(
      `v1/wiki/${documentId}`,
      { title, wiki_folder_id: folderId, ...(content ? { content } : {}) }
    );

    return res.data;
  },

  async setDocumentContent(id: number, content: OutputData) {
    const res = await requestInstance().patch<ResponseType<OutputData>>('v1/wiki', content);

    return res.data;
  },

  async getDocument(id: number) {
    const res = await requestInstance().get<ResponseType<WikiDocumentType>>(`v1/wiki/${id}`);

    return res.data;
  }
};
