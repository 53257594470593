import { PlanPriceType, PlanType, OauthType } from 'types/types';
import { requestInstance, ResponseType } from './api';

export const authAPI = {
  async login(email: string, password: string) {
    const res = await requestInstance(false).post('v1/auth/credentials/sign-in', {
      email,
      password
    });

    return res.data;
  },
  async resetPasswordWithEmail(email: string) {
    const res = await requestInstance(false).post('v1/auth/password/email', {
      email
    });

    return res.data;
  },
  async confirmPassword(email: string, password: string, password_confirmation: string, token: string) {
    const res = await requestInstance(false).post('v1/auth/password/reset', {
      email,
      password,
      password_confirmation,
      token
    });

    return res.data;
  },

  async signup(email: string, name: string, password: string, stripe_plan?: string) {
    const res = await requestInstance(false).post('v1/auth/credentials/sign-up', {
      email, name, password, password_confirmation: password, stripe_plan
    });

    return res.data;
  },

  async getPlan(paidPlan: string, duration = 'year') {
    const res = await requestInstance(false)
      .get<ResponseType<PlanType>>(`v1/subscription/plans/${paidPlan}?filter_prices_by_duration=${duration}`);

    return res.data;
  },

  async getPlanPrice(priceSlug: string) {
    const res = await requestInstance(false)
      .get<ResponseType<PlanPriceType>>(`v1/subscription/prices/${priceSlug}`);

    return res.data;
  },

  async oauthLogin(data: OauthType) {
    const res = await requestInstance().post(
      'oauth/token',
      {
        ...data,
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
        registration_source: 'web'
      }
    );

    return res.data;
  },

  async refreshToken(refresh_token: string) {
    const res = await requestInstance().post(
      'oauth/token',
      {
        refresh_token,
        grant_type: 'refresh_token',
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET
      }
    );

    return res.data;
  }

};
