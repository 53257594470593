import { Button, Flex, Square, Text, useDisclosure } from '@chakra-ui/react';
import { workspaceAPI } from 'api/workspaceApi';
import cn from 'classnames';
import Popup from 'components/common/popup/Popup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteWorkspace, deleteWorkspaceImage, updateWorkspace } from 'store/workspace-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import { WorkspaceType } from 'types/types';
import { Delete } from '../Delete';
import EditManagement from './EditManagement';
import style from '../Popup.module.scss';

type EditWorkspaceProps = {
  onClose: () => void;
  workspace: WorkspaceType;
};

export const EditWorkspace = ({ onClose, workspace }: EditWorkspaceProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const workspacesList = useAppSelector((state) => state.workspaces.workspacesList);

  const [targetWorkspace, setTargetWorkspace] = useState(workspace);
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();
  const { workspaceId } = params;

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

  useEffect(() => {
    workspaceAPI.getWorkspace(workspace.id).then((response) => {
      setTargetWorkspace(response.data);
    });
  }, []);

  const handleDelete = () => {
    setIsLoading(true);

    dispatch(deleteWorkspace(targetWorkspace.id)).then(() => {
      if (String(targetWorkspace.id) === String(workspaceId)) {
        const currentWorkspace = workspacesList.find((item) => item.id !== targetWorkspace.id);
        navigate(`/workspace/${currentWorkspace?.id}`, { replace: true });
      }
      onDeleteClose();
      onClose();
    })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <EditManagement
      item={targetWorkspace}
      icon={(
        <Square background="ghost_blue" borderRadius="4px" size="28px">
          <i className={cn('fa-solid fa-gear', style.workspace_icon)} />
        </Square>
      )}
      setTargetItem={setTargetWorkspace}
      onClose={onClose}
      title="Workspace Settings"
      updateAction={updateWorkspace}
      deleteImageAction={deleteWorkspaceImage}
    >
      <>
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          gap="16px"
          border="1px solid"
          borderColor="gray.100"
          borderRadius="4px"
          p={{ base: '16px', md: '16px 24px' }}
        >
          <Text fontWeight="400" fontSize="12px" lineHeight="170%" color="light_gray" mr="37px">
            The workspace will no longer be available, and all data in the workspace will be permanently deleted!
          </Text>
          <Button onClick={onDeleteOpen} colorScheme="red" variant="delete" size="sm" px="24px">
            Delete Workspace
          </Button>
        </Flex>

        <Popup isOpen={isDeleteOpen} onClose={onDeleteClose}>
          <Delete
            isLoading={isLoading}
            title="Delete Workspace"
            description="Are you sure, you want to delete this Workspace?"
            handleDelete={handleDelete}
            onClose={onDeleteClose}
            helpText="Remember: You can not delete your last Workspace!"
          />
        </Popup>
      </>
    </EditManagement>
  );
};
