import { Flex, Square, Stack, Text } from '@chakra-ui/react';
import { ButtonGroupPopup } from 'components/common/buttons/ButtonGroupPopup';
import { MouseEventHandler, ReactNode, useEffect } from 'react';

type PropsType = {
  title: string;
  description: ReactNode;
  handleClick: (() => void) | MouseEventHandler<HTMLButtonElement>;
  onClose: () => void;
  icon: JSX.Element;
  iconBg?: string;
  iconColor?: string;
  buttonsColorScheme?: string;
  isLoading?: boolean;
  submitButtonLabel?: string;
};
export const DialogModal = ({
  title,
  handleClick,
  onClose,
  description,
  isLoading, buttonsColorScheme = 'green',
  icon, iconBg = 'green.50', iconColor = 'green.500', submitButtonLabel = 'Confirm'
}: PropsType): JSX.Element => {
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleClick(event);
      }
    };
    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Flex direction="column">
      <Stack spacing="24px">
        <Flex>
          <Flex alignItems="center">
            <Square background={iconBg} borderRadius="4px" size="28px" color={iconColor}>
              {icon}
            </Square>
            <Text mx="12px" fontWeight="600" fontSize="16px" lineHeight="19px">
              {title}
            </Text>
          </Flex>
        </Flex>
      </Stack>
      <Text mt="24px" fontWeight="400" fontSize="14px" lineHeight="150%" color="gray.500">
        {description}
      </Text>

      <ButtonGroupPopup
        mt="16px"
        isLoading={isLoading}
        label={submitButtonLabel}
        handleClick={handleClick}
        colorScheme={buttonsColorScheme}
        handleCancelClick={onClose}
      />
    </Flex>
  );
};
