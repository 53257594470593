import { Flex, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';

type PropsType = {
  tags: string[];
  hasCloseButton?: boolean;
  handleClick?:(tag: string) => void;
  borderColor?: string;
  bg?: string;
  bgHover?: string;
  color?: string;
  colorHover?: string;
  maxH?:string;
  flexDirection?: 'row' | 'column';
}

const TagsList = ({
  tags, hasCloseButton, handleClick,
  borderColor, bg = 'white.50',
  bgHover, color = 'black', colorHover, maxH = '24px', flexDirection = 'row'
}: PropsType): JSX.Element => {
  const onClick = (tag: string, e:any) => {
    e.stopPropagation();
    e.preventDefault();
    if (handleClick) handleClick(tag);
  };

  return (
    <Flex gap="8px" flexWrap="wrap" flexDirection={flexDirection}>
      {tags?.map((tag) => (
        <Tag
          key={tag}
          p="4px 8px"
          maxH={maxH}
          minH={maxH}
          borderRadius="4px"
          border="1px solid"
          borderColor={borderColor || bg}
          color={color}
          bg={bg}
          _hover={{
            transition: '150ms',
            color: colorHover || color,
            bg: bgHover || bg,
            borderColor: bgHover || borderColor
          }}
          cursor={hasCloseButton ? 'initial' : 'pointer'}
          onClick={(e) => !hasCloseButton && onClick(tag, e)}
        >
          <TagLabel>
            #
            {tag}
          </TagLabel>

          {hasCloseButton && (
            <TagCloseButton
              color={color}
              opacity="1"
              fontSize="15px"
              boxSize="14px"
              onClick={(e) => onClick(tag, e)}
            />
          )}
        </Tag>
      ))}
    </Flex>
  );
};

export default TagsList;
