import { Box, Button, Divider, Flex, HStack, Image, Spacer, Text, VStack } from '@chakra-ui/react';
import { workspaceAPI } from 'api/workspaceApi';
import { BlurLoading } from 'components/common/BlurLoading';
import { differenceInDays } from 'date-fns';
import { PlansListType } from 'plans';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getSubscriptionsPlansList, upgradePlan } from 'store/subscription-reducer';
import logo from 'assets/image/logo.svg';
import ae from 'assets/image/plans/ae.svg';
import discover from 'assets/image/plans/discover.svg';
import jcb from 'assets/image/plans/JCB.svg';
import master from 'assets/image/plans/MasterCard.svg';
import stripe from 'assets/image/plans/stripe.svg';
import visa from 'assets/image/plans/visa.svg';
import stripe_logo from 'assets/image/stripe_logo.svg';
import PlanBenefitsList from './PlanBenefitsList/PlanBenefitsListContainer';
import PlanCard from './PlanCard';
import PlanLimitItem from './PlanLimitItem';
import { SelectPlanPeriod } from './SelectPlanPeriod';

type PropsType = {
  onClose?: () => void,
  isInPopup?: boolean;
}

const UpgradePlan = ({ onClose, isInPopup = true }: PropsType): JSX.Element => {
  const dispatch = useAppDispatch();
  const { currentEnvironment } = useAppSelector((state) => state.profile);
  const { subscriptionsPlanList, currentPlan } = useAppSelector((state) => state.subscription);
  const { isLoading } = useAppSelector((state) => state.popup);
  const { upgradePlanMessage } = useAppSelector((state) => state.popup.upgrade);

  const [selectedPlan, setSelectedPlan] = useState<keyof PlansListType | null>(currentPlan?.subscription_plan.plan.slug || null);
  const [itemCounts, setItemCounts] = useState({
    foldersCount: 0,
    bookmarksCount: 0,
    workspaceCount: 0
  });
  const [period, setPeriod] = useState<string>(currentPlan?.subscription_plan.interval ?? 'month');
  const [trialDays, setTrialDays] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getSubscriptionsPlansList());

    const counts = {
      foldersCount: 0,
      bookmarksCount: 0,
      workspaceCount: 0
    };

    workspaceAPI.getWorkspacesList(currentEnvironment.type, currentEnvironment.team_id)
      .then((response) => {
        response.data.forEach((workspace) => {
          counts.bookmarksCount += workspace.bookmarks_count;
          counts.foldersCount += workspace.folders_count;
          counts.workspaceCount += 1;
        });

        setItemCounts(counts);
      });

    if (!!currentPlan && currentPlan.subscription.trial_ends_at && currentPlan.subscription.stripe_status === 'trialing') {
      const result = differenceInDays(new Date(currentPlan.subscription.trial_ends_at), new Date());
      setTrialDays(result + 1);
    }
  }, []);

  const handleUpgradePlan = () => {
    dispatch(upgradePlan(selectedPlan ?? 'basic', period))
      .then(() => {
        if (onClose) onClose();
      });
  };

  return (
    <Flex
      w={{ base: isInPopup ? '100vw' : '100%', md: '100%', lg: '100%' }}
      minW={isInPopup ? { base: '100%', md: '536px', lg: '941px' } : 'unset'}
      maxW={isInPopup ? { base: '100%', md: '576px', lg: '981px' } : 'unset'}
      flexDirection={{ base: 'column', lg: 'row' }}
      position="relative"
      h="100%"
    >

      {((!currentPlan && !selectedPlan) || !subscriptionsPlanList.length) && (
        <BlurLoading isTransparent={!isInPopup} />
      )}

      <VStack
        minW={isInPopup ? 'unset' : '220px'}
        maxW={isInPopup ? 'unset' : '300px'}
        w="100%"
        spacing="16px"
        p={isInPopup ? { base: '50px 0 32px', md: '82px 48px', lg: '32px 48px' } : '0px'}
        bg={isInPopup ? { base: 'white.50', md: 'gradient.250' } : 'white.50'}
      >
        {isInPopup ? (
          <VStack
            w="100%"
            spacing="16px"
            py="16px"
            bg="white.50"
            border="1px solid"
            borderColor="gray.100"
            borderRadius="8px"
            boxShadow="0px 0px 16px rgba(199, 199, 199, 0.25)"
          >
            <HStack alignItems="flex-start" justifyContent="center" px="22px" spacing="14px" w="100%">
              <Image
                mt="5px"
                src={logo}
                alt="linker"
                boxSize={{ base: '20px', lg: '28px' }}
                filter="drop-shadow(0px 0px 4px rgba(75, 75, 75, 0.25))"
              />
              <Text fontWeight="700" fontSize={{ base: '20px', lg: '22px' }}>
                {upgradePlanMessage || 'Upgrade Your Plan'}
              </Text>
            </HStack>

            <HStack justifyContent="space-around" w="80%" maxW="100%" px="22px" gap={{ base: '4px', md: '40px' }}>
              <PlanLimitItem
                itemName="Workspaces"
                current={itemCounts.workspaceCount}
                limit={currentPlan?.subscription_plan.plan.workspaces_count}
              />
              <PlanLimitItem
                itemName="Folders"
                current={itemCounts.foldersCount}
                limit={currentPlan?.subscription_plan.plan.folders_count}
              />
              <PlanLimitItem
                itemName="Bookmarks"
                current={itemCounts.bookmarksCount}
                limit={currentPlan?.subscription_plan.plan.bookmarks_count}
              />
            </HStack>
          </VStack>
        )
          : (
            <Text display={{ base: 'none', lg: 'unset' }} w="100%" textAlign="left" fontWeight="600" fontSize="14px">
              Features
            </Text>
          )}

        <Box w="100%" h="100%" display={{ base: 'none', lg: 'block' }}>
          <PlanBenefitsList
            isInPopup={isInPopup}
            planType={selectedPlan ?? 'basic'}
          />
        </Box>
      </VStack>

      <Flex
        flexDirection="column"
        alignItems="flex-start"
        p={isInPopup ? { base: '0', md: '32px 48px 48px' } : { base: '0', lg: '0 32px ' }}
        minW={{ base: '100%', lg: '406px' }}
        maxW={isInPopup ? { base: '100%' } : { base: '100%', lg: '420px' }}
        width="100%"
      >
        <Text
          mb={isInPopup ? { base: '16px', md: '31px' } : '16px'}
          fontWeight={isInPopup ? '700' : '600'}
          fontSize={isInPopup ? '24px' : '14px'}
        >
          Choose a Plan
        </Text>

        <Box pb={isInPopup ? { base: '16px', md: '24px' } : '16px'}>
          <SelectPlanPeriod
            setPeriod={setPeriod}
            period={period}
          />
        </Box>

        <VStack w="100%" spacing="16px" mb="24px">

          {subscriptionsPlanList.length > 0 && subscriptionsPlanList.map((plan) => plan.prices?.map((per) => (
            (per.interval === period || !per.interval)
            && (
              <PlanCard
                handleSelect={() => setSelectedPlan(plan.slug)}
                checked={selectedPlan === plan.slug}
                isCurrentPlan={
                  currentPlan?.subscription_plan.plan.slug === plan.slug
                  && per.interval === currentPlan?.subscription_plan.interval
                }
                plan={plan}
                price={per.amount}
                explanation={
                  !currentPlan?.subscription.trial_ends_at || plan.slug === 'basic'
                    ? `${per.interval
                      ? '* 30 days Fee Trial - No credit card required'
                      : '* You can use this application for free forever'}`
                    : `${plan.slug === 'pro'
                      ? 'Switch to “Pro” plan and use more features'
                      : 'Switch to “Team” plan and enjoy Linker with your team'}`
                }
                trialDays={trialDays}
                isInPopup={isInPopup}
              />
            )
          )))}
        </VStack>

        {isInPopup && <Spacer />}

        <Button
          isDisabled={
            currentPlan?.subscription_plan.plan.slug === selectedPlan
            && (currentPlan?.subscription_plan.interval === period || !currentPlan?.subscription_plan.interval)
          }
          isLoading={isLoading}
          onClick={handleUpgradePlan}
          fontSize={{ base: '16px', md: '14px' }}
          colorScheme="green"
          w="100%"
          h={{ base: '48px', lg: '40px' }}
        >
          {currentPlan?.subscription.trial_ends_at || selectedPlan === 'basic' ? 'Switch plan' : 'Start free trial'}
        </Button>

        <Divider w="42px" my="16px" mx="auto" />

        {window.innerWidth < 576
          ? (
            <Flex w="100%" justifyContent="center">
              <Image opacity="0.25" src={stripe_logo} />
            </Flex>
          )
          : (
            <Flex h="39px" w="100%" justifyContent="space-between" alignItems="center">
              <Image src={stripe} />
              <Divider h="28px" orientation="vertical" />
              <Image src={visa} />
              <Image src={master} />
              <Image src={ae} />
              <Image src={discover} />
              <Image src={jcb} />
            </Flex>
          )}
      </Flex>
    </Flex>
  );
};

export default UpgradePlan;
