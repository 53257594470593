import {
  Action, combineReducers, configureStore, ThunkAction
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { appReducer } from './app-reducer';
import authReducer from './auth-reducer';
import { bookmarkReducer } from './bookmark-reducer';
import collectionsReducer from './collections-reducer';
import { folderReducer } from './folder-reducer';
import { integrationsReducer } from './integrations-reducer';
import notificationsReducer from './notifications-reducer';
import { popupReducer } from './popup-reducer';
import profileReducer from './profile-reducer';
import { reminderReducer } from './reminder-reducer';
import { sessionsReducer } from './sessions-reducer';
import { shareReducer } from './share-reducer';
import snippetsReducer from './snippets-reducer';
import subscriptionReducer from './subscription-reducer';
import { teamReducer } from './team-reducer';
import { trackerReducer } from './tracker-reducer';
import { documentReducer } from './wiki/document-reducer';
import { wikiFolderReducer } from './wiki/folder-reducer';
import { wikiTrashReducer } from './wiki/trash-reducer';
import workspaceReducer from './workspace-reducer';

const rootReducer = combineReducers({
  profile: profileReducer,
  auth: authReducer,
  workspaces: workspaceReducer,
  folders: folderReducer,
  bookmarks: bookmarkReducer,
  app: appReducer,
  popup: popupReducer,
  share: shareReducer,
  reminders: reminderReducer,
  tracker: trackerReducer,
  team: teamReducer,
  integrations: integrationsReducer,
  subscription: subscriptionReducer,
  notifications: notificationsReducer,
  collections: collectionsReducer,
  snippets: snippetsReducer,
  sessions: sessionsReducer,
  wikiFolders: wikiFolderReducer,
  wikiDocuments: documentReducer,
  wikiTrash: wikiTrashReducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
  devTools: true
});

export type AppStateType = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<AppStateType> = useSelector;

export type DispatchResponse = { [key: string]: string } | string

export type AppThunkType<R = Promise<void | DispatchResponse | any>> = ThunkAction<
  R,
  AppStateType,
  undefined,
  Action
>

export default store;
