import { folderAPI } from 'api/folderApi';
import { logOut } from 'store/auth-reducer';
import { errorHandler } from 'utils/errorHandler';
import { FolderType, InferActionsTypes, RenameFolderType } from '../types/types';
import { addAfterPinned } from '../utils/addAfterPinned';
import { deleteItem } from '../utils/delete';
import { pin } from '../utils/pin';
import { showAlert } from './app-reducer';
import { getOneWorkspace } from './workspace-reducer';
import { AppThunkType } from './store';

const initialState = {
  data: [] as Array<FolderType>,
  allFolders: [] as Array<FolderType>,
  isFetching: false
};

export const actionsFolders = {
  setFolderList: (data: Array<FolderType>) => ({ type: 'SET_LIST_FOLDERS', data }) as const,
  setAllFolderList: (data: Array<FolderType>) => ({ type: 'SET_ALL_FOLDERS_LIST', data }) as const,
  updateFolders: (newFoldersList: Array<FolderType>) => ({ type: 'EDIT_FOLDER', newFoldersList }) as const,
  setIsFetching: (isFetching: boolean) => ({ type: 'SET_IS_FETCHING_FOLDERS', isFetching }) as const,
  cleanUp: () => ({ type: 'CLEAN_UP_FOLDER_REDUCER' }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsFolders>

export const folderReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_LIST_FOLDERS':
      return {
        ...state,
        data: action.data
      };
    case 'SET_ALL_FOLDERS_LIST':
      return {
        ...state,
        allFolders: action.data
      };

    case 'EDIT_FOLDER':
      return {
        ...state,
        data: action.newFoldersList
      };

    case 'SET_IS_FETCHING_FOLDERS':
      return {
        ...state,
        isFetching: action.isFetching
      };
    case 'CLEAN_UP_FOLDER_REDUCER':
      return {
        ...state,
        data: [],
        isFetching: false
      };

    default:
      return state;
  }
};

export const getFolderList = (
  workspaceId: string,
  folderId?: string | null,
  pageNumber?: number | null,
  filter?: string | null,
  refresh = true
): AppThunkType => async (dispatch) => {
  if (refresh) {
    dispatch(actionsFolders.setIsFetching(true));
  }
  try {
    const response = await folderAPI.getFolderList(workspaceId, folderId ?? null, pageNumber ?? null, filter ?? null);

    dispatch(actionsFolders.setFolderList(response.data));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);
    Promise.reject(error);
  } finally {
    if (refresh) {
      dispatch(actionsFolders.setIsFetching(false));
    }
  }
};

export const getAllFolders = (): AppThunkType => async (dispatch, getState) => {
  const { currentWorkspaceId } = getState().workspaces;
  try {
    const response = await folderAPI.getAllFoldersList(currentWorkspaceId);

    dispatch(actionsFolders.setAllFolderList(response.data));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const addFolderToList = (data: FolderType): AppThunkType => async (dispatch, getState) => {
  const folders = getState().folders.data;
  const { mainSharedFolder } = getState().share;

  const folderIds = folders.map((bookmark) => bookmark.id);

  if (!folderIds.includes(data.id)) {
    dispatch(actionsFolders.updateFolders(addAfterPinned([data, ...folders], data)));
    if (!mainSharedFolder) {
      dispatch(getAllFolders());
      dispatch(getOneWorkspace(data.workspace_id));
    }
  }
};

export const addNewFolder = (
  name: string,
  parentId?: string | null,
  workspaceId?: number,
  updateList?: boolean
): AppThunkType => async (dispatch, getState) => {
  const { currentWorkspaceId } = getState().workspaces;
  try {
    const response = await folderAPI.setNewFolder(workspaceId ?? +currentWorkspaceId, name, parentId);
    if (updateList || (response.data.workspace_id.toString() === currentWorkspaceId.toString())) {
      dispatch(addFolderToList(response.data));
    } else {
      dispatch(getOneWorkspace(workspaceId || currentWorkspaceId));
    }

    dispatch(showAlert('Folder successfully added', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    if (error.response.status === 401) dispatch(logOut());

    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const pinFolder = (folder: FolderType, isPin: boolean): AppThunkType => async (dispatch, getState) => {
  const folders = getState().folders.data;
  let newFoldersList = [] as Array<FolderType>;

  try {
    let response;

    if (isPin) {
      response = await folderAPI.pinFolder(folder.id);
      newFoldersList = pin(folders, isPin, folder);
    } else {
      response = await folderAPI.unpinFolder(folder.id);
      newFoldersList = addAfterPinned(folders, folder, isPin);
    }

    dispatch(actionsFolders.updateFolders(newFoldersList));
    dispatch(showAlert(response.data.message, response.success));
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export const deleteFolder = (folderId: number): AppThunkType => async (dispatch, getState) => {
  const folders = getState().folders.data;
  const { currentWorkspaceId } = getState().workspaces;

  try {
    const response = await folderAPI.deleteFolder(folderId);
    dispatch(actionsFolders.updateFolders(deleteItem(folders, folderId)));
    dispatch(getOneWorkspace(currentWorkspaceId));
    dispatch(showAlert(response.data.message, response.success));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const updateFolder = (updatedFolder: RenameFolderType): AppThunkType => async (dispatch, getState) => {
  const folders = getState().folders.data;

  const targetFolder = folders.find((folder) => folder.id === updatedFolder.id);

  if (targetFolder
    && (targetFolder.name !== updatedFolder.name || Number(targetFolder.bookmarks_count) !== Number(updatedFolder.bookmarks_count))) {
    const newFolderList = folders.map((fold) => (Number(fold.id) === Number(updatedFolder.id)
      ? {
        ...fold,
        name: updatedFolder.name,
        bookmarks_count: updatedFolder.bookmarks_count,
        updated_at: updatedFolder.updated_at
      }
      : fold));
    dispatch(actionsFolders.updateFolders(newFolderList));
  }
};

export const renameFolder = (
  folderId: number,
  newFolderName: string,
  parentId: number | string
): AppThunkType => async (dispatch) => {
  try {
    const response = await folderAPI.renameFolder(folderId, newFolderName, parentId);

    if (response.success) {
      dispatch(updateFolder(response.data));
      dispatch(showAlert('Folder successfully renamed', response.success));
    }

    return Promise.resolve();
  } catch (error: any) {
    if (error.response.status === 401) dispatch(logOut());

    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const moveFolder = (
  folder: FolderType,
  workspaceId: number,
  parentId: number | null
): AppThunkType => async (dispatch, getState) => {
  const foldersInState = getState().folders.data;

  try {
    const response = await folderAPI.moveFolder(folder.id, workspaceId, parentId || undefined);

    if (folder.parent_id !== parentId || folder.workspace_id !== workspaceId) {
      dispatch(actionsFolders.updateFolders(deleteItem(foldersInState, folder.id)));
    }
    dispatch(showAlert('Folder moved successfully', response.success));

    return Promise.resolve(true);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => {
      if (res.validationErrors) {
        dispatch(showAlert(Object.values(res.validationErrors), false));
      }
    });
  }
};

export const orderFolders = (
  firstIndex: number,
  secondIndex: number
): AppThunkType => async (dispatch, getState) => {
  const foldersInState = getState().folders.data;

  try {
    const fodlersClone = [...foldersInState];
    const replaceable = fodlersClone.splice(firstIndex, 1);
    fodlersClone.splice(secondIndex, 0, replaceable[0]);
    dispatch(actionsFolders.setFolderList(fodlersClone));
    await folderAPI.orderFolders(fodlersClone.map((item) => item.id));

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};
