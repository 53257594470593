import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  label?: string;
  error?: string;
};

const EditFormControlWrapper = ({ children, label, error }: Props) => (
  <FormControl isInvalid={!!error}>
    <FormLabel
      w="fit-content"
      fontWeight="400"
      fontSize={{ base: '14px', md: '12px' }}
      lineHeight="24px"
      textTransform="uppercase"
      color="gray.500"
      mb="8px"
    >
      {label}
    </FormLabel>
    {children}
    <FormErrorMessage>{error}</FormErrorMessage>
  </FormControl>
);

export default EditFormControlWrapper;
