import { OutputData } from '@editorjs/editorjs';
import { documentAPI } from 'api/wiki/wikiDocumentAPI';
import { showAlert } from 'store/app-reducer';
import { AppThunkType } from 'store/store';
import { InferActionsTypes, PaginationType } from 'types/types';
import { WikiDocumentType } from 'types/wiki';
import { deleteItem } from 'utils/delete';
import { errorHandler } from 'utils/errorHandler';
import { actionsWikiFolders } from './folder-reducer';

const initialState = {
  data: [] as Array<WikiDocumentType>,
  pagination: {
    total: 0,
    count: 0,
    per_page: 20,
    current_page: 1,
    total_pages: 1
  } as PaginationType,
  isFetching: false
};

export const actionsWikiDocuments = {
  setDocuments: (data: Array<WikiDocumentType>, pagination?: PaginationType) => ({
    type: 'SET_WIKI_DOCUMENT_LIST', data, pagination
  }) as const,
  setNewPortionDocuments: (data: Array<WikiDocumentType>, pagination: PaginationType) => ({
    type: 'SET_NEW_PORTION_WIKI_DOCUMENTS_TO_LIST', data, pagination
  }) as const,
  setNewDocument: (document: WikiDocumentType) => ({
    type: 'SET_NEW_WIKI_DOCUMENT_TO_LIST', document
  }) as const,
  setIsFetching: (isFetching: boolean) => ({ type: 'SET_IS_FETCHING_WIKI_DOCUMENTS', isFetching }) as const,
  cleanUp: () => ({ type: 'CLEAN_UP_WIKI_DOCUMENTS_REDUCER' }) as const
};

export type InitialStateType = typeof initialState
export type ActionType = InferActionsTypes<typeof actionsWikiDocuments>

export const documentReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_WIKI_DOCUMENT_LIST':
      return {
        ...state,
        data: action.data,
        pagination: action.pagination || state.pagination
      };

    case 'SET_NEW_PORTION_WIKI_DOCUMENTS_TO_LIST':
      return {
        ...state,
        data: [...state.data, ...action.data],
        pagination: action.pagination
      };

    case 'SET_NEW_WIKI_DOCUMENT_TO_LIST':
      if (state.pagination.total_pages === 1) {
        return {
          ...state,
          data: [action.document, ...state.data],
          pagination: {
            ...state.pagination, total: state.pagination.total + 1
          }
        };
      }

      return {
        ...state,
        data: [action.document, ...state.data.slice(0, -1)]
      };

    case 'SET_IS_FETCHING_WIKI_DOCUMENTS':
      return {
        ...state,
        isFetching: action.isFetching
      };

    case 'CLEAN_UP_WIKI_DOCUMENTS_REDUCER':
      return {
        ...state,
        data: [],
        pagination: {
          total: 0,
          count: 0,
          per_page: 20,
          current_page: 1,
          total_pages: 1
        },
        isFetching: false
      };
    default:
      return state;
  }
};

export const getDocuments = (
  pageNumber: number,
  teamId: number | null,
  folderId?: string | null,
  keyword?: string | null
): AppThunkType => async (dispatch) => {
  dispatch(actionsWikiDocuments.setIsFetching(true));

  try {
    const response = await documentAPI.getDocuments(folderId ?? null, pageNumber ?? 1, keyword ?? null, teamId);

    if (response.pagination.current_page === 1) {
      dispatch(actionsWikiDocuments.setDocuments(response.data, response.pagination));
    } else {
      dispatch(actionsWikiDocuments.setNewPortionDocuments(response.data, response.pagination));
    }

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);
    Promise.reject(error);
  } finally {
    dispatch(actionsWikiDocuments.setIsFetching(false));
  }
};

export const addNewDocument = (
  title: string,
  teamId: number | null,
  folderId?: string
): AppThunkType => async (dispatch, getState) => {
  const folderList = getState().wikiFolders.data;

  try {
    const response = await documentAPI.addDocument(title, teamId, folderId ?? null, null);

    dispatch(actionsWikiDocuments.setNewDocument(response.data));

    if (folderId) {
      const newFolderList = folderList.map((folder) => (Number(folder.id) === Number(folderId) ? {
        ...folder,
        wikis: [{
          id: response.data.id,
          title: response.data.title
        }, ...folder.wikis ?? []]
      }
        : folder));
      dispatch(actionsWikiFolders.setFolderList(newFolderList));
    }

    dispatch(showAlert('Document successfully created', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const deleteDocument = (documentId: number, folderId?: number): AppThunkType => async (dispatch, getState) => {
  const documents = getState().wikiDocuments.data;
  const foldersList = getState().wikiFolders.data;
  const { pagination } = getState().wikiDocuments;

  try {
    const response = await documentAPI.deleteDocument(documentId);
    dispatch(actionsWikiDocuments.setDocuments(deleteItem(documents, documentId), { ...pagination, total: pagination.total - 1 }));

    if (folderId) {
      const newFolderList = foldersList.map((folder) => (folder.id === folderId ? {
        ...folder,
        wikis: folder.wikis.filter((wiki) => wiki.id !== documentId)
      }
        : folder));
      dispatch(actionsWikiFolders.setFolderList(newFolderList));
    }

    dispatch(showAlert(response.data.message, response.success));

    return Promise.resolve();
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const editWikiDocument = (documentId: number, newBookmarkName: string, content?: OutputData, folderId?: number)
  : AppThunkType => async (dispatch, getState) => {
    const documents = getState().wikiDocuments.data;
    const foldersList = getState().wikiFolders.data;

    try {
      const response = await documentAPI.renameDocument(documentId, newBookmarkName, content || null, folderId || null);

      if (response.success) {
        const newFolderList = foldersList.map((folder) => (folder.id === folderId ? {
          ...folder,
          wikis: folder.wikis.map((wiki) => (wiki.id === documentId ? { ...wiki, title: response.data.title } : wiki))
        }
          : folder));

        const newDocumentsList = documents.map((document) => (document.id === documentId
          ? response.data
          : document));

        dispatch(actionsWikiDocuments.setDocuments(newDocumentsList));
        dispatch(actionsWikiFolders.setFolderList(newFolderList));

        dispatch(showAlert('Document successfully updated', response.success));
      }

      return Promise.resolve();
    } catch (error: any) {
      return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
    }
  };

export const getDocument = (
  id: number
): AppThunkType => async (dispatch) => {
  try {
    const response = await documentAPI.getDocument(id);

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);
    Promise.reject(error);
  } finally {
    dispatch(actionsWikiDocuments.setIsFetching(false));
  }
};
