import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Spinner,
  Square,
  Stack,
  Text
} from '@chakra-ui/react';
import cn from 'classnames';
import { ButtonGroupPopup } from 'components/common/buttons/ButtonGroupPopup';
import HotKey from 'components/common/HotKey';
import { TagsInputWrapper } from 'components/common/Hashtags/TagsInputWrapper';
import CommandIcon from 'components/BookmarksManager/Icons/CommandIcon';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { actionsBookmarks, addBookmark, getBookmarkPreview } from 'store/bookmark-reducer';
import { standalone } from 'App';
import BookmarkPreview from 'components/BookmarksManager/Workspace/Bookmarks/BookmarkPreview';
import { useAppDispatch, useAppSelector } from '../../../store/store';
import { VersionOSType } from '../../../utils/getOS';
import style from './Popup.module.scss';

type AddLinkProps = {
  onClose: () => void;
  workspaceId?: number
};

export const AddLink = ({ onClose, workspaceId }: AddLinkProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const bookmarkPreview = useAppSelector((state) => state.bookmarks.bookmarkPreview);
  const OSVersion = useAppSelector((state) => state.app.versionOS);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[] | null>([]);

  const typingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { folderId } = useParams();

  const formik = useFormik({
    initialValues: {
      url: ''
    },
    onSubmit: (value) => {
      setLoading(true);

      dispatch(addBookmark(
        value.url,
        standalone ? 'pwa_manual' : 'web_manual',
        folderId ? Number(folderId) : undefined,
        workspaceId,
        selectedTags || []
      ))
        .then(() => {
          onClose();
        })
        .catch((errors) => {
          formik.setStatus(errors);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  useEffect(
    () => () => {
      if (typingTimeout.current) clearTimeout(typingTimeout.current);
      dispatch(actionsBookmarks.setBookmarkPreview(null));
    },
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    if (formik.status?.url) formik.setStatus({ ...formik.status, url: undefined });
    if (typingTimeout.current) clearTimeout(typingTimeout.current);

    typingTimeout.current = setTimeout(() => {
      setPreviewLoading(true);
      dispatch(getBookmarkPreview(e.target.value)).finally(() => {
        setPreviewLoading(false);
      });
    }, 500);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex direction="column">
        <Stack spacing="24px">
          <Flex>
            <Flex alignItems="center">
              <Square background="ghost_blue" borderRadius="4px" size="28px">
                <i className={cn('fa-solid fa-bookmark', style.linkIcon)} />
              </Square>
              <Text mx="12px" fontWeight="600" fontSize="16px" lineHeight="19px">
                Add Bookmark
              </Text>
            </Flex>
            <Spacer />
            <Flex display={{ base: 'none', lg: 'flex' }} alignItems="center" color="purple.500" gap="8px" fontWeight="500">
              <HotKey background="purple.500" color="white.50">
                {OSVersion === VersionOSType.Mac ? (
                  <CommandIcon />
                ) : (
                  <Text px="7px">
                    <i className="fa-brands fa-windows" />
                    {' '}
                    Ctrl
                  </Text>
                )}
              </HotKey>
              +
              <HotKey borderRadius="8px">B</HotKey>
            </Flex>
          </Flex>

          <FormControl isInvalid={!!formik.status?.url}>
            <InputGroup size="md">
              <Input
                variant="formInput"
                name="url"
                placeholder="Paste URL here..."
                onChange={handleChange}
                value={formik.values.url}
                autoComplete="off"
              />
              {!formik.values.url && (
                <InputLeftElement fontSize="12px" color="light_gray" h="100%">
                  <i className="fa-solid fa-link" />
                </InputLeftElement>
              )}
              {previewLoading && (
                <InputRightElement fontSize="16px" color="green.500" h="100%">
                  <Spinner emptyColor="green.50" colorScheme="green" boxSize="18px" />
                </InputRightElement>
              )}
            </InputGroup>
            <FormErrorMessage>{formik.status?.url}</FormErrorMessage>
          </FormControl>
        </Stack>

        {bookmarkPreview && (
          <Box flex="1 1 auto" minH="24px" mt="8px" overflowY="auto" p="12px">
            <BookmarkPreview bookmark={bookmarkPreview} />
          </Box>
        )}

        <Box mt="8px" w="100%">
          <TagsInputWrapper whoseTags="bookmark" selectedTags={selectedTags || []} setSelectedTags={setSelectedTags} onlyTagsSearch />
        </Box>

        <ButtonGroupPopup mt="32px" isLoading={loading} label="Save" handleCancelClick={onClose} />
      </Flex>
    </form>
  );
};
