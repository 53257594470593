import { Button, Center, Image } from '@chakra-ui/react';
import { ReactNode } from 'react';

type SocialAuthButtonProps = {
  icon: string;
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  ref?: React.MutableRefObject<HTMLButtonElement | null>;
  loading?: boolean;
};

const SocialAuthButton = ({ icon, children, disabled, onClick, ref, loading }: SocialAuthButtonProps) => (
  <Button
    ref={ref}
    disabled={disabled || loading}
    isLoading={loading}
    variant="social"
    h="auto"
    p="12px"
    border="1px solid"
    borderColor="gray.50"
    lineHeight="19px"
    onClick={onClick}
  >
    <Center gap="8px">
      <Image src={icon} objectFit="contain" boxSize="24px" />
      {children}
    </Center>
  </Button>
);

export default SocialAuthButton;
