import { useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';

import Popup from 'components/common/popup/Popup';
import {
  Box,
  Button,
  Center,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Square,
  Stack,
  Text
} from '@chakra-ui/react';
import getCroppedImg from 'utils/cropImage';

type ImageCropperProps = {
  image: string;
  onClose: (reset?: boolean) => void;
  getImage: (image: any, area: Area) => void;
};

export const ImageCropper = ({ onClose, image, getImage }: ImageCropperProps): JSX.Element => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const onCropComplete = (area: Area, areaPixels: Area) => {
    setCroppedAreaPixels(areaPixels);
  };

  const handleReset = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  const handleCrop = async () => {
    if (croppedAreaPixels) {
      const img = await getCroppedImg(image, croppedAreaPixels);
      getImage(img, croppedAreaPixels);
    }
  };

  const zoomOut = () => {
    if (zoom > 1) setZoom((z) => z - 1);
  };

  const zoomIn = () => {
    if (zoom < 10) setZoom((z) => z + 1);
  };

  return (
    <Popup hasCloseButton width="591px" isOpen={!!image} onClose={() => onClose(true)}>
      <Flex direction="column">
        <Stack spacing="24px">
          <Flex>
            <Flex alignItems="center">
              <Square background="white.300" borderRadius="4px" size="28px" fontSize="14px" color="gray.500">
                <i className="fa-solid fa-crop-simple" />
              </Square>
              <Text mx="12px" fontWeight="600" fontSize="16px" lineHeight="19px">
                Image Cropper
              </Text>
            </Flex>
          </Flex>
          <Center flexDir="column" w="100%" p="32px 32px 28px" bg="gray.400" borderRadius="4px">
            <Square maxW="100%" size="256px" position="relative">
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                style={{
                  containerStyle: { background: 'rgba(255, 255, 255, 0.7)' }
                }}
              />
            </Square>
            <Flex mt="19px" borderRadius="80px" maxW="100%" w="256px" p="8px 19px" bg="white.50">
              <Box cursor="pointer" _hover={{ color: 'light_blue.100' }} onClick={zoomOut} color="ghost_blue">
                <i className="fa-solid fa-minus" />
              </Box>
              <Slider
                mx="17px"
                min={1}
                max={10}
                step={0.5}
                value={zoom}
                aria-label="slider-ex-2"
                defaultValue={zoom}
                onChange={(val) => setZoom(val)}
              >
                <SliderTrack h="2px">
                  <SliderFilledTrack bg="light_green.300" />
                </SliderTrack>
                <SliderThumb boxSize="22px" />
              </Slider>
              <Box cursor="pointer" _hover={{ color: 'light_blue.100' }} onClick={zoomIn} color="ghost_blue">
                <i className="fa-solid fa-plus" />
              </Box>
            </Flex>
          </Center>
        </Stack>

        <Flex
          flexDirection={{ base: 'column-reverse', lg: 'row' }}
          justifyContent="space-between"
          w="100%"
          gap="8px"
          mt="32px"
        >
          <Button variant="reset" size="sm" onClick={handleReset}>
            Reset
          </Button>

          <Button
            onClick={handleCrop}
            color="white.50"
            colorScheme="yellow"
            size="sm"
            filter="drop-shadow(0px 1px 4px rgba(87, 84, 14, 0.25))"
          >
            Finish Cropping
          </Button>
        </Flex>
      </Flex>
    </Popup>
  );
};
