import { SlackChannelType, SlackTeamType } from 'types/types';
import { EditType, requestInstance, ResponseType } from './api';

export const slackAPI = {
  async setTokenForTeam(teamId: string | number, code: string) {
    const res = await requestInstance().put<EditType>(`/v1/slack/team/${teamId}/token/set`, { code });

    return res.data;
  },
  async getSlackTeam(teamId: string | number) {
    const res = await requestInstance().get<ResponseType<SlackTeamType>>(`/v1/slack/team/${teamId}/info`);

    return res.data;
  },
  async getSlackChannels(teamId: string | number) {
    const res = await requestInstance().get<ResponseType<SlackChannelType[]>>(`/v1/slack/team/${teamId}/channels/list`);

    return res.data;
  },
  async setChannelForTeam(teamId: string | number, channelId: string) {
    const res = await requestInstance().put<EditType>(`/v1/slack/team/${teamId}/channel/set`, { channel_id: channelId });

    return res.data;
  },
  async setChannelForWorkspace(workspaceId: string | number, channelId: string) {
    const res = await requestInstance()
      .put<EditType>(`/v1/slack/workspace/${workspaceId}/channel/set`, { channel_id: channelId });

    return res.data;
  },
  async deleteChannelForWorkspace(workspaceId: string | number) {
    const res = await requestInstance().delete<EditType>(`/v1/slack/workspace/${workspaceId}/channel/delete`);

    return res.data;
  },
  async deleteTokenForTeam(teamId: string | number) {
    const res = await requestInstance().delete<EditType>(`/v1/slack/team/${teamId}/token/delete`);

    return res.data;
  },
  async deleteChannelForTeam(teamId: string | number) {
    const res = await requestInstance().delete<EditType>(`/v1/slack/team/${teamId}/channel/delete`);

    return res.data;
  }
};
