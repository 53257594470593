import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { Alerts } from 'components/Alerts/Alerts';
import ForgetPassword from 'pages/ForgetPassword';
import ResetPassword from 'pages/ResetPassword';
import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
import UpdateApp from 'components/Alerts/UpdateApp';

const UnauthorizedRoutes = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const isRegistered = searchParams.get('is_registered');

  return (
    <>
      <UpdateApp />

      <Routes>
        <Route path="/settings/integrations/ifttt" element={<Login />} />
        <Route path="/set-new-password" element={<ResetPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgetPassword />} />
        <Route
          path="/invitation"
          element={isRegistered ? <Login /> : <SignUp />}
        />
        <Route path="/join" element={<SignUp />} />
        <Route path="/shared/*" element={<SignUp />} />
        <Route path="/*" element={<Navigate to="/login" />} />
      </Routes>

      <Alerts />

    </>
  );
};

export default UnauthorizedRoutes;
