import { notificationAPI } from 'api/notificationsApi';
import { deleteItem } from 'utils/delete';
import { errorHandler } from 'utils/errorHandler';
import { rewriteData } from 'utils/rewriteData';
import {
  InferActionsTypes,
  NotificationSettingCode,
  NotificationSettings,
  NotificationType,
  PaginationType
} from '../types/types';
import { showAlert } from './app-reducer';
import { AppThunkType } from './store';

const initialState = {
  notificationsList: [] as Array<NotificationType>,
  pagination: null as PaginationType | null,
  unreadCount: 0 as number,
  settings: [] as Array<NotificationSettings>
};

export const actionsNotifications = {
  setNotifications: (notifications: Array<NotificationType>, pagination: PaginationType) => (
    { type: 'SET_NOTIFICATIONS_LIST', notifications, pagination }) as const,
  setInitialNotificationsList: (notifications: Array<NotificationType>, pagination: PaginationType | null) => (
    { type: 'SET_INITIAL_NOTIFICATIONS_LIST', notifications, pagination }) as const,
  setUnreadCount: (count: number) => ({ type: 'SET_UNREAD_NOTIFICATIONS_COUNT', count }) as const,
  setNotificationIsRead: (newList: Array<NotificationType>) => ({ type: 'SET_NOTIFICATION_IS_READ', newList }) as const,
  setNotificationSettingsList: (data: Array<NotificationSettings>) => ({ type: 'SET_NOTIFICATION_SETTINGS_LIST', data }) as const,
  cleanUpNotificationSettings: () => ({ type: 'CLEAN_UP_NOTIFICATION_SETTINGS' }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsNotifications>

const notificationsReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS_LIST':
      return {
        ...state,

        notificationsList: [...state.notificationsList, ...action.notifications],
        pagination: action.pagination

      };

    case 'SET_INITIAL_NOTIFICATIONS_LIST':
      return {
        ...state,

        notificationsList: action.notifications,
        pagination: action.pagination

      };

    case 'SET_UNREAD_NOTIFICATIONS_COUNT':
      return {
        ...state,
        unreadCount: action.count
      };

    case 'SET_NOTIFICATION_IS_READ':
      return {
        ...state,

        notificationsList: action.newList

      };

    case 'SET_NOTIFICATION_SETTINGS_LIST':
      return {
        ...state,
        settings: action.data
      };

    case 'CLEAN_UP_NOTIFICATION_SETTINGS':
      return {
        ...state,
        settings: []
      };

    default:
      return state;
  }
};

export const getNotificationsList = (page: number): AppThunkType => async (dispatch) => {
  try {
    const response = await notificationAPI.getNotificationsList(page);
    if (response.pagination.current_page === 1) {
      dispatch(actionsNotifications.setInitialNotificationsList(response.data, response.pagination));
    } else {
      dispatch(actionsNotifications.setNotifications(response.data, response.pagination));
    }

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export const deleteNotification = (id: string): AppThunkType => async (dispatch, getState) => {
  const notifications = getState().notifications.notificationsList;
  const { pagination } = getState().notifications;

  try {
    const response = await notificationAPI.delete(id);

    dispatch(showAlert(response.data.message, true));
    dispatch(actionsNotifications.setInitialNotificationsList(deleteItem(notifications, id), pagination));
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const getUnreadNotificationsCount = (): AppThunkType => async (dispatch) => {
  try {
    const response = await notificationAPI.unreadCount();
    dispatch(actionsNotifications.setUnreadCount(response.data));
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export const setNotificationIsRead = (id: string): AppThunkType => async (dispatch, getState) => {
  const notifications = getState().notifications.notificationsList;

  try {
    const response = await notificationAPI.setIsRead(id);
    if (response.success) {
      dispatch(actionsNotifications.setNotificationIsRead(rewriteData(notifications, id, 'read_at', new Date())));
      dispatch(getUnreadNotificationsCount());
    }
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export const getNotificationsSettings = (): AppThunkType => async (dispatch) => {
  try {
    const response = await notificationAPI.getSettingsList();
    dispatch(actionsNotifications.setNotificationSettingsList(response.data));

    return Promise.resolve(response.data);
  } catch (error) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const updateNotificationSettings = (
  code: NotificationSettingCode,
  settingsObject: { [key: string]: boolean }
): AppThunkType => async (dispatch) => {
  try {
    const response = await notificationAPI.updateSettings(code, settingsObject);

    return Promise.resolve(response.data);
  } catch (error) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};
export default notificationsReducer;
