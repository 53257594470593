import Pusher from 'pusher-js';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY || '', {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  authEndpoint: process.env.REACT_APP_PUSHER_AUTH_URL,
  auth: {
    headers: { Authorization: `Bearer ${cookies.get('token')}`, accept: 'application/json' }
  }
});

declare global {
  interface Window {
    pusher: typeof pusher;
    needReload: boolean;
  }
}

