import { wikiFolderAPI } from 'api/wiki/folderApi';
import { showAlert } from 'store/app-reducer';
import { AppThunkType } from 'store/store';
import { InferActionsTypes } from 'types/types';
import { ListStyleType, WikiFolderType } from 'types/wiki';
import { deleteItem } from 'utils/delete';
import { errorHandler } from 'utils/errorHandler';
import { rewriteData } from 'utils/rewriteData';

const INITIAL_LIST_STYLE_TYPE = localStorage.getItem('listStyleType') || 'grid' as ListStyleType;

const initialState = {
  data: [] as Array<WikiFolderType>,
  isFetching: false,
  listStyleType: INITIAL_LIST_STYLE_TYPE
};

export const actionsWikiFolders = {
  setFolderList: (data: Array<WikiFolderType>) => ({ type: 'SET_WIKI_LIST_FOLDERS', data }) as const,
  setNewFolder: (newFolder: WikiFolderType) => ({ type: 'SET_NEW_WIKI_FOLDER', newFolder }) as const,
  setIsFetching: (isFetching: boolean) => ({ type: 'SET_IS_FETCHING_WIKI_FOLDERS', isFetching }) as const,
  setListStileType: (listStyleType: ListStyleType) => ({ type: 'SET_LIST_WIKI_DOCUMENTS_STYLE_TYPE', listStyleType }) as const,
  cleanUp: () => ({ type: 'CLEAN_UP_WIKI_FOLDER_REDUCER' }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsWikiFolders>

export const wikiFolderReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_WIKI_LIST_FOLDERS':
      return {
        ...state,
        data: action.data
      };
    case 'SET_NEW_WIKI_FOLDER':
      return {
        ...state,
        data: [...state.data, action.newFolder]
      };

    case 'SET_IS_FETCHING_WIKI_FOLDERS':
      return {
        ...state,
        isFetching: action.isFetching
      };
    case 'CLEAN_UP_WIKI_FOLDER_REDUCER':
      return {
        ...state,
        data: [],
        isFetching: false
      };

    case 'SET_LIST_WIKI_DOCUMENTS_STYLE_TYPE':
      return {
        ...state,
        listStyleType: action.listStyleType
      };

    default:
      return state;
  }
};

export const getFolderList = (
  teamId?: number | null
): AppThunkType => async (dispatch) => {
  dispatch(actionsWikiFolders.setIsFetching(true));

  try {
    const response = await wikiFolderAPI.getFolderList(teamId);
    dispatch(actionsWikiFolders.setFolderList(response.data));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);
    Promise.reject(error);
  } finally {
    dispatch(actionsWikiFolders.setIsFetching(false));
  }
};

export const addNewFolder = (name: string, teamId: number | null): AppThunkType => async (dispatch) => {
  try {
    const response = await wikiFolderAPI.setNewFolder(name, teamId);

    dispatch(actionsWikiFolders.setNewFolder(response.data));

    dispatch(showAlert('Folder successfully added', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const deleteFolder = (folderId: number): AppThunkType => async (dispatch, getState) => {
  const folders = getState().wikiFolders.data;

  try {
    const response = await wikiFolderAPI.deleteFolder(folderId);
    dispatch(actionsWikiFolders.setFolderList(deleteItem(folders, folderId)));
    dispatch(showAlert(response.data.message, response.success));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const renameFolder = (
  folderId: number,
  newFolderName: string
): AppThunkType => async (dispatch, getState) => {
  const foldersInState = getState().wikiFolders.data;

  try {
    const response = await wikiFolderAPI.renameFolder(folderId, newFolderName);

    dispatch(actionsWikiFolders.setFolderList(rewriteData(foldersInState, folderId, 'name', response.data.name)));
    dispatch(showAlert('Folder successfully renamed', response.success));

    return Promise.resolve();
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const setListStileType = (listStyleType: ListStyleType): AppThunkType => async (dispatch) => {
  dispatch(actionsWikiFolders.setListStileType(listStyleType));
  localStorage.setItem('listStyleType', listStyleType);
};
