import { InviteWorkspaceMembers, NewWorkspace, UpdateWorkspace, WorkspaceType } from 'types/types';
import { checkParam, EditType, requestInstance, ResponseType } from './api';

export const workspaceAPI = {
  async getWorkspacesList(filterByType: string, filterByTeam: number | null) {
    const filterTeam = checkParam(filterByTeam);
    const res = await requestInstance().get<ResponseType<Array<WorkspaceType>>>(
      `v1/workspace?filter_by_type=${filterByType}&filter_by_team=${filterTeam}`
    );

    return res.data;
  },
  async createWorkspace({ name, type = 'personal', image, team_id }: NewWorkspace) {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('type', type);
    formData.append('team_id', team_id?.toString() ?? '');

    if (image?.file) {
      formData.append('image', image.file);
      formData.append('width', image.width.toString());
      formData.append('height', image.height.toString());
      formData.append('x', image.x.toString());
      formData.append('y', image.y.toString());
    }
    const res = await requestInstance().post<ResponseType<WorkspaceType>>('v1/workspace', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return res.data;
  },
  async deleteWorkspace(workspace_id: number | string) {
    const res = await requestInstance().delete(`v1/workspace/${workspace_id}`);

    return res.data;
  },
  async getWorkspace(workspace_id: number | string) {
    const res = await requestInstance().get<ResponseType<WorkspaceType>>(`v1/workspace/${workspace_id}`);

    return res.data;
  },
  async updateWorkspace(id: number, { name, image }: UpdateWorkspace) {
    const formData = new FormData();
    formData.append('name', name);
    if (image?.file) {
      formData.append('image', image.file);
      formData.append('width', image.width.toString());
      formData.append('height', image.height.toString());
      formData.append('x', image.x.toString());
      formData.append('y', image.y.toString());
    }
    const res = await requestInstance().post<ResponseType<WorkspaceType>>(`v1/workspace/${id}?_method=patch`, formData, {
      headers: {
        'Content-Type': 'application/form-data'
      }
    });

    return res.data;
  },
  async deleteWorkspaceMember(workspaceId: number | string, userId: number) {
    const res = await requestInstance().delete<EditType>(`v1/workspace/${workspaceId}/user/${userId}/invite`);

    return res.data;
  },
  async updateWorkspaceMemberRole(workspaceId: number | string, userId: number, access_role_code: string) {
    const res = await requestInstance().put<EditType>(`v1/workspace/${workspaceId}/user/${userId}`, { access_role_code });

    return res.data;
  },
  async inviteWorkspaceMembers(workspaceId: number | string, invites: InviteWorkspaceMembers) {
    const res = await requestInstance().post<EditType>(`v1/workspace/${workspaceId}/invite`, { invites });

    return res.data;
  },
  async deleteWorkspaceImage(workspaceId: number | string) {
    const res = await requestInstance().delete<ResponseType<WorkspaceType>>(`v1/workspace/${workspaceId}/remove-image`);

    return res.data;
  },
  async orderWorkspaces(workspaces: number[]) {
    const res = await requestInstance().post<ResponseType<WorkspaceType>>('v1/workspace/order', { workspaces });

    return res.data;
  },
  async getWorkspaceListWithFoldersTree(workspaceType: string, teamId: number | null) {
    const res = await requestInstance().get<ResponseType<WorkspaceType>>(
      `v1/workspace/list-with-folders?filter_by_type=${workspaceType}&filter_by_team=${checkParam(teamId)}`
    );

    return res.data;
  }
};
