import {
  Button, Menu,
  MenuButton,
  MenuButtonProps,
  useDisclosure
} from '@chakra-ui/react';
import { DialogModal } from 'components/common/popup/DialogModal';
import OneFieldModal from 'components/common/popup/OneFieldModal';
import { ContextMenuButton, ContextMenuWrapper } from 'components/common/wiki/contextMenu';
import { Delete } from 'components/modals/Popups/Delete';
import { useState } from 'react';
import Popup from '../../common/popup/Popup';

type Props = {
  itemName: string;
  isTrashPage?: boolean;
  btnStyle?: MenuButtonProps;
  onDelete: () => Promise<any>;
  onEdit: (value: string) => Promise<any>;
  onRecover?: () => Promise<any>;
}

const EditItem = ({ itemName, btnStyle, isTrashPage, onDelete, onEdit, onRecover }: Props): JSX.Element => {
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const { isOpen: isRecoverOpen, onOpen: onRecoverOpen, onClose: onRecoverClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string } | null>(null);

  const handleRename = (name: string) => {
    if (errors) setErrors(null);
    setLoading(true);

    onEdit(name)
      .then(() => {
        onEditClose();
      })
      .catch((err) => {
        setErrors(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (action: () => Promise<any>) => {
    if (errors) setErrors(null);
    setLoading(true);

    action()
      .then(() => {
        onDeleteClose();
      })
      .catch((err) => {
        setErrors(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Menu autoSelect={false} direction="ltr" isLazy lazyBehavior="unmount">
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              fontSize="16px"
              color="dark_gray.200"
              variant="wikiIconButton"
              size="icon"
              opacity={{ base: 1, lg: isOpen ? 1 : 0 }}
              _groupHover={{ opacity: 1 }}
              {...btnStyle}
            >
              <i className="fa-solid fa-ellipsis" />
            </MenuButton>

            <ContextMenuWrapper>
              <>
                {isTrashPage
                  ? (
                    <ContextMenuButton
                      label="Recover"
                      handleClick={onRecoverOpen}
                      icon={<i className="fa-solid fa-rotate-left" />}
                    />
                  )
                  : (
                    <ContextMenuButton
                      label="Edit"
                      handleClick={onEditOpen}
                      icon={<i className="fa-solid fa-pen" />}
                    />
                  )}
                <ContextMenuButton
                  label={isTrashPage ? 'Delete' : 'Move to Trash'}
                  handleClick={onDeleteOpen}
                  icon={isTrashPage
                    ? <i className="fa-solid fa-trash" />
                    : <i className="fa-solid fa-trash-can-arrow-up" />}
                />
              </>
            </ContextMenuWrapper>
          </>
        )}
      </Menu>

      <Popup isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <Delete
          title="Delete"
          description={isTrashPage
            ? `Are you sure you want to delete "${itemName}"?`
            : `Are you sure you want to move "${itemName}" to the Trash?`}
          handleDelete={() => handleDelete(onDelete)}
          onClose={onDeleteClose}
          isLoading={loading}
        />
      </Popup>

      {onRecover && (
        <Popup isOpen={isRecoverOpen} onClose={onRecoverClose}>
          <DialogModal
            title="Recover"
            description={`Are you sure you want to restore the "${itemName}"?`}
            icon={<i className="fa-solid fa-rotate-left" />}
            handleClick={() => handleDelete(onRecover)}
            onClose={onRecoverClose}
            isLoading={loading}
          />
        </Popup>
      )}

      <Popup isOpen={isEditOpen} onClose={onEditClose} overflow="unset">
        <OneFieldModal
          errors={errors}
          title={isTrashPage ? 'Recover' : 'Edit'}
          onClose={onEditClose}
          setErrors={setErrors}
          handleSubmit={handleRename}
          fieldValue={itemName}
          loading={loading}
        />
      </Popup>
    </>
  );
};

export default EditItem;
