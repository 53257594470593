import { useSearchParams } from 'react-router-dom';

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParam = (keyQuery: string) => {
    const param = searchParams.get(keyQuery);

    if (param) {
      searchParams.delete(keyQuery);
      setSearchParams(searchParams);
    }
  };

  const updateQueryValue = (newQueries: { [key: string]: string | undefined }) => {
    const queries = Object.fromEntries(searchParams.entries());
    const filteredQueries: Record<string, string> = {};

    Object.entries(newQueries).forEach(([key, value]) => {
      if (value) {
        filteredQueries[key] = value;
      } else {
        delete queries[key];
      }
    });

    setSearchParams({ ...queries, ...filteredQueries });
  };

  return { updateQueryValue, removeQueryParam };
};
