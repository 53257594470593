import { errorHandler } from 'utils/errorHandler';
import { reminderAPI } from '../api/reminderApi';
import { InferActionsTypes, PaginationType, Reminder, ReminderOptions } from '../types/types';
import { showAlert } from './app-reducer';
import { AppThunkType } from './store';

const initialState = {
  data: [] as Array<Reminder>,
  pagination: {
    count: 0,
    current_page: 1,
    per_page: 10,
    total: 0,
    total_pages: 1
  }
};

export const actionsReminders = {
  setRemindersList: (data: Array<Reminder>) => ({ type: 'SET_REMINDERS_LIST', data }) as const,
  setRemindersPagination: (data: PaginationType) => ({ type: 'SET_REMINDERS_PAGINATION', data }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsReminders>

export const reminderReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_REMINDERS_LIST':
      return {
        ...state,
        data: action.data
      };
    case 'SET_REMINDERS_PAGINATION':
      return {
        ...state,
        pagination: action.data
      };
    default:
      return state;
  }
};

export const getRemindersList = (page = 1): AppThunkType => async (dispatch) => {
  try {
    const response = await reminderAPI.getRemindersList(page);

    dispatch(actionsReminders.setRemindersList(response.data));
    dispatch(actionsReminders.setRemindersPagination(response.pagination));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const addReminder = (bookmarkId: number | string, options: ReminderOptions): AppThunkType => async (dispatch, getState) => {
  try {
    const { current_page } = getState().reminders.pagination;
    const response = await reminderAPI.createReminder(bookmarkId, options);
    dispatch(getRemindersList(current_page));
    dispatch(showAlert('Reminder successfully saved', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch, (res) => {
      if (res.validationErrors) {
        dispatch(showAlert(Object.values(res.validationErrors), false));
      }
    });

    return Promise.reject(error);
  }
};

export const updateReminder = (reminderId: number | string, options: ReminderOptions): AppThunkType => async (dispatch) => {
  try {
    const response = await reminderAPI.updateReminder(reminderId, options);

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch, (res) => {
      if (res.validationErrors) {
        dispatch(showAlert(Object.values(res.validationErrors), false));
      }
    });

    return Promise.reject(error);
  }
};

export const deleteReminder = (reminderId: number | string): AppThunkType => async (dispatch) => {
  try {
    const response = await reminderAPI.deleteReminder(reminderId);
    dispatch(showAlert(response.data.message, true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};
