import { useRef } from 'react';

interface DragAndDropProps {
  className?: string;
  offsetX?: number;
  offsetY?: number;
  disableDrag?: boolean;
  disableDrop?: boolean
}

export const useDragAndDrop = (props?: DragAndDropProps) => {
  const draggableElement = useRef<HTMLDivElement | null>(null);

  const { className = 'drag-element-wrapper', offsetX = 50, offsetY = 50, disableDrag = false, disableDrop = false } = { ...props };

  const cloneElement = (e: React.DragEvent<HTMLDivElement | HTMLAnchorElement>) => {
    if (e.target) {
      const element = e.target as Element;
      const elementClone = element.cloneNode(true);
      const wrapperElement = document.createElement('div');
      wrapperElement.classList.add(className);
      document.body.appendChild(wrapperElement);
      wrapperElement.appendChild(elementClone);
      draggableElement.current = wrapperElement;
      e.dataTransfer?.setDragImage(wrapperElement, offsetX, offsetY);
    }
  };

  const onDrag = (e: React.DragEvent<HTMLDivElement | HTMLAnchorElement>, params: { [key: string]: number | string }) => {
    if (disableDrag) return;
    Object.entries(params).forEach(([key, value]) => {
      e.dataTransfer?.setData(key, value.toString());
    });

    cloneElement(e);
  };

  const removeDragElement = () => {
    if (draggableElement.current) {
      document.body.removeChild(draggableElement.current);
      draggableElement.current = null;
    }
  };

  const onDrop = (e: React.DragEvent<HTMLAnchorElement | HTMLDivElement>, index: number, cb: () => void) => {
    if (disableDrop) return;
    if (draggableElement.current) {
      const firstIndex = e.dataTransfer?.getData('index');
      if (firstIndex && +firstIndex !== index) {
        cb();
      }
      removeDragElement();
    }
  };

  return {
    draggableElement,
    onDrag,
    onDrop,
    removeDragElement
  };
};

