import { Box, Button, Center, Flex, Image } from '@chakra-ui/react';
import diamond from 'assets/image/diamond.svg';
import install_desktop from 'assets/image/icons/install_desktop.svg';
import logo from 'assets/image/logo.svg';
import { useDeviceParams } from 'hooks/useDeviceParams';
import { actionsPopup } from 'store/popup-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';

type PropsType = {
  toggleNavbar: () => void;
  isStandalone: boolean;
  onOpenUpgradePlan: () => void
}

const FooterButtons = ({ toggleNavbar, isStandalone, onOpenUpgradePlan }: PropsType): JSX.Element => {
  const dispatch = useAppDispatch();
  const { deviceType, browserType } = useDeviceParams();
  const { extensionIsInstalled } = useAppSelector((state) => state.app);

  const needToShowExtinctionButton = deviceType === 'desktop' && browserType === 'chrome' && !extensionIsInstalled;

  const openExtensionPage = () => {
    window.open(process.env.REACT_APP_CHROME_EXTENSION, '_blank');
    toggleNavbar();
  };

  const onInstallClick = () => {
    dispatch(actionsPopup.setShowInstallApp(true));
    toggleNavbar();
  };

  if (isStandalone && (extensionIsInstalled || deviceType === 'mobile')) {
    return (
      <Flex w="100%" justifyContent="flex-end" gap="24px" alignItems="center">
        <Box
          position="relative"
          h="34px"
          w="34px"
          bgImage={logo}
          bgSize="cover"
          filter="drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.25))"
        >
          <Image
            position="absolute"
            top="70%"
            left="73%"
            src={diamond}
            objectFit="cover"
            boxSize="14px"
          />
        </Box>

        <Button
          onClick={onOpenUpgradePlan}
          w="85px"
          h="24px"
          colorScheme="transparent"
          borderRadius="0"
          borderBottom="1px solid"
          borderColor="transparent"
          _hover={{
            bg: 'transparent',
            borderBottom: '1px solid',
            borderColor: 'white.50'
          }}
        >
          Upgrade
          <Center boxSize="20px" fontSize="16px" ml="8px" font-size="16px">
            <i className="fa-solid fa-arrow-right-long" />
          </Center>
        </Button>
      </Flex>
    );
  }

  return (
    <>
      {needToShowExtinctionButton && (
        <Button
          flex="1"
          minH="32px"
          colorScheme="green"
          fontSize="12px"
          onClick={openExtensionPage}
          leftIcon={(
            <Center fontSize="16px">
              <i className="fa-solid fa-puzzle-piece" />
            </Center>
          )}
          iconSpacing="8px"
        >
          Extension
        </Button>
      )}

      {!isStandalone && (
        <Button
          flex="1"
          minH="32px"
          colorScheme={!needToShowExtinctionButton ? 'green' : 'transparent'}
          _hover={{
            bg: !needToShowExtinctionButton ? 'green.700' : 'rgba(255, 255, 255, 0.15)'
          }}
          color="white.50"
          border="1px solid"
          borderColor={!needToShowExtinctionButton ? 'transparent' : 'white.50'}
          fontSize="12px"
          onClick={onInstallClick}
          leftIcon={(
            <Image
              boxSize="16px"
              src={install_desktop}
            />
          )}
          iconSpacing="8px"
        >
          Install App
        </Button>
      )}
    </>
  );
};

export default FooterButtons;
