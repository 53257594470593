import { FileImage, UploadImage, UserType } from 'types/types';
import { EditType, requestInstance, ResponseType } from './api';

export const profileAPI = {
  async getProfile() {
    const res = await requestInstance().get<ResponseType<UserType>>(
      'v1/profile'
    );

    return res.data;
  },

  async resendVerificationCode() {
    const res = await requestInstance().post(
      'v1/profile/resend-verification-code'
    );

    return res.data;
  },

  async verifyAccount(verificationCode: string) {
    const res = await requestInstance().post('v1/profile/verify-account', { verification_code: verificationCode });

    return res.data;
  },

  async updateUserName(name: string) {
    const res = await requestInstance().patch<EditType>('v1/profile', {
      name
    });

    return res.data;
  },

  async updateUserAvatar(img: UploadImage) {
    const image = new FormData();

    if (img?.image) {
      image.append('image[image]', img.image);
      image.append('image[width]', img.width.toString());
      image.append('image[height]', img.height.toString());
      image.append('image[x]', img.x.toString());
      image.append('image[y]', img.y.toString());
    }
    const res = await requestInstance().post<ResponseType<FileImage>>('v1/profile/avatar', image, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return res.data;
  },

  async setPassword(password: string, password_confirmation: string, old_password: string) {
    const res = await requestInstance().post('v1/auth/password/set', {
      password,
      password_confirmation,
      old_password
    });

    return res.data;
  },

  async deleteProfileImage() {
    const res = await requestInstance().delete<EditType>('v1/profile/avatar-remove');

    return res.data;
  }
};
