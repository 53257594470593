import { Center } from '@chakra-ui/react';

type Props = {
  toggleNavbar: () => void
}

export const CloseNavbarButton = ({ toggleNavbar }: Props): JSX.Element => (
  <Center
    onClick={toggleNavbar}
    display={{ base: 'flex', lg: 'none' }}
    ml="auto"
    boxSize="48px"
    minW="48px"
    borderRadius="50%"
    fontSize="24px"
    bg="white.600"
    color="dark_gray.200"
    cursor="pointer"
  >
    <i className="fa-solid fa-xmark" />
  </Center>
);

export const OpenNavbarButton = ({ toggleNavbar }: Props): JSX.Element => (
  <Center
    onClick={() => toggleNavbar()}
    w="28px"
    display={{
      base: 'flex',
      lg: 'none'
    }}
    fontSize="28px"
    color="dark_gray.200"
    cursor="pointer"
  >
    <i className="fa-solid fa-bars" />
  </Center>
);
