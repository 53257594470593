import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, CloseButton } from '@chakra-ui/react';
import { actionsPopup } from 'store/popup-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';

const UpdateApp = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector((state) => state.popup.showUpdateApp);

  const handleClose = () => {
    dispatch(actionsPopup.setShowUpdateApp(false));
  };

  const handleUpdateApp = () => {
    window.location.reload();
  };

  if (!isVisible) return <div />;

  return (
    <Alert status="info" fontSize="12px" p="8px" bg="gray.100" lineHeight="unset">
      <AlertIcon />
      <Box w="100%">
        <AlertTitle>Update Required</AlertTitle>
        <AlertDescription w="fit-content">
          There is newer version of the app. Please update to have access to new version.
        </AlertDescription>
      </Box>
      <Button onClick={handleUpdateApp} mr="8px" size="sm">
        Update
      </Button>
      <CloseButton
        alignSelf="center"
        position="relative"
        onClick={handleClose}
      />
    </Alert>
  );
};

export default UpdateApp;
