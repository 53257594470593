import { AddLinkSourcesType, BookmarkPreviewType, BookmarkType, RenameFolderType, TagType } from 'types/types';
import { prependSchema } from 'utils/regex';
import { checkParam, requestInstance, EditType, GetItemsType, ResponseType } from './api';

export const bookmarkAPI = {
  async getBookmarks(
    workspaceId: string,
    folderId: string | null,
    pageNumber: number,
    filter: string | null,
    tagsList: string | null,
    sortOptions: { sort: string, order: string } | null
  ) {
    const query = checkParam(filter);
    const folder = checkParam(folderId);
    const tags = checkParam(tagsList);
    const sort = checkParam(sortOptions?.sort);
    const order = checkParam(sortOptions?.order);

    const res = await requestInstance().get<GetItemsType<Array<BookmarkType>>>(
      `v1/bookmark?workspace_id=${workspaceId}
      &page=${pageNumber}
      &folder_id=${folder}
      &keyword=${query}
      &tags=${tags}
      &sort=${sort}
      &order=${order}`
    );

    return res.data;
  },

  async addBookmark(
    workspaceId: number,
    link: string,
    folder_id: number | null = null,
    tags: string[] = [],
    source: AddLinkSourcesType
  ) {
    const url = prependSchema(link);
    const res = await requestInstance().post<
      ResponseType<BookmarkType>
    >('v1/bookmark', { workspace_id: workspaceId, url, folder_id, source, tags });

    return res.data;
  },

  async deleteBookmark(bookmarkId: number) {
    const res = await requestInstance().delete<EditType>(`v1/bookmark/${bookmarkId}`);

    return res.data;
  },
  async deleteBookmarksList(bookmarks: Array<number>) {
    const res = await requestInstance().post<EditType>('v1/bookmark/bulk-delete', { bookmarks });

    return res.data;
  },
  async pinBookmark(bookmarkId: number) {
    const res = await requestInstance().post<EditType>(`v1/bookmark/${bookmarkId}/pin`);

    return res.data;
  },
  async unpinBookmark(bookmarkId: number) {
    const res = await requestInstance().post<EditType>(`v1/bookmark/${bookmarkId}/unpin`);

    return res.data;
  },
  async renameBookmark(bookmarkId: number, name: string, tags: string[] = []) {
    const res = await requestInstance().put<ResponseType<RenameFolderType>>(`v1/bookmark/${bookmarkId}`, { name, tags });

    return res.data;
  },
  async copyBookmark(bookmarkId: number, folder_ids: Array<number>) {
    const res = await requestInstance().post<EditType>(`v1/bookmark/${bookmarkId}/copy-to`, { folder_ids });

    return res.data;
  },
  async moveBookmark(bookmarkId: number, folder_id: number | null) {
    const res = await requestInstance().post<EditType>(`v1/bookmark/${bookmarkId}/move-to`, { folder_id });

    return res.data;
  },
  async moveBookmarksList(bookmarks: number[], folder_id: number | null) {
    const res = await requestInstance().post<EditType>('v1/bookmark/multi-move-to', { folder_id, bookmarks });

    return res.data;
  },
  async getPreview(url: string) {
    const res = await requestInstance().post<ResponseType<BookmarkPreviewType>>('v1/bookmark/parse-url', { url });

    return res.data;
  },
  async getFolders(bookmarkId: number) {
    const res = await requestInstance().get<ResponseType<{ folder_ids: Array<null | number> }>>(`/v1/bookmark/${bookmarkId}/link/folders`);

    return res.data;
  },
  async orderBookmarks(bookmarks: number[], folderId?: number | string) {
    const res = await requestInstance().post<ResponseType<any>>('v1/bookmark/order', {
      bookmarks,
      folder_id: folderId
    });

    return res.data;
  },
  async getBookmarkByUrl(url: string) {
    const res = await requestInstance().get<ResponseType<{ exists: boolean }>>(`v1/bookmark/exists-by-url?url=${url}`);

    return res.data;
  },
  async getTags(whoseTags: 'bookmark' | 'snippet', keyword: string = '', filterByUser: 'yes' | 'no' = 'no', teamId: string = '') {
    const res = await requestInstance().get<ResponseType<TagType[]>>(
      `v1/${whoseTags}/tag?filter_by_user=${filterByUser}&filter_by_team${teamId}=&keyword=${keyword}`
    );

    return res.data;
  }
};
