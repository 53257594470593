import { Link, Image } from '@chakra-ui/react';

interface AppLinkProps {
  link: string;
  image: string;
}
const AppLink = ({ link, image }: AppLinkProps) => (
  <Link
    href={link}
    isExternal
    boxSize="56px"
    bg="white.500"
    borderRadius="10px"
    display="flex"
    justifyContent="center"
    alignItems="center"
    filter={{ base: 'drop-shadow(0px 2px 6px rgba(86, 86, 86, 0.25))', lg: 'none' }}
  >
    <Image boxSize="40px" objectFit="cover" src={image} alt="Google Store" />
  </Link>
);
export default AppLink;
