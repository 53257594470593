import { useEffect, useState } from 'react';
import { bookmarkAPI } from 'api/bookmarksApi';
import AbandonedBookmark from 'components/modals/Popups/AbandonedBookmark';

const urlExpression = `(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9]
[a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})`;

const SaveBookmarkFromClipboard = () => {
  const [suggestedUrl, setSuggestedUrl] = useState('');

  const handleGetCopiedUrl = async () => {
    try {
      const clipboardData = await navigator.clipboard.readText();
      const regex = new RegExp(urlExpression);
      if (!clipboardData) {
        return;
      }
      if (clipboardData.match(regex)) {
        try {
          const resp = await bookmarkAPI.getBookmarkByUrl(clipboardData);
          if (!resp.data.exists) {
            const canceledUrls = window.localStorage.getItem('canceledUrls');

            if (canceledUrls) {
              const canceledUrlList: Array<{ url: string; time: number }> = JSON.parse(canceledUrls);
              const canceledUrl = canceledUrlList.find(
                (item) => item.url === clipboardData && new Date().getTime() - item.time < 21600000
              );
              if (!canceledUrl) {
                setSuggestedUrl(clipboardData);
              }
            } else {
              setSuggestedUrl(clipboardData);
            }
          }
        } catch {
          setSuggestedUrl('');
        }
      }
    } catch (e) {
      return e;
    }
  };

  const handleCancelSuggestion = () => {
    const canceledUrls = window.localStorage.getItem('canceledUrls');
    if (canceledUrls) {
      const canceledUrlList: Array<{ url: string; time: number }> = JSON.parse(canceledUrls);
      const updatedCanceledUrlList = canceledUrlList.filter((item) => new Date().getTime() - item.time > 21600000);
      if (suggestedUrl) {
        updatedCanceledUrlList.push({
          url: suggestedUrl,
          time: new Date().getTime()
        });
      }
      window.localStorage.setItem('canceledUrls', JSON.stringify(updatedCanceledUrlList));
    } else {
      window.localStorage.setItem('canceledUrls', JSON.stringify([{ url: suggestedUrl, time: new Date().getTime() }]));
    }

    setSuggestedUrl('');
  };

  useEffect(() => {
    handleGetCopiedUrl();

    const checkVisibility = () => {
      if (document.visibilityState === 'visible') {
        handleGetCopiedUrl();
      }
    };

    document.addEventListener('visibilitychange', checkVisibility);

    return () => {
      document.removeEventListener('visibilitychange', checkVisibility);
    };
  }, []);

  if (!suggestedUrl) {
    return null;
  }

  return (
    <AbandonedBookmark
      handleClose={() => setSuggestedUrl('')}
      handleCancel={handleCancelSuggestion}
      url={suggestedUrl}
    />
  );
};

export default SaveBookmarkFromClipboard;
