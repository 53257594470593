import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  ResponsiveValue
} from '@chakra-ui/react';
import { useRef, useEffect, useState } from 'react';
import { Union } from 'types/types';

interface PasswordInputProps {
  label: string;
  placeholder?: string;
  error?: string;
  name: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  value: string;
  height?: ResponsiveValue<Union<number | 'px' | (string & {})>>;
  hideLabelOnMobile?: boolean;
}

const PasswordInput = ({
  value,
  handleChange,
  name,
  label,
  error,
  placeholder,
  height = '48px',
  hideLabelOnMobile
}: PasswordInputProps) => {
  const [show, setShow] = useState(false);
  const passwordInput = useRef<HTMLInputElement | null>(null);
  const selectionTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleChangeShow = () => {
    setShow((val) => !val);
    passwordInput.current?.focus();

    if (selectionTimeout.current) clearTimeout(selectionTimeout.current);

    selectionTimeout.current = setTimeout(() => {
      passwordInput.current?.setSelectionRange(value.length, value.length);
    }, 1);
  };

  useEffect(
    () => () => {
      if (selectionTimeout.current) clearTimeout(selectionTimeout.current);
    },
    []
  );

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel
        display={{ base: hideLabelOnMobile ? 'none' : 'block', lg: 'block' }}
        fontSize="inherit"
        fontWeight="inherit"
        lineHeight="19px"
        mb={{ base: '16px', md: '8px' }}
      >
        {label}
      </FormLabel>
      <InputGroup size="md">
        <Input
          variant="formInput"
          ref={passwordInput}
          name={name}
          value={value}
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
          h={height}
          p="12px 32px 12px 16px"
          onChange={(e) => handleChange(e, name)}
        />
        <InputRightElement zIndex={0} h="100%">
          <Button
            onClick={handleChangeShow}
            fontSize="14px"
            colorScheme="transparent"
            tabIndex={-1}
            color={value && !error ? 'light_blue.100' : 'light_gray'}
          >
            <i className={`fa-solid ${show ? 'fa-eye' : 'fa-eye-slash'}`} />
          </Button>
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default PasswordInput;
