import { ReactNode, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import applelogo from 'assets/image/applelogo.svg';
import { oauthLogin } from 'store/auth-reducer';
import { errorHandler } from 'utils/errorHandler';
import SocialAuthButton from './SocialAuthButton';

type AppleAuthButtonProps = {
  children: ReactNode;
};

const AppleAuthButton = ({ children }: AppleAuthButtonProps) => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const planSlug = searchParams.get('plan');
  const [isReady, setIsReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);

  const initClient = () => {
    window.AppleID.auth.init({
      clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
      redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
      scope: 'name email',
      usePopup: true
    });
    setIsReady(true);
  };

  useEffect(() => {
    if (!window.AppleID) {
      intervalId.current = setInterval(() => {
        if (window.AppleID && !isReady) {
          initClient();
          if (intervalId.current) clearInterval(intervalId.current);
        }
      }, 500);
    } else {
      initClient();
    }

    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
    };
  }, []);

  const handleLogin = async () => {
    setLoading(true);

    window.AppleID.auth
      .signIn()
      .then((res: any) => {
        dispatch(
          oauthLogin(
            {
              grant_type: 'apple_access_token',
              apple_access_token: res.authorization.id_token
            },
            planSlug
          )
        ).finally(() => {
          setLoading(false);
        });
      })
      .catch((error: any) => {
        if (error?.response?.status) {
          errorHandler(error, dispatch);
        }
        setLoading(false);
      });
  };

  return (
    <SocialAuthButton disabled={!isReady} loading={loading} onClick={handleLogin} icon={applelogo}>
      {children}
    </SocialAuthButton>
  );
};

export default AppleAuthButton;
