import { Flex, Square, Text } from '@chakra-ui/react';
import cn from 'classnames';
import HotKey from 'components/common/HotKey';
import OneFieldModal from 'components/common/popup/OneFieldModal';
import CommandIcon from 'components/BookmarksManager/Icons/CommandIcon';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { moveBookmarksList } from 'store/bookmark-reducer';
import { addNewFolder } from 'store/folder-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import { FolderType } from 'types/types';
import { VersionOSType } from 'utils/getOS';

import style from './Popup.module.scss';

type AddFolderProps = {
  onClose: () => void;
  workspaceId?: number;
  handleAddFolder?: (folder: FolderType) => void;
  bookmarkIds?: number[];
  toggleEditMode?: () => void;
};

export const AddFolder = ({
  onClose,
  workspaceId,
  handleAddFolder,
  bookmarkIds,
  toggleEditMode
}: AddFolderProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const OSVersion = useAppSelector((state) => state.app.versionOS);

  const { folderSlug, folderId } = useParams();
  const [errors, setErrors] = useState<{ [key: string]: string } | null>(null);
  const [loading, setLoading] = useState(false);

  const handleCreate = (name: string) => {
    if (errors) setErrors(null);
    setLoading(true);

    dispatch(addNewFolder(name, folderId, workspaceId, !!folderSlug))
      .then((folder: FolderType) => {
        if (bookmarkIds) {
          dispatch(moveBookmarksList(bookmarkIds ?? [], folder.id)).then(() => {
            if (toggleEditMode) {
              toggleEditMode();
            }
          });
        }
        onClose();
        if (handleAddFolder) {
          handleAddFolder(folder);
        }
      })
      .catch((err) => {
        setErrors(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <OneFieldModal
      errors={errors}
      title={folderId ? 'Create Sub-folder' : 'Create Folder'}
      onClose={onClose}
      setErrors={setErrors}
      handleSubmit={handleCreate}
      fieldValue=""
      placeholder="Folder name..."
      loading={loading}
      titleIcon={(
        <Square background="light_yellow.50" borderRadius="4px" size="28px">
          <i className={cn('fa-solid fa-folder', style.folderIcon)} />
        </Square>
      )}
      hotKeys={(
        <Flex display={{ base: 'none', lg: 'flex' }} alignItems="center" color="purple.500" gap="8px" fontWeight="500">
          <HotKey background="purple.500" color="white.50">
            {OSVersion === VersionOSType.Mac ? (
              <CommandIcon />
            ) : (
              <Text px="7px">
                <i className="fa-brands fa-windows" />
                {' '}
                Ctrl
              </Text>
            )}
          </HotKey>
          +
          <HotKey borderRadius="15px">
            <Text px="7px">Shift</Text>
          </HotKey>
          +
          <HotKey borderRadius="8px">F</HotKey>
        </Flex>
      )}
    />
  );
};
