import { HStack, Spacer } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { SnippetAddButton } from './AddButton/SnippetAddButton';
import { WorkspaceAddButton } from './AddButton/WorkspaceAddButton';
import Notifications from './Notifications/Notifications';
import { SearchFormWrapper } from './SearchForm/SearchFormWrapper';

import style from '../../Layouts/Layouts.module.scss';

const ToolsBar = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const isShare = location.pathname.startsWith('/share');
  const isWorkspace = location.pathname.startsWith('/workspace');
  const isSnippets = location.pathname.startsWith('/snippets');

  return (
    <HStack
      className={style.searchBar}
      position="fixed"
      justifyContent="space-between"
      bg="white.100"
      zIndex={2}
      maxH="80px"
      h="80px"
      p={{ base: '16px', md: '20px 16px', lg: '20px 32px' }}
      borderBottom="1px solid"
      borderColor="gray.100"
      spacing="0"
      gap="24px"
    >
      {children}

      {(isShare || isWorkspace || isSnippets) && <SearchFormWrapper whoseTags={isSnippets ? 'snippet' : 'bookmark'} hideTags={isShare} />}
      <Spacer display={{ base: 'none', lg: 'block' }} />
      <Notifications />

      {(isShare || isWorkspace) && <WorkspaceAddButton isShare={isShare} />}
      {isSnippets && <SnippetAddButton />}
    </HStack>
  );
};
export default ToolsBar;
