import { Box, Circle, Flex, Image, Spacer, Square, Stack, Tag, Text } from '@chakra-ui/react';
import { useAppSelector } from 'store/store';
import flag from 'assets/image/icons/flag.svg';
import diamond from 'assets/image/icons/diamond.svg';
import team from 'assets/image/icons/team.svg';
import PlanLimitWrapper from 'components/Wrappers/PlanLimitWrapper';
import { useEffect, useState } from 'react';

const icons = {
  basic: { icon: 'fa-solid fa-font-awesome', image: flag },
  pro: { icon: 'fa-regular fa-gem', image: diamond },
  team: { icon: 'fa-solid fa-users', image: team }
};

type UserPlanTagProps = {
  showDetails: boolean;
};

const UserPlanTag = ({ showDetails }: UserPlanTagProps) => {
  const workspaces = useAppSelector((state) => state.workspaces.workspacesList);
  const currentTeam = useAppSelector((state) => state.team.currentTeam);
  const plan = useAppSelector((state) => state.subscription.currentPlan?.subscription_plan?.plan);
  const [itemCounts, setItemCounts] = useState({
    foldersCount: 0,
    bookmarksCount: 0,
    workspaceCount: 0
  });

  const workspaceProgress = (itemCounts.workspaceCount / (plan?.workspaces_count ?? 1)) * 100;
  const folderProgress = (itemCounts.foldersCount / (plan?.folders_count ?? 1)) * 100;
  const bookmarkProgress = (itemCounts.bookmarksCount / (plan?.bookmarks_count ?? 1)) * 100;

  useEffect(() => {
    const counts = {
      foldersCount: 0,
      bookmarksCount: 0,
      workspaceCount: 0
    };
    workspaces.forEach((workspace) => {
      counts.bookmarksCount += workspace.bookmarks_count;
      counts.foldersCount += workspace.folders_count;
      counts.workspaceCount += 1;
    });

    setItemCounts(counts);
  }, [workspaces]);

  const workspaceStatus = !currentTeam && plan?.workspaces_count && workspaceProgress >= 90 ? 'danger' : 'success';
  const folderStatus = !currentTeam && plan?.folders_count && folderProgress >= 90 ? 'danger' : 'success';
  const bookmarkStatus = !currentTeam && plan?.bookmarks_count && bookmarkProgress >= 90 ? 'danger' : 'success';

  if (!plan) return <span />;

  return (
    <Circle
      position="relative"
      size="20px"
      border="2px solid"
      borderColor="white"
      bg="light_green.300"
      color="white"
      fontSize="9px"
      data-group
    >
      <i className={icons[plan.slug]?.icon} />
      {showDetails && (
        <Box
          w="208px"
          position="absolute"
          bottom="-6px"
          left="0"
          bg="white"
          transform="translate(0, 100%)"
          zIndex={3}
          boxShadow="0px 0px 16px rgba(101, 101, 101, 0.25)"
          borderRadius="8px"
          display="none"
          _groupHover={{
            display: 'block'
          }}
        >
          <Flex alignItems="center" w="100%" p="16px" borderBottom="1px solid" borderColor="gray.100" gap="8px">
            <Square size="20px" bg="gray.100" borderRadius="3px">
              <Image boxSize="16px" objectFit="contain" src={icons[plan.slug]?.image} />
            </Square>
            <Text fontWeight="600" fontSize="14px" lineHeight="150%" color="black">
              {plan.name}
            </Text>
            <Spacer />
            <Tag variant="status" colorScheme="status_green" borderRadius="4px" p="4px 10px" fontSize="12px">
              Current Plan
            </Tag>
          </Flex>
          <Stack w="100%" p="16px 13px" spacing="4px">
            <PlanLimitWrapper
              count={itemCounts.workspaceCount}
              progress={workspaceProgress}
              status={workspaceStatus}
            >
              <Square background="ghost_blue" color="light_blue.500" borderRadius="3px" size="20px" fontSize="10px">
                <i className="fa-solid fa-briefcase" />
              </Square>
            </PlanLimitWrapper>
            <PlanLimitWrapper
              count={itemCounts.foldersCount}
              progress={folderProgress}
              status={folderStatus}
            >
              <Square background="light_yellow.50" color="yellow.50" borderRadius="3px" size="20px" fontSize="10px">
                <i className="fa-solid fa-folder" />
              </Square>
            </PlanLimitWrapper>
            <PlanLimitWrapper
              count={itemCounts.bookmarksCount}
              progress={bookmarkProgress}
              status={bookmarkStatus}
            >
              <Square background="status_green.50" color="#44D600" borderRadius="3px" size="20px" fontSize="10px">
                <i className="fa-solid fa-bookmark" />
              </Square>
            </PlanLimitWrapper>
          </Stack>
        </Box>
      )}
    </Circle>
  );
};

export default UserPlanTag;
