import {
  Button,
  Center,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { notificationAPI } from 'api/notificationsApi';
import cn from 'classnames';
import { useEffect } from 'react';
import { actionsNotifications, getUnreadNotificationsCount } from 'store/notifications-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import style from './Notifications.module.scss';
import { NotificationsList } from './NotificationsList';

const Notifications = () => {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const userId = useAppSelector((state) => state.profile.user?.id);
  const notifications = useAppSelector((state) => state.notifications.notificationsList);
  const unreadNotificationsCount = useAppSelector((state) => state.notifications.unreadCount);
  const { pusher } = window;
  const setAllIsRead = () => {
    notificationAPI.setAllIsRead().then(() => {
      dispatch(actionsNotifications.setUnreadCount(0));
    });
  };

  useEffect(() => {
    dispatch(getUnreadNotificationsCount());

    const notificationsChannel = pusher.subscribe(`private-app.user.browser_notifications.${userId}`);

    notificationsChannel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (data: any) => {
      dispatch(actionsNotifications.setUnreadCount(data.unread_notifications_count));
    });

    return () => {
      pusher.unsubscribe(`private-app.user.browser_notifications.${userId}`);
    };
  }, []);

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement="bottom-start"
      closeOnBlur
      autoFocus
      variant="mobile"
    >
      <PopoverTrigger>
        <Button onClick={onToggle} boxSize="40px" colorScheme="transparent" className={style.container}>
          <i className={cn('fa-solid fa-bell', style.bell)} />
          {unreadNotificationsCount > 0 && <i className={cn('fa-solid fa-circle', style.circle)} />}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        position={{ base: 'fixed', md: 'relative' }}
        border="transparent"
        bg="white.500"
        boxShadow={{ base: 'none', md: '0px 2px 17px rgba(171, 171, 171, 0.45)' }}
        borderRadius={{ base: '0', md: '8px' }}
        w={{ base: '100%', md: '370px' }}
        h={{ base: '100%', md: '500px' }}
        overflow="hidden"
        inset={0}
      >
        <Flex
          position="relative"
          alignItems="center"
          h={{ base: '66px', md: '56px' }}
          mb={{ base: '16px', md: '8px' }}
          p="16px"
          borderBottom="1px solid"
          borderColor="gray.100"
        >
          <Text color="black" fontWeight="600">
            Notifications
          </Text>
          <Spacer />

          <Button
            colorScheme="transparent"
            color="light_gray"
            h={{ base: '24px', md: '100%' }}
            w={{ base: 'auto', md: '91px' }}
            fontSize={{ base: '14px', md: '12px' }}
            p={{ base: '0', md: '8px 24px' }}
            mr={{ base: '16px', md: '0' }}
            onClick={setAllIsRead}
            disabled={notifications.length === 0 || unreadNotificationsCount <= 0}
            _disabled={{
              color: 'light_gray',
              cursor: 'initial',
              _hover: {
                color: 'light_gray'
              }
            }}
            _hover={{
              color: '#225A94'
            }}
          >
            Mark all as read
          </Button>
          <Center
            as="button"
            display={{ base: 'flex', md: 'none' }}
            boxSize="34px"
            color="light_gray"
            fontSize="32px"
            onClick={onClose}
          >
            <i className="fa-solid fa-xmark" />
          </Center>
        </Flex>

        <NotificationsList />
      </PopoverContent>
    </Popover>
  );
};

export default Notifications;
