import { Flex, HStack, Image, Tag, Text } from '@chakra-ui/react';
import addToHomeScreen from '../../../../assets/image/icons/add_to_homeScreen.svg';
import logo from '../../../../assets/image/logo.svg';
import install_desktop from '../../../../assets/image/icons/install_desktop.svg';

type PropsType = {
  os: 'iOS' | 'android' | null;
  deviceType: 'mobile' | 'desktop' | null;
}

const InstructionList = ({ os, deviceType }: PropsType): JSX.Element => (
  <Flex mb="16px" direction="column" w="100%" gap="24px" fontSize="14px" fontWeight="400">
    <HStack spacing="12px">
      <Tag bg="white.50" fontSize="14px" border="1px solid" borderColor="gray.500" borderRadius="3px" color="gray.700" fontWeight="400">
        1
      </Tag>
      <Flex flexWrap="wrap">
        {deviceType === 'desktop' ? 'Click the ' : 'Tap on'}
        <Tag
          mx="8px"
          justifyContent="center"
          boxSize="24px"
          borderRadius="4px"
          border="0.7px solid"
          borderColor="light_gray"
          fontSize={os === 'iOS' ? '12px' : '13px'}
          color={os === 'iOS' ? '#2596BE' : 'gray.600'}
          bg="#F5F5F5"
          p="0"
        >
          {deviceType === 'desktop'
            ? <Image boxSize="16px" filter="invert(80%)" src={install_desktop} />
            : <i className={`fa-solid ${os === 'iOS' ? 'fa-arrow-up-from-bracket' : 'fa-ellipsis-vertical'}`} />}
        </Tag>
        {deviceType === 'desktop' ? 'on the tab bar' : 'in the tab bar'}
      </Flex>
    </HStack>

    <HStack spacing="12px">
      <Tag bg="white.50" fontSize="14px" border="1px solid" borderColor="gray.500" borderRadius="3px" color="gray.700" fontWeight="400">
        2
      </Tag>
      <Flex flexWrap="wrap">
        {deviceType === 'desktop' ? 'On the opened window click the' : 'Scroll and select'}
        <Tag
          mx={deviceType === 'desktop' ? '4px' : '8px'}
          justifyContent="center"
          borderRadius="4px"
          border="0.88px solid"
          borderColor="light_gray"
          color="gray.600"
          bg="#F1F1F1"
          fontSize="14px"
          gap="6px"
          maxH="24px"
        >
          {os === 'android' && <Image boxSize="14px" src={addToHomeScreen} />}

          <Text fontSize="12px">
            {os === 'iOS' ? 'Add to Home Screen' : `${deviceType === 'mobile' ? 'Install app' : 'Install'}`}
          </Text>
          {os === 'iOS' && <i className="fa-regular fa-square-plus" />}
        </Tag>
        {deviceType === 'desktop' && 'button'}
      </Flex>
    </HStack>

    <HStack spacing="12px">
      <Tag bg="white.50" fontSize="14px" border="1px solid" borderColor="gray.500" borderRadius="3px" color="gray.700" fontWeight="400">
        3
      </Tag>
      {deviceType === 'mobile' ? (
        <Flex flexWrap="wrap">
          Look for the
          <Image mx="8px" display="inline-flex" src={logo} boxSize="24px" objectFit="cover" />
          icon on your home screen
        </Flex>
      )
        : (
          <Flex>
            After installation, the app will automatically open
          </Flex>
        )}
    </HStack>
  </Flex>
);

export default InstructionList;
