import { Box, Square } from '@chakra-ui/react';
import AddFolderIcon from 'components/BookmarksManager/Icons/AddFolderIcon';

type AddFolderButtonProps = {
  onOpen: () => void;
}

const AddFolderButton = ({ onOpen }: AddFolderButtonProps) => (
  <Square
    size="94px"
    borderRadius="12px"
    bg="#F7F7F7"
    cursor="pointer"
    transition="150ms"
    _hover={{ bg: '#EAEAEA' }}
    data-group
    onClick={onOpen}
  >
    <Box
      transition="150ms"
      _groupHover={{
        transform: 'scale(.9)'
      }}
    >
      <AddFolderIcon />
    </Box>
  </Square>
);

export default AddFolderButton;
