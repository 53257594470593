import { TrackerOptions, TrackerType } from 'types/types';
import { requestInstance, EditType, GetItemsType } from './api';

export const trackerAPI = {
  async getTrackerList(page: number) {
    const res = await requestInstance().get<GetItemsType<Array<TrackerType>>>(`v1/bookmark/tracker/get?page=${page}`);

    return res.data;
  },
  async createTracker(bookmarkId: number | string, reminderOptions: TrackerOptions) {
    const res = await requestInstance().post<GetItemsType<TrackerType>>(`v1/bookmark/tracker/${bookmarkId}`, reminderOptions);

    return res.data;
  },
  async updateTracker(trackerId: number | string, reminderOptions: TrackerOptions) {
    const res = await requestInstance().patch<GetItemsType<TrackerType>>(`v1/bookmark/tracker/${trackerId}`, reminderOptions);

    return res.data;
  },
  async deleteTracker(trackerId: number | string) {
    const res = await requestInstance().delete<EditType>(`v1/bookmark/tracker/${trackerId}`);

    return res.data;
  }
};
