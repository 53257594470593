import { Box, Center, Spinner, Flex, StackDivider, Text, VStack } from '@chakra-ui/react';
import ReminderBookmarkItem from 'components/BookmarksManager/Reminder/ReminderBookmarkItem';
import SearchInput from 'components/common/inputs/SearchInput';
import { usePaginationObserver } from 'hooks/usePaginationObserver';
import { useAppSelector } from 'store/store';
import { BookmarkType } from 'types/types';

type BookmarksListProps = {
  handlePickBookmark: (bookmark: BookmarkType) => void;
  keyword?: string;
  handleSearch: (value: string) => void;
  setCurrentPage: (value: React.SetStateAction<number>) => void;
  currentPage: number;
};

const BookmarksList = ({
  handlePickBookmark,
  keyword = '',
  handleSearch,
  setCurrentPage,
  currentPage
}: BookmarksListProps) => {
  const { data: bookmarks, isFetching: bookmarksLoading, pagination } = useAppSelector((state) => state.bookmarks);
  const { setLastElement } = usePaginationObserver({ setCurrentPage });

  return (
    <Flex
      position="relative"
      direction="column"
      p={{ base: '16px 16px 0', sm: '16px 24px 0 16px', ml: '24px 24px 0' }}
      flex="1"
    >
      <Text
        display={{ base: 'block', ml: 'none' }}
        fontWeight="600"
        fontSize={{ base: '12px', sm: '14px' }}
        lineHeight="150%"
        pb="10px"
        mb="16px"
        borderBottom="1px solid"
        borderColor="gray.100"
      >
        Bookmarks
      </Text>
      <Box
        maxW="299px"
        w="100%"
        ml={{ base: '0', sm: '8px' }}
        minH="32px"
        h="32px"
        mb="24px"
        pr={{ base: '0', ml: '48px' }}
      >
        <SearchInput iconPlacement="left" value={keyword} onChange={handleSearch} />
      </Box>

      <VStack
        flex="1 1 auto"
        h="100%"
        overflowY="auto"
        divider={<StackDivider borderColor="gray.100" />}
        spacing="8px"
        pb="24px"
      >
        {bookmarks.map((bookmark, idx) => (idx === bookmarks.length - 6 && currentPage < pagination.total_pages && !bookmarksLoading ? (
          <Box key={`${bookmark.id}_${idx}`} ref={setLastElement} w="100%">
            <ReminderBookmarkItem handleClick={() => handlePickBookmark(bookmark)} bookmark={bookmark} />
          </Box>
        ) : (
          <Box key={`${bookmark.id}_${idx}`} w="100%">
            <ReminderBookmarkItem handleClick={() => handlePickBookmark(bookmark)} bookmark={bookmark} />
          </Box>
        )
        ))}

        {bookmarksLoading && (
          <Center>
            <Spinner color="green" />
          </Center>
        )}
      </VStack>

      <Box
        position="absolute"
        left="0"
        bottom="0"
        w="100%"
        h="64px"
        bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 46.35%, #FFFFFF 100%)"
        pointerEvents="none"
      />
    </Flex>
  );
};

export default BookmarksList;
