import { useState, useEffect } from 'react';
import { Flex, Stack, Text, Link } from '@chakra-ui/react';
import { useFormik } from 'formik';
import AuthLayout from 'components/BookmarksManager/Layouts/AuthLayout';
import UserIcon from 'components/Auth/UserIcon';
import FormInput from 'components/common/inputs/FormInput';
import PasswordInput from 'components/common/inputs/PasswordInput';
import SubmitButton from 'components/common/buttons/SubmitButton';
import JoinWith from 'components/Auth/JoinWith';
import { Link as ReactLink, useSearchParams } from 'react-router-dom';

import { useAppDispatch } from 'store/store';
import { signup } from 'store/auth-reducer';
import { getTeamByToken } from 'store/team-reducer';
import { TeamInvitationType } from 'types/types';

import bgimage from 'assets/image/linker.webp';

const SignUp = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [teamInvitation, setTeamInvitation] = useState<TeamInvitationType | undefined>();

  const [searchParams] = useSearchParams();
  const planSlug = searchParams.get('plan');
  const invitationToken = searchParams.get('invitation_token');
  const joinToken = searchParams.get('join_token');

  useEffect(() => {
    if (invitationToken || joinToken) {
      dispatch(getTeamByToken(invitationToken ?? (joinToken as string), !!joinToken)).then((res) => {
        setTeamInvitation(res);
      });
    }
  }, [invitationToken, joinToken]);

  const formik = useFormik({
    initialValues: { name: '', email: '', password: '' },
    onSubmit: ({ email, name, password }) => {
      setIsLoading(true);
      dispatch(signup(teamInvitation?.email ?? email, name, password, planSlug))
        .then((url) => {
          if (joinToken || invitationToken) {
            window.location.replace(
              joinToken ? `/join?join_token=${joinToken}` : `/invitation?invitation_token=${invitationToken}`
            );
          }
          if (url) window.open(String(url), '_blank');
        })
        .catch((errors) => {
          setIsLoading(false);
          formik.setStatus(errors);
          window.scrollTo(0, 0);
        });
    }
  });
  const { name, email, password } = formik.values;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    formik.handleChange(e);
    if (formik.status?.[key]) formik.setStatus({ ...formik.status, [key]: undefined });
  };

  return (
    <AuthLayout
      image={bgimage}
      title="Sign Up"
      description={teamInvitation && `Sign up to join <b>${teamInvitation.team.name}</b> Team`}
      teamInvitation={teamInvitation}
      icon={<UserIcon background="gradient.150" />}
    >
      <form onSubmit={formik.handleSubmit}>
        <Flex mt={{ base: '32px', lg: '48px' }} w="100%" flexDir="column">
          <Stack spacing={{ base: '16px', lg: '24px' }} mb="32px" fontWeight="400">
            <FormInput
              value={name}
              handleChange={onChange}
              name="name"
              label="Name"
              placeholder="Enter your name..."
              autoComplete="off"
              error={formik.status?.name}
              hideLabelOnMobile
            />
            <FormInput
              value={teamInvitation?.email ?? email}
              disabled={!!teamInvitation?.email}
              handleChange={onChange}
              name="email"
              label="Email"
              type="email"
              placeholder="Enter your Email..."
              error={formik.status?.email}
              hideLabelOnMobile
            />
            <PasswordInput
              value={password}
              handleChange={onChange}
              name="password"
              label="Password"
              placeholder="Enter your password..."
              error={formik.status?.password}
              hideLabelOnMobile
            />
          </Stack>
          <SubmitButton isLoading={isLoading}>Sign Up</SubmitButton>
          <JoinWith
            appleLabel="Continue with Apple ID"
            googleLabel="Continue with Google"
            fbLabel="Continue with Facebook"
          />
          <Text align="center" mt="24px" fontSize="14px">
            Already have an account?
            {' '}
            <Link
              as={ReactLink}
              to={`/login${invitationToken ? `?invitation_token=${invitationToken}` : ''}${joinToken ? `?join_token=${joinToken}` : ''
                }`}
              color="light_blue.100"
            >
              Log In
            </Link>
          </Text>
        </Flex>
      </form>
    </AuthLayout>
  );
};

export default SignUp;
