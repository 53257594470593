import { Center, Spinner } from '@chakra-ui/react';

export const BlurLoading = ({ isTransparent }: {isTransparent? : boolean}) => (
  <Center
    zIndex="999"
    position="absolute"
    top="0"
    left="0"
    w="100%"
    h="100%"
    bg={isTransparent ? 'transparent' : 'rgba(194, 194, 194, 0.25)'}
    backdropFilter="blur(2px)"
  >
    <Spinner color="green" boxSize="32px" />
  </Center>
  );
