import { PlanType, SubscriptionDetailsType } from 'types/types';
import { requestInstance, ResponseType, checkParam } from './api';

export const subscriptionAPI = {
  async getDetails() {
    const res = await requestInstance().get<ResponseType<SubscriptionDetailsType>>('v1/subscription/details');

    return res.data;
  },
  async upgradePlan(stripePlan: string) {
    const res = await requestInstance().post('v1/subscription/swap', { stripe_plan: stripePlan });

    return res.data;
  },
  async getListPlans(period?: string) {
    const res = await requestInstance()
      .get<ResponseType<Array<PlanType>>>(`v1/subscription/plans?filter_prices_by_duration=${checkParam(period)} `);

    return res.data;
  },
  async getBillingPortalUrl() {
    const res = await requestInstance()
      .post<ResponseType<{ url: string }>>('v1/subscription/billing-portal-url');

    return res.data;
  },
  async getPaymentMethod() {
    const res = await requestInstance()
      .post<ResponseType<{ url: string }>>('v1/subscription/payment-method');

    return res.data;
  }
};
