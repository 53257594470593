import { useEffect, useRef, useState } from 'react';
import { Box, Center, Flex, Stack, Text, Button } from '@chakra-ui/react';
import { NavLink, Outlet } from 'react-router-dom';
import cn from 'classnames';

import { getBillingPortalUrl } from 'store/subscription-reducer';
import { useAppDispatch } from 'store/store';
import style from './Layouts.module.scss';

const SettingsLayout = () => {
  const dispatch = useAppDispatch();
  const selected = ({ isActive }: any) => cn(style.link_container, { [style.active]: isActive });
  const scrollBar = useRef<HTMLDivElement | null>(null);
  const timeOutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleScrollTo = (element: Element) => {
    timeOutId.current = setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }, 100);
  };

  const scrollToLink = (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (window.innerWidth < 576) {
      if (scrollBar.current) {
        if (event) {
          const element = event.target as Element;
          handleScrollTo(element);
        } else {
          const elements = Array.from(scrollBar.current.children);
          elements.forEach((element) => {
            if (element.classList.contains(style.active)) {
              handleScrollTo(element);
            }
          });
        }
      }
    }
  };

  useEffect(() => {
    scrollToLink();
  }, [scrollBar.current]);

  useEffect(
    () => () => {
      if (timeOutId.current) {
        clearTimeout(timeOutId.current);
      }
    },
    [timeOutId]
  );

  const goToBillingPortal = () => {
    setIsDisabled(true);
    dispatch(getBillingPortalUrl())
      .then((url) => {
        window.open(url, '_self');
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  return (
    <Flex p={{ base: '0', lg: '24px 32px' }} w="100%" minH="100%">
      <Flex
        flexDir={{ base: 'column', lg: 'row' }}
        flex="1"
        w="100%"
        border={{ base: 'none', lg: '1px solid' }}
        borderColor={{ base: 'none', lg: 'gray.100' }}
        borderRadius="8px"
      >
        <Box
          flex={{ base: 'unset', lg: '1' }}
          w={{ base: '100%', lg: '262px' }}
          minW="200px"
          p={{ base: '0', lg: '24px' }}
          borderRight={{ base: 'none', lg: '1px solid' }}
          borderColor={{ base: 'none', lg: 'gray.100' }}
        >
          <Text m={{ base: '16px 24px 24px', lg: '0 0 24px' }} fontWeight="600" lineHeight="19px" color="gray.600">
            Settings
          </Text>
          <Stack
            w="100%"
            spacing={{ base: '4px', lg: '8px' }}
            direction={{ base: 'row', lg: 'column' }}
            overflowX={{ base: 'auto', lg: 'unset' }}
            borderBottom={{ base: '1px solid', lg: 'none' }}
            borderColor={{ base: 'gray.100', lg: 'none' }}
            px={{ base: '12px', lg: '0' }}
            sx={{
              '::-webkit-scrollbar': {
                display: 'none'
              }
            }}
            ref={scrollBar}
          >
            <NavLink className={selected} to="/settings/account" onClick={scrollToLink}>
              <Center boxSize="16px" fontSize="16px">
                <i className="fa-solid fa-circle-user" />
              </Center>
              <Text fontSize="14px" lineHeight="17px">
                Account
              </Text>
            </NavLink>

            <NavLink className={selected} to="/settings/password" onClick={scrollToLink}>
              <Center boxSize="16px" fontSize="16px">
                <i className="fa-solid fa-key" />
              </Center>
              <Text fontSize="14px" lineHeight="17px">
                Password
              </Text>
            </NavLink>

            <NavLink className={selected} to="/settings/notifications" onClick={scrollToLink}>
              <Center boxSize="16px" fontSize="16px">
                <i className="fa-solid fa-bell" />
              </Center>
              <Text fontSize="14px" lineHeight="17px">
                Notifications
              </Text>
            </NavLink>

            <NavLink className={selected} to="/settings/integrations" onClick={scrollToLink}>
              <Center boxSize="16px" fontSize="16px">
                <i className="fa-solid fa-puzzle-piece" />
              </Center>
              <Text fontSize="14px" lineHeight="17px">
                Integrations
              </Text>
            </NavLink>

            <NavLink className={selected} to="/settings/subscription" onClick={scrollToLink}>
              <Center boxSize="16px" fontSize="16px">
                <i className="far fa-gem" />
              </Center>
              <Text fontSize="14px" lineHeight="17px">
                Subscription
              </Text>
            </NavLink>

            <Button
              disabled={isDisabled}
              variant="transparent"
              justifyContent="flex-start"
              onClick={goToBillingPortal}
              p={{ base: '12px 12px 20px 12px', lg: '8px 10px' }}
              borderRadius={{ base: '0', lg: '4px' }}
              display="flex"
              minW="fit-content"
              color="gray.700"
              gap="10px"
              borderBottom={{ base: '4px solid', lg: 'none' }}
              borderColor="transparent"
              _hover={{
                background: { base: 'none', lg: 'white.600' },
                color: 'gray.600',
                borderColor: { base: 'green.100', lg: 'transparent' }
              }}
            >
              <Center boxSize="16px" fontSize="16px">
                <i className="fa-solid fa-credit-card" />
              </Center>
              <Text fontSize="14px" lineHeight="17px">
                Billing
              </Text>
            </Button>

            <NavLink className={selected} to="/settings/sessions" onClick={scrollToLink}>
              <Center boxSize="16px" fontSize="16px">
                <i className="fa-solid fa-desktop" />
              </Center>
              <Text fontSize="14px" lineHeight="17px">
                Sessions
              </Text>
            </NavLink>
          </Stack>
        </Box>
        <Flex direction="column" flex={4} overflowX="auto">
          <Outlet />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SettingsLayout;
