export enum VersionOSType {
  Windows,
  Mac,
  Linux,
  unknown
}

export const getOS = () => {
  if (navigator.userAgent.indexOf('Win') !== -1) return VersionOSType.Windows;
  if (navigator.userAgent.indexOf('Mac') !== -1) return VersionOSType.Mac;
  if (navigator.userAgent.indexOf('Linux') !== -1) return VersionOSType.Linux;

  return VersionOSType.unknown;
};
