import { environmentAPI } from 'api/environmentApi';
import { profileAPI } from 'api/profileApi';
import Cookies from 'universal-cookie';
import { getCurrentHost } from 'utils/currentHost';
import { errorHandler } from 'utils/errorHandler';
import {
  EnvironmentType,
  InferActionsTypes, UploadImage,
  UserType
} from '../types/types';
import { InitializationApp, showAlert } from './app-reducer';
import { refreshSession } from './auth-reducer';
import { AppThunkType } from './store';
import { getCurrentPlan } from './subscription-reducer';
import { getCurrentTeam } from './team-reducer';

const cookies = new Cookies();

const initialState = {
  user: null as UserType | null,
  environmentsList: [] as Array<EnvironmentType>,
  currentEnvironment: {
    name: '',
    type: 'personal',
    team_id: null,
    is_own: true,
    image: null
  } as EnvironmentType
};

export const actionsProfile = {
  setUserProfile: (data: UserType | null) => ({ type: 'SET_USER_DATA', data }) as const,
  setEnvironmentsList: (data: Array<EnvironmentType>) => ({ type: 'SET_ENVIRONMENT_LIST', data }) as const,
  setCurrentEnvironment: (data: EnvironmentType) => ({ type: 'SET_CURRENT_ENVIRONMENT', data }) as const,
  cleanUp: () => ({ type: 'CLEAN_UP_PROFILE_DATA' }) as const,
  cleanUpCurrentEnvironment: () => ({ type: 'CLEAN_UP_CURRENT_ENVIRONMENT_DATA' }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsProfile>

const profileReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_USER_DATA':
      return {
        ...state,
        user: action.data
      };
    case 'SET_ENVIRONMENT_LIST':
      return {
        ...state,
        environmentsList: action.data
      };
    case 'CLEAN_UP_PROFILE_DATA':
      return {
        ...state,
        user: null,
        environmentsList: [],
        currentEnvironment: {
          name: '',
          type: 'personal',
          team_id: null,
          is_member: true,
          is_own: true
        } as EnvironmentType
      };
    case 'SET_CURRENT_ENVIRONMENT':
      return {
        ...state,
        currentEnvironment: action.data
      };
    case 'CLEAN_UP_CURRENT_ENVIRONMENT_DATA':
      return {
        ...state,
        currentEnvironment: {
          name: '',
          type: 'personal',
          team_id: null,
          is_own: true,
          is_member: true,
          image: null
        }
      };

    default:
      return state;
  }
};

export const getProfile = (): AppThunkType => async (dispatch) => {
  try {
    const response = await profileAPI.getProfile();

    if (response.success) {
      dispatch(InitializationApp()).then(() => {
        dispatch(actionsProfile.setUserProfile(response.data));
        dispatch(getCurrentPlan());
      });
    }

    return Promise.resolve();
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const autoLogin = (): AppThunkType => async (dispatch) => {
  const refreshToken = cookies.get('refresh_token');
  try {
    await dispatch(getProfile());

    return Promise.resolve();
  } catch (error: any) {
    if (error.response.status === 401 && refreshToken) {
      return dispatch(refreshSession(refreshToken)).catch((err: any) => Promise.reject(err));
    }

    return Promise.reject(error);
  }
};

export const updateUserName = (newName: string): AppThunkType => async (dispatch, getState) => {
  const profile = getState().profile.user;
  try {
    const response = await profileAPI.updateUserName(newName);
    if (response.success && profile) {
      dispatch(actionsProfile.setUserProfile({ ...profile, name: newName }));
      dispatch(showAlert(response.data.message, true));
    }

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const updateUserAvatar = (image: UploadImage): AppThunkType => async (dispatch, getState) => {
  const profile = getState().profile.user;
  try {
    const response = await profileAPI.updateUserAvatar(image);
    if (response.success && profile) {
      dispatch(actionsProfile.setUserProfile({ ...profile, avatar: response.data }));
      dispatch(showAlert('User photo changed successfully', true));
    }

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export const resendVerificationCode = (): AppThunkType => async (dispatch) => {
  try {
    const response = await profileAPI.resendVerificationCode();
    dispatch(showAlert(response.data.message, response.success));

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export const verifyAccount = (code: string): AppThunkType => async (dispatch, getState) => {
  const { user } = getState().profile;
  try {
    const response = await profileAPI.verifyAccount(code);
    dispatch(showAlert(response.data.message, true));
    if (user) {
      dispatch(actionsProfile.setUserProfile({ ...user, verified: true }));
    }

    return Promise.resolve();
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const getEnvironments = (env?: { env_type: string, env_team: number | null }): AppThunkType => async (dispatch) => {
  try {
    const response = await environmentAPI.getEnvironmentList();
    dispatch(actionsProfile.setEnvironmentsList(response.data));
    if (env) {
      const envItem = response.data.find(
        (item) => item.type === env.env_type && item.team_id === env.env_team
      );
      if (envItem) {
        dispatch(actionsProfile.setCurrentEnvironment(envItem));
        await dispatch(getCurrentTeam(envItem.team_id));
      }
    }

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const updatePassword = (
  password: string,
  passwordConfirmation: string,
  oldPassword: string
): AppThunkType => async (dispatch, getState) => {
  const user = getState().profile.user as UserType;

  try {
    const response = await profileAPI.setPassword(password, passwordConfirmation, oldPassword);
    if (!oldPassword) {
      dispatch(actionsProfile.setUserProfile({ ...user, has_password: true }));
    }
    dispatch(showAlert('Password successfully updated!', true));
    cookies.set('token', response.token, {
      path: '/',
      expires: new Date(Date.now() + 30 * 86400000),
      domain: getCurrentHost()
    });

    return Promise.resolve(response);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export default profileReducer;
