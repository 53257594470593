import { Box, Flex, Image, Input, Square, Stack, Text, Wrap } from '@chakra-ui/react';
import SubmitButton from 'components/common/buttons/SubmitButton';
import UploadImage from 'components/common/UploadImage';
import CommonItemWrapper from 'components/Wrappers/CommonItemWrapper';
import EditFormControlWrapper from 'components/Wrappers/EditFormControlWrapper';
import { useFormik } from 'formik';
import { Dispatch, ReactNode, useRef, useState } from 'react';
import { Area } from 'react-easy-crop';
import { AppThunkType, useAppDispatch, useAppSelector } from 'store/store';
import { Team, UpdateWorkspace, WorkspaceType } from 'types/types';
import { ImageCropper } from '../ImageCropper';

type EditManagementPropsType = {
  icon: ReactNode;
  title: string;
  item: WorkspaceType | Team;
  onClose: () => void;
  children?: ReactNode;
  setTargetItem: Dispatch<React.SetStateAction<any>>;
  updateAction: (id: number, data: UpdateWorkspace) => AppThunkType<Promise<any>>;
  deleteImageAction: (id: number) => AppThunkType<Promise<any>>
}

const EditManagement = ({
  icon, title, item, children, onClose, setTargetItem, updateAction, deleteImageAction
}: EditManagementPropsType): JSX.Element => {
  const dispatch = useAppDispatch();
  const teamId = useAppSelector((state) => state.profile.currentEnvironment.team_id);
  const imageRef = useRef<HTMLInputElement | null>(null);

  const [imagePreview, setImagePreview] = useState<string>();
  const [croppedImage, setCroppedImage] = useState('');
  const [cropArea, setCropArea] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0
  });
  const [image, setImage] = useState<File | null>(null);

  const formik = useFormik({
    initialValues: {
      name: item.name
    },
    onSubmit: (value) => {
      dispatch(
        updateAction(+item.id, {
          name: value.name,
          image: image ? { file: image, ...cropArea } : undefined,
          team_id: teamId
        })

      )
        .then(() => {
          onClose();
        })
        .catch((errors: any) => {
          formik.setStatus(errors);
        }).finally(() => {
          formik.setSubmitting(false);
        });
    }
  });

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
      const preview = URL.createObjectURL(e.target.files[0]);
      setImagePreview(preview);
    }
  };

  const resetImage = () => {
    setImagePreview(undefined);
    if (imageRef.current) imageRef.current.value = '';
  };

  const getImage = (img: string, area: Area) => {
    setCroppedImage(img);
    resetImage();
    setCropArea(area);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    if (formik.status?.name) formik.setStatus({ ...formik.status, name: undefined });
  };

  const deleteAvatar = async () => {
    if (croppedImage) {
      resetImage();
      setCroppedImage('');
      setImage(null);

      return Promise.resolve();
    }

    return dispatch(deleteImageAction(Number(item.id)))
      .then((updatedWorkspace: WorkspaceType) => {
        setCroppedImage('');
        formik.setSubmitting(false);
        setTargetItem({ ...item, image: updatedWorkspace.image });

        return Promise.resolve();
      })
      .catch((e) => Promise.reject(e));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex direction="column">
        <Stack w="100%" spacing="24px">
          <Flex alignItems="center">
            {icon}
            <Text mx="12px" fontWeight="600" fontSize="16px" lineHeight="19px">
              {title}
            </Text>
          </Flex>

          <Box w="100%" bg="white.200" p={{ base: '0', md: '24px' }}>
            <Flex flexDirection={{ base: 'column', md: 'row' }} gap="22px">
              <Square
                background="white.500"
                borderRadius="8px"
                border="1px solid"
                borderColor={{ base: 'transparent', md: 'gray.50' }}
                p="16px"
              >
                <UploadImage
                  size={window.innerWidth < 576 ? '168px' : '117px'}
                  iconSize={window.innerWidth < 576 ? '56px' : '40px'}
                  image={croppedImage || item.image}
                  imageRef={imageRef}
                  handleChange={handleUpload}
                  deleteImage={deleteAvatar}
                >
                  {croppedImage ? (
                    <Image boxSize="100%" objectFit="cover" src={croppedImage} />
                  ) : (
                    <Image boxSize="100%" objectFit="cover" src={item.image} />
                  )}
                </UploadImage>
              </Square>

              <Stack spacing="16px" w="100%">
                <EditFormControlWrapper error={formik.status?.name} label="name:">
                  <Input
                    name="name"
                    bg="white.500"
                    variant="formInput"
                    autoComplete="off"
                    placeholder={item.name}
                    value={formik.values.name}
                    onChange={handleChange}
                    height={{ base: '48px', md: '40px' }}
                  />
                </EditFormControlWrapper>

                <EditFormControlWrapper label="Actives:">
                  <Wrap spacing="9px">
                    {('users_count' in item) && (
                      <CommonItemWrapper>
                        <Square
                          background="gradient.280"
                          color="white.500"
                          borderRadius="4px"
                          size="20px"
                          fontSize="10px"
                        >
                          <i className="fa-solid fa-user" />
                        </Square>
                        {item.users_count}
                      </CommonItemWrapper>
                    )}
                    {('workspaces_count' in item) && (
                      <CommonItemWrapper>
                        <Square
                          background="gradient.290"
                          color="white.500"
                          borderRadius="4px"
                          size="20px"
                          fontSize="10px"
                        >
                          <i className="fa-solid fa-briefcase" />
                        </Square>
                        {item.workspaces_count}
                      </CommonItemWrapper>
                    )}
                    <CommonItemWrapper>
                      <Square
                        background="gradient.170"
                        color="white.500"
                        borderRadius="4px"
                        size="20px"
                        fontSize="10px"
                      >
                        <i className="fa-solid fa-folder" />
                      </Square>
                      {item.folders_count}
                    </CommonItemWrapper>
                    <CommonItemWrapper>
                      <Square
                        background="gradient.300"
                        color="white.500"
                        borderRadius="4px"
                        size="20px"
                        fontSize="11px"
                      >
                        <i className="fa-solid fa-bookmark" />
                      </Square>
                      {item.bookmarks_count}
                    </CommonItemWrapper>
                  </Wrap>
                </EditFormControlWrapper>
              </Stack>
            </Flex>

            <Flex pt="16px" justifyContent="flex-end">
              <SubmitButton
                width={{ base: '100%', md: 'fit-content' }}
                isLoading={formik.isSubmitting}
              >
                Save Changes
              </SubmitButton>
            </Flex>
          </Box>
          {children}
        </Stack>
      </Flex>

      {imagePreview && <ImageCropper getImage={getImage} image={imagePreview} onClose={resetImage} />}
    </form>
  );
};

export default EditManagement;
