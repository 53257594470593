import { ReactNode, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { oauthLogin } from 'store/auth-reducer';
import googlelogo from 'assets/image/googlelogo.svg';
import SocialAuthButton from './SocialAuthButton';

type GoogleAuthButtonProps = {
  children: ReactNode;
};

const GoogleAuthButton = ({ children }: GoogleAuthButtonProps) => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const planSlug = searchParams.get('plan');

  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [client, setClient] = useState<any>();
  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (!window.google) {
      intervalId.current = setInterval(() => {
        if (window.google && !isReady) {
          initClient();
          if (intervalId.current) clearInterval(intervalId.current);
        }
      }, 500);
    } else {
      initClient();
    }

    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
    };
  }, []);

  function initClient() {
    const { google } = window;
    if (google) {
      const googleClient = google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        ux_mode: 'popup',
        callback: (response: any) => {
          setLoading(true);
          dispatch(
            oauthLogin(
              {
                grant_type: 'google_access_token',
                google_access_token: response.access_token
              },
              planSlug
            )
          ).finally(() => {
            setLoading(false);
          });
        }
      });
      setClient(googleClient);
    }
  }
  const getAuthCode = () => {
    client.requestAccessToken();
  };

  useEffect(() => {
    if (client && !isReady) {
      setIsReady(true);
    }
  }, [client]);

  return (
    <SocialAuthButton disabled={!isReady} onClick={getAuthCode} loading={loading} icon={googlelogo}>
      {children}
    </SocialAuthButton>
  );
};

export default GoogleAuthButton;
