export const plansList = {
    basic: [
        '3 Workspaces',
        '100 Bookmarks',
        '10 Folders',
        'Sort Bookmarks',
        'Share Bookmarks',
        'iOS & Android App',
        'Sync via Google Chrome Extension'
    ],
    pro: [
        'Everything from Basic',
        'Unlimited Workspaces',
        'Unlimited Folders',
        'Sub Folders',
        'Shared Folders',
        'Bookmark Reminder',
        'Bookmark Content Changes Tracker'
    ],
    team: [
        'Everything from Personal plan',
        'Unlimited Users',
        'Unlimited Workspaces',
        'Integration with Slack',
        'Import Bookmarks from Excel, CSV'
    ]
};

export type PlansListType = typeof plansList
