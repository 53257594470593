import { Box, IconButton, Modal, ModalBody, ModalContent, ModalOverlay, ResponsiveValue } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Union } from 'types/types';

interface PopupProps {
  children: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  width?: ResponsiveValue<Union<string | 'px' | (string & {})>>;
  padding?: ResponsiveValue<Union<string | 'px' | (string & {})>>;
  hasCloseButton?: boolean;
  blockScrollOnMount?: boolean;
  closeOnOverlayClick?: boolean;
  iconColor?: ResponsiveValue<Union<string | 'px' | (string & {})>>;
  borderRadius?: ResponsiveValue<Union<string | 'px' | (string & {})>>;
  maxW?: ResponsiveValue<Union<string | 'px' | (string & {})>>;
  isCentered?: boolean;
  overflow?: string;
  hasBottomIndent?: boolean
}

const Popup = ({
  children,
  isOpen,
  onClose = () => {},
  width = '500px',
  padding = { base: '16px', md: '32px' },
  hasCloseButton,
  blockScrollOnMount = false,
  closeOnOverlayClick = true,
  isCentered = true,
  iconColor = { base: 'light_gray', md: 'light_gray', lg: 'white.50' },
  maxW = { base: 'calc(100vw - 40px)', lg: `${width}` },
  borderRadius = '12px',
  overflow = 'hidden',
  hasBottomIndent
}: PopupProps) => (
  <Modal
    closeOnOverlayClick={closeOnOverlayClick}
    blockScrollOnMount={blockScrollOnMount}
    autoFocus
    onClose={onClose}
    isOpen={isOpen}
    isCentered={isCentered}
  >
    <ModalOverlay background="rgba(194, 194, 194, 0.25)" backdropFilter="blur(5px)" />
    <ModalContent w={width} maxW={maxW} my="auto" bg="transparent" boxShadow="none">
      <Box
        w="100%"
        position="relative"
        boxShadow="0px 0px 16px rgba(101, 101, 101, 0.25)"
        borderRadius={borderRadius}
        bg="white"
        m={{ base: hasBottomIndent ? '0 0 150px' : '0', md: '15px 0' }}
      >
        {hasCloseButton && (
          <IconButton
            zIndex="99"
            position="absolute"
            top={{ base: '14px', md: `calc(${padding} + 0px)`, lg: '0' }}
            right={{ base: '45px', md: `calc(${padding} + 30px)`, lg: '-12px' }}
            transform="translateX(100%)"
            onClick={onClose}
            aria-label="Close"
            size="xs"
            boxSize="28px"
            bg={{ base: 'transparent', lg: 'rgba(27, 27, 27, 0.6)' }}
            color={iconColor}
            _hover={{
              base: { bg: 'transparent' },
              lg: { bg: 'rgba(27, 27, 27, 0.75)' }
            }}
            isRound
            fontSize={{ base: '32px', lg: '14px' }}
          >
            <i className="fa-solid fa-xmark" />
          </IconButton>
        )}
        <ModalBody borderRadius={borderRadius} overflow={overflow} p={padding}>
          {children}
        </ModalBody>
      </Box>
    </ModalContent>
  </Modal>
);

export default Popup;
