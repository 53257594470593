import { useEffect } from 'react';
import { Flex, Stack, Text, Link, Button } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { Link as ReactLink } from 'react-router-dom';

import AuthLayout from 'components/BookmarksManager/Layouts/AuthLayout';
import FormInput from 'components/common/inputs/FormInput';
import SubmitButton from 'components/common/buttons/SubmitButton';
import KeyIcon from 'components/Auth/KeyIcon';
import { useAppDispatch, useAppSelector } from 'store/store';
import { actionsAuth, forgetPassword } from 'store/auth-reducer';

import bgimage from 'assets/image/login.webp';

const ForgetPassword = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.popup.isLoading);
  const successMessage = useAppSelector((state) => state.auth.resetPasswordMessage);

  const formik = useFormik({
    initialValues: { email: '' },
    onSubmit: ({ email }) => {
      dispatch(forgetPassword(email)).catch((errors) => {
        formik.setStatus(errors);
        window.scrollTo(0, 0);
      });
    }
  });
  const { email } = formik.values;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    formik.handleChange(e);
    if (formik.status?.[key]) formik.setStatus({ ...formik.status, [key]: undefined });
  };

  useEffect(
    () => () => {
      dispatch(actionsAuth.setResetPasswordMessage(''));
    },
    []
  );

  return (
    <AuthLayout
      image={bgimage}
      title="Reset password"
      icon={<KeyIcon background="gradient.140" />}
      topSpace={{ base: '40px', md: '118px' }}
    >
      <form onSubmit={formik.handleSubmit}>
        {successMessage ? (
          <Stack mt={{ base: '32px', lg: '48px' }} spacing={{ base: '24px', md: '32px' }}>
            <Text
              bg="light_green.400"
              p="16px 38px"
              borderRadius="4px"
              fontWeight={400}
              fontSize="14px"
              lineHeight="150%"
              color="light_green.50"
            >
              {successMessage}
            </Text>
            <Button
              as={ReactLink}
              colorScheme="green"
              to="/login"
              h="48px"
              fontWeight="500"
              fontSize="16px"
              lineHeight="150%"
            >
              Back to Log In
            </Button>
          </Stack>
        ) : (
          <Flex mt={{ base: '32px', lg: '48px' }} w="100%" flexDir="column">
            <Stack spacing="24px" mb={{ base: '24px', lg: '32px' }} fontWeight="400">
              <FormInput
                value={email}
                handleChange={onChange}
                name="email"
                label="Email"
                placeholder="Enter your Email..."
                error={formik.status?.email}
                hideLabelOnMobile
              />
            </Stack>
            <SubmitButton isLoading={isLoading}>Send recovery Email</SubmitButton>
            <Text align="center" mt="24px" fontSize="14px">
              Remembered your password?
              {' '}
              <Link as={ReactLink} to="/login" color="light_blue.100">
                Log In
              </Link>
            </Text>
          </Flex>
        )}
      </form>
    </AuthLayout>
  );
};

export default ForgetPassword;
