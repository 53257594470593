import { Box, Flex, List, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { ReactNode } from 'react';

type PropsType = {
  title: string;
  titleIcon: string;
  description:string;
  list: { feature: string, sub_feature?: string[] }[];
  children?: ReactNode;
}
const Chapter = ({ list, title, titleIcon, description, children }: PropsType) => (
  <Flex flexDirection="column" p="24px" gap="8px" bg="#F9F9F9" borderRadius="12px">
    <Text fontSize="18px" fontWeight="700" lineHeight="34px">
      {title}
      <Box as="span" ml="6px" fontSize="18px">{titleIcon}</Box>
    </Text>
    <Text fontSize="14px" lineHeight="24px" color="gray.600">
      {description}
    </Text>

    <List spacing="8px">
      {list.map((item) => (
        <ListItem key={item.feature} fontWeight="600" lineHeight="24px" color="gray.600" fontSize="14px">
          <Box as="span" mr="10px" fontSize="18px">&#9989;</Box>
          {item.feature}

          {item.sub_feature && (
            <UnorderedList pt="8px" pl="24px">
              {item.sub_feature?.map((sub_feature) => (

                <ListItem key={sub_feature} fontStyle="italic" fontWeight="500" color="gray.700">
                  {sub_feature}
                </ListItem>

              ))}
            </UnorderedList>
          )}
        </ListItem>
      ))}
    </List>

    {children}
  </Flex>
);

export default Chapter;
