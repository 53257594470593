export const addAfterPinned = (dataInState, item, isPin = false) => {
  const pined = [];
  const unPined = [];

  dataInState.forEach((fold) => {
    if (fold.id === item.id) {
      unPined.push({ ...fold, pinned: isPin });
    } else if (fold.pinned) {
      pined.push(fold);
    } else {
      unPined.push(fold);
    }
  });

  return [...pined, ...unPined];
};
