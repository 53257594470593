import { Box, Button, Center, Image, Text } from '@chakra-ui/react';
import Popup from 'components/common/popup/Popup';
import { useAppDispatch, useAppSelector } from 'store/store';
import { actionsSubscription } from 'store/subscription-reducer';
import rocket from '../../../../assets/image/rocket.svg';

const Congratulate = () :JSX.Element => {
  const dispatch = useAppDispatch();
  const currentPlan = useAppSelector((state) => state.subscription.currentPlan?.subscription_plan.name);
  const { congratulationIsOpen } = useAppSelector((state) => state.subscription);

  const handleClose = () => {
    dispatch(actionsSubscription.setCongratulationIsOpen(false));
  };

return (
  <Popup onClose={handleClose} isOpen={congratulationIsOpen} width="480px">
    <Center flexDirection="column" w="100%">
      <Image h="178px" w="100%" src={rocket} mb="16px" />

      <Text mb="16px" fontSize="24px" fontWeight="700" lineHeight="150%" textAlign="center">
        Congratulations! You&apos;ve successfully switched to
        {' '}
        <br />
        <Box as="span" color="yellow.100">
          {currentPlan?.split(' ')[0]}
        </Box>
        {' '}
        plan
      </Text>

      <Text w="100%" mb="24px" textAlign="center" fontSize="14px" fontWeight="400" color="light_gray" lineHeight="150%">
        Lorem ipsum dolor sit amet consectetur. Faucibus turpis nunc nisi velit mi risus tempor turpis.
      </Text>

      <Button
        onClick={handleClose}
        size="lg"
        colorScheme="green"
        boxShadow="0px 1px 4px rgba(14, 87, 72, 0.25)"
      >
        Go to Dashboard
      </Button>
    </Center>
  </Popup>
  );
};

export default Congratulate;
