import { Avatar, Box, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { WorkspaceType } from 'types/types';

type WorkspacesListProps = {
  workspaces: WorkspaceType[];
  selectedWorkspace: WorkspaceType;
  handleSelectWorkspace: (workspace: WorkspaceType) => void;
  hasBookmarks?: boolean;
};

const WorkspacesList = ({
  hasBookmarks,
  workspaces,
  selectedWorkspace,
  handleSelectWorkspace
}: WorkspacesListProps) => (
  <Box
    w={hasBookmarks ? { base: '100%', ml: '212px' } : { base: '100%', md: '212px' }}
    minW="120px"
    maxW={hasBookmarks ? { base: '100%', ml: '212px' } : '212px'}
    bg="gray.900"
    p={{ base: `16px 16px ${!hasBookmarks ? '177px' : '16px'}`, lg: '16px' }}
    borderRight="1px solid"
    borderColor="gray.100"
    flex="1 1 auto"
    h={{ base: hasBookmarks ? '50%' : '100%', ml: '100%' }}
  >
    <Text
      fontWeight="600"
      minH="24px"
      fontSize={hasBookmarks ? { base: '12px', sm: '14px' } : '14px'}
      lineHeight="150%"
      color={hasBookmarks ? { base: 'black', ml: 'gray.500' } : 'gray.500'}
      mb={hasBookmarks ? { base: '5px', sm: '8px' } : '8px'}
    >
      Workspaces
    </Text>
    <Divider borderColor="gray.100" />
    <Stack
      w="100%"
      maxH="calc(100% - 40px)"
      mt="16px"
      spacing="4px"
      overflowY="auto"
      pb={{ base: !hasBookmarks ? '12px' : '0', lg: '0' }}
    >
      {workspaces.map((item) => (
        <Flex
          key={item.id}
          color="gray.700"
          w="100%"
          gap="12px"
          cursor="pointer"
          alignItems="center"
          p={{ base: '8px', sm: '6px 8px', ml: '4px 8px' }}
          bg={String(selectedWorkspace.id) === String(item.id) ? 'white.300' : 'transparent'}
          _hover={{ background: 'white.300' }}
          borderRadius="8px"
          fontWeight="500"
          fontSize={{ base: '12px', sm: '14px' }}
          lineHeight="150%"
          onClick={() => handleSelectWorkspace(item)}
        >
          <Avatar
            src={item.image}
            boxSize={{ base: '24px', sm: '28px', ml: '32px' }}
            borderRadius="50%"
            objectFit="cover"
          />
          <Text noOfLines={1}>{item.name}</Text>
        </Flex>
      ))}
    </Stack>
  </Box>
);

export default WorkspacesList;
