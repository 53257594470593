import { useEffect, lazy, Suspense } from 'react';
import { Alerts } from 'components/Alerts/Alerts';
import NavbarLayout from 'components/BookmarksManager/Layouts/NavbarLayout';
import SettingsLayout from 'components/BookmarksManager/Layouts/SettingsLayout';
import Popup from 'components/common/popup/Popup';
import Congratulate from 'components/modals/Popups/upgradePlan/Congratulate';
import UpgradePlan from 'components/modals/Popups/upgradePlan/UpgradePlan';
import Verification from 'components/modals/Popups/Verification';
import WelcomeToLinker from 'components/modals/Popups/WelcomeToLinker/WelcomeToLinker';
import WikiLayout from 'components/Wiki/Layout/WikiLayout';

import { Navigate, Route, Routes } from 'react-router-dom';
import { actionsPopup } from 'store/popup-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import { actionsSubscription } from 'store/subscription-reducer';
import { UserType } from 'types/types';

const Folder = lazy(() => import('pages/wiki/Folder'));
const Trash = lazy(() => import('pages/wiki/Trash'));
const Document = lazy(() => import('pages/wiki/Document'));
const Tracker = lazy(() => import('pages/Tracker'));
const Invite = lazy(() => import('pages/TeamInvitation'));
const Subscription = lazy(() => import('pages/Subscription'));
const Snippets = lazy(() => import('pages/Snippets'));
const Slack = lazy(() => import('pages/Slack'));
const Team = lazy(() => import('pages/Team'));
const Pocket = lazy(() => import('pages/Pocket'));
const Reminders = lazy(() => import('pages/Reminders'));
const Sessions = lazy(() => import('pages/Sessions'));
const Shared = lazy(() => import('pages/Shared'));
const AccountSettings = lazy(() => import('pages/AccountSettings'));
const ChangePassword = lazy(() => import('pages/ChangePassword'));
const IFTTT = lazy(() => import('pages/IFTTTT'));
const Integrations = lazy(() => import('pages/Integrations'));
const JoinTeam = lazy(() => import('pages/JoinTeam'));
const NotificationSettings = lazy(() => import('pages/NotificationSettings'));
const Workspace = lazy(() => import('pages/Workspace'));

type PropsType = {
  user: UserType;
};

const AuthorizedRoutes = ({ user }: PropsType): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showUpgradePlan } = useAppSelector((state) => state.popup.upgrade);
  const { currentWorkspaceId } = useAppSelector((state) => state.workspaces);

  const { pusher } = window;

  const connectWebsocket = () => {
    const swappedPlan = pusher.subscribe(`private-plan.swapped.${user.id}`);

    swappedPlan.bind('PlanSwapped', () => {
      dispatch(actionsSubscription.setCongratulationIsOpen(true));
    });
  };

  const onCloseUpgradePlan = () => {
    dispatch(actionsPopup.setShowUpgradePopup(false));
    dispatch(actionsPopup.setUpgradePlanMessage(null));
  };

  useEffect(() => {
    connectWebsocket();

    return () => {
      pusher.unsubscribe(`private-plan.swapped.${user.id}`);
    };
  }, [user.id]);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route element={<NavbarLayout />}>
            <Route path="/workspace/:workspaceId" element={<Workspace />} />
            <Route path="/workspace/:workspaceId/:folderId" element={<Workspace />} />
            <Route path="/shared/:folderSlug/:folderId" element={<Shared />} />
            <Route path="/shared" element={<Shared />} />
            <Route path="/snippets/:collectionId" element={<Snippets />} />
            <Route path="/snippets" element={<Snippets />} />
            <Route element={<SettingsLayout />}>
              <Route path="/settings/account" element={<AccountSettings />} />
              <Route path="/settings/password" element={<ChangePassword />} />
              <Route path="/settings/notifications" element={<NotificationSettings />} />
              <Route path="/settings/integrations" element={<Integrations />} />
              <Route path="/settings/subscription" element={<Subscription />} />
              <Route path="/settings/integrations/slack" element={<Slack />} />
              <Route path="/settings/integrations/pocket" element={<Pocket />} />
              <Route path="/settings/sessions" element={<Sessions />} />
              <Route path="/settings" element={<Navigate to="/settings/account" />} />
            </Route>
            <Route path="/reminders" element={<Reminders />} />
            <Route path="/team" element={<Team />} />
            <Route path="/tracker" element={<Tracker />} />
            <Route path="/invitation" element={<Invite />} />
            <Route path="/join" element={<JoinTeam />} />
          </Route>

          <Route path="/settings/integrations/ifttt" element={<IFTTT />} />

          <Route path="/wiki" element={<WikiLayout />}>
            <Route index element={<Navigate to="home" />} />
            <Route path="home" element={<Folder />} />
            <Route path="trash" element={<Trash />} />
            <Route path="home/:documentId" element={<Document />} />
            <Route path=":folderId" element={<Folder />} />
            <Route path=":folderId/:documentId" element={<Document />} />
            <Route path="*" element={<Folder />} />
          </Route>

          {currentWorkspaceId && <Route path="/*" element={<Navigate to={`/workspace/${currentWorkspaceId}`} />} />}
        </Routes>
      </Suspense>

      <Alerts />
      <Congratulate />

      <Popup
        padding={{ base: '16px', md: '0' }}
        width="fit-content"
        hasCloseButton
        isOpen={showUpgradePlan}
        onClose={onCloseUpgradePlan}
        iconColor={{ base: 'light_gray', md: 'white.50', lg: 'white.50' }}
        borderRadius={{ base: '0', md: '12px' }}
        maxW={{ base: '100%', lg: 'fit-content' }}
      >
        <UpgradePlan onClose={onCloseUpgradePlan} />
      </Popup>

      <Popup width="440px" isOpen={!user.verified}>
        <Verification user={user} />
      </Popup>

      <WelcomeToLinker />
    </>
  );
};

export default AuthorizedRoutes;
