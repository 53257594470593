import { authAPI } from 'api/authApi';
import { addDays } from 'date-fns';
import { InferActionsTypes, OauthType } from 'types/types';
import Cookies from 'universal-cookie';
import { getCurrentHost } from 'utils/currentHost';
import { errorHandler } from 'utils/errorHandler';
import { showAlert } from './app-reducer';
import { actionsPopup } from './popup-reducer';
import { actionsProfile, getProfile } from './profile-reducer';
import { AppThunkType } from './store';
import { actionsSubscription } from './subscription-reducer';
import { actionsWorkspace } from './workspace-reducer';

const initialState = {
  resetPasswordMessage: ''
};

export const actionsAuth = {
  setResetPasswordMessage: (data: string) => ({ type: 'SET_RESET_PASSWORD_MESSAGE', data } as const)
};

type InitialStateType = typeof initialState;
type AuthActionType = InferActionsTypes<typeof actionsAuth>;

const authReducer = (state = initialState, action: AuthActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_RESET_PASSWORD_MESSAGE':
      return {
        ...state,
        resetPasswordMessage: action.data
      };

    default:
      return state;
  }
};

export const signup = (
  email: string,
  name: string,
  password: string,
  planSlug: string | null
): AppThunkType => async (dispatch) => {
  const cookies = new Cookies();

  try {
    const planPrice = planSlug ? await authAPI.getPlanPrice(planSlug) : undefined;

    const response = await authAPI.signup(email, name, password, planPrice?.data?.stripe_price);

    if (response.data.token) {
      cookies.set('token', response.data.token.access_token, {
        path: '/',
        expires: new Date(Date.now() + 30 * 86400000),
        domain: getCurrentHost()
      });

      await dispatch(getProfile());

      return Promise.resolve(response.data?.checkout?.url ?? null);
    }
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const forgetPassword = (email: string): AppThunkType => async (dispatch) => {
  try {
    dispatch(actionsPopup.setIsLoading(true));

    const response = await authAPI.resetPasswordWithEmail(email);
    if (response.data.message) {
      dispatch(actionsAuth.setResetPasswordMessage(response.data.message));
    }
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  } finally {
    dispatch(actionsPopup.setIsLoading(false));
  }
};

export const resetPassword = (
  email: string,
  password: string,
  password_confirmation: string,
  token: string
): AppThunkType => async (dispatch) => {
  const cookies = new Cookies();

  try {
    dispatch(actionsPopup.setIsLoading(true));
    const response = await authAPI.confirmPassword(email, password, password_confirmation, token);
    cookies.set('token', response.token, {
      path: '/',
      expires: new Date(Date.now() + 30 * 86400000),
      domain: getCurrentHost()
    });
    dispatch(getProfile());

    return Promise.resolve();
  } catch (error: any) {
    return errorHandler(
      error,
      dispatch,
      (res) => {
        if (res.validationErrors?.email || res.validationErrors?.token) {
          dispatch(showAlert('Something went wrong!', false));
        }

        return Promise.reject(res.validationErrors);
      }
    );
  } finally {
    dispatch(actionsPopup.setIsLoading(false));
  }
};

export const logOut = (): AppThunkType => async (dispatch) => {
  const cookies = new Cookies();
  cookies.remove('env', { path: '/' });
  cookies.remove('visible_navbar', { path: '/' });
  cookies.remove('token', { path: '/', domain: getCurrentHost() });
  cookies.remove('refresh_token', { path: '/' });
  cookies.remove('expires_in', { path: '/' });
  dispatch(actionsProfile.cleanUp());
  dispatch(actionsWorkspace.cleanUp());
  dispatch(actionsSubscription.cleanUp());
};

export const oauthLogin = (data: OauthType, planSlug: string | null = ''): AppThunkType => async (dispatch) => {
  const cookies = new Cookies();
  try {
    const planPrice = planSlug ? await authAPI.getPlanPrice(planSlug) : undefined;

    const response = await authAPI.oauthLogin({ ...data, stripe_plan: planPrice?.data?.stripe_price });
    if (response.access_token) {
      cookies.set('token', response.access_token, {
        path: '/',
        expires: new Date(Date.now() + 30 * 86400000),
        domain: getCurrentHost()
      });

      cookies.set('refresh_token', response.refresh_token, {
        path: '/',
        expires: new Date(Date.now() + 30 * 86400000)
      });

      const expiresInDate = addDays(new Date(), Math.floor(response.expires_in / (3600 * 24)));
      cookies.set('expires_in', expiresInDate, {
        path: '/',
        expires: new Date(Date.now() + 30 * 86400000)
      });
      await dispatch(getProfile());

      return Promise.resolve(response.data);
    }
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => {
      if (data.grant_type === 'password') {
        return Promise.reject(res.validationErrors);
      }
    });
  }
};

export const refreshSession = (refreshToken: string): AppThunkType => async (dispatch) => {
  const cookies = new Cookies();

  try {
    const response = await authAPI.refreshToken(refreshToken);
    cookies.set('token', response.access_token, {
      path: '/',
      expires: new Date(Date.now() + 30 * 86400000),
      domain: getCurrentHost()
    });
    cookies.set('refresh_token', response.refresh_token, {
      path: '/',
      expires: new Date(Date.now() + 30 * 86400000)
    });
    const expiresInDate = addDays(new Date(), Math.floor(response.expires_in / (3600 * 24)));
    cookies.set('expires_in', expiresInDate, {
      path: '/',
      expires: new Date(Date.now() + 30 * 86400000)
    });
    await dispatch(getProfile());

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export default authReducer;

