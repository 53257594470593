import { Image, Square } from '@chakra-ui/react';
import { PlanType } from 'types/types';
import slack from 'assets/image/icons/slack.svg';

export const BenefitsIcons = ({ plan }: { plan: PlanType }) => (
  <>
    {plan.slug === 'basic' && (
      <>
        <Square borderRadius="2.34px" size="16px" fontSize="8px" color="light_blue.50" bg="gradient.60">
          <i className="fa-solid fa-briefcase" />
        </Square>

        <Square borderRadius="2.34px" size="16px" fontSize="8px" color="light_green.100" bg="gradient.70">
          <i className="fa-solid fa-bookmark" />
        </Square>

        <Square borderRadius="2.34px" size="16px" fontSize="8px" color="dark_yellow.100" bg="gradient.100">
          <i className="fa-solid fa-folder" />
        </Square>

        <Square borderRadius="2.34px" size="16px" fontSize="11px" color="white.50" bg="gradient.270">
          <i className="fab fa-get-pocket" />
        </Square>
      </>
    )}

    {plan.slug === 'pro' && (
      <>
        <Square borderRadius="2.34px" size="16px" fontSize="9px" color="purple.300" bg="gradient.80">
          <i className="fa-solid fa-folder-tree" />
        </Square>

        <Square borderRadius="2.34px" size="16px" fontSize="9px" color="pink.700" bg="gradient.90">
          <i className="fa-solid fa-clock" />
        </Square>

        <Square borderRadius="2.34px" size="16px" fontSize="9px" color="dark_yellow.100" bg="gradient.100">
          <i className="fa-solid fa-bolt" />
        </Square>
      </>
    )}

    {plan.has_users_for_teams === 'yes' && (
    <Square borderRadius="2.34px" size="16px" fontSize="9px" color="white.50" bg="gradient.230">
      <i className="fa-solid fa-users" />
    </Square>
    )}

    {plan.has_slack_integration === 'yes' && (
    <Square borderRadius="2.34px" size="16px" color="purple.300" bg="gradient.240">
      <Image src={slack} boxSize="9px" />
    </Square>
    )}

  </>
);
