import { Flex, Spinner, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import TagsList from './TagsList';

type PropsType = {
  tags: string[];
  loading: boolean;
  handleClick: (tag: string) => void;
  onSuggestionTagsContainerClick: () => void;
  children?: ReactNode;
}
const SuggestionTags = ({ loading, tags, handleClick, onSuggestionTagsContainerClick, children }: PropsType): JSX.Element => (
  <Flex
    position="absolute"
    zIndex={2}
    bg="white.50"
    gap="8px"
    flexDirection="column"
    boxShadow="0px 0px 6px rgba(101, 101, 101, 0.25)"
    borderRadius=" 8px"
    top="50px"
    left="50%"
    transform="translate(-50%, 0)"
    width="inherit"
    padding="16px"
    onClick={onSuggestionTagsContainerClick}
  >
    {children}

    <Text color="light_gray" fontWeight="400" fontSize="14px">
      Tags
    </Text>

    {loading && <Spinner alignSelf="center" emptyColor="green.50" color="green.500" />}
    {tags?.length ? (
      <TagsList
        bgHover="light_blue.100"
        colorHover="white.50"
        borderColor="gray.700"
        tags={tags}
        handleClick={handleClick}
      />
    )
      : (
        <Text fontSize="12px" color="light_gray" fontStyle="italic" fontWeight="400">
          Not found tags
        </Text>
      )}
  </Flex>
);
export default SuggestionTags;
