import * as Sentry from '@sentry/react';
import { showAlert } from 'store/app-reducer';
import { logOut } from 'store/auth-reducer';
import { actionsPopup } from 'store/popup-reducer';
import { AppDispatch } from '../store/store';

interface ErrorBox {
    validationErrors: { [key: string]: string } | null;
    errorMessage: string | null;
}

export const errorHandler = (error: any, dispatch: AppDispatch, cb?: (value: ErrorBox) => void) => {
    const result: ErrorBox = {
        validationErrors: null,
        errorMessage: null
    };

    if (error?.response?.status === 402 && dispatch) {
        dispatch(actionsPopup.setShowUpgradePopup(true));
        dispatch(actionsPopup.setUpgradePlanMessage(error.response.data?.error));
    } else if (error?.response?.status === 422 && error.response.data?.error) {
        result.validationErrors = {};
        const errors: { [key: string]: string[] } = { ...error.response.data.error };
        Object.keys(errors).forEach((key) => {
            const val = errors[key][0];
            result.validationErrors = { ...result.validationErrors, [key]: val };
        });
    } else if (error?.response?.status >= 400 && error?.response?.status < 500 && error.response.data.error) {
        dispatch(showAlert(error.response.data?.message || error.response.data.error, false));

        if (error.response.status === 401) {
            dispatch(logOut());
        }
    } else if (typeof error === 'object' && error?.response?.status !== 0) {
        Sentry.captureException(error, {
            extra: {
                message: 'captured in errorHandler',
                statusCode: JSON.stringify(error?.response?.status)
            }
        });
    }

    if (cb) return cb(result);

    return result;
};
