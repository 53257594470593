import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import { standalone } from 'App';

export const useDeviceParams = () => {
  const cookies = new Cookies();

  const [deviceType, setDeviceType] = useState<'mobile' | 'desktop' | null>(null);
  const [browserType, setBrowserType] = useState<'chrome' | 'safari' | null>(null);
  const [os, setOs] = useState<'android' | 'iOS' | null>(null);
  const [isInstalled, setIsInstalled] = useState(!!cookies.get('isInstalled'));
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType('mobile');
    } else {
      setDeviceType('desktop');
    }

    if (navigator.userAgent.match(/chrome|chromium|crios/i)) {
      setBrowserType('chrome');
    } else if (navigator.userAgent.match(/safari/i)) {
      setBrowserType('safari');
    }

    setIsStandalone(standalone);
  }, []);

  useEffect(() => {
    if ((deviceType === 'mobile' && browserType === 'safari') || navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      setOs('iOS');
    } else if (deviceType === 'mobile') {
      setOs('android');
    }

    setIsInstalled(!!(isStandalone || (os === 'iOS' && browserType !== 'safari')) || !!cookies.get('isInstalled'));
  }, [deviceType, browserType, isStandalone]);

  return {
    deviceType,
    browserType,
    os,
    isInstalled,
    isStandalone
  };
};
