import { Box, PopoverContent } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'store/store';
import { EnvironmentType } from 'types/types';
import Cookies from 'universal-cookie';
import { EnvironmentItem } from './EnvironmentItem';
import { PendingEnvironmentItem } from './PendingEnvironmentItem';

type Props = {
  onCloseSwitcher: () => void;
  onSwitchEnvironment: (type: string, teamId: number | null) => void;
}

const EnvironmentList = ({ onCloseSwitcher, onSwitchEnvironment }: Props): JSX.Element => {
  const environments = useAppSelector((state) => state.profile.environmentsList);
  const currentEnvironment = useAppSelector((state) => state.profile.currentEnvironment);

  const [environmentList, setEnvironmentList] = useState<Array<EnvironmentType>>([]);
  const cookies = new Cookies();

  useEffect(() => {
    const env = {
      env_type: currentEnvironment.type,
      env_team: currentEnvironment.team_id
    };

    setEnvironmentList(environments.filter((item) => item.team_id !== currentEnvironment.team_id));

    cookies.set('env', JSON.stringify(env), { path: '/' });
  }, [currentEnvironment, environments]);

  const switchEnvironment = (type: string, teamId: number | null) => {
    onCloseSwitcher();
    onSwitchEnvironment(type, teamId);
  };

  return (
    <PopoverContent
      _focusVisible={{
        outline: 'none'
      }}
      border="transparent"
      bg="white.100"
      boxShadow="0px 0px 16px rgba(101, 101, 101, 0.25)"
      borderRadius="8px"
      w={{ base: 'calc(100vw - 20px)', xs: '284px' }}
      maxW={{ base: 'calc(100vw - 20px)', xs: '284px' }}
      maxH="80vh"
      p="12px"
    >
      {environmentList.map((environment) => (
        <Box
          key={environment.team_id}
          onClick={() => switchEnvironment(environment.type, environment.team_id)}
        >
          {environment.is_member ? (
            <EnvironmentItem environment={environment} />
          ) : (
            <PendingEnvironmentItem environment={environment} />
          )}
        </Box>
      ))}
    </PopoverContent>
  );
};

export default EnvironmentList;
