import { MenuDivider, useDisclosure } from '@chakra-ui/react';
import cn from 'classnames';
import { ContextMenuButton } from 'components/common/buttons/ContextMenuButton';
import Popup from 'components/common/popup/Popup';
import { AddCollection } from 'components/modals/Popups/AddCollection';
import { AddSnippet } from 'components/modals/Popups/AddSnippet';
import { useParams } from 'react-router-dom';
import { AddButton } from './AddButton';
import style from './AddButton.module.scss';

export const SnippetAddButton = () => {
  const { isOpen: isAddSnippetOpen, onOpen: onAddSnippetOpen, onClose: onAddSnippetClose } = useDisclosure();
  const { isOpen: isAddCollectionOpen, onOpen: onAddCollectionOpen, onClose: onAddCollectionClose } = useDisclosure();
  const { collectionId } = useParams();

  return (
    <>
      <AddButton>
        {!collectionId && (
          <>
            <ContextMenuButton handleClick={onAddCollectionOpen}>
              <i className={cn('fa-solid fa-layer-group', style.collection)} />
              Create Collection
            </ContextMenuButton>

            <MenuDivider borderColor="gray.100" opacity="1" />
          </>
        )}

        <ContextMenuButton handleClick={onAddSnippetOpen}>
          <i className={cn('fa-solid fa-code', style.snippet)} />
          Add Snippet
        </ContextMenuButton>
      </AddButton>
      <Popup isOpen={isAddCollectionOpen} onClose={onAddCollectionClose} overflow="unset">
        <AddCollection onClose={onAddCollectionClose} />
      </Popup>

      <Popup width="560px" isOpen={isAddSnippetOpen} onClose={onAddSnippetClose} overflow="unset">
        <AddSnippet onClose={onAddSnippetClose} />
      </Popup>
    </>
  );
};
