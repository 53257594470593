import { Box, Flex, Square, Stack, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';

import { ButtonGroupPopup } from 'components/common/buttons/ButtonGroupPopup';
import FormInput from 'components/common/inputs/FormInput';
import { useAppDispatch } from 'store/store';
import FormTextarea from 'components/common/inputs/FormTextarea';
import { createSnippet } from 'store/snippets-reducer';
import { useParams } from 'react-router-dom';
import { showAlert } from 'store/app-reducer';
import { TagsInputWrapper } from 'components/common/Hashtags/TagsInputWrapper';
import { useState } from 'react';

type AddSnippetProps = {
  onClose: () => void;
};

export const AddSnippet = ({ onClose }: AddSnippetProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [selectedTags, setSelectedTags] = useState<string[] | null>([]);

  const { collectionId } = useParams();

  const formik = useFormik({
    initialValues: {
      title: '',
      content: ''
    },
    onSubmit: (value) => {
      dispatch(createSnippet(collectionId ?? null, value.title, value.content, selectedTags || []))
        .then(() => {
          onClose();
        })
        .catch((errors) => {
          if (errors) {
            if (errors.collection_id) {
              dispatch(showAlert(errors.collection_id, false));
            }
            formik.setStatus(errors);
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    }
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.handleChange(e);
    if (formik.status?.title || formik.status?.content) formik.setStatus({});
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex direction="column" maxH="430px" h="90vh">
        <Stack spacing="8px" h="100%">
          <Flex alignItems="center" pb="16px">
            <Square background="purple.100" color="purple.300" borderRadius="4px" size="28px">
              <i className="fa-solid fa-code" />
            </Square>

            <Text mx="12px" fontWeight="600" fontSize="16px" lineHeight="19px">
              Create Snippet
            </Text>
          </Flex>

          <FormInput
            label=""
            name="title"
            autoComplete="off"
            placeholder="Title"
            handleChange={onChange}
            value={formik.values.title}
            error={formik.status?.title}
            height="40px"
          />

          <FormTextarea
            height="100%"
            placeholder="Code"
            name="content"
            handleChange={onChange}
            value={formik.values.content}
            error={formik.status?.content}
          />
        </Stack>

        <Box mt="8px" w="100%">
          <TagsInputWrapper whoseTags="bookmark" selectedTags={selectedTags || []} setSelectedTags={setSelectedTags} onlyTagsSearch />
        </Box>

        <ButtonGroupPopup mt="32px" isLoading={formik.isSubmitting} label="Save" handleCancelClick={onClose} />
      </Flex>
    </form>
  );
};
