import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Cookies from 'universal-cookie';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'store/store';
import { actionsApp } from 'store/app-reducer';
import SuggestInstallApp from 'components/modals/Popups/SuggestInstallApp/SuggestInstallApp';
import AddPaymentMethod from 'components/modals/Popups/upgradePlan/AddPaymentMethod';
import UpdateApp from 'components/Alerts/UpdateApp';
import { OpenNavbarButton } from 'components/common/buttons/ToggleNavbarButtons';
import NavbarOverlay from 'components/common/Overlay';

import ToolsBar from 'components/BookmarksManager/Workspace/ToolsBar/ToolsBar';
import { Navbar } from 'components/BookmarksManager/Navbar/Navbar';
import SaveBookmarkFromClipboard from 'components/BookmarksManager/Workspace/SaveBookmarkFromClipboard';
import style from './Layouts.module.scss';

const NavbarLayout = (): JSX.Element => {
  const cookies = new Cookies();
  const dispatch = useAppDispatch();
  const { currentPlan } = useAppSelector((state) => state.subscription);
  const { showInstallApp } = useAppSelector((state) => state.popup);

  const [height, setHeight] = useState(0);
  const [showAddPaymentPopup, setShowAddPaymentPopup] = useState(false);
  const [visibleNav, setVisibleNav] = useState<boolean>(
    cookies.get('visible_navbar') && window.innerWidth > 1023
      ? cookies.get('visible_navbar') === 'true'
      : window.innerWidth > 1023
  );

  const toggleNavbar = (checkScreenWidth?: boolean) => {
    const screenWidth = window.innerWidth;

    if ((checkScreenWidth && screenWidth < 1024) || !checkScreenWidth) {
      setVisibleNav((visible) => !visible);
      dispatch(actionsApp.setIsVisibleNavbar(!visibleNav));
      cookies.set('visible_navbar', !visibleNav, { path: '/' });
    }
  };

  const onCloseAddPaymentMethodPopup = () => {
    cookies.set('show_add_payment', false, {
      path: '/',
      expires: new Date(Date.now() + (process.env.REACT_APP_ENVIRONMENT === 'production' ? 86400000 : 180000))
    });
    setShowAddPaymentPopup(!showAddPaymentPopup);
  };

  useEffect(() => {
    if (currentPlan?.subscription.stripe_status === 'past_due' && !cookies.get('show_add_payment')) {
      setShowAddPaymentPopup(true);
    }
  }, [currentPlan?.subscription.stripe_status]);

  const getWindowHeight = () => {
    setHeight(window.innerHeight);
  };

  useLayoutEffect(() => {
    getWindowHeight();
    window.addEventListener('resize', () => {
      getWindowHeight();
    });

    return (() => {
      window.removeEventListener('resize', () => {
        getWindowHeight();
      });
    });
  }, []);

  return (
    <>
      <Flex
        alignItems="flex-start"
        margin="auto"
        height={`${height}px`}
        maxW="1920px"
        overflow="hidden"
        background="white.50"
        position="relative"
      >
        <Navbar toggleNavbar={toggleNavbar} visibleNav={visibleNav} />
        <i
          className={cn('fa-solid fa-angles-left', style.angles, { [style.hidden_menu]: !visibleNav })}
          onClick={() => toggleNavbar()}
          tabIndex={0}
          role="button"
        />

        <div className={style.content}>
          <UpdateApp />

          <ToolsBar>
            <OpenNavbarButton toggleNavbar={toggleNavbar} />
          </ToolsBar>

          <Box mt="80px" overflowY="auto" w="100%" overflowX="hidden" h="calc(100vh - 80px)" pb={{ base: '80px', md: '0' }}>
            <NavbarOverlay display={{ base: visibleNav ? 'block' : 'none', lg: 'none' }} handleClick={toggleNavbar} />
            <Outlet />
          </Box>
        </div>
      </Flex>

      <AddPaymentMethod
        handleClose={onCloseAddPaymentMethodPopup}
        isOpen={showAddPaymentPopup}
      />

      <SaveBookmarkFromClipboard />

      {showInstallApp && <SuggestInstallApp />}
    </>
  );
};

export default NavbarLayout;
