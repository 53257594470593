import { Button, Center } from '@chakra-ui/react';

type ShowMoreButtonProps = {
  onClick: () => void,
  isDisabled: boolean
}

export const ShowMoreButton = ({ onClick, isDisabled }: ShowMoreButtonProps): JSX.Element => (
  <Button
    colorScheme="transparent"
    color="light_gray"
    _hover={{
      color: 'gray.500'
    }}
    onClick={onClick}
    disabled={isDisabled}
    fontSize="12px"
    fontWeight="400"
    mt="auto!important"
    iconSpacing="6px"
    rightIcon={(
      <Center boxSize="12px" fontSize="12px">
        <i className="fa-solid fa-angle-down" />
      </Center>
    )}
  >
    SHOW MORE
  </Button>

);
