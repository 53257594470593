import { Center, Flex, Image, Text } from '@chakra-ui/react';
import { CloseNavbarButton } from 'components/common/buttons/ToggleNavbarButtons';
import { NavLink } from 'react-router-dom';
import linker_logo from '../../../assets/image/linker_logo.png';

type Props = {
  toggleNavbar: () => void;
}

const BackToDashboard = ({ toggleNavbar }: Props): JSX.Element => (
  <Center
    p="12px 8px"
    borderBottom="1px solid"
    borderColor="gray.100"
    width="100%"
  >
    <Flex
      as={NavLink}
      to="/workspace"
      alignItems="center"
      h="32px"
      w="100%"
      p="8px"
      borderRadius="4px"
      color="gray.500"
      _hover={{
        bg: '#F2F2F2'
      }}
    >
      <Center mr="12px">
        <i className="fa-solid fa-arrow-left" />
      </Center>
      <Image src={linker_logo} boxSize="16px" />

      <Text ml="8px" fontWeight="600">
        Back to Dashboard
      </Text>
    </Flex>

    <CloseNavbarButton toggleNavbar={toggleNavbar} />
  </Center>
);

export default BackToDashboard;
