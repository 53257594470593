import { Flex } from '@chakra-ui/react';
import { bookmarkAPI } from 'api/bookmarksApi';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { hashtag } from 'utils/regex';
import SuggestionTags from './SuggestionTags';
import { TagsInput } from './TagsInput';
import TagsList from './TagsList';

type PropsType = {
  setSelectedTags: (value: string[] | null) => void;
  selectedTags: string[];
  whoseTags: 'bookmark' | 'snippet';
  onlyTagsSearch?: boolean;
  hideTags?: boolean;
  filterByUser?: 'yes' | 'no';
}

export const TagsInputWrapper = ({
  whoseTags, setSelectedTags,
  selectedTags, onlyTagsSearch,
  hideTags, filterByUser = 'no' }: PropsType): JSX.Element => {
  const [showSuggestionTags, setShowSuggestionTags] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const inputTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [searchParams] = useSearchParams();
  const keywordQuery = searchParams.get('keyword');

  const getTags = async (keyword: string = '', teamId: string = '') => {
    setTags([]);
    setLoading(true);

    await bookmarkAPI.getTags(whoseTags, keyword, filterByUser, teamId)
      .then((response) => {
        const allTagsWithoutSelected = response.data.map((tag) => tag.name).filter((tag) => !selectedTags.includes(tag));
        setTags(allTagsWithoutSelected);

        return Promise.resolve();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => () => {
    if (inputTimeout.current) clearTimeout(inputTimeout.current);
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowSuggestionTags(true);
    const checkedInputValue = hashtag(e.target.value.toLowerCase());
    if (checkedInputValue !== searchValue) {
      setSearchValue(checkedInputValue);
      if (inputTimeout.current) clearTimeout(inputTimeout.current);
      inputTimeout.current = setTimeout(() => {
        getTags(e.target.value);
      }, 500);
    }
  };

  const handleSelect = (tag: string) => {
    if (!selectedTags.includes(tag) && !!tag) {
      setSelectedTags([...selectedTags, tag]);
      setSearchValue('');
    }
    inputRef.current?.blur();
    setShowSuggestionTags(false);
  };

  const handleTagsSubmit = (e: any) => {
    if (e.key === 'Enter') {
      handleSelect(searchValue);
      setSearchValue('');
      e.preventDefault();
    }
  };

  const handleRemoveTag = (tag: string) => {
    setShowSuggestionTags(false);
    setSelectedTags(selectedTags.filter((item) => item !== tag));
  };

  const handleOnFocus = () => {
    getTags(onlyTagsSearch ? searchValue : keywordQuery?.trim().split(' ').pop());
    setShowSuggestionTags(true);
  };

  const handleOnBlur = () => {
    inputTimeout.current = setTimeout(() => {
      if (showSuggestionTags) setShowSuggestionTags(false);
    }, 400);
  };

  const onSuggestionTagsContainerClick = () => {
    if (inputTimeout.current) clearTimeout(inputTimeout.current);
    inputRef.current?.focus();
  };

  const handleCloseSuggestions = () => {
    setShowSuggestionTags(false);
    setTags([]);
    inputRef.current?.blur();
  };

  return (
    <Flex position="relative" flexDirection="column" gap="8px" w="100%">
      <TagsInput
        handleOnBlur={handleOnBlur}
        handleOnFocus={handleOnFocus}
        inputRef={inputRef}
        onlyTagsSearch={onlyTagsSearch}
        handleTagsSubmit={handleTagsSubmit}
        handleChange={handleChange}
        searchValue={searchValue}
        getTags={getTags}
        onSubmit={handleCloseSuggestions}
      />

      {showSuggestionTags && !hideTags && (!!tags.length || !onlyTagsSearch) && (
        <SuggestionTags
          loading={loading}
          onSuggestionTagsContainerClick={onSuggestionTagsContainerClick}
          handleClick={handleSelect}
          tags={tags}
        >
          {!onlyTagsSearch && (
            <TagsList
              color="light_blue.100"
              colorHover="light_blue.100"
              bg="#CAE3EE"
              bgHover="#9BD3EC"
              tags={selectedTags}
              handleClick={handleRemoveTag}
              hasCloseButton
            />
          )}
        </SuggestionTags>
      )}

      {onlyTagsSearch && (
        <TagsList
          color="light_blue.100"
          colorHover="light_blue.100"
          bg="#CAE3EE"
          bgHover="#9BD3EC"
          tags={selectedTags}
          handleClick={handleRemoveTag}
          hasCloseButton
        />
      )}
    </Flex>
  );
};
