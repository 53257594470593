import { Box, Center, Flex, HStack, Square, Stack, Text } from '@chakra-ui/react';
import { ButtonGroupPopup } from 'components/common/buttons/ButtonGroupPopup';
import { MouseEventHandler, ReactNode, useEffect } from 'react';

type PropsType = {
  title: string;
  description: ReactNode;
  helpText?: string;
  handleDelete: (() => void) | MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
  onClose: () => void;
  isLoading?: boolean;
};
export const Delete = ({
  title,
  handleDelete,
  children,
  onClose,
  helpText,
  description,
  isLoading
}: PropsType): JSX.Element => {
  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleDelete(event);
      }
    };
    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Flex direction="column">
      <Stack spacing="24px">
        <Flex>
          <Flex alignItems="center">
            <Square background="red.50" borderRadius="4px" size="28px" color="red.100">
              <i className="fa-solid fa-trash-can" />
            </Square>
            <Text mx="12px" fontWeight="600" fontSize="16px" lineHeight="19px">
              {title}
            </Text>
          </Flex>
        </Flex>
      </Stack>
      <Text mt="24px" fontWeight="400" fontSize="14px" lineHeight="150%" color="gray.500">
        {description}
      </Text>

      {children && (
        <Box flex="1 1 auto" minH="24px" mt="16px" mb="-16px" overflowY="auto">
          {children}
        </Box>
      )}

      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent={{ base: 'flex-end', lg: 'space-between' }}
        alignItems="flex-end"
        gap="16px"
      >
        {helpText && (
          <HStack
            mb={{ base: '-32px', lg: '0' }}
            mt={{ base: '32px', lg: '0' }}
            alignItems="flex-start"
            spacing="8px"
            w="100%"
            fontSize="12px"
            color="light_gray"
          >
            <Center pt="3px" fontSize="12px">
              <i className="fa-solid fa-circle-info" />
            </Center>
            <Text fontWeight="400" fontSize="12px" fontStyle="italic" lineHeight="150%">
              {helpText}
            </Text>
          </HStack>
        )}

        <ButtonGroupPopup
          mt="16px"
          isLoading={isLoading}
          label="Confirm"
          handleClick={handleDelete}
          colorScheme="red"
          handleCancelClick={onClose}
        />
      </Flex>
    </Flex>
  );
};
