import { useEffect, useState } from 'react';
import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  HStack,
  Image,
  PinInput,
  PinInputField,
  Stack,
  Text
} from '@chakra-ui/react';

import SubmitButton from 'components/common/buttons/SubmitButton';
import { resendVerificationCode, verifyAccount } from 'store/profile-reducer';
import { useAppDispatch } from 'store/store';
import { UserType } from 'types/types';

import illustration from 'assets/image/verification.svg';

type VerificationProps = {
  user: UserType;
};

const Verification = ({ user }: VerificationProps) => {
  const dispatch = useAppDispatch();

  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string } | null>(null);

  const resendVerification = () => {
    dispatch(resendVerificationCode());
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault();
    if (errors) setErrors(null);
    setLoading(true);
    dispatch(verifyAccount(code))
      .catch((err) => {
        setErrors(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (errors) setErrors(null);
    if (code.length === 6) {
      handleSubmit();
    }
  }, [code]);

  return (
    <form onSubmit={handleSubmit}>
      <Center flexDirection="column" w="100%">
        <Image w="264px" src={illustration} />
        <Text fontWeight="700" fontSize="32px" lineHeight="130%" textAlign="center" maxW="324px" mb="8px">
          <Box as="span" color="yellow.50">
            One
          </Box>
          {' '}
          step to be verified
        </Text>
        <Text
          maxW="290px"
          textAlign="center"
          mb="24px"
          fontWeight="400"
          fontSize="14px"
          lineHeight="150%"
          color="light_gray"
        >
          We have sent a verification code to
          {' '}
          <Box as="span" color="gray.500">
            {user.email}
          </Box>
        </Text>
        <FormControl mb="32px" isInvalid={!!errors?.verification_code}>
          <HStack spacing={{ base: '4px', xs: '8px' }} justifyContent="center">
            <PinInput
              isDisabled={loading}
              autoFocus
              value={code}
              onChange={setCode}
              size={{ base: 'sm', xs: 'md' }}
              otp
              variant="outline"
              isInvalid={!!errors?.verification_code}
            >
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
          <FormErrorMessage mx="auto" maxW={{ base: '210px', xs: '280px' }}>{errors?.verification_code}</FormErrorMessage>
        </FormControl>
        <Stack w="100%" spacing="32px">
          <SubmitButton isLoading={loading}>Verify</SubmitButton>
          <Text textAlign="center" fontWeight="400" fontSize="14px" lineHeight="150%">
            Didn’t recieve the code?
            {' '}
            <Box
              as="span"
              fontWeight="400"
              textDecoration="underline"
              color="light_blue.100"
              cursor="pointer"
              role="button"
              _hover={{
                color: 'blue'
              }}
              onClick={resendVerification}
            >
              Click to resend
            </Box>
          </Text>
        </Stack>
      </Center>
    </form>
  );
};

export default Verification;
