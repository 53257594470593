import { Divider, Flex, Stack, Text } from '@chakra-ui/react';
import GoogleAuthButton from 'components/common/buttons/GoogleAuthButton';
import AppleAuthButton from 'components/common/buttons/AppleAuthButton';
import FacebookAuthButton from 'components/common/buttons/FacebookAuthButton';

interface JoinWithProps {
  googleLabel: string;
  appleLabel: string;
  fbLabel: string;
}

const JoinWith = ({ googleLabel, appleLabel, fbLabel }: JoinWithProps) => (
  <Flex direction="column" w="100%">
    <Flex w="100%" alignItems="center" my="16px">
      <Divider flex={1} />
      <Text px="8px" fontWeight={400} fontSize="16px" lineHeight="19px">
        Join with
      </Text>
      <Divider flex={1} />
    </Flex>
    <Stack spacing="8px" w="100%">
      <GoogleAuthButton>{googleLabel}</GoogleAuthButton>
      <AppleAuthButton>{appleLabel}</AppleAuthButton>
      <FacebookAuthButton>{fbLabel}</FacebookAuthButton>
    </Stack>
  </Flex>
);

export default JoinWith;
