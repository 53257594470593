import { Circle, Flex, HStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { LocationState } from 'types/types';
import { BreadcrumbButton } from './BreadcrumbButton';

type Props = {
  children: ReactNode;
}

const Header = ({ children }: Props): JSX.Element => {
  const { folderId, documentId } = useParams();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const trashMatch = location.pathname.split('/').pop() === 'trash';

  return (
    <HStack
      width={{ base: '100%', lg: 'calc(100% - 240px)' }}
      h={{ base: '48px', md: '56px' }}
      position="fixed"
      justifyContent="space-between"
      bg="white.100"
      zIndex={2}
      p={{ base: '16px', md: '16px 24px', lg: '16px 32px' }}
      borderBottom="1px solid"
      borderColor="gray.100"
      spacing="8px"
      gap="16px"
    >
      <Flex gap="22px" maxW="calc(100% - 42px)" alignItems="center">
        {children}

        <Flex
          alignItems="center"
          gap="6px"
          overflowX="scroll"
        >
          <BreadcrumbButton
            navigateTo="home"
            name="Home"
            icon={<i className="fa-solid fa-house" />}
            locationState={locationState}
            theFirst
          />
          {folderId && (
            <BreadcrumbButton
              navigateTo={folderId}
              name={locationState?.folder}
              locationState={locationState}
              icon={<i className="fa-solid fa-folder-open" />}
            />
          )}
          {documentId && (
            <BreadcrumbButton
              navigateTo={`${folderId ?? 'home'}/${documentId}`}
              name={locationState?.document}
              locationState={locationState}
              icon={<i className="fa-solid fa-file-lines" />}
            />
          )}
          {trashMatch && (
            <BreadcrumbButton
              navigateTo="trash"
              name="Trash"
              locationState={locationState}
              icon={<i className="fa-solid fa-trash" />}
            />
          )}
        </Flex>
      </Flex>

      <Circle color="gray.700" fontSize="12px" bg="white.50" border="1px solid" borderColor="gray.50" size="24px">
        <i className="fa-solid fa-question" />
      </Circle>
    </HStack>
  );
};

export default Header;
