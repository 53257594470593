import { CollectionType } from 'types/types';
import { checkParam, EditType, GetItemsType, requestInstance, ResponseType } from './api';

export const collectionsApi = {
  async getCollectionsList(
    teamId: string | number | null
  ) {
    const team_id = checkParam(teamId);

    const res = await requestInstance().get<GetItemsType<Array<CollectionType>>>(
      `/v1/collection?filter_by_team=${team_id}`
    );

    return res.data;
  },

  async getCollection(
    collectionId: string | number
  ) {
    const res = await requestInstance().get<ResponseType<CollectionType>>(
      `/v1/collection/${collectionId}`
    );

    return res.data;
  },

  async createCollection(
    name: string,
    team_id: number | null
  ) {
    const res = await requestInstance().post<ResponseType<CollectionType>>('/v1/collection', {
      name,
      team_id
    });

    return res.data;
  },

  async updateCollection(
    name: string,
    collectionId: number | string
  ) {
    const res = await requestInstance().put<ResponseType<CollectionType>>(`/v1/collection/${collectionId}`, {
      name
    });

    return res.data;
  },

  async deleteCollection(
    collectionId: number | string
  ) {
    const res = await requestInstance().delete<EditType>(`/v1/collection/${collectionId}`);

    return res.data;
  }
};
