import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  breakpoints: {
    xxs: '340px',
    xs: '376px',
    sm: '460px',
    md: '576px',
    ml: '820px',
    lg: '1024px',
    xl: '1200px',
    '2xl': '1536px'
  },
  styles: {
    global: {
      body: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: '500',
        color: 'black',
        boxSizing: 'border-box',
        background: 'white.500'
      }
    }
  },
  fonts: {
    heading: "'Inter', sans-serif"
  },
  colors: {
    green: {
      50: '#DDF2ED',
      100: '#55bca7',
      500: '#55bca7',
      600: '#449686',
      700: '#449686',
      900: '#449686',
      1000: '#55BCA7'
    },
    light_green: {
      50: '#62A44B',
      100: '#80C578',
      200: '#48A44B',
      300: '#55BC59',
      400: '#EAF5E5',
      500: '#7AE06A'
    },
    status_green: {
      50: '#EAF5E5',
      100: '#55BC59'
    },
    ghost_blue: '#D5E2F1',
    black: '#282828',
    light_gray: '#BABABA',
    gray: {
      50: '#E1E1E1',
      100: '#EEEEEE',
      200: '#D1D1D1',
      300: '#B3B3B3',
      400: '#4D4D4D',
      500: '#999999',
      600: '#464646',
      700: '#717171',
      800: '#F6F6F6',
      900: '#f8f8f8'
    },
    dark_gray: {
      50: '#D9D9D9',
      100: '#808080',
      200: '#767676',
      300: '#424242'
    },
    red: {
      50: '#F9DBDB',
      100: '#E24A4A',
      500: '#E24A4A',
      600: '#B63B3B',
      700: '#E24A4A',
      800: '#EF4056',
      900: '#B63B3B'
    },
    pink: {
      50: '#F2DDF2',
      100: '#faa1d8',
      700: '#9D3657'
    },
    yellow: {
      50: '#ECC76A',
      100: '#ECC76A',
      400: '#ECC76A',
      500: '#C5A556',
      600: '#C5A556',
      700: '#ECC76A',
      900: '#C5A556'
    },
    light_yellow: {
      50: '#FBF4E1',
      100: '#EDDAAB',
      500: '#ECC76A'
    },
    dark_yellow: {
      50: '#BFAFA1',
      100: '#C1AE3C',
      200: '#A9A041',
      300: '#C4A14A'
    },
    purple: {
      50: '#E0DDF2',
      100: '#E1D5F1',
      200: '#D7D5F1',
      300: '#966ACE',
      400: '#7269F1',
      500: '#635DAB',
      600: '#6355BC'
    },
    white: {
      50: '#FFFFFF',
      100: '#FFFFFF',
      200: 'F6F6F6',
      300: '#E6E6E6',
      500: '#FFFFFF',
      600: '#F3F3F3',
      700: '#F3F3F3',
      800: '#F3F8FE',
      900: '#F3F3F3'
    },
    blue: '#2B70B9',
    primary_blue: {
      50: '#2B70B9',
      100: '#2B70B9',
      200: '#2B70B9',
      300: '#225A94',
      400: '#225A94',
      500: '#225A94',
      600: '#225A94',
      700: '#225A94',
      800: '#6FBFE5'
    },
    light_blue: {
      50: '#4A99FF',
      100: '#2B8EB9',
      200: '#2570E5',
      300: '#BAD8FC',
      400: '#B3D9FC',
      500: '#2A77DA',
      600: '#6AAACE',
      700: '#51ABFF',
      800: '#DBEFF9'
    },
    cyan: {
      50: '#CAE3EE'
    },
    dark_blue: {
      50: '#8C9CD2',
      100: '#202136',
      200: '#27314B'
    },
    peach: {
      100: '#FBEAE1',
      800: '#C57756'
    },
    transparent: {
      50: 'transparent',
      100: 'transparent',
      500: 'transparent',
      600: 'transparent',
      700: 'transparent',
      900: 'transparent'
    },
    gradient: {
      10: 'linear-gradient(180deg, rgba(160, 160, 160, 0) 0%, rgba(40, 40, 40, 0.7) 100%)',
      20: 'linear-gradient(135deg, #00F0FF 0%, #88BAFF 93.75%), #464646',
      30: 'linear-gradient(135deg, #FFFF78 0%, #FFE382 100%), #464646',
      40: 'linear-gradient(137.45deg, #D4FC79 4.69%, #96E6A1 96.26%), #464646',
      50: 'linear-gradient(80.04deg, rgba(17, 108, 255, 0.8) 0.79%, rgba(0, 174, 255, 0.8) 99.1%)',
      60: 'linear-gradient(135deg, #00F0FF 0%, #88BAFF 93.75%)',
      70: 'linear-gradient(137.45deg, #D4FC79 4.69%, #96E6A1 96.26%)',
      80: 'linear-gradient(135deg, #FBC2EB 0%, #BC9FFF 100%)',
      90: 'linear-gradient(135.91deg, #FF7EB3 0%, #FF758C 98.46%)',
      100: 'linear-gradient(135deg, #FFFF78 0%, #FFE382 100%)',
      110: 'linear-gradient(136.85deg, #FDFCFB 1.51%, #E2D1C3 98.49%)',
      120: 'linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%)',
      130: 'linear-gradient(136.85deg, #667EEA 0%, #764BA2 96.98%)',
      140: 'linear-gradient(135deg, #557EBC 0%, #5755BC 100%)',
      150: 'linear-gradient(135deg, #55BCA7 0%, #269C84 100%)',
      160: 'linear-gradient(135deg, #67A44B 0%, #4BA464 100%)',
      170: 'linear-gradient(126.87deg, #ECC76A -1.4%, #FFBD00 101.4%), #FFFFFF',
      180: 'linear-gradient(152.56deg, #8FD3F4 2.14%, #00A1FF 100%)',
      190: 'linear-gradient(90deg, rgba(230, 185, 128, 0.3) 0%, rgba(234, 205, 163, 0.3) 100%)',
      200: 'linear-gradient(90deg, #A7A6CB 0%, #8989BA 52%, #8989BA 100%)',
      210: 'linear-gradient(90deg, #E6E9F0 0%, #EEF1F5 100%)',
      220: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 46.35%, #FFFFFF 100%)',
      230: 'linear-gradient(135deg, #00C9D6 0%, #036DFF 93.75%)',
      240: 'linear-gradient(135deg, #840084 0%, #3F0F3F 100%)',
      250: 'linear-gradient(146.02deg, #6FD8A1 0%, #0090E1 100%)',
      260: 'linear-gradient(135deg, #1DA1F2 0%, #0077B5 100%)',
      270: 'linear-gradient(315deg, #EF4056 0%, #FF6477 100%)',
      280: 'linear-gradient(152.56deg, #966ACE 2.14%, #6355BC 100%)',
      290: 'linear-gradient(152.56deg, #1A73E8 2.14%, #2B70B9 100%)',
      300: 'linear-gradient(137.45deg, #D4FC79 4.69%, #96E6A1 96.26%), linear-gradient(152.56deg, #8FD3F4 2.14%, #00A1FF 100%)',
      310: 'linear-gradient(90deg, rgba(111, 191, 229, 0.12) 0%, rgba(111, 191, 229, 0.18) 100%)',
      320: 'linear-gradient(90deg, rgba(235, 189, 245, 0.2) 0%, rgba(235, 189, 245, 0.26) 98.96%)',
      330: 'linear-gradient(180deg, rgba(32, 33, 54, 0) 0%, #202136 100%)'
    }
  },
  components: {
    Divider: {
      baseStyle: {
        opacity: 1,
        borderColor: 'gray.100'
      }
    },
    Checkbox: {
      variants: {
        primary: {
          control: {
            w: '18px',
            h: '18px',
            boxShadow: 'none!important',
            border: 'none',
            bg: '#fff',
            borderRadius: '4px',
            transition: '150ms',
            _hover: {
              bg: '#F6F6F6'
            }
          },
          icon: {
            color: 'blue'
          },
          container: {
            boxShadow: 'unset',
            border: '1px solid #BABABA',
            borderRadius: '4px',
            bg: '#fff',
            _checked: {
              bg: '#fff',
              borderColor: 'blue'
            }
          }
        },
        round: (props: any) => ({
          control: {
            w: '100%',
            h: '100%',
            borderRadius: '50%',
            boxShadow: 'none!important',
            border: 'none',
            bg: 'white.50',
            transition: '150ms',
            _hover: {
              bg: '#F6F6F6',
              borderColor: props.color
            },
            _checked: {
              bg: props.color,
              borderColor: props.color,

              _hover: {
                bg: props.color,
                borderColor: props.color
              }
            },
            _disabled: {
              _checked: {
                borderColor: 'dark_gray.50',
                bg: 'dark_gray.50'
              }
            }
          },
          icon: {
            color: 'white.50'
          },
          container: {
            w: props.boxSize,
            h: props.boxSize,
            boxShadow: 'unset',
            border: '1px solid',
            borderColor: 'dark_gray.50',
            borderRadius: '50%',
            _checked: {
              bg: 'light_green.50',
              borderColor: props.color

            },
            _disabled: {
              _checked: {
                borderColor: 'dark_gray.50',
                bg: 'dark_gray.50'
              }
            }
          }
        })
      }
    },
    Button: {
      baseStyle: {
        fontSize: '14px',
        lineHeight: '100%',
        borderRadius: '4px',
        fontWeight: '500',
        fontFamily: "'Inter',sans-serif",
        color: 'black',
        background: 'white.500',
        _focus: {
          boxShadow: 'none'
        },
        transition: '150ms'
      },
      sizes: {
        sm: {
          height: { base: '48px', lg: '40px' },
          px: '24px',
          fontWeight: '500',
          fontSize: { base: '16px', lg: '14px' },
          lineHeight: '17px'
        },
        md: {
          fontSize: '14px',
          h: '100%',
          p: '8px 16px'
        },
        lg: {
          fontSize: { base: '16px', lg: '14px' },
          w: '100%',
          height: { base: '48px', lg: '40px' },
          p: '8px'
        },
        icon: (props: any) => ({
          w: props.boxSize || '20px',
          h: props.boxSize || '20px',
          minW: props.boxSize || '20px',
          minH: props.boxSize || '20px'
        })
      },
      variants: {
        navbar: {
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          w: '100%',
          color: 'black',
          gap: '10px',
          p: '10px 8px',
          border: '1px solid',
          borderColor: 'transparent',
          borderRadius: '8px',
          _hover: {
            bg: 'white.600',
            borderColor: 'transparent'
          },
          _active: {
            bg: '#F3F8FE',
            borderColor: '#D5E2F1'
          },
          transition: 'none'
        },
        menuItem: {
          color: 'black',
          gap: '10px',
          bg: 'white.100',
          _hover: {
            bg: 'white.700'
          },
          justifyContent: 'flex-start'
        },
        social: {
          color: 'black',
          colorScheme: 'white',
          _hover: {
            background: '#F7F7F7',
            borderColor: '#F7F7F7'
          },
          _disabled: {
            pointerEvents: 'none'
          }
        },
        cancel: (props: any) => ({
          color: '#BABABA',
          size: 'sm',
          border: '1px solid #E1E1E1',
          _hover: {
            background: `${props.colorScheme}.50`,
            borderColor: `${props.colorScheme}.50`,
            color: `${props.colorScheme}.500`
          }
        }),
        delete: (props: any) => ({
          minW: 'auto',
          color: `${props.colorScheme}.100`,
          background: `${props.colorScheme}.50`,
          size: 'sm',
          _hover: {
            background: `${props.colorScheme}.600`,
            color: 'white'
          }
        }),
        status: (props: any) => ({
          color: `${props.colorScheme}.100`,
          background: 'transparent',
          _hover: {
            background: `${props.colorScheme}.50`
          }
        }),
        table: (props: any) => ({
          color: { base: `${props.colorScheme}.500`, lg: '#D1D1D1' },
          border: 'none',
          minW: '32px',
          maxW: '32px',
          p: '0',
          _hover: {
            color: `${props.colorScheme}.500`
          },
          _active: {
            color: `${props.colorScheme}.500`
          }
        }),
        feature: (props: any) => ({
          bg: 'transparent',
          color: `${props.colorScheme}.200`,
          border: 'none',
          p: '0',
          _hover: {
            color: `${props.colorScheme}.300`
          },
          _active: {
            color: `${props.colorScheme}.300`
          },
          fontSize: '19px'
        }),
        wikiIconButton: (props: any) => ({
          fontSize: props.fontSize || '12px',
          color: props.color || 'gray.700',
          bg: 'transparent',
          _hover: {
            bg: '#E1E1E1'
          },
          _active: {
            bg: '#E1E1E1'
          },
          borderRadius: '3px'
        }),
        reset: {
          colorScheme: 'gray',
          color: '#BABABA',
          size: 'sm',
          border: '1px solid #E1E1E1',
          _hover: {
            background: 'ghost_blue',
            borderColor: 'ghost_blue',
            color: 'blue'
          }
        },
        link: {
          color: '#2B8EB9',
          padding: 0,
          minWidth: 'fit-content',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '17px',
          _hover: {
            textDecoration: 'none',
            color: 'blue'
          }
        },
        select: {
          h: '34px',
          p: '8px',
          bg: 'white.500',
          border: '1px solid',
          borderRadius: '8px',
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '17px',
          justifyContent: 'space-between',
          _hover: {
            bg: 'white.500'
          },
          _active: {
            bg: 'white.500'
          }
        },
        pagination: {
          w: '24px',
          h: '24px',
          borderRadius: '6px',
          border: '1px solid #E1E1E1',
          bg: '#fff',
          color: '#BABABA',
          fontWeight: '500',
          fontSize: '12px',
          lineHeight: '20px',
          minW: '24px',
          minH: '24px',
          maxH: '24px',
          p: '0',
          _hover: {
            bg: 'green.100',
            borderColor: 'green.100',
            color: '#fff'
          },
          _active: {
            bg: 'green.100',
            borderColor: 'green.100',
            color: '#fff'
          }
        },
        circle: {
          boxSize: '32px',
          maxW: '32px',
          minW: '32px',
          p: '0',
          bg: '#F0F0F0',
          color: 'gray.500',
          fontSize: '12px',
          borderRadius: '50%',
          _hover: {
            bg: '#E5E5E5'
          },
          _active: {
            bg: '#E5E5E5'
          }
        }
      }
    },
    Input: {
      baseStyle: {
        field: {
          fontSize: '14px',
          lineHeight: '100%',
          borderRadius: '4px',
          fontWeight: '400',
          fontFamily: "'Inter',sans-serif",
          _focus: {
            boxShadow: 'none'
          }
        }
      },
      variants: {
        search: {
          field: {
            fontSize: '14px',
            height: '100%',
            background: '#F6F6F6',
            _placeholder: {
              color: '#BABABA'
            },
            color: 'gray.600',
            p: '8px 16px',
            border: '1px solid #E1E1E1',
            _focus: {
              filter: 'drop-shadow(0px 0px 1px #2B70B9)',
              border: '1px solid #2B70B9',
              background: 'white.500',
              _placeholder: {
                opacity: 0
              }
            }
          }
        },
        formInput: {
          field: {
            border: '1px solid #E1E1E1',
            borderRadius: '4px',
            lineHeight: '17px',
            fontWeight: '400',
            fontSize: '14px',
            _focusVisible: {
              border: '1px solid #2B70B9',
              filter: 'drop-shadow(0px 0px 1px #2B70B9)',
              background: 'white.500'
            },
            _focus: {
              border: '1px solid #2B70B9',
              filter: 'drop-shadow(0px 0px 1px #2B70B9)',
              background: 'white.500'
            },
            _invalid: {
              borderColor: '#E24A4A',
              filter: 'drop-shadow(0px 0px 2px #B92B2B)'
            }
          }
        }
      }
    },
    Radio: {
      variants: {
        primary: (props: any) => ({
          control: {
            w: '16px',
            h: '16px',
            border: '1px solid',
            borderColor: '#BABABA',
            _checked: {
              bg: '#fff',
              '&::before': {
                bg: `${props.colorScheme}.100`,
                w: '8px',
                h: '8px'
              },
              _hover: {
                bg: '#fff'
              }
            }
          },
          label: {
            ml: '10px'
          }
        })
      }
    },
    Tag: {
      variants: {
        status: (props: any) => ({
          container: {
            bg: `${props.colorScheme}.50`,
            color: `${props.colorScheme}.100`
          }
        }),
        code: (props: any) => ({
          container: {
            justifyContent: 'center',
            bg: '#F6F6F6',
            h: props.height || '24px',
            lineHeight: '14px',
            fontSize: '14px',
            border: '1px solid #EEEEEE',
            borderRadius: '3px',
            color: props.color || '#464646',
            fontWeight: '700',
            p: '2px 4px',
            mx: '3px'
          }
        })
      }
    },
    Table: {
      variants: {
        primary: {
          th: {
            textTransform: 'capitalize',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '17px',
            color: 'gray.700',
            paddingLeft: '16px',
            paddingRight: '0',
            py: '22px',
            '&:first-of-type': {
              paddingLeft: '24px'
            },
            '&:last-of-type': {
              paddingRight: '24px'
            }
          },
          td: {
            py: '22px',
            paddingLeft: '16px',
            paddingRight: '0',
            '&:first-of-type': {
              paddingLeft: '24px'
            },
            '&:last-of-type': {
              paddingRight: '24px'
            }
          }
        }
      }
    },
    PinInput: {
      variants: {
        outline: {
          bg: '#fff',
          borderRadius: '8px!important',
          _focus: {
            border: '1px solid #2B70B9',
            filter: 'drop-shadow(0px 0px 2px #2B70B9)',
            boxShadow: 'none'
          },
          _invalid: {
            border: '1px solid #E24A4A',
            filter: 'drop-shadow(0px 0px 2px #B92B2B)',
            boxShadow: 'none'
          }
        }
      }
    },
    Popover: {
      variants: {
        mobile: {
          popper: {
            '@media screen and (max-width: 576px)': {
              transform: 'none !important',
              position: 'fixed',
              width: '100%',
              zIndex: '500 !important'
            }
          }
        },
        switcher: {
          popper: {
            '@media screen and (max-width: 1023px)': {
              width: '100%',
              left: '10px !important'
            }
          }
        }
      }
    },
    Modal: {
      baseStyle: () => ({
        dialog: {
          sx: {
            ':has(>div)': {
              bg: 'red',
              pt: '50px',
              pb: '50px'
            }
          }
        }
      })
    },
    Switch: {
      variants: {
        primary: {
          container: {
            width: '40px'
          },
          track: {
            w: '34px',
            alignItems: 'center',
            outline: 'none',
            px: '3px',
            bg: 'dark_gray.50',
            _checked: {
              bg: 'light_green.300'
            },
            _focusVisible: {
              boxShadow: 'none'
            }
          },
          thumb: {
            w: '14px',
            h: '14px',
            _checked: {
              transform: 'translateX(20px)'
            }
          }
        }
      },
      sizes: {
        xl: {
          container: {
            width: '58px',
            height: '30px'
          },
          track: {
            w: '48px',
            height: '26px',
            alignItems: 'center',
            outline: 'none',
            px: '5px',
            bg: 'dark_gray.50',
            _checked: {
              bg: 'light_green.300'
            },
            _focusVisible: {
              boxShadow: 'none'
            }
          },
          thumb: {
            w: '20px',
            h: '20px',
            _checked: {
              transform: 'translateX(28px)'
            }
          }
        }
      }
    },
    Tooltip: {
      variants: {
        primary: {
          p: '16px',
          bg: 'light_green.400',
          color: 'light_green.50',
          border: '1px solid',
          borderColor: 'light_green.50',
          borderRadius: '4px',
          fontWeight: '500',
          fontSize: '16px',
          lineHeight: '150%'
        }
      }
    },
    Textarea: {
      variants: {
        primary: {
          p: '16px',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '17px',
          border: '1px solid #E1E1E1',
          _focusVisible: {
            border: '1px solid #2B70B9',
            filter: 'drop-shadow(0px 0px 1px #2B70B9)',
            background: 'white.500'
          },
          _focus: {
            border: '1px solid #2B70B9',
            filter: 'drop-shadow(0px 0px 1px #2B70B9)',
            background: 'white.500'
          },
          _invalid: {
            borderColor: '#E24A4A',
            filter: 'drop-shadow(0px 0px 2px #B92B2B)'
          }
        },
        preview: {
          border: 'none',
          minH: '100%',
          w: 'calc(100% - 43px)',
          whiteSpace: 'pre',
          borderRadius: '0',
          bg: '#202136',
          color: 'white',
          p: '0 24px',
          lineHeight: '24px',
          fontSize: '14px',
          fontWeight: '400',
          outline: 'none',
          overflowY: 'hidden',
          _disabled: {
            opacity: 1,
            cursor: 'auto'
          }
        }
      }
    },
    Progress: {
      variants: {
        success: {
          filledTrack: {
            bg: 'light_green.300'
          }
        },
        danger: {
          filledTrack: {
            bg: 'red.100'
          }
        }
      }
    }
  }
});
