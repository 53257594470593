import { Divider, Flex, HStack, Image, Link, Text, VStack } from '@chakra-ui/react';
import { PlansListType } from 'plans';
import diamond_icon from 'assets/image/diamond.svg';
import flag from 'assets/image/icons/flag.svg';
import team from 'assets/image/icons/team.svg';
import { PlanBenefitsList } from './PlanBenefitsList';

type PlanListCardProps = {
  planType: keyof PlansListType;
  isInPopup?: boolean
};

const PlanBenefitsListContainer = ({ planType, isInPopup }: PlanListCardProps) => (
  <VStack
    alignItems="flex-start"
    spacing="12px"
    w="100%"
    h="100%"
    p="24px"
    bg="white.500"
    border="1px solid"
    borderColor="gray.100"
    boxShadow={isInPopup ? '0px 0px 16px rgba(199, 199, 199, 0.25)' : 'none'}
    borderRadius="8px"
  >
    <HStack spacing="6px" w="100%">
      <Image
        src={planType === 'basic' ? flag : `${planType === 'pro' ? diamond_icon : team}`}
        objectFit="cover"
        boxSize="24px"
        p={planType === 'pro' ? '2px' : '0px'}
      />
      <Text
        textTransform="capitalize"
        fontWeight={isInPopup ? '700' : '600'}
        fontSize={isInPopup ? '16px' : '14px'}
        lineHeight="150%"
      >
        {planType}
      </Text>
    </HStack>

    <Divider />

    <PlanBenefitsList planType={planType} isInPopup={isInPopup} />

    {isInPopup && (
      <>
        <Divider mt="auto!important" />

        <Flex h="20px" fontSize="14px" gap="8px" color="light_gray">
          <i className="fa-solid fa-circle-info" />
          <Text fontWeight="400" fontSize="12px" lineHeight="150%">
            Please visit the
            {' '}
            <Link color="light_blue.100" outline="none" href={`${process.env.REACT_APP_HOME_URL}/plans`} isExternal textDecor="underline">
              Pricing page
            </Link>
            {' '}
            for more information
          </Text>
        </Flex>
      </>
    )}
  </VStack>
);

export default PlanBenefitsListContainer;
