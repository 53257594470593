import { NotificationSettingCode, NotificationSettings, NotificationType } from 'types/types';
import { requestInstance, GetItemsType } from './api';

export const notificationAPI = {
  async getNotificationsList(page: number) {
    const res = await requestInstance().get<GetItemsType<Array<NotificationType>>>(`v1/notification?page=${page}`);

    return res.data;
  },
  async setAllIsRead() {
    const res = await requestInstance().post('v1/notification/mark_all_as_read');

    return res.data;
  },
  async setIsRead(id: string) {
    const res = await requestInstance().patch(`v1/notification/${id}/mark_as_read`);

    return res.data;
  },
  async delete(id: string) {
    const res = await requestInstance().delete(`v1/notification/${id}`);

    return res.data;
  },
  async unreadCount() {
    const res = await requestInstance().get('v1/notification/unread_count');

    return res.data;
  },
  async getSettingsList() {
    const res = await requestInstance().get<GetItemsType<Array<NotificationSettings>>>('v1/notification/settings');

    return res.data;
  },
  async updateSettings(code: NotificationSettingCode, settingsObject: { [key: string]: boolean }) {
    const res = await requestInstance()
      .patch<GetItemsType<Array<NotificationSettings>>>(`v1/notification/settings/${code}`, settingsObject);

    return res.data;
  }
};

