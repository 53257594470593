import { Box, Square } from '@chakra-ui/react';
import { teamAPI } from 'api/teamApi';
import cn from 'classnames';
import { BlurLoading } from 'components/common/BlurLoading';
import { useEffect, useState } from 'react';
import { deleteTeamImage, updateTeam } from 'store/team-reducer';
import { Team } from 'types/types';
import style from '../Popup.module.scss';
import EditManagement from './EditManagement';

type EditTeamProps = {
  onClose: () => void;
  teamId: number;
};

export const EditTeam = ({ onClose, teamId }: EditTeamProps): JSX.Element => {
  const [targetTeam, setTargetTeam] = useState<Team | null>(null);

  useEffect(() => {
     teamAPI.getTeamById(teamId).then((response) => {
      setTargetTeam(response.data);
    });
  }, []);

  if (!targetTeam) return <Box minH="350px"><BlurLoading /></Box>;

  return (
    <EditManagement
      item={targetTeam}
      icon={(
        <Square background="ghost_blue" borderRadius="4px" size="28px">
          <i className={cn('fa-solid fa-users', style.workspace_icon)} />
        </Square>
      )}
      setTargetItem={setTargetTeam}
      onClose={onClose}
      title="Manage Team"
      updateAction={updateTeam}
      deleteImageAction={deleteTeamImage}
    />
  );
};
