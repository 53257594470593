import { InferActionsTypes } from '../types/types';

const initialState = {
  isLoading: false,
  isFetching: false,
  deferredPromptEvent: null as any,
  showInstallApp: false,
  upgrade: {
    showUpgradePlan: false,
    upgradePlanMessage: null as string | null
  },
  showUpdateApp: false
};

export const actionsPopup = {
  setIsLoading: (isLoading: boolean) => ({ type: 'SET_LOADING', isLoading }) as const,
  setShowUpgradePopup: (showUpgradePlan: boolean) => ({ type: 'SET_SHOW_UPGRADE_POPUP', showUpgradePlan }) as const,
  setDeferredPromptEvent: (deferredPromptEvent: any) => ({ type: 'SET_DEFERRED_PROMPT_EVENT', deferredPromptEvent }) as const,
  setShowInstallApp: (showInstallApp: boolean) => ({ type: 'SET_SHOW_INSTALL_APP', showInstallApp }) as const,
  setUpgradePlanMessage: (message: string | null) => ({ type: 'SET_UPGRADE_PLAN_MESSAGE', message }) as const,
  setShowUpdateApp: (showUpdateApp: boolean) => ({ type: 'SET_SHOW_UPDATE_APP', showUpdateApp }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsPopup>

export const popupReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      };

    case 'SET_SHOW_UPGRADE_POPUP':
      return {
        ...state,
        upgrade: { ...state.upgrade, showUpgradePlan: action.showUpgradePlan }
      };

    case 'SET_DEFERRED_PROMPT_EVENT':
      return {
        ...state,
        deferredPromptEvent: action.deferredPromptEvent
      };

    case 'SET_SHOW_INSTALL_APP':
      return {
        ...state,
        showInstallApp: action.showInstallApp
      };

    case 'SET_UPGRADE_PLAN_MESSAGE':
      return {
        ...state,
        upgrade: { ...state.upgrade, upgradePlanMessage: action.message }
      };

    case 'SET_SHOW_UPDATE_APP':
      return {
        ...state,
        showUpdateApp: action.showUpdateApp
      };

    default:
      return state;
  }
};

