import { useEffect, useState } from 'react';
import { Flex, Stack, Text, Link } from '@chakra-ui/react';
import { Link as ReactLink, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import AuthLayout from 'components/BookmarksManager/Layouts/AuthLayout';
import UserIcon from 'components/Auth/UserIcon';
import FormInput from 'components/common/inputs/FormInput';
import PasswordInput from 'components/common/inputs/PasswordInput';
import SubmitButton from 'components/common/buttons/SubmitButton';
import JoinWith from 'components/Auth/JoinWith';

import { useAppDispatch } from 'store/store';
import { oauthLogin } from 'store/auth-reducer';
import { TeamInvitationType } from 'types/types';
import { getTeamByToken } from 'store/team-reducer';

import bgimage from 'assets/image/login.webp';

const Login = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [teamInvitation, setTeamInvitation] = useState<TeamInvitationType | undefined>();

  const [searchParams] = useSearchParams();
  const invitationToken = searchParams.get('invitation_token');
  const joinToken = searchParams.get('join_token');

  useEffect(() => {
    if (invitationToken || joinToken) {
      dispatch(getTeamByToken(invitationToken ?? (joinToken as string), !!joinToken)).then((res) => {
        setTeamInvitation(res);
      });
    }
  }, [invitationToken, joinToken]);

  const formik = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: ({ email, password }) => {
      setIsLoading(true);
      dispatch(oauthLogin({ username: teamInvitation?.email ?? email, password, grant_type: 'password' }))
        .then(() => {
          if (joinToken || invitationToken) {
            window.location.replace(
              joinToken ? `/join?join_token=${joinToken}` : `/invitation?invitation_token=${invitationToken}`
            );
          }
        })
        .catch((errors) => {
          setIsLoading(false);
          formik.setStatus(errors);
          window.scrollTo(0, 0);
        })
        .finally(() => setIsLoading(false));
    }
  });
  const { email, password } = formik.values;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    formik.handleChange(e);
    if (formik.status?.[key]) formik.setStatus({ ...formik.status, [key]: undefined });
  };

  return (
    <AuthLayout
      image={bgimage}
      title={invitationToken || joinToken ? 'Log In' : 'Welcome back'}
      description={teamInvitation && `Log in to manage the invitation to join <b>${teamInvitation.team.name}</b> Team`}
      teamInvitation={teamInvitation}
      icon={<UserIcon background="gradient.150" />}
    >
      <form onSubmit={formik.handleSubmit}>
        <Flex mt={{ base: '32px', lg: '48px' }} w="100%" flexDir="column">
          <Stack spacing={{ base: '16px', lg: '24px' }} mb="32px" fontWeight="400">
            <FormInput
              value={teamInvitation?.email ?? email}
              disabled={!!teamInvitation?.email}
              handleChange={onChange}
              name="email"
              label="Email"
              placeholder="Enter your Email..."
              error={formik.status?.email}
              hideLabelOnMobile
            />
            <Flex direction="column" alignItems="flex-end" fontWeight="400">
              <PasswordInput
                value={password}
                handleChange={onChange}
                name="password"
                label="Password"
                placeholder="Enter your password..."
                error={formik.status?.password}
                hideLabelOnMobile
              />
              <Link
                as={ReactLink}
                to="/forgot-password"
                textAlign="right"
                color="light_blue.100"
                mt="8px"
                fontSize="14px"
              >
                Forgot password?
              </Link>
            </Flex>
          </Stack>
          <SubmitButton isLoading={isLoading}>Log In</SubmitButton>
          <JoinWith
            appleLabel="Continue with Apple ID"
            googleLabel="Continue with Google"
            fbLabel="Continue with Facebook"
          />
          <Text align="center" mt="24px" fontSize="14px">
            Don’t have an account?
            {' '}
            <Link
              as={ReactLink}
              to={`/signup${invitationToken ? `?invitation_token=${invitationToken}` : ''}${joinToken ? `?join_token=${joinToken}` : ''
                }`}
              color="light_blue.100"
            >
              Sign Up
            </Link>
          </Text>
        </Flex>
      </form>
    </AuthLayout>
  );
};

export default Login;
