import { bookmarkAPI } from 'api/bookmarksApi';
import { shareAPI } from 'api/shereApi';
import { deleteItem } from 'utils/delete';
import { errorHandler } from 'utils/errorHandler';
import { AddLinkSourcesType, FolderType, InferActionsTypes, InviteFolderMembers, UsersSuggestions } from '../types/types';
import { showAlert } from './app-reducer';
import { actionsBookmarks } from './bookmark-reducer';
import { actionsFolders } from './folder-reducer';
import { actionsPopup } from './popup-reducer';
import { AppThunkType } from './store';

const initialState = {
  usersSug: [] as Array<UsersSuggestions>,
  currentSharedFolder: null as FolderType | null,
  mainSharedFolder: null as FolderType | null
};

export const actionsShare = {
  setUsersSuggestions: (usersSuggestions: Array<UsersSuggestions>) => ({ type: 'SET_USERS_SUGGESTIONS', usersSuggestions }) as const,
  setCurrentSharedFolder: (data: FolderType | null) => ({ type: 'SET_CURRENT_SHARED_FOLDER', data }) as const,
  setMainSharedFolder: (data: FolderType | null) => ({ type: 'SET_MAIN_SHARED_FOLDER', data }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsShare>

export const shareReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_USERS_SUGGESTIONS':
      return {
        ...state,
        usersSug: action.usersSuggestions
      };

    case 'SET_CURRENT_SHARED_FOLDER':
      return {
        ...state,
        currentSharedFolder: action.data
      };

    case 'SET_MAIN_SHARED_FOLDER':
      return {
        ...state,
        mainSharedFolder: action.data
      };

    default:
      return state;
  }
};

export const getUsersSuggestions = (): AppThunkType => async (dispatch) => {
  try {
    const response = await shareAPI.getUsersWithShare();
    if (response.success) {
      dispatch(actionsShare.setUsersSuggestions(response.data));
    }
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export const shareBookmark = (bookmarkId: number, emails: Array<string>): AppThunkType => async (dispatch) => {
  dispatch(actionsPopup.setIsLoading(true));
  try {
    const response = await shareAPI.shareBookmark(bookmarkId, emails);
    if (response.success) {
      dispatch(showAlert(response.data.message, response.success));
    }

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch, (res) => {
      if (res.validationErrors) {
        dispatch(showAlert(Object.values(res.validationErrors), false));
      }
    });

    return Promise.reject(error);
  } finally {
    dispatch(actionsPopup.setIsLoading(false));
  }
};

export const shareFolder = (folderId: number, invitedUsers: InviteFolderMembers | null): AppThunkType => async (dispatch, getState) => {
  const folders = getState().folders.data;

  try {
    const response = await shareAPI.shareFolder(folderId, invitedUsers);
    dispatch(showAlert(response.data.message, response.success));

    const newFolderList = folders.map((fold) => (fold.id === folderId
      ? {
        ...fold,
        shared: !!invitedUsers?.length,
        updated_at: String(new Date())
      }
      : fold));
    dispatch(actionsFolders.updateFolders(newFolderList));

    return Promise.resolve();
  } catch (err) {
    return errorHandler(err, dispatch, (errors) => {
      if (errors.validationErrors) {
        return Promise.reject(errors.validationErrors[
          Object.keys(errors.validationErrors)[Object.keys(errors.validationErrors).length - 1]
        ]);
      }
    });
  }
};

export const getSharedBookmarks = (
  folderId?: string | number,
  page?: number,
  keyword?: string
): AppThunkType => async (dispatch) => {
  dispatch(actionsBookmarks.setIsFetching(true));
  try {
    const response = await shareAPI.getSharedBookmarks(folderId, page, keyword);

    if (response.pagination.current_page === 1) {
      dispatch(actionsBookmarks.setNewBookmarksInFolder(response.data, response.pagination));
    } else {
      dispatch(actionsBookmarks.setBookmarks(response.data, response.pagination));
    }

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  } finally {
    dispatch(actionsBookmarks.setIsFetching(false));
  }
};

export const getSharedFolders = (
  folderId?: string | number,
  page?: number,
  keyword?: string
): AppThunkType => async (dispatch) => {
  dispatch(actionsFolders.setIsFetching(true));
  try {
    const response = await shareAPI.getSharedFolders(folderId, page, keyword);

    dispatch(actionsFolders.setFolderList(response.data));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);
    Promise.reject(error);
  } finally {
    dispatch(actionsFolders.setIsFetching(false));
  }
};

export const saveSharedBookmark = (
  url: string,
  source: AddLinkSourcesType,
  workspaceId: number,
  folder?: number
): AppThunkType => async (dispatch) => {
  try {
    const response = await bookmarkAPI.addBookmark(workspaceId, url, folder, [], source);
    dispatch(showAlert('Link successfully saved', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch, (res) => {
      if (res.validationErrors) {
        dispatch(showAlert(Object.values(res.validationErrors), false));
      }
    });

    Promise.reject(error);
  }
};

export const removeSharedBookmark = (
  bookmarkId: number | string
): AppThunkType => async (dispatch, getState) => {
  const { data: bookmarks, pagination } = getState().bookmarks;

  try {
    const response = await shareAPI.deleteSharedBookmark(bookmarkId);
    dispatch(actionsBookmarks.updateBookmarks(deleteItem(bookmarks, bookmarkId)));
    dispatch(actionsBookmarks.setAllBookmarksCount(pagination.total - 1));
    dispatch(showAlert(response.data.message, response.success));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    Promise.reject(error);
  }
};

export const removeSharedFolder = (folderId: number): AppThunkType => async (dispatch, getState) => {
  const folders = getState().folders.data;

  try {
    const response = await shareAPI.deleteSharedFolder(folderId);

    dispatch(actionsFolders.updateFolders(deleteItem(folders, folderId)));
    dispatch(showAlert(response.data.message, response.success));

    return Promise.resolve(response.data.message);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const getSharedFolder = (folderId: number | string): AppThunkType => async (dispatch) => {
  try {
    const response = await shareAPI.getSharedFolder(folderId);
    dispatch(actionsShare.setCurrentSharedFolder(response.data));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const getMainSharedFolder = (slug: string): AppThunkType => async (dispatch) => {
  try {
    const response = await shareAPI.getSharedFolderBySlug(slug);
    dispatch(actionsShare.setMainSharedFolder(response.data));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};
