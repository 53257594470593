import { Grid, GridItem, Image, Text } from '@chakra-ui/react';
import { BookmarkPreviewType } from 'types/types';
import imgBookmarkDefault from 'assets/image/img_bookmark_default.webp';

interface BookmarkPreviewProps {
  bookmark: BookmarkPreviewType;
  iconSize?: string;
}

const BookmarkPreview = ({ bookmark, iconSize = '78px' }: BookmarkPreviewProps) => (
  <Grid
    templateRows="repeat(3, 1fr)"
    templateColumns="repeat(5, 1fr)"
    gap="4px"
    bg="white.200"
    borderRadius="4px"
  >
    <GridItem boxSize={iconSize} rowSpan={3} colSpan={1} mr="12px">
      <Image w="100%" h="100%" objectFit="cover" borderRadius="4px" src={bookmark.icon || imgBookmarkDefault} alt="" />
    </GridItem>
    <GridItem rowSpan={1} colSpan={4}>
      <Text fontWeight="500" fontSize="14px" lineHeight="150%" noOfLines={1}>
        {bookmark.title}
      </Text>
    </GridItem>
    <GridItem rowSpan={2} colSpan={4}>
      <Text fontWeight="400" fontSize="12px" lineHeight="150%" color="gray.500" noOfLines={2}>
        {bookmark.description}
      </Text>
    </GridItem>
  </Grid>
);

export default BookmarkPreview;
