import { wikiTrashAPI } from 'api/wiki/trashApi';
import { showAlert } from 'store/app-reducer';
import { AppThunkType } from 'store/store';
import { InferActionsTypes } from 'types/types';
import { WikiDocumentType, WikiFolderType } from 'types/wiki';
import { deleteItem } from 'utils/delete';
import { errorHandler } from 'utils/errorHandler';

const initialState = {
  data: [] as (WikiDocumentType | WikiFolderType)[],
  isFetching: false
};

export const actionsWikiTrash = {
  setTrashedList: (data: Array<WikiDocumentType | WikiFolderType>) => ({ type: 'SET_TRASHED_LIST', data }) as const,
  setIsFetching: (isFetching: boolean) => ({ type: 'SET_IS_FETCHING_WIKI_TRASH_LIST', isFetching }) as const,
  cleanUp: () => ({ type: 'CLEAN_UP_WIKI_TRASH_REDUCER' }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsWikiTrash>

export const wikiTrashReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_TRASHED_LIST':
      return {
        ...state,
        data: action.data
      };

    case 'SET_IS_FETCHING_WIKI_TRASH_LIST':
      return {
        ...state,
        isFetching: action.isFetching
      };
    case 'CLEAN_UP_WIKI_TRASH_REDUCER':
      return {
        ...state,
        data: [],
        isFetching: false
      };

    default:
      return state;
  }
};

export const getTrashedList = (): AppThunkType => async (dispatch) => {
  dispatch(actionsWikiTrash.setIsFetching(true));

  try {
    const responseDocuments = await wikiTrashAPI.getTrashedDocumentList();
    const responseFolders = await wikiTrashAPI.getTrashedFolderList();

    Promise.all([responseDocuments, responseFolders]).then(() => {
      dispatch(actionsWikiTrash.setTrashedList([...responseFolders.data, ...responseDocuments.data]));

      return Promise.resolve();
    });
  } catch (error: any) {
    errorHandler(error, dispatch);
    Promise.reject(error);
  } finally {
    dispatch(actionsWikiTrash.setIsFetching(false));
  }
};

export const restoreDocument = (documentId: number): AppThunkType => async (dispatch, getState) => {
  const trashedList = getState().wikiTrash.data;

  try {
    const response = await wikiTrashAPI.restoreDocument(documentId);
    dispatch(actionsWikiTrash.setTrashedList(deleteItem(trashedList, documentId)));
    dispatch(showAlert(response.data.message, response.success));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

