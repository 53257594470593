import { useRef, useState } from 'react';
import { Center, Flex, Image, Square, Stack, Text } from '@chakra-ui/react';
import { Area } from 'react-easy-crop';
import { useFormik } from 'formik';
import cn from 'classnames';

import UploadImage from 'components/common/UploadImage';
import { ButtonGroupPopup } from 'components/common/buttons/ButtonGroupPopup';
import FormInput from 'components/common/inputs/FormInput';
import { useAppDispatch, useAppSelector } from 'store/store';
import { showAlert } from 'store/app-reducer';
import { createNewWorkspace } from 'store/workspace-reducer';
import { ImageCropper } from './ImageCropper';

import style from './Popup.module.scss';

type AddWorkspaceProps = {
  onClose: () => void;
};

export const AddWorkspace = ({ onClose }: AddWorkspaceProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const team = useAppSelector((state) => state.profile.currentEnvironment);
  const [image, setImage] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>();
  const [croppedImage, setCroppedImage] = useState('');
  const [cropArea, setCropArea] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0
  });
  const imageRef = useRef<HTMLInputElement | null>(null);

  const formik = useFormik({
    initialValues: {
      workspaceName: ''
    },
    onSubmit: (value) => {
      dispatch(
        createNewWorkspace({
          name: value.workspaceName,
          type: 'personal',
          image: image ? { file: image, ...cropArea } : undefined,
          team_id: team?.team_id
        })
      )
        .then(() => {
          onClose();
        })
        .catch((errors) => {
          if (errors?.team_id) {
            dispatch(showAlert(errors.team_id, false));
          }
          formik.setStatus(errors);
        }).finally(() => {
          formik.setSubmitting(false);
        });
    }
  });

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
      const preview = URL.createObjectURL(e.target.files[0]);
      setImagePreview(preview);
    }
  };

  const resetImage = (reset = false) => {
    setImagePreview(undefined);
    if (reset) {
      setImage(null);
    }
    if (imageRef.current) imageRef.current.value = '';
  };

  const getImage = (img: string, area: Area) => {
    setCroppedImage(img);
    resetImage();
    setCropArea(area);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    if (formik.status?.name) formik.setStatus({ ...formik.status, name: undefined });
  };

  const handleDeleteImage = async () => {
    setCroppedImage('');
    resetImage(true);

    return Promise.resolve();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex direction="column">
        <Stack spacing="24px" mb="16px">
          <Flex>
            <Flex alignItems="center">
              <Square background="ghost_blue" borderRadius="4px" size="28px">
                <i className={cn('fa-solid fa-briefcase', style.workspace_icon)} />
              </Square>
              <Text mx="12px" fontWeight="600" fontSize="16px" lineHeight="19px">
                Create Workspace
              </Text>
            </Flex>
          </Flex>
          <Center minH="24px" bg="white.200" borderRadius="4px" p="29px">
            <UploadImage
              image={croppedImage}
              deleteImage={handleDeleteImage}
              imageRef={imageRef}
              handleChange={handleUpload}
            >
              {croppedImage ? (
                <Image boxSize="100%" objectFit="cover" src={croppedImage} />
              ) : (
                <Center boxSize="120px" bg="blue" color="white.50" fontSize="50px">
                  <i className="fa-solid fa-briefcase" />
                </Center>
              )}
            </UploadImage>
          </Center>
        </Stack>
        <FormInput
          name="workspaceName"
          placeholder="Workspace name..."
          handleChange={onChange}
          value={formik.values.workspaceName}
          autoComplete="off"
          error={formik.status?.name}
          height="40px"
        />
        <ButtonGroupPopup isLoading={formik.isSubmitting} label="Save" handleCancelClick={onClose} />
      </Flex>

      {imagePreview && <ImageCropper getImage={getImage} image={imagePreview} onClose={resetImage} />}
    </form>
  );
};
