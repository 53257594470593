import { logOut } from 'store/auth-reducer';
import { errorHandler } from 'utils/errorHandler';
import { BookmarkSortOption, options } from 'utils/bookmarkSortOptions';
import { bookmarkAPI } from '../api/bookmarksApi';
import {
  AddLinkSourcesType,
  BookmarkPreviewType,
  BookmarkType, InferActionsTypes, PaginationType
} from '../types/types';
import { addAfterPinned } from '../utils/addAfterPinned';
import { deleteItem } from '../utils/delete';
import { pin } from '../utils/pin';
import { showAlert } from './app-reducer';
import { getOneWorkspace } from './workspace-reducer';
import { AppThunkType } from './store';

const initialState = {
  data: [] as Array<BookmarkType>,
  pagination: {
    total: 0,
    count: 0,
    per_page: 20,
    current_page: 1,
    total_pages: 1
  } as PaginationType,
  bookmarkPreview: null as BookmarkPreviewType | null,
  isFetching: false
};

export const actionsBookmarks = {
  setBookmarks: (data: Array<BookmarkType>, pagination: PaginationType) => ({ type: 'SET_BOOKMARKS', data, pagination }) as const,
  setNewBookmarksInFolder: (data: Array<BookmarkType>, pagination: PaginationType) => ({
    type: 'SET_NEW_BOOKMARKS_IN_FOLDER', data, pagination
  }) as const,
  setIsFetching: (isFetching: boolean) => ({ type: 'SET_IS_FETCHING_BOOKMARKS', isFetching }) as const,
  setBookmarksPagination: (data: PaginationType) => ({ type: 'SET_BOOKMARKS_PAGINATION', data }) as const,
  setAllBookmarksCount: (bookmarksCount: number) => ({ type: 'SET_BOOKMARKS_COUNT', bookmarksCount }) as const,
  addNewBookmark: (bookmarks: Array<BookmarkType>) => ({ type: 'ADD_NEW_BOOKMARK', bookmarks }) as const,
  updateBookmarks: (newBookmarksList: Array<BookmarkType>) => ({ type: 'EDIT_BOOKMARKS', newBookmarksList }) as const,
  setBookmarkPreview: (bookmark: BookmarkPreviewType | null) => ({ type: 'SET_BOOKMARK_PREVIEW', bookmark }) as const,
  cleanUp: () => ({ type: 'CLEAN_UP_BOOKMARKS_REDUCER' }) as const
};

export type InitialStateType = typeof initialState
export type ActionType = InferActionsTypes<typeof actionsBookmarks>

export const bookmarkReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_BOOKMARKS':
      return {
        ...state,
        data: [...state.data, ...action.data],
        pagination: action.pagination
      };

    case 'SET_BOOKMARKS_PAGINATION':
      return {
        ...state,
        pagination: action.data
      };

    case 'SET_NEW_BOOKMARKS_IN_FOLDER':
      return {
        ...state,
        data: action.data,
        pagination: action.pagination
      };

    case 'SET_BOOKMARKS_COUNT':
      return {
        ...state,
        pagination: { ...state.pagination, total: action.bookmarksCount }

      };

    case 'SET_IS_FETCHING_BOOKMARKS':
      return {
        ...state,
        isFetching: action.isFetching
      };

    case 'ADD_NEW_BOOKMARK':
      if (state.pagination.total_pages === 1) {
        return {
          ...state,
          data: action.bookmarks
        };
      }

      return {
        ...state,
        data: action.bookmarks.slice(0, -1)
      };

    case 'EDIT_BOOKMARKS':
      return {
        ...state,
        data: action.newBookmarksList
      };

    case 'SET_BOOKMARK_PREVIEW':
      return {
        ...state,
        bookmarkPreview: action.bookmark
      };

    case 'CLEAN_UP_BOOKMARKS_REDUCER':
      return {
        ...state,
        data: [],
        pagination: {
          total: 0,
          count: 0,
          per_page: 20,
          current_page: 1,
          total_pages: 1
        },
        isFetching: false
      };
    default:
      return state;
  }
};

export const getBookmarks = (
  workspaceId: string,
  folderId?: string | null,
  pageNumber?: number,
  filter?: string | null,
  tags?: string | null,
  sortBy?: BookmarkSortOption
): AppThunkType => async (dispatch) => {
  dispatch(actionsBookmarks.setIsFetching(true));

  try {
    const tagsList = tags?.replaceAll(' ', ',');
    const sort = sortBy ? { sort: options[sortBy].sort, order: options[sortBy].order } : null;

    const response = await bookmarkAPI
      .getBookmarks(workspaceId, folderId ?? null, pageNumber ?? 1, filter ?? null, tagsList ?? null, sort);

    if (response.success) {
      if (response.pagination.current_page === 1) {
        dispatch(actionsBookmarks.setNewBookmarksInFolder(response.data, response.pagination));
      } else {
        dispatch(actionsBookmarks.setBookmarks(response.data, response.pagination));
      }
    }

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);
    Promise.reject(error);
  } finally {
    dispatch(actionsBookmarks.setIsFetching(false));
  }
};

export const addBookmarkToList = (data: BookmarkType): AppThunkType => async (dispatch: any, getState) => {
  const { mainSharedFolder } = getState().share;
  const bookmarksCount = getState().bookmarks.pagination.total;
  const bookmarks = getState().bookmarks.data;

  const bookmarksIds = bookmarks.map((bookmark) => bookmark.id);

  if (!bookmarksIds.includes(data.id)) {
    dispatch(actionsBookmarks.addNewBookmark(addAfterPinned([data, ...bookmarks], data)));
    dispatch(actionsBookmarks.setAllBookmarksCount(bookmarksCount + 1));
    if (!mainSharedFolder) {
      dispatch(getOneWorkspace(data.workspace.id));
    }
  }
};

export const addBookmark = (
  url: string,
  source: AddLinkSourcesType,
  folder?: number,
  workspaceId?: number,
  tags: string[] = [],
  updateList = true
): AppThunkType => async (dispatch: any, getState) => {
  const { currentWorkspaceId } = getState().workspaces;

  try {
    const response = await bookmarkAPI.addBookmark(workspaceId ?? +currentWorkspaceId, url, folder, tags, source);
    if (updateList) {
      dispatch(addBookmarkToList(response.data));
    } else {
      dispatch(getOneWorkspace(response.data.workspace?.id));
    }

    dispatch(showAlert('Link successfully saved', true));

    return Promise.resolve();
  } catch (error: any) {
    if (error.response.status === 401) dispatch(logOut());

    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const deleteBookmark = (bookmarkId: number): AppThunkType => async (dispatch, getState) => {
  const bookmarks = getState().bookmarks.data;
  const bookmarksCount = getState().bookmarks.pagination.total;
  const { currentWorkspaceId } = getState().workspaces;

  try {
    const response = await bookmarkAPI.deleteBookmark(bookmarkId);
    if (response.success) {
      dispatch(actionsBookmarks.updateBookmarks(deleteItem(bookmarks, bookmarkId)));
      dispatch(actionsBookmarks.setAllBookmarksCount(bookmarksCount - 1));
      dispatch(getOneWorkspace(currentWorkspaceId));
      dispatch(showAlert(response.data.message, response.success));
    }
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export const deleteBookmarksList = (bookmarks: Array<number>): AppThunkType => async (dispatch, getState) => {
  const { currentWorkspaceId } = getState().workspaces;

  try {
    const response = await bookmarkAPI.deleteBookmarksList(bookmarks);

    if (response.success) {
      dispatch(getOneWorkspace(currentWorkspaceId));
      dispatch(showAlert(response.data.message, response.success));
    }

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const pinBookmark = (bookmark: BookmarkType, isPin: boolean): AppThunkType => async (dispatch, getState) => {
  const bookmarks = getState().bookmarks.data;
  let newFoldersList = [] as Array<BookmarkType>;

  try {
    let response;

    if (isPin) {
      response = await bookmarkAPI.pinBookmark(bookmark.id);
      newFoldersList = pin(bookmarks, isPin, bookmark);
    } else {
      response = await bookmarkAPI.unpinBookmark(bookmark.id);
      newFoldersList = addAfterPinned(bookmarks, bookmark, isPin);
    }

    dispatch(actionsBookmarks.updateBookmarks(newFoldersList));
    dispatch(showAlert(response.data.message, response.success));
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export const renameBookmark = (bookmarkId: number, newBookmarkName: string, tags: string[] = [])
  : AppThunkType => async (dispatch, getState) => {
    const bookmarks = getState().bookmarks.data;

    try {
      const response = await bookmarkAPI.renameBookmark(bookmarkId, newBookmarkName, tags);

      if (response.success) {
        const newBookmarksList = bookmarks.map((bookmark) => (bookmark.id === bookmarkId
          ? {
            ...bookmark,
            name: response.data.name,
            tags: response.data.tags,
            updated_at: response.data.updated_at
          }
          : bookmark));

        dispatch(actionsBookmarks.updateBookmarks(newBookmarksList));

        dispatch(showAlert('Bookmark successfully renamed', response.success));
      }

      return Promise.resolve();
    } catch (error: any) {
      return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
    }
  };

export const copyBookmark = (
  bookmarkId: number,
  foldersList: Array<number>

): AppThunkType => async (dispatch, getState) => {
  const { currentWorkspaceId } = getState().workspaces;
  try {
    const response = await bookmarkAPI.copyBookmark(bookmarkId, foldersList);

    if (response.success) {
      dispatch(getOneWorkspace(currentWorkspaceId));
      dispatch(showAlert(response.data.message, response.success));
    }

    return Promise.resolve();
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => {
      if (res.validationErrors) {
        dispatch(showAlert(Object.values(res.validationErrors), false));
      }

      return Promise.reject(error);
    });
  }
};

export const moveBookmark = (
  bookmarkId: number,
  folderId: number | null
): AppThunkType => async (dispatch, getState) => {
  const bookmarks = getState().bookmarks.data;
  const bookmarksCount = getState().bookmarks.pagination.total;
  try {
    const response = await bookmarkAPI.moveBookmark(bookmarkId, folderId);

    dispatch(actionsBookmarks.updateBookmarks(deleteItem(bookmarks, bookmarkId)));
    dispatch(actionsBookmarks.setAllBookmarksCount(bookmarksCount - 1));
    dispatch(showAlert(response.data.message, response.success));

    return Promise.resolve(true);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => {
      if (res.validationErrors) {
        dispatch(showAlert(Object.values(res.validationErrors), false));
      }
    });
  }
};

export const moveBookmarksList = (
  bookmarks: number[],
  folderId: number | null
): AppThunkType => async (dispatch, getState) => {
  const bookmarksInState = getState().bookmarks.data;
  const bookmarksCount = getState().bookmarks.pagination.total;
  try {
    const response = await bookmarkAPI.moveBookmarksList(bookmarks, folderId);
    const updatedList = bookmarksInState.filter((item) => !bookmarks.includes(item.id));

    dispatch(actionsBookmarks.updateBookmarks(updatedList));
    dispatch(actionsBookmarks.setAllBookmarksCount(bookmarksCount - bookmarks.length));
    dispatch(showAlert(response.data.message, response.success));

    return Promise.resolve(true);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => {
      if (res.validationErrors) {
        dispatch(showAlert(Object.values(res.validationErrors), false));
      }
    });
  }
};

export const getBookmarkPreview = (url: string): AppThunkType => async (dispatch) => {
  dispatch(actionsBookmarks.setBookmarkPreview(null));
  if (!url) return;
  try {
    const response = await bookmarkAPI.getPreview(url);
    if (response.success) {
      dispatch(actionsBookmarks.setBookmarkPreview(response.data));
    }

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export const getSelectedFolders = (bookmarkId: number): AppThunkType => async (dispatch) => {
  try {
    const response = await bookmarkAPI.getFolders(bookmarkId);
    const folders = response.data.folder_ids.filter((id) => id !== null);

    return Promise.resolve([...folders] as number[]);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const orderBookmarks = (
  firstIndex: number,
  secondIndex: number,
  folderId?: string | number
): AppThunkType => async (dispatch, getState) => {
  const { pagination, data } = getState().bookmarks;

  try {
    const bookmarksClone = [...data];
    const replaceable = bookmarksClone.splice(firstIndex, 1);
    bookmarksClone.splice(secondIndex, 0, replaceable[0]);
    dispatch(actionsBookmarks.setNewBookmarksInFolder(bookmarksClone, pagination));
    await bookmarkAPI.orderBookmarks(bookmarksClone.map((item) => item.id), folderId);

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};
