import { Button, Flex, ResponsiveValue } from '@chakra-ui/react';
import { MouseEventHandler } from 'react';
import { Union } from 'types/types';

type PropsType = {
  label: string;
  handleClick?: (() => void) | MouseEventHandler<HTMLButtonElement>;
  handleCancelClick?: (() => void) | undefined;
  type?: 'button' | 'submit' | 'reset';
  cancel?: boolean;
  colorScheme?: string;
  isLoading?: boolean;
  mt?: ResponsiveValue<Union<string | 'px' | (string & {})>> | string;
  isDisabled?: boolean;
  cancelLabel?: string;
};

export const ButtonGroupPopup = ({
  label,
  handleClick,
  type = 'submit',
  cancel = true,
  handleCancelClick,
  colorScheme = 'green',
  isLoading,
  mt = '32px',
  cancelLabel = 'Cancel',
  isDisabled
}: PropsType) => (
  <Flex
    width="100%"
    flexDirection={{ base: 'column-reverse', lg: 'row' }}
    alignItems="center"
    justifyContent="flex-end"
    mt={typeof mt === 'string' ? { base: '32px', lg: mt } : mt}
    gap={{ base: '8px', lg: '12px' }}
  >
    {cancel && (
      <Button
        onClick={handleCancelClick}
        colorScheme={colorScheme}
        variant="cancel"
        width={{ base: '100%', lg: 'unset' }}
        size="sm"
        fontFamily="'Inter',sans-serif"
      >
        {cancelLabel}
      </Button>
    )}
    <Button
      isDisabled={isDisabled}
      colorScheme={colorScheme}
      onClick={handleClick}
      isLoading={isLoading}
      type={type}
      size="sm"
      width={{ base: '100%', lg: 'unset' }}
      fontFamily="'Inter',sans-serif"
      filter="drop-shadow(0px 1px 4px rgba(14, 87, 72, 0.25))"
    >
      {label}
    </Button>
  </Flex>
);
