import { EnvironmentType } from 'types/types';
import { requestInstance, ResponseType } from './api';

export const environmentAPI = {
  async getEnvironmentList() {
    const res = await requestInstance().get<ResponseType<Array<EnvironmentType>>>('v1/environment?include_pending_teams=yes');

    return res.data;
  }
};
