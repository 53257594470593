import { Center } from '@chakra-ui/react';

export const UpgradeTag = ({ title }:{title:string}):JSX.Element => (
  <Center ml="auto" borderRadius="24px" p="4px 8px" color="white.50" bg="blue" gap="6px" fontWeight="600" fontSize="12px">
    <Center boxSize="12px">
      <i className="fa-regular fa-gem" />
    </Center>
    {title}
  </Center>
  );
