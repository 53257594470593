import { Box, Flex, Link, Image, Text } from '@chakra-ui/react';
import appStore from 'assets/image/icons/appstore.svg';
import googlePlay from 'assets/image/icons/googleplay.svg';
import googleStore from 'assets/image/icons/googlestore.svg';
import logo from 'assets/image/icons/logosmall.svg';
import AppLink from './AppLink';

const AuthFooter = () => (
  <Box
    bg={{ base: 'none', lg: 'gradient.10' }}
    color={{ base: 'black', lg: 'white.500' }}
    p={{ base: '24px', md: '79px 24px 20px', lg: '24px' }}
  >
    <Flex w="100%" mb="16px" gap="16px" alignItems="center" justifyContent={{ base: 'center', lg: 'initial' }}>
      <AppLink image={appStore} link={`${process.env.REACT_APP_APP_STORE_URL}`} />
      <AppLink image={googlePlay} link={`${process.env.REACT_APP_GOOGLE_PLAY_URL}`} />
      <AppLink image={googleStore} link={`${process.env.REACT_APP_CHROME_EXTENSION}`} />
    </Flex>
    <Flex
      w="100%"
      py={{ base: '16px', lg: '8px' }}
      gap={{ base: '32px', md: '16px' }}
      borderTop={{ base: 'none', lg: '1px solid' }}
      borderBottom={{ base: '1px solid', lg: 'none' }}
      borderColor={{ base: 'gray.100', lg: 'white.500' }}
      alignItems="center"
      justifyContent={{ base: 'center', lg: 'initial' }}
      flexWrap={{ base: 'wrap', lg: 'initial' }}
    >
      <Link href="/" fontWeight={600} fontSize="14px" lineHeight="17px">
        Privacy Policy
      </Link>
      <Link href="/" fontWeight={600} fontSize="14px" lineHeight="17px">
        Terms of Service
      </Link>
      <Link href="/" fontWeight={600} fontSize="14px" lineHeight="17px">
        Contact Us
      </Link>
    </Flex>
    <Flex
      alignItems="center"
      w="100%"
      justifyContent={{ base: 'center', lg: 'initial' }}
      color={{ base: 'light_gray', lg: 'inherit' }}
      mt={{ base: '16px', lg: '0' }}
    >
      <Image src={logo} alt="Linker" boxSize="16px" mr="8px" display={{ base: 'none', lg: 'block' }} />
      <Text fontSize="14px">
        Copyright ©
        {' '}
        {new Date().getFullYear()}
        {' '}
        Linker
      </Text>
    </Flex>
  </Box>
);
export default AuthFooter;
