import { Avatar, Box, Button, Flex, Tag, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { EnvironmentType } from 'types/types';

type EnvironmentItemProps = {
  environment: EnvironmentType;
};

export const PendingEnvironmentItem = ({ environment }: EnvironmentItemProps) => {
  const { name, image, invite } = environment;
  const navigate = useNavigate();

  const handleManage = () => {
    navigate(`/invitation?invitation_token=${invite?.token}`);
  };

  return (
    <Box
      onClick={(e) => e.stopPropagation()}
      position="relative"
      p="8px"
    >
      <Flex alignItems="center" gap="8px" mb="10px">
        <Avatar
          h="32px"
          w="32px"
          borderRadius="50px"
          bg="#dedede"
          opacity={0.5}
          src={image ?? ''}
          mr="2px"
          icon={(
            <Box fontSize="16px" color="#8f8f8f">
              <i className="fa-solid fa-user" />
            </Box>
          )}
        />

        <Text
          maxW="130px"
          fontStyle="normal"
          fontWeight="500"
          fontSize="14px"
          color="black"
          noOfLines={1}
          wordBreak="break-all"
          display="block"
          lineHeight="17px"
          opacity={0.5}
          whiteSpace="nowrap"
        >
          {name}
        </Text>
        <Tag borderRadius="full" fontSize="10px" lineHeight="12px" colorScheme="peach">
          Pending
        </Tag>
      </Flex>
      <Button w="100%" h="32px" fontSize="12px" lineHeight="15px" colorScheme="green" onClick={handleManage}>
        Manage Invitation
      </Button>
    </Box>
  );
};
