const AddFolderIcon = ({ size = 78 }: { size?: number }) => (
  <svg width={size} height={size} viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d={`M38.5666 22.0632V21.4127C38.5666 20.3565 37.7235 19.5 36.6837
       19.5H17.0499C16.0102 19.5 15.167 20.3565 15.167 21.4127L15.167 
       55.904H61.9663V23.9767C61.9663 22.9205 61.1231 22.064 60.0834 22.064H38.5666V22.0632Z`}
      fill="#FEE795"
    />
    <path
      d={`M37.899 28.7498L37.204 30.7061H17.0499C16.0102 30.7061
       15.167 31.5626 15.167 32.6188V56.9729C15.167 58.0291
        16.0102 58.8856 17.0499 58.8856H60.0834C61.1231 58.8856 
        61.9663 58.0291 61.9663 56.9729V29.3995C61.9663 28.3433 
        61.1231 27.4868 60.0834 27.4868L39.6703 27.4868C38.8763 27.486 38.1681 27.9915 37.899 28.7498Z`}
      fill="#F3D157"
    />
    <rect x="1" y="1" width="76" height="76" rx="11" stroke="#E0E0E0" strokeWidth="2" strokeDasharray="18 18 17" />
    <path
      d={`M46.5837 43.3333C46.5837 43.9783 46.0612 44.5004
       45.417 44.5004H40.167V49.7504C40.167 50.3953 39.6445
       50.9167 39.0003 50.9167C38.3561 50.9167 37.8337 
       50.3953 37.8337 49.7504V44.5004H32.5837C31.9394 
       44.5004 31.417 43.9783 31.417 43.3333C31.417 42.6884
        31.9394 42.167 32.5837 42.167H37.8337V36.917C37.8337 
        36.2721 38.3561 35.75 39.0003 35.75C39.6445 35.75 40.167 36.2721
         40.167 36.917V42.167H45.417C46.0623 42.1667 46.5837 42.688 46.5837 43.3333Z`}
      fill="#CEAF40"
    />
  </svg>
);

export default AddFolderIcon;
