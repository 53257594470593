import { Image, Text, Center } from '@chakra-ui/react';
import no_notifications from '../../../../../assets/image/no_notifications.svg';

export const NoNotifications = () => (
  <Center pt={{ base: '0', md: '48px' }} w={{ base: '306px', md: '248px' }} flexDirection="column" h={{ base: '100%', md: 'auto' }}>
    <Image pb={{ base: '24px', md: '16px' }} h={{ base: '180px', md: '135px' }} src={no_notifications} />
    <Text pb="8px">
      You do not have notifications!
    </Text>
    <Text textAlign="center" fontSize={{ base: '14px', md: '12px' }} fontWeight="400" color="light_gray">
      Here you will see notifications received from the Linker team, other users, and more.
    </Text>
  </Center>
);
