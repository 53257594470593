import { Button, ResponsiveValue } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Union } from 'types/types';

type SubmitButtonProps = {
  children: ReactNode;
  isLoading?: boolean;
  height?: string;
  width?: ResponsiveValue<Union<string | 'px' | (string & {})>>;
};

const SubmitButton = ({ children, isLoading, height = '40px', width }: SubmitButtonProps) => (
  <Button
    colorScheme="green"
    type="submit"
    filter="drop-shadow(0px 1px 4px rgba(14, 87, 72, 0.25))"
    lineHeight="150%"
    fontSize={{ base: '16px', md: '14px' }}
    isLoading={isLoading}
    w={width}
    h={{ base: '48px', lg: height }}
    _disabled={{
      opacity: '0.5',
      cursor: 'initial'
    }}
  >
    {children}
  </Button>
);

export default SubmitButton;
