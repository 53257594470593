import { environmentAPI } from 'api/environmentApi';
import { teamAPI } from 'api/teamApi';
import { errorHandler } from 'utils/errorHandler';
import {
  EnvironmentType, InferActionsTypes,
  InviteType, PaginationType, PendingTeamMember, Team, TeamMember, UpdateWorkspace
} from '../types/types';
import { showAlert } from './app-reducer';
import { actionsProfile, getEnvironments } from './profile-reducer';
import { AppThunkType } from './store';
import { actionsWorkspace, getWorkspaces } from './workspace-reducer';

const initialState = {
  teamMembers: [] as Array<TeamMember>,
  teamPendingMembers: [] as Array<PendingTeamMember>,
  currentTeam: null as Team | null,
  pagination: {
    count: 0,
    current_page: 1,
    per_page: 15,
    total: 0,
    total_pages: 1
  },
  pendingMembersPagination: {
    count: 0,
    current_page: 1,
    per_page: 15,
    total: 0,
    total_pages: 1
  }
};

export const actionsTeam = {
  setTeamMembersList: (data: Array<TeamMember>) => ({ type: 'SET_TEAM_MEMBERS_LIST', data }) as const,
  setTeamPendingMembersList: (data: Array<PendingTeamMember>) => ({ type: 'SET_TEAM_PENDING_MEMBERS_LIST', data }) as const,
  setCurrentTeam: (data: Team | null) => ({ type: 'SET_CURRENT_TEAM', data }) as const,
  setMembersPagination: (data: PaginationType) => ({ type: 'SET_TEAM_MEMBERS_PAGINATION', data }) as const,
  setPendingMembersPagination: (data: PaginationType) => ({ type: 'SET_TEAM_PENDING_MEMBERS_PAGINATION', data }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsTeam>

export const teamReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_TEAM_MEMBERS_LIST':
      return {
        ...state,
        teamMembers: action.data
      };

    case 'SET_TEAM_PENDING_MEMBERS_LIST':
      return {
        ...state,
        teamPendingMembers: action.data
      };

    case 'SET_CURRENT_TEAM':
      return {
        ...state,
        currentTeam: action.data
      };

    case 'SET_TEAM_MEMBERS_PAGINATION':
      return {
        ...state,
        pagination: action.data
      };

    case 'SET_TEAM_PENDING_MEMBERS_PAGINATION':
      return {
        ...state,
        pendingMembersPagination: action.data
      };

    default:
      return state;
  }
};

export const getTeamMembersList = (
  page = 1,
  keyword?: string,
  exclude_workspace_id?: string | number
): AppThunkType => async (dispatch, getState) => {
  try {
    const teamId = getState().profile.currentEnvironment.team_id as number;

    const response = await teamAPI.getTeamMembers(teamId, page, keyword, exclude_workspace_id);
    dispatch(actionsTeam.setTeamMembersList(response.data));
    dispatch(actionsTeam.setMembersPagination(response.pagination));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const getTeamPendingMembersList = (
  page = 1
): AppThunkType => async (dispatch, getState) => {
  try {
    const teamId = getState().profile.currentEnvironment.team_id as number;

    const response = await teamAPI.getTeamPendingMembers(teamId, page);
    dispatch(actionsTeam.setTeamPendingMembersList(response.data));
    dispatch(actionsTeam.setPendingMembersPagination(response.pagination));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const inviteTeamMembers = (data: Array<InviteType>): AppThunkType => async (dispatch, getState) => {
  const { team_id } = getState().profile.currentEnvironment;
  const { pendingMembersPagination } = getState().team;
  try {
    if (team_id) {
      const response = await teamAPI.inviteTeamMembers(team_id, data);
      dispatch(showAlert(response.data.message, true));
      dispatch(getTeamPendingMembersList(pendingMembersPagination.current_page));

      return Promise.resolve(response.data);
    }

    return Promise.resolve(null);
  } catch (error: any) {
    errorHandler(error, dispatch, (errors) => {
      if (errors.validationErrors) {
        dispatch(showAlert(Object.values(errors.validationErrors), false));
      }
    });

    return Promise.reject(error);
  }
};

export const deleteTeamMember = (teamId: number | string, memberId: number | string): AppThunkType => async (dispatch) => {
  try {
    const response = await teamAPI.deleteTeamMember(teamId, memberId);
    dispatch(showAlert(response.data.message, true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const deleteTeamInvitation = (inviteToken: string): AppThunkType => async (dispatch) => {
  try {
    const response = await teamAPI.deleteTeamInvitation(inviteToken);
    dispatch(showAlert(response.data.message, true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const getCurrentTeam = (teamId: number | string | null): AppThunkType => async (dispatch) => {
  if (teamId) {
    try {
      const response = await teamAPI.getTeamById(teamId);
      dispatch(actionsTeam.setCurrentTeam(response.data));

      return Promise.resolve(response.data);
    } catch (error: any) {
      errorHandler(error, dispatch);

      return Promise.reject(error);
    }
  } else {
    dispatch(actionsTeam.setCurrentTeam(null));

    return Promise.resolve(null);
  }
};

export const acceptInvitation = (invitationToken: string, teamId?: number): AppThunkType => async (dispatch) => {
  try {
    const response = await teamAPI.acceptTeamInvitation(invitationToken);
    const environments = await environmentAPI.getEnvironmentList();

    if (teamId && environments.data) {
      const environment = environments.data.find((item: EnvironmentType) => item.team_id === teamId);
      dispatch(actionsProfile.setEnvironmentsList(environments.data));
      if (environment) {
        dispatch(actionsProfile.cleanUpCurrentEnvironment());
        dispatch(actionsProfile.setCurrentEnvironment(environment));
        await dispatch(getWorkspaces('team', teamId));
        await dispatch(getCurrentTeam(teamId));
      }
    }

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const joinByToken = (joinToken: string, teamId?: number): AppThunkType => async (dispatch) => {
  try {
    const response = await teamAPI.joinTeamByToken(joinToken);
    const environments = await environmentAPI.getEnvironmentList();

    if (teamId && environments.data) {
      const environment = environments.data.find((item: EnvironmentType) => item.team_id === teamId);
      dispatch(actionsProfile.setEnvironmentsList(environments.data));

      if (environment) {
        dispatch(actionsProfile.cleanUpCurrentEnvironment());
        dispatch(actionsWorkspace.cleanUp());
        dispatch(actionsProfile.setCurrentEnvironment(environment));
        dispatch(getWorkspaces('team', teamId));

        await dispatch(getCurrentTeam(teamId));
      }
    }

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const declineInvitation = (invitationToken: string): AppThunkType => async (dispatch) => {
  try {
    const response = await teamAPI.declineTeamInvitation(invitationToken);
    dispatch(getEnvironments());

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const getTeamByToken = (token: string, isJoinUrl?: boolean): AppThunkType => async (dispatch) => {
  try {
    if (isJoinUrl) {
      const response = await teamAPI.getTeamJoinInvitation(token);

      return Promise.resolve({ team: response.data });
    }

    const response = await teamAPI.getTeamInvitation(token);

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const updateTeam = (id: number, data: UpdateWorkspace): AppThunkType => async (dispatch, getState) => {
  const { environmentsList, currentEnvironment } = getState().profile;

  try {
    const responseName = await teamAPI.updateTeamName(id, data.name);
    let { image } = responseName.data;
    if (data.image) {
      const responseImage = await teamAPI.updateTeamImage(id, data.image);
      image = responseImage.data.image;
    }

    dispatch(actionsProfile.setEnvironmentsList(
      environmentsList.map((item) => (item.team_id === id ? { ...item, name: responseName.data.name, image } : item))
    ));

    if (currentEnvironment.team_id === id) {
      dispatch(actionsProfile.setCurrentEnvironment({
        ...currentEnvironment,
        name: responseName.data.name,
        image
      }));
    }

    dispatch(showAlert('Team successfully updated', true));

    return Promise.resolve();
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const deleteTeamImage = (id: number): AppThunkType => async (dispatch, getState) => {
  const { environmentsList, currentEnvironment } = getState().profile;

  try {
    const response = await teamAPI.deleteTeamImage(id);
    const newWorkspacesList = environmentsList.map((team) => (
      team.team_id === response.data.id
        ? { ...team, image: response.data.image } : team
    ));
    dispatch(actionsProfile.setEnvironmentsList(newWorkspacesList));
    dispatch(actionsProfile.setCurrentEnvironment({ ...currentEnvironment, image: response.data.image }));

    dispatch(showAlert('Team image successfully deleted', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};
