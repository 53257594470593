import { SessionType } from 'types/types';
import { requestInstance, GetItemsType, EditType } from './api';

export const sessionsAPI = {
  async getSessionsList() {
    const res = await requestInstance().get<GetItemsType<Array<SessionType>>>('/oauth/device?filter_by_revoke=not_revoked');

    return res.data;
  },
  async revoke(deviceId: string) {
    const res = await requestInstance().get<EditType>(`/oauth/device/${deviceId}/revoke`);

    return res.data;
  },
  async revokeAll() {
    const res = await requestInstance().get<EditType>('/oauth/device/revoke');

    return res.data;
  }
};
