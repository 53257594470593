import { Avatar, Button, Center, Text, useDisclosure } from '@chakra-ui/react';
import EnvironmentSwitcher from 'components/common/EnvironmentSwitcher/EnvironmentSwitcher';
import { useNavigate } from 'react-router-dom';
import { actionsProfile } from 'store/profile-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getCurrentTeam } from 'store/team-reducer';

type Props = {
  toggleNavbar: () => void;
}

const UserName = ({ toggleNavbar }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const avatar = useAppSelector((state) => state.profile.user?.avatar?.url);
  const userName = useAppSelector((state) => state.profile.user?.name);
  const { currentEnvironment, environmentsList } = useAppSelector((state) => state.profile);

  const { isOpen, onToggle, onClose } = useDisclosure();
  const navigate = useNavigate();

  const handleCloseSwitcher = () => {
    toggleNavbar();
    onClose();
  };

  const onSwitchEnvironment = (type: string, teamId: number | null) => {
    handleCloseSwitcher();
    navigate('home');

    dispatch(actionsProfile.setCurrentEnvironment(
      environmentsList.find((item) => item.team_id === teamId && item.type === type) ?? currentEnvironment
    ));
    dispatch(getCurrentTeam(teamId));
  };

  return (
    <EnvironmentSwitcher
      onClose={onClose}
      isOpen={isOpen}
      onSwitchEnvironment={onSwitchEnvironment}
      onCloseSwitcher={handleCloseSwitcher}
    >
      <Button
        onClick={onToggle}
        isDisabled={!(environmentsList.length > 1)}
        justifyContent="flex-start"
        alignItems="center"
        gap="8px"
        borderRadius="4px"
        border="1px solid"
        borderColor="gray.100"
        bg="white.50"
        p="8px 10px"
        maxH="40px"
        data-group
        _hover={{
          bg: 'white.50'
        }}
        _disabled={{
          cursor: 'unset'
        }}
        _active={{
          bg: 'white.50'
        }}
      >
        <Avatar
          boxSize="24px"
          src={currentEnvironment.type === 'personal' ? avatar : `${currentEnvironment.image || ''}`}
        />
        <Text
          fontWeight="600"
          fontSize="14px"
          noOfLines={1}
          wordBreak="break-all"
          textOverflow="ellipsis"
        >
          {currentEnvironment.type === 'personal' ? userName : currentEnvironment.name}
        </Text>

        {environmentsList.length > 1 && (
          <Center _groupHover={{ color: '#717171' }} color="#9D9EB5" ml="auto">
            <i className="fa-solid fa-repeat" />
          </Center>
        )}
      </Button>
    </EnvironmentSwitcher>

  );
};
export default UserName;
