import { Dispatch, SetStateAction } from 'react';
import { Center, HStack, Radio, RadioGroup, Text } from '@chakra-ui/react';

type SelectPlanPeriodProps = {
  period: string,
  setPeriod: Dispatch<SetStateAction<string>>
}

export const SelectPlanPeriod = ({ period, setPeriod }: SelectPlanPeriodProps): JSX.Element => (
  <RadioGroup onChange={setPeriod} value={period}>
    <HStack spacing={{ base: '16px', md: '32px' }}>
      <Radio value="month" variant="primary" colorScheme="green">
        <Text color={period === 'month' ? 'black' : 'light_gray'} ml="-2px" fontSize="14px" fontWeight={500}>
          Monthly
        </Text>
      </Radio>
      <Radio value="year" variant="primary" colorScheme="green">
        <HStack spacing="8px">
          <Text color={period === 'year' ? 'black' : 'light_gray'} ml="-2px" fontSize="14px" fontWeight={500}>
            Yearly
          </Text>
          <Center
            h="24px"
            borderRadius="24px"
            p="3px 8px"
            color="white.50"
            bg={period === 'year' ? '#555FBC' : 'gray.200'}
            fontSize="12px"
            fontWeight={500}
          >
            - 20%
          </Center>
        </HStack>
      </Radio>
    </HStack>
  </RadioGroup>
);
