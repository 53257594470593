import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';

interface FormTextareaProps {
  value: string;
  name: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>, key: string) => void;
  label?: string;
  placeholder?: string;
  error?: string;
  autoComplete?: string;
  height?: string;
  disabled?: boolean;
  hideLabelOnMobile?: boolean;
  resize?: 'vertical' | 'horizontal' | 'both' | 'none';
}

export default function FormTextarea({
  name,
  value,
  handleChange,
  label = '',
  error,
  placeholder,
  autoComplete,
  height = '48px',
  disabled,
  hideLabelOnMobile,
  resize = 'none'
}: FormTextareaProps) {
  return (
    <FormControl isInvalid={!!error} h="100%">
      {label && (
        <FormLabel
          display={{ base: hideLabelOnMobile ? 'none' : 'block', lg: 'block' }}
          fontSize="inherit"
          fontWeight="inherit"
          mb="8px"
        >
          {label}
        </FormLabel>
      )}
      <Textarea
        autoComplete={autoComplete}
        variant="primary"
        name={name}
        value={value}
        onChange={(e) => handleChange(e, name)}
        placeholder={placeholder}
        h={height}
        p="12px 16px"
        disabled={disabled}
        resize={resize}
        whiteSpace="nowrap"
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
}
