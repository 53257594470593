import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserTracing } from '@sentry/tracing';
import { ScrollToTop } from 'hoc/scrollToTop';
import { pusher } from 'services/websocket';
import { actionsPopup } from 'store/popup-reducer';
import App from './App';
import { register } from './serviceWorkerRegistration';
import store from './store/store';
import { theme } from './utils/theme';
import './index.css';

const setUpdateAppIsVisible = () => {
  store.dispatch(actionsPopup.setShowUpdateApp(true));
};

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  register(setUpdateAppIsVisible);
  if ('registerProtocolHandler' in navigator) {
    navigator.registerProtocolHandler('web+getlinkerpwa', 'https://app.getlinker.app/?q=%s');
  }
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: Number(process.env.REACT_APP_TRACES_SAMPLE_RATE)
});

window.pusher = pusher;

const el = document.getElementById('root');
if (el) {
  const root = ReactDOM.createRoot(el);

  root.render(
    <BrowserRouter>
      <React.StrictMode>
        <Provider store={store}>
          <ChakraProvider theme={theme}>
            <ScrollToTop />
            <App />
          </ChakraProvider>
        </Provider>
      </React.StrictMode>
    </BrowserRouter>
  );
}
