import { EditType, GetItemsType, requestInstance } from 'api/api';
import { WikiDocumentType, WikiFolderType } from 'types/wiki';

export const wikiTrashAPI = {
  async getTrashedDocumentList() {
    const res = await requestInstance().get<GetItemsType<Array<WikiDocumentType>>>('v1/wiki/trashed');

    return res.data;
  },

  async getTrashedFolderList() {
    const res = await requestInstance().get<GetItemsType<Array<WikiFolderType>>>('v1/wiki/folders/trashed');

    return res.data;
  },

  async restoreDocument(documentId: number) {
    const res = await requestInstance().patch<EditType>(`v1/wiki/${documentId}/restore`);

    return res.data;
  }
};
