import { useEffect, useState } from 'react';
import { Box, Divider, Flex, Link, Text, useDisclosure } from '@chakra-ui/react';
import { ButtonGroupPopup } from 'components/common/buttons/ButtonGroupPopup';
import Popup from 'components/common/popup/Popup';
import { bookmarkAPI } from 'api/bookmarksApi';
import { useAppDispatch } from 'store/store';
import { addBookmark } from 'store/bookmark-reducer';
import { BookmarkPreviewType } from 'types/types';
import { showAlert } from 'store/app-reducer';
import { useParams } from 'react-router-dom';
import { errorHandler } from 'utils/errorHandler';
import { standalone } from 'App';
import BookmarkPreview from 'components/BookmarksManager/Workspace/Bookmarks/BookmarkPreview';
import WorkspaceItemPicker from './WorkspaceItemPicker/WorkspaceItemPicker';

type AbandonedBookmarkProps = {
  handleCancel: () => void;
  handleClose: () => void;
  url: string;
};

const AbandonedBookmark = ({ handleCancel, handleClose, url }: AbandonedBookmarkProps) => {
  const dispatch = useAppDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [preview, setPreview] = useState<null | BookmarkPreviewType>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { folderId: folderIdParam } = useParams();

  const getBookmarkPreview = async (link: string) => {
    setPreview(null);
    try {
      const response = await bookmarkAPI.getPreview(link);
      setPreview(response.data);
    } catch (error: any) {
      errorHandler(error, dispatch);
    }
  };

  useEffect(() => {
    if (url) {
      getBookmarkPreview(url);
    }
  }, [url]);

  const handleSave = (workspaceId: string | number, folderId: number | null) => {
    setIsLoading(true);
    dispatch(
      addBookmark(
        url,
        standalone ? 'pwa_clipboard' : 'web_clipboard',
        folderId ?? undefined,
        +workspaceId,
        [],
        folderId === null || folderIdParam === folderId?.toString()
      )
    )
      .then(() => {
        handleClose();
      })
      .catch((errors: any) => {
        if (errors) {
          dispatch(showAlert(Object.values(errors), false));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Flex
        zIndex={1}
        position="fixed"
        w="401px"
        maxW="calc(100vw - 20px)"
        bottom="32px"
        left="50%"
        transform="translateX(-50%)"
        direction="column"
        bg="white"
        boxShadow="0px 0px 41px rgba(101, 101, 101, 0.35)"
        borderRadius="12px"
        p="24px"
      >
        <Text fontWeight="600" fontSize="14px" lineHeight="150%" mb="16px">
          Save copied link?
        </Text>
        <Link
          isExternal
          href={url}
          fontWeight="500"
          fontSize="12px"
          lineHeight="150%"
          noOfLines={1}
          wordBreak="break-all"
          color="light_blue.100"
        >
          <Box as="span" pr="8px" fontSize="11px">
            <i className="fa-solid fa-link" />
          </Box>
          {url}
        </Link>
        <Divider my="8px" borderColor="gray.100" />

        {preview && (
          <Box w="100%" pt="4px">
            <BookmarkPreview bookmark={preview} iconSize="68px" />
          </Box>
        )}

        <ButtonGroupPopup
          label="Save"
          cancelLabel="Dismiss"
          handleCancelClick={handleCancel}
          handleClick={onOpen}
          mt="10px"
        />
      </Flex>

      <Popup isOpen={isOpen} onClose={onClose} padding="0">
        <WorkspaceItemPicker
          hasBookmarks={false}
          loading={isLoading}
          onClose={onClose}
          handlePickFolder={handleSave}
          applyLabel="Save"
          emptyFoldersLabel="Please create a folder to save your bookmark"
        />
      </Popup>
    </>
  );
};

export default AbandonedBookmark;
