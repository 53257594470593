import { Input, InputGroup, InputRightElement, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import cn from 'classnames';
import { useQueryParams } from 'hooks/useQueryParams';
import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useParams, useSearchParams } from 'react-router-dom';
import { keyMap } from 'utils/shortcuts';
import style from './SearchForm.module.scss';

type PropsType = {
  handleOnBlur?: () => void;
  handleOnFocus?: () => void;
  inputRef?: any;
  getTags?: (keyword?: string, teamId?: string) => Promise<void>;
  onSubmit?: () => void;
};
const SearchForm = ({ handleOnBlur, handleOnFocus, inputRef, getTags, onSubmit }: PropsType): JSX.Element => {
  const typingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { workspaceId } = useParams();

  const [searchParams] = useSearchParams();
  const keyword = searchParams.get('keyword');
  const tags = searchParams.get('tags');

  const [searchValue, setSearchValue] = useState('');

  const { removeQueryParam, updateQueryValue } = useQueryParams();

  useHotkeys(keyMap.SET_ACTIVE_SEARCH, (e) => {
    inputRef.current?.focus();
    e.preventDefault();
  });

  const onChange = (value: string) => {
    setSearchValue(value);

    if (typingTimeout.current) clearTimeout(typingTimeout.current);

    typingTimeout.current = setTimeout(() => {
      updateQueryValue({ keyword: value });

      if (getTags) {
        const lastWordInQuery = value.split(' ').pop();
        getTags(lastWordInQuery);
      }
    }, 500);
  };

  const onCancelClick = () => {
    removeQueryParam('keyword');
  };

  useEffect(() => {
    setSearchValue(keyword ?? '');
  }, [keyword]);

  useEffect(
    () => () => {
      if (typingTimeout.current) clearTimeout(typingTimeout.current);
    },
    []
  );

  const removeAllTags = () => {
    removeQueryParam('tags');
  };

  const handleSubmit = (e: React.FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (onSubmit) onSubmit();
  };

  return (
    <InputGroup as="form" onSubmit={handleSubmit} w="100%" h="inherit">
      <Input
        className={style.search_input}
        pr={tags ? '115px' : '87px'}
        variant="search"
        onChange={(e) => onChange(e.target.value)}
        placeholder={workspaceId ? 'Search for the folders and bookmarks in the workspace' : 'Type something'}
        _placeholderShown={{
          textOverflow: 'ellipsis'
        }}
        value={searchValue}
        ref={inputRef}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />

      <InputRightElement zIndex="unset" width="fit-content" height="100%" gap="8px" mr="12px" color="gray.50">
        {!!searchValue && (
          <i className={cn('fa-solid fa-xmark', style.cancel)} onClick={onCancelClick} role="button" tabIndex={0} />
        )}
        |
        {!tags
          ? <i className={cn('fa-solid fa-magnifying-glass', style.search)} />
          : (
            <Tag
              className={style.tag}
              borderRadius="3px"
              variant="solid"
              bg="gray.500"
            >
              <TagLabel>
                #
                {tags.split(' ').length > 9 ? '9+' : tags.split(' ').length}
              </TagLabel>
              <TagCloseButton onClick={removeAllTags} fontSize="15px" ml="4px" opacity={1} />
            </Tag>
          )}
      </InputRightElement>
    </InputGroup>
  );
};

export default SearchForm;
