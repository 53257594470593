import { Avatar, Box, Flex, Text } from '@chakra-ui/react';
import cn from 'classnames';
import { CloseNavbarButton } from 'components/common/buttons/ToggleNavbarButtons';
import { useAppSelector } from 'store/store';
import { UsersToolsMenu } from './UsersToolsMenu';

import style from './UserBar.module.scss';
import UserPlanTag from './UserPlanTag';

type PropsType = {
  toggleNavbar: () => void;
  visibleNav: boolean
};

const UserBar = ({ toggleNavbar, visibleNav }: PropsType): JSX.Element => {
  const userName = useAppSelector((state) => state.profile.user?.name);
  const email = useAppSelector((state) => state.profile.user?.email);
  const avatar = useAppSelector((state) => state.profile.user?.avatar);
  const currentEnvironment = useAppSelector((state) => state.profile.currentEnvironment);

  return (
    <Flex h="64px" gap="16px" pb="16px" w="100%" borderBottom="1px solid" borderColor="gray.100">
      <Flex
        alignItems="center"
        position="relative"
        justifyContent="center"
        bg="light_green.500"
        borderRadius="50px"
        h="48px"
        minW="48px"
      >
        <Avatar
          border="2px solid"
          borderColor="white.50"
          h="44px"
          w="44px"
          bg="#dedede"
          src={currentEnvironment.type === 'personal' ? `${avatar?.url ?? ''}` : `${currentEnvironment.image || ''}`}
          icon={<i className={cn('fa-solid fa-user', style.avatar)} />}
        />
        <Box position="absolute" bottom="-2px" right="-5px">
          <UserPlanTag showDetails={visibleNav} />
        </Box>
      </Flex>

      <Flex flexDirection="column" flex="1" alignItems="flex-start" justifyContent="center">
        <Flex alignItems="center" w="100%" justifyContent={{ base: 'flex-start', lg: 'space-between' }}>
          <Text
            alignContent="center"
            fontStyle="normal"
            fontWeight="700"
            color="black"
            noOfLines={1}
            maxW={{
              base: '99px',
              lg: '139px'
            }}
          >
            {currentEnvironment.type === 'personal' ? userName : currentEnvironment.name}
          </Text>

          <UsersToolsMenu toggleNavbar={toggleNavbar} />
        </Flex>

        <Text
          alignContent="center"
          fontStyle="normal"
          fontSize="12px"
          fontWeight="400"
          color="light_gray"
          noOfLines={1}
          maxW="139px"
        >
          {currentEnvironment.type === 'personal' ? email : `${currentEnvironment.is_own ? 'Owner' : 'Member'}`}
        </Text>
      </Flex>

      <CloseNavbarButton toggleNavbar={toggleNavbar} />
    </Flex>
  );
};

export default UserBar;
