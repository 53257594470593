import { ReactNode, useEffect, useRef, useState } from 'react';
import fblogo from 'assets/image/icons/fb.svg';
import { oauthLogin } from 'store/auth-reducer';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import SocialAuthButton from './SocialAuthButton';

type FacebookAuthButtonProps = {
  children: ReactNode;
};

const FacebookAuthButton = ({ children }: FacebookAuthButtonProps) => {
  const [loading, setLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const planSlug = searchParams.get('plan');
  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);

  const statusChangeCallback = (response: any) => {
    if (response.status === 'connected') {
      setLoading(true);
      dispatch(
        oauthLogin(
          {
            grant_type: 'facebook_access_token',
            facebook_access_token: response.authResponse?.accessToken
          },
          planSlug
        )
      ).finally(() => {
        setLoading(false);
      });
    } else {
      window.FB.login(
        (resp: any) => {
          setLoading(true);
          dispatch(
            oauthLogin(
              {
                grant_type: 'facebook_access_token',
                facebook_access_token: resp.authResponse?.accessToken
              },
              planSlug
            )
          ).finally(() => {
            setLoading(false);
          });
        },
        { scope: 'public_profile,email' }
      );
    }
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus((response: any) => {
      statusChangeCallback(response);
    });
  };

  const initFacebookClient = () => {
    const { FB } = window;
    if (FB) {
      setIsReady(true);

      FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v15.0'
      });
    }
  };

  useEffect(() => {
    if (!window.FB) {
      intervalId.current = setInterval(() => {
        if (window.FB && !isReady) {
          initFacebookClient();
          if (intervalId.current) clearInterval(intervalId.current);
        }
      }, 500);
    } else {
      initFacebookClient();
    }

    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
    };
  }, []);

  return (
    <SocialAuthButton disabled={!isReady} onClick={checkLoginState} loading={loading} icon={fblogo}>
      {children}
    </SocialAuthButton>
  );
};

export default FacebookAuthButton;
