import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image, Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger, useDisclosure
} from '@chakra-ui/react';
import diamond from 'assets/image/icons/diamond.svg';
import flag from 'assets/image/icons/flag.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { logOut } from 'store/auth-reducer';
import { actionsPopup } from 'store/popup-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getWorkspaces } from 'store/workspace-reducer';
import { WorkspaceType } from 'types/types';
import { actionsProfile } from 'store/profile-reducer';
import { getCurrentTeam } from 'store/team-reducer';
import { EnvironmentItem } from '../../../common/EnvironmentSwitcher/EnvironmentItem';
import SwitcherButton from '../../../common/EnvironmentSwitcher/SwitcherButton';
import EnvironmentSwitcher from '../../../common/EnvironmentSwitcher/EnvironmentSwitcher';

type PropsType = {
  toggleNavbar: () => void;
}

export const UsersToolsMenu = ({ toggleNavbar }: PropsType): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentEnvironment = useAppSelector((state) => state.profile.currentEnvironment);
  const { environmentsList } = useAppSelector((state) => state.profile);

  const { isOpen: isOpenUsersToolsMenu, onClose: onCloseUsersToolsMenu, onOpen: onOpenUsersToolsMenu } = useDisclosure();

  const openUpgradePlan = () => {
    dispatch(actionsPopup.setShowUpgradePopup(true));
  };

  const navigate = useNavigate();
  const location = useLocation();

  const logout = () => {
    dispatch(logOut());
  };

  const onSettingsClick = () => {
    onCloseUsersToolsMenu();
    if (!location.pathname.includes('/settings')) navigate('/settings/account');
    toggleNavbar();
  };

  const openSupportPage = () => {
    onCloseUsersToolsMenu();
    toggleNavbar();
    window.open('https://getlinker.tawk.help/', '_blank');
  };

  const handleCloseSwitcher = () => {
    onCloseUsersToolsMenu();
    toggleNavbar();
  };

  const onSwitchEnvironment = (type: string, teamId: number | null) => {
    dispatch(getWorkspaces(type, teamId)).then((workspaces: WorkspaceType[]) => {
      dispatch(
        actionsProfile.setCurrentEnvironment(
          environmentsList.find((item) => item.team_id === teamId && item.type === type) ?? currentEnvironment
        )
      );
      dispatch(getCurrentTeam(teamId))
        .then(() => {
          navigate(`/workspace/${workspaces[0].id}`);
        });
    });
  };

  return (
    <Popover
      variant="switcher"
      isLazy
      lazyBehavior="keepMounted"
      placement="bottom"
      offset={[-125, 8]}
      closeOnBlur
      isOpen={isOpenUsersToolsMenu}
      onClose={onCloseUsersToolsMenu}
    >
      <PopoverTrigger>
        <Button
          onClick={onOpenUsersToolsMenu}
          p="0"
          ml="8px"
          fontSize="16px"
          colorScheme="white"
          borderRadius="4px"
          maxW="24px"
          minW="24px"
          h="24px"
          color="dark_gray.200"
          _hover={{
            transition: '150ms',
            background: '#EAEAEA',
            color: 'dark_gray.300'
          }}
          _active={{
            transition: '150ms',
            background: '#EAEAEA',
            color: 'dark_gray.300'
          }}
        >
          <i className="fa-solid fa-angle-down" />
        </Button>
      </PopoverTrigger>

      <PopoverContent
        _focusVisible={{
          outline: 'none'
        }}
        border="transparent"
        bg="white.100"
        boxShadow="0px 0px 16px rgba(101, 101, 101, 0.25)"
        borderRadius="8px"
        w={{ base: 'calc(100vw - 20px)', xs: '284px' }}
        maxW={{ base: 'calc(100vw - 20px)', xs: '284px' }}
        maxH="80vh"
      >
        <PopoverHeader p="16px 16px 8px 16px" borderColor="gray.100">
          <EnvironmentItem isActive environment={currentEnvironment} />
        </PopoverHeader>

        <PopoverBody p="0" overflowY="auto">
          {environmentsList.length > 1 && (
            <EnvironmentSwitcher onSwitchEnvironment={onSwitchEnvironment} onCloseSwitcher={handleCloseSwitcher}>
              <Box w="100%" p="8px 16px">
                <SwitcherButton hasArrow label="Switch to" iconClassName="fa-solid fa-repeat" />
              </Box>
            </EnvironmentSwitcher>
          )}

          <Flex
            flexDir="column"
            w="100%"
            p="8px 16px"
            borderTop="1px solid"
            borderColor={environmentsList.length > 1 ? 'gray.100' : 'transparent'}
          >
            <SwitcherButton label="Settings" iconClassName="fa-solid fa-gear" handleClick={onSettingsClick} />
            <SwitcherButton label="Support" iconClassName="fa-solid fa-headset" handleClick={openSupportPage} />
            <SwitcherButton label="Log Out" iconClassName="fa-solid fa-right-from-bracket" handleClick={logout} />
          </Flex>
        </PopoverBody>

        <PopoverFooter p="0">
          <HStack
            borderRadius="0 0 8px 8px"
            p="16px"
            spacing="8px"
            h="65px"
            bg="linear-gradient(90deg, #A3BDED 0%, #6991C7 100%)"
            color="white.100"
          >
            <Center boxSize="32px" fontSize="16px" borderRadius="50px" bg="white.100">
              <Image src={flag} boxSize="20px" />
            </Center>

            <Center boxSize="20px" fontSize="14px">
              <i className="fa-solid fa-repeat" />
            </Center>

            <Center boxSize="32px" fontSize="16px" borderRadius="50px" bg="white.100">
              <Image src={diamond} boxSize="20px" />
            </Center>

            <Center w="101px" ml="auto!important">
              <Button
                onClick={openUpgradePlan}
                w="85px"
                h="24px"
                colorScheme="transparent"
                borderRadius="0"
                borderBottom="1px solid"
                borderColor="transparent"
                _hover={{
                  bg: 'transparent',
                  borderBottom: '1px solid',
                  borderColor: 'white.50'
                }}
              >
                Upgrade
                <Center boxSize="20px" ml="8px" fontSize="16px">
                  <i className="fa-solid fa-arrow-right-long" />
                </Center>
              </Button>
            </Center>
          </HStack>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
