import { EditType, requestInstance, ResponseType } from './api';

export const pocketAPI = {
  async getUrl(workspaceId: string) {
    const res = await requestInstance().post<ResponseType<{ redirect_url: string }>>('/v1/pocket/get-url', { workspace_id: workspaceId });

    return res.data;
  },
  async storeAccessToken(requesToken: string) {
    const res = await requestInstance()
      .post<EditType>('/v1/pocket/store-token', { request_token: requesToken });

    return res.data;
  },
  async syncWithPocket(workspaceId: number | string, folderId?: number | null) {
    const res = await requestInstance().post<EditType>('/v1/pocket/sync', {
      workspace_id: workspaceId,
      folder_id: folderId
    });

    return res.data;
  },
  async deleteAccessToken() {
    const res = await requestInstance().delete<EditType>('/v1/pocket');

    return res.data;
  }
};
