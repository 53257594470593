import { Box, ResponsiveValue } from '@chakra-ui/react';
import { Union } from 'types/types';

type Props = {
  display: ResponsiveValue<Union<'none' | 'block'>> | string;
  handleClick: () => void;
}

const Overlay = ({ display, handleClick }: Props): JSX.Element => (
  <Box
    onClick={() => handleClick()}
    zIndex="8"
    display={display}
    position="fixed"
    top="0"
    left="0"
    w="100vw"
    h="100vh"
    bg="rgba(58, 58, 58, 0.6);"
    backdropFilter="blur(1px)"
  />
);

export default Overlay;
