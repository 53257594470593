import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = ({ element }: { element?: HTMLDivElement | null }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        if (element) {
            element.scrollTo(0, 0);
        } else {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    return null;
};
