import { Center, Flex, Text } from '@chakra-ui/react';

type PlanLimitItemProps = {
  current: string | number;
  limit?: number | null;
  itemName: string;
};

const PlanLimitItem = ({ current, limit, itemName }: PlanLimitItemProps) => (
  <Flex fontWeight="300" flexDirection="column">
    <Flex alignItems="flex-end">
      <Text
        fontSize={{ base: '24px', md: '34px' }}
        color={limit && current >= limit ? 'red.100' : 'light_green.300'}
        lineHeight={{ base: '42px', md: '51px' }}
      >
        {current}
      </Text>

      <Text fontSize={{ base: '12px', md: '16px' }} lineHeight="37px">
        /
      </Text>

      {limit ? (
        <Text fontSize={{ base: '12px', md: '16px' }} lineHeight="37px">
          {limit}
        </Text>
      )
        : (
          <Center mb="10px" fontSize={{ base: '10px', md: '12px' }}>
            <i className="fa-solid fa-infinity" />
          </Center>
        )}
    </Flex>

    <Text color="light_gray" fontSize="12px">
      {itemName}
    </Text>
  </Flex>
);

export default PlanLimitItem;
