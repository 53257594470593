import { ChangeEvent } from 'react';
import { Box, Input } from '@chakra-ui/react';
import SearchForm from 'components/BookmarksManager/Workspace/ToolsBar/SearchForm/SearchForm';

type PropsType = {
  handleOnBlur: () => void;
  handleOnFocus: () => void;
  inputRef?: any;
  onlyTagsSearch?: boolean;
  handleTagsSubmit: (e: any) => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  getTags: (keyword?: string, teamId?: string) => Promise<void>;
  searchValue: string;
  onSubmit: () => void;
};
export const TagsInput = ({
  inputRef,
  onSubmit,
  handleOnFocus,
  handleOnBlur,
  onlyTagsSearch,
  handleTagsSubmit,
  handleChange,
  searchValue,
  getTags
}: PropsType): JSX.Element => {
  if (!onlyTagsSearch) {
    return (
      <Box w="100%" h="40px">
        <SearchForm
          getTags={getTags}
          inputRef={inputRef}
          handleOnBlur={handleOnBlur}
          handleOnFocus={handleOnFocus}
          onSubmit={onSubmit}
        />
      </Box>
    );
  }

  return (
    <Input
      ref={inputRef}
      onBlur={handleOnBlur}
      onKeyDown={handleTagsSubmit}
      variant="formInput"
      name="tag"
      placeholder="Add tags"
      onChange={handleChange}
      value={searchValue}
      autoComplete="off"
      onFocus={handleOnFocus}
    />
  );
};
