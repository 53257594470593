import { WrapItem } from '@chakra-ui/react';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  gap?: string;
};

const CommonItemWrapper = ({ children, gap = '8px' }: Props) => (
  <WrapItem
    bg="white.500"
    borderRadius="4px"
    p="4px 8px"
    border="1px solid"
    borderColor="gray.50"
    gap={gap}
    alignItems="center"
    color="light_gray"
    fontWeight="500"
    fontSize="16px"
    lineHeight="19px"
  >
    {children}
  </WrapItem>
);

export default CommonItemWrapper;
