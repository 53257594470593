import { Flex, Progress, Text } from '@chakra-ui/react';

type PlanLimitWrapperProps = {
  children: JSX.Element;
  count: number;
  progress: number;
  status?: 'danger' | 'success';
};

const PlanLimitWrapper = ({ children, count, progress, status = 'success' }: PlanLimitWrapperProps) => (
  <Flex alignItems="center" w="100%" p="8px" gap="8px" borderRadius="4px" border="1px solid" borderColor="gray.100">
    {children}
    <Progress borderRadius="40px" value={progress} h="6px" flex="1" variant={status} />
    <Text
      maxW="47px"
      textAlign="right"
      fontWeight="500"
      fontSize="14px"
      lineHeight="150%"
      color={status === 'danger' ? 'red.100' : 'light_green.300'}
    >
      {count > 9999 ? '9999+' : count}
    </Text>
  </Flex>
);

export default PlanLimitWrapper;
