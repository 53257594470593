import { Box, Center, Flex, Image, Text, VStack } from '@chakra-ui/react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import trash from 'assets/image/wiki/trash.svg';
import footer_bg from 'assets/image/wiki/footer_bg.png';
import footer_image from 'assets/image/wiki/footer_image.png';
import wikiHome from 'assets/image/wiki/wiki_general.svg';
import style from './WikiNavbar.module.scss';
import BackToDashboard from './BackToDashboard';
import UserName from './UserName';
import FolderList from './FolderList/FolderList';

type Props = {
  isOpenNavbar: boolean;
  toggleNavbar: () => void;
}

const WikiNavbar = ({ isOpenNavbar, toggleNavbar }: Props): JSX.Element => {
  const selected = ({ isActive }: any) => cn(style.link_container, { [style.active]: isActive });

  return (
    <VStack
      spacing="0"
      position={{
        base: 'fixed',
        lg: 'relative'
      }}
      zIndex={{
        base: '9',
        lg: 'unset'
      }}
      h="100%"
      w={{
        base: '100%',
        xs: '288px',
        lg: '240px'
      }}
      maxW={{
        base: '100%',
        xs: '288px',
        lg: '240px'
      }}
      borderRight="1px solid"
      borderColor="gray.100"
      transition="0.3s"
      bg="#FAFBFB"
      transform={{
        base: isOpenNavbar ? 'translate(0,0)' : 'translate(-400px, 0)',
        lg: 'none'
      }}
    >
      <Flex
        direction="column"
        w="100%"
        h="calc(100vh - 88px)"
        maxH="calc(100vh - 88px)"
        overflow="hidden"
      >
        <BackToDashboard toggleNavbar={toggleNavbar} />

        <Flex h="100%" flexDir="column" p="16px 8px" gap="16px">
          <UserName toggleNavbar={toggleNavbar} />

          <NavLink onClick={toggleNavbar} className={selected} to="home">
            <Image boxSize="16px" src={wikiHome} />
            <Text noOfLines={1} maxW="172px">
              Home
            </Text>
          </NavLink>

          <FolderList toggleNavbar={toggleNavbar} />

          <Box mt="auto">
            <NavLink onClick={toggleNavbar} className={selected} to="trash">
              <Image boxSize="16px" src={trash} />
              <Text noOfLines={1} maxW="172px">
                Trash
              </Text>
            </NavLink>
          </Box>
        </Flex>
      </Flex>

      <Center
        h="88px"
        maxH="88px"
        w="100%"
        bgImage={footer_bg}
        bgColor="white.50"
        bgSize="cover"
        borderTop="1px solid"
        borderColor="gray.100"
      >
        <Image h="44px" w="199px" src={footer_image} />
      </Center>
    </VStack>
  );
};
export default WikiNavbar;
