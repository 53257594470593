import { Flex } from '@chakra-ui/react';
import { TagsInputWrapper } from 'components/common/Hashtags/TagsInputWrapper';
import { useQueryParams } from 'hooks/useQueryParams';
import { useSearchParams } from 'react-router-dom';

type SearchFormWrapperProps = {
  hideTags?: boolean;
  whoseTags?: 'bookmark' | 'snippet';
}

export const SearchFormWrapper = ({ hideTags, whoseTags = 'bookmark' }: SearchFormWrapperProps) => {
  const [searchParams] = useSearchParams();
  const tags = searchParams.get('tags');
  const keyword = searchParams.get('keyword');

  const { updateQueryValue, removeQueryParam } = useQueryParams();

  const setSelectedTags = (selectedTags: string[] | null) => {
    if ((selectedTags && tags && selectedTags.length < tags.split(' ').length)) {
      if (selectedTags?.length) {
        updateQueryValue({ tags: selectedTags?.join(' ') });
      } else {
        removeQueryParam('tags');
      }
    } else {
      const lastIndex = keyword?.lastIndexOf(' ');
      const newKeywords = keyword?.substring(0, lastIndex);
      const newQueries = { keyword: newKeywords, tags: selectedTags?.join(' ') };
      updateQueryValue(newQueries);
    }
  };

  return (
    <Flex w="100%" maxW="475px">
      <TagsInputWrapper
        filterByUser="yes"
        whoseTags={whoseTags}
        hideTags={hideTags}
        selectedTags={tags?.split(' ') || []}
        setSelectedTags={setSelectedTags}
      />
    </Flex>
  );
};

