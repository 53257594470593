import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
import { getEnvironments } from 'store/profile-reducer';
import { AlertsType, InferActionsTypes } from '../types/types';
import { getOS, VersionOSType } from '../utils/getOS';
import { AppThunkType } from './store';
import { getWorkspaces } from './workspace-reducer';

const initialState = {
  initialization: false,
  alerts: [] as Array<AlertsType>,
  versionOS: VersionOSType.unknown,
  isVisibleNavbar: true,
  extensionIsInstalled: false
};

export const actionsApp = {
  setInitialization: () => ({ type: 'INITIALIZATION_SUCCESS' }) as const,
  setAlert: (alert: string, success: boolean, idAlert: string) => ({
    type: 'SET_ALERT', alert, success, idAlert
  }) as const,
  removeAlert: (alerts: Array<AlertsType>) => ({ type: 'REMOVE_ALERT', alerts }) as const,
  setVersionOS: (versionOS: VersionOSType) => ({ type: 'SET_VERSION_OS', versionOS }) as const,
  setIsVisibleNavbar: (isVisibleNavbar: boolean) => ({ type: 'SET_IS_VISIBLE_NAVBAR', isVisibleNavbar }) as const,
  setExtensionIsInstalled: (isInstalled: boolean) => ({ type: 'SET_EXTENSION_IS_INSTALLED', isInstalled }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsApp>

export const appReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'INITIALIZATION_SUCCESS':
      return {
        ...state,
        initialization: true
      };
    case 'SET_ALERT':
      return {
        ...state,
        alerts: [{
          message: action.alert,
          id: action.idAlert,
          success: action.success
        },
        ...state.alerts
        ]
      };
    case 'REMOVE_ALERT':
      return {
        ...state,
        alerts: action.alerts
      };
    case 'SET_VERSION_OS':
      return {
        ...state,
        versionOS: action.versionOS
      };
    case 'SET_IS_VISIBLE_NAVBAR':
      return {
        ...state,
        isVisibleNavbar: action.isVisibleNavbar
      };
    case 'SET_EXTENSION_IS_INSTALLED':
      return {
        ...state,
        extensionIsInstalled: action.isInstalled
      };
    default:
      return state;
  }
};

export const InitializationApp = (): AppThunkType => async (dispatch, getState) => {
  const cookie = new Cookies();
  const env = cookie.get('env');

  const versionOS = getOS();
  const { currentEnvironment } = getState().profile;

  const promise_1 = dispatch(actionsApp.setVersionOS(versionOS));
  const promise_2 = dispatch(getWorkspaces(env?.env_type ?? currentEnvironment.type, env?.env_team ?? currentEnvironment.team_id));
  const promise_3 = dispatch(getEnvironments(env));

  return Promise.all([promise_1, promise_2, promise_3]).then(() => {
    dispatch(actionsApp.setInitialization());

    return true;
  });
};

export const showAlert = (alerts: Array<string> | string, successType: boolean): AppThunkType => async (dispatch, getState) => {
  if (Array.isArray(alerts)) {
    alerts.forEach((item) => {
      if (item !== '') {
        const idAlert = uuidv4();

        dispatch(actionsApp.setAlert(item, successType, idAlert));

        const idTimeOut = setTimeout(() => {
          const alertsInState = getState().app.alerts.filter(
            (alert) => alert.id !== idAlert
          );

          dispatch(actionsApp.removeAlert(alertsInState));
          clearTimeout(idTimeOut);
        }, 3000);
      }
    });
  }
  if (typeof alerts === 'string') {
    if (alerts !== '') {
      const idAlert = uuidv4();

      dispatch(actionsApp.setAlert(alerts, successType, idAlert));

      const idTimeOut = setTimeout(() => {
        const alertsInState = getState().app.alerts.filter(
          (alert) => alert.id !== idAlert
        );

        dispatch(actionsApp.removeAlert(alertsInState));
        clearTimeout(idTimeOut);
      }, 3000);
    }
  }
};
