import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';

interface FormInputProps {
  value: string;
  name: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  label?: string;
  type?: string;
  placeholder?: string;
  error?: string;
  autoComplete?: string;
  height?: string;
  disabled?: boolean;
  hideLabelOnMobile?: boolean;
}

const FormInput = ({
  name,
  value,
  handleChange,
  label = '',
  error,
  placeholder,
  autoComplete,
  type = 'text',
  height = '48px',
  disabled,
  hideLabelOnMobile
}: FormInputProps) => (
  <FormControl isInvalid={!!error}>
    {label && (
      <FormLabel
        display={{ base: hideLabelOnMobile ? 'none' : 'block', lg: 'block' }}
        fontSize="inherit"
        fontWeight="inherit"
        mb="8px"
      >
        {label}
      </FormLabel>
    )}
    <Input
      autoComplete={autoComplete}
      variant="formInput"
      name={name}
      value={value}
      onChange={(e) => handleChange(e, name)}
      type={type}
      placeholder={placeholder}
      h={height}
      p="12px 16px"
      disabled={disabled}
    />
    <FormErrorMessage>{error}</FormErrorMessage>
  </FormControl>
);

export default FormInput;
