import { Button, Center, Text } from '@chakra-ui/react';

type PropsType = {
  label: string;
  iconClassName: string;
  handleClick?: () => void;
  hasArrow?: boolean;
}

const SwitcherButton = ({ label, iconClassName, handleClick, hasArrow }: PropsType): JSX.Element => (
  <Button h="52px" onClick={handleClick} variant="navbar" data-group>
    <Center
      transition="150ms"
      color="blue"
      _groupActive={{
        bg: 'blue',
        color: 'white.100'
      }}
      _groupHover={{
        bg: 'blue',
        color: 'white.100'
      }}
      boxSize="32px"
      fontSize="16px"
      borderRadius="50px"
      bg="#F3F8FE"
    >
      <i className={iconClassName} />
    </Center>

    <Text>{label}</Text>

    {hasArrow && (
      <Center boxSize="16px" ml="auto" color="dark_gray.200" fontSize="12px">
        <i className="fa-solid fa-angle-right" />
      </Center>
    )}
  </Button>
);

export default SwitcherButton;
