import { Popover, PopoverTrigger } from '@chakra-ui/react';
import EnvironmentList from './EnvironmentList';

type Props = {
  children: JSX.Element;
  onCloseSwitcher: () => void;
  onClose?: () => void;
  onSwitchEnvironment: (type: string, teamId: number | null) => void;
  isOpen?: boolean;
}

const EnvironmentSwitcher = ({ onCloseSwitcher, onSwitchEnvironment, isOpen, onClose, children }: Props): JSX.Element => (
  <Popover
    isOpen={isOpen}
    variant="mobile"
    isLazy
    lazyBehavior="keepMounted"
    placement="right"
    onClose={onClose}
    closeOnBlur
  >
    <PopoverTrigger>
      {children}
    </PopoverTrigger>

    <EnvironmentList onSwitchEnvironment={onSwitchEnvironment} onCloseSwitcher={onCloseSwitcher} />
  </Popover>
);
export default EnvironmentSwitcher;
