
import { sessionsAPI } from 'api/sessionsApi';
import { InferActionsTypes, SessionType } from 'types/types';
import { errorHandler } from 'utils/errorHandler';

import { showAlert } from './app-reducer';
import { AppThunkType } from './store';

const initialState = {
  data: [] as Array<SessionType>
};

export const actionsSessions = {
  setSessionsList: (data: Array<SessionType>) => ({ type: 'SET_SESSIONS_LIST', data }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsSessions>

export const sessionsReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_SESSIONS_LIST':
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
};

export const getSessionsList = (): AppThunkType => async (dispatch) => {
  try {
    const response = await sessionsAPI.getSessionsList();

    dispatch(actionsSessions.setSessionsList(response.data));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const revokeSession = (deviceId: string): AppThunkType => async (dispatch, getState) => {
  const sessions = getState().sessions.data;
  try {
    const response = await sessionsAPI.revoke(deviceId);
    dispatch(actionsSessions.setSessionsList(sessions.filter((item) => item.id !== deviceId)));
    dispatch(showAlert(response.data.message, true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const revokeAllSessions = (): AppThunkType => async (dispatch) => {
  try {
    const response = await sessionsAPI.revokeAll();

    dispatch(showAlert(response.data.message, true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};
