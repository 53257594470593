import { rewriteData } from 'utils/rewriteData';
import { trackerAPI } from 'api/trackerApi';
import { InferActionsTypes, TrackerOptions, TrackerType, PaginationType } from 'types/types';
import { errorHandler } from 'utils/errorHandler';

import { showAlert } from './app-reducer';
import { AppThunkType } from './store';

const initialState = {
  data: [] as Array<TrackerType>,
  pagination: {
    count: 10,
    current_page: 1,
    per_page: 10,
    total: 10,
    total_pages: 1
  }
};

export const actionsTracker = {
  setTrackerList: (data: Array<TrackerType>) => ({ type: 'SET_TRACKER_LIST', data }) as const,
  setTrackersPagination: (data: PaginationType) => ({ type: 'SET_TRACKER_PAGINATION', data }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsTracker>

export const trackerReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_TRACKER_LIST':
      return {
        ...state,
        data: action.data
      };
    case 'SET_TRACKER_PAGINATION':
      return {
        ...state,
        pagination: action.data
      };
    default:
      return state;
  }
};

export const getTrackersList = (page = 1): AppThunkType => async (dispatch) => {
  try {
    const response = await trackerAPI.getTrackerList(page);

    dispatch(actionsTracker.setTrackerList(response.data));
    dispatch(actionsTracker.setTrackersPagination(response.pagination));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const addTracker = (bookmarkId: number | string, options: TrackerOptions): AppThunkType => async (dispatch, getState) => {
  try {
    const { current_page } = getState().reminders.pagination;
    const response = await trackerAPI.createTracker(bookmarkId, options);
    dispatch(getTrackersList(current_page));
    dispatch(showAlert('Tracker successfully saved', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch, (res) => {
      if (res.validationErrors) {
        dispatch(showAlert(Object.values(res.validationErrors), false));
      }
    });

    return Promise.reject(error);
  }
};

export const updateTracker = (trackerId: number | string, options: TrackerOptions): AppThunkType => async (dispatch, getState) => {
  const trackerList = getState().tracker.data;
  try {
    const response = await trackerAPI.updateTracker(trackerId, options);

    dispatch(actionsTracker.setTrackerList(rewriteData(trackerList, trackerId, 'percentage', response.data.percentage)));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch, (res) => {
      if (res.validationErrors) {
        dispatch(showAlert(Object.values(res.validationErrors), false));
      }
    });

    return Promise.reject(error);
  }
};

export const deleteTracker = (reminderId: number | string): AppThunkType => async (dispatch) => {
  try {
    const response = await trackerAPI.deleteTracker(reminderId);
    dispatch(showAlert(response.data.message, true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};
