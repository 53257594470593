import { Button, Center, Flex, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import folderIcon from 'assets/image/wiki/folder.svg';
import { CreateFolder } from 'components/modals/Popups/wiki/CreateFolder';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { getFolderList } from 'store/wiki/folder-reducer';
import ItemList from './ItemList';

type Props = {
  toggleNavbar: () => void;
}

const FolderList = ({ toggleNavbar }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { data: folders, isFetching } = useAppSelector((state) => state.wikiFolders);
  const teamId = useAppSelector((state) => state.profile.currentEnvironment.team_id);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(getFolderList(teamId));
  }, [teamId]);

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" px="12px" color="light_gray">
        <Text
          fontSize="13px"
          lineHeight="16px"
          noOfLines={1}
          wordBreak="break-all"
          textOverflow="ellipsis"
        >
          Folders
        </Text>
        <Button onClick={onOpen} color="#9D9EB5" variant="wikiIconButton" size="icon">
          <i className="fa-solid fa-plus" />
        </Button>
      </Flex>

      <Flex
        flexDirection="column"
        color="gray.600"
        gap="4px"
        my="-10px"
        pb="8px"
        flex="1 1 100px"
        overflow="auto"
        width="100%"
        minH={window.innerHeight < 700 ? '70px' : '140px'}
      >
        {
          isFetching ? (
            <Center alignItems="flex-start" w="100%" h="100%" p="18px">
              <Spinner boxSize="18px" emptyColor="green.50" color="green.500" />
            </Center>
          ) : (folders.map((folder) => (
            <ItemList
              key={folder.id}
              link={String(folder.id)}
              icon={folderIcon}
              item={{
                id: folder.id,
                title: folder.name,
                wikis: folder.wikis
              }}
              toggleNavbar={toggleNavbar}
            />
          )))
        }
      </Flex>

      <CreateFolder teamId={teamId} isOpen={isOpen} onClose={onClose} />
    </>
  );
};
export default FolderList;
