import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MenuDivider, Square, useDisclosure } from '@chakra-ui/react';
import { useHotkeys } from 'react-hotkeys-hook';
import { ContextMenuButton } from 'components/common/buttons/ContextMenuButton';
import Popup from 'components/common/popup/Popup';
import { AddFolder } from 'components/modals/Popups/AddFolder';
import { AddLink } from 'components/modals/Popups/AddLink';
import { useAppSelector } from 'store/store';
import { keyMap } from 'utils/shortcuts';
import { AddButton } from './AddButton';

export const WorkspaceAddButton = ({ isShare }: { isShare?: boolean }) => {
  const { isOpen: isAddLinkOpen, onOpen: onAddLinkOpen, onClose: onAddLinkClose } = useDisclosure();
  const { isOpen: isAddFolderOpen, onOpen: onAddFolderOpen, onClose: onAddFolderClose } = useDisclosure();
  const { mainSharedFolder } = useAppSelector((state) => state.share);

  const { folderSlug, folderId } = useParams();

  useHotkeys(keyMap.ADD_LINK, (e) => {
    onAddLinkOpen();
    e.preventDefault();
  });

  useHotkeys(keyMap.ADD_FOLDER, (e) => {
    onAddFolderOpen();
    e.preventDefault();
  });

  const hideAddButton = useMemo(
    () => isShare && (!folderSlug
      || !mainSharedFolder
      || (mainSharedFolder?.slug !== folderSlug)
      || (mainSharedFolder?.access_role_code !== 'manager' && mainSharedFolder?.access_role_code !== 'editor')),
    [isShare, mainSharedFolder, folderSlug]
  );

  if (hideAddButton) {
    return null;
  }

  return (
    <>
      <AddButton>
        <ContextMenuButton handleClick={onAddFolderOpen}>
          <Square borderRadius="4px" size="20px" bg="#fbece1" color="#ECB86A" fontSize="14px">
            <i className="fa-solid fa-folder-plus" />
          </Square>
          {folderId ? 'Create Sub-folder' : 'Create Folder'}
        </ContextMenuButton>

        <MenuDivider borderColor="gray.100" opacity="1" />

        <ContextMenuButton handleClick={onAddLinkOpen}>
          <Square borderRadius="4px" size="20px" bg="ghost_blue" color="blue" fontSize="14px">
            <i className="fa-solid fa-bookmark" />
          </Square>
          Add Bookmark
        </ContextMenuButton>
      </AddButton>
      <Popup isOpen={isAddLinkOpen} onClose={onAddLinkClose} overflow="unset">
        <AddLink
          workspaceId={mainSharedFolder?.workspace_id}
          onClose={onAddLinkClose}
        />
      </Popup>

      <Popup isOpen={isAddFolderOpen} onClose={onAddFolderClose}>
        <AddFolder workspaceId={mainSharedFolder?.workspace_id} onClose={onAddFolderClose} />
      </Popup>
    </>
  );
};
