import { Avatar, Box, Button, Center, Circle, HStack, Text, useDisclosure, VStack } from '@chakra-ui/react';
import Popup from 'components/common/popup/Popup';
import { SelectionIndicator } from 'components/common/SelectionIndicator';
import { EditTeam } from 'components/modals/Popups/EditManagement/EditTeam';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import { EnvironmentType } from 'types/types';

type EnvironmentItemProps = {
  environment: EnvironmentType,
  isActive?: boolean,
}

export const EnvironmentItem = ({ environment, isActive }: EnvironmentItemProps) => {
  const { is_own, name, type, image } = environment;
  const user = useAppSelector((state) => state.profile.user);
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
  const isWiki = useLocation().pathname.match('wiki');

  const editTeam = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onOpenEdit();
    e.stopPropagation();
  };

  return (
    <>
      <Button
        data-group
        position="relative"
        variant="navbar"
        isActive={isActive}
        cursor={isActive ? 'initial' : 'pointer'}
        h="52px"
      >
        <Avatar
          h="32px"
          w="32px"
          borderRadius="50px"
          bg="#dedede"
          src={type === 'personal' ? user?.avatar?.url : image as string}
          icon={<Box fontSize="16px" color="#8f8f8f"><i className="fa-solid fa-user" /></Box>}
        />

        <VStack
          alignItems="flex-start"
          justifyContent="center"
          spacing="2px"
          maxW={environment.team_id && environment.is_own && isActive ? '140px' : '165px'}
          w="100%"
        >
          <HStack justifyContent="flex-start" spacing="6px" w="100%">
            <Text
              fontStyle="normal"
              fontWeight={isActive ? '600' : '500'}
              fontSize="14px"
              color="black"
              noOfLines={1}
              wordBreak="break-all"
              display="block"
              lineHeight="17px"
            >
              {type === 'personal' ? user?.name : name}
            </Text>

            {type !== 'team' ? (
              (
                <Center
                  textTransform="capitalize"
                  p="4px"
                  h="19px"
                  borderRadius="4px"
                  color="white.100"
                  fontSize="12px"
                  fontWeight="400px"
                  bg="yellow.100"
                >
                  {type}
                </Center>
              )
            )
              : (
                <>
                  <Circle size="3px" bg="light_gray" />
                  <Text fontSize="12px" fontWeight="400" color="light_gray">
                    {is_own ? 'Owner' : 'Member'}
                  </Text>
                </>
              )}
          </HStack>

          {type === 'personal' && (
            <Text
              fontStyle="normal"
              fontSize="12px"
              fontWeight="400"
              color="#8D8D8D"
              noOfLines={1}
              wordBreak="break-all"
              display="block"
              maxW="100%"
            >
              {user?.email}
            </Text>
          )}
        </VStack>

        {environment.team_id && environment.is_own && !isWiki && (
          <Center
            pr={isActive ? '24px' : 0}
            ml="auto"
            display={{ base: 'flex', lg: 'none' }}
            cursor="pointer"
            onClick={editTeam}
            fontSize="16px"
            color={{ base: isActive ? 'blue' : 'dark_gray', lg: 'dark_gray.200' }}
            _groupHover={{
              display: 'flex'
            }}
            _hover={{
              color: 'blue'
            }}
          >
            <i className="fa-solid fa-sliders" />
          </Center>
        )}

        {isActive && (
          <Box position="absolute" top="8px" right="8px">
            <SelectionIndicator fontSize="16px" />
          </Box>
        )}
      </Button>

      <Popup isOpen={isOpenEdit} onClose={onCloseEdit} hasCloseButton width="643px">
        <EditTeam teamId={environment.team_id as number} onClose={onCloseEdit} />
      </Popup>
    </>
  );
};
