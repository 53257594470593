import { Button, Center, Menu, MenuButton, MenuList, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

export const AddButton = ({ children }: { children: ReactNode }) => (
  <Menu autoSelect={false} isLazy>
    <MenuButton
      as={Button}
      colorScheme="green"
      position={{ base: 'fixed', lg: 'relative' }}
      w={{ base: '64px', lg: '96px' }}
      h={{ base: '64px', lg: '40px' }}
      p={{ base: '0', lg: '8px 24px' }}
      inset={{ base: 'auto 32px 40px auto', md: 'auto 32px 56px auto', lg: 'auto' }}
      borderRadius={{ base: '50%', lg: '4px' }}
      boxShadow={{
        base: '0px 1px 30px rgba(14, 87, 72, 0.25)',
        lg: '0px 1px 4px rgba(14, 87, 72, 0.25)'
      }}
    >
      <Center as="span" gap="4px" fontSize={{ base: '22px', lg: '12px' }}>
        <i className="fa-solid fa-plus" />
        <Text display={{ base: 'none', lg: 'block' }} m="0" width="28px" fontSize="14px">
          Add
        </Text>
      </Center>
    </MenuButton>
    <MenuList
      border="transparent"
      bg="white.500"
      boxShadow="0px 0px 16px rgba(101, 101, 101, 0.25)"
      borderRadius="8px"
      boxSizing="border-box"
      minW="164px"
    >
      {children}
    </MenuList>
  </Menu>
);
