import { Box, Button, Center, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { standalone } from 'App';
import bg_footer from 'assets/image/bg_footer.webp';
import bg_install_app from 'assets/image/bg_install_app.webp';
import cn from 'classnames';
import { UpgradeTag } from 'components/common/UpgradeTag';
import { differenceInDays } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { actionsApp } from 'store/app-reducer';
import { actionsPopup } from 'store/popup-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import FooterButtons from './FooterButtons';
import style from './Navbar.module.scss';
import UserBar from './UserBar/UserBar';
import { WorkspacesList } from './Workspaces/WorkspacesList';

type PropsType = {
  visibleNav: boolean;
  toggleNavbar: (checkScreenWidth?: boolean) => void;
};

export const Navbar = ({ visibleNav, toggleNavbar }: PropsType): JSX.Element => {
  const dispatch = useAppDispatch();
  const { currentPlan } = useAppSelector((state) => state.subscription);
  const { currentEnvironment } = useAppSelector((state) => state.profile);
  const [showItems, setShowItems] = useState(false);
  const firstItemInList = useRef<HTMLAnchorElement | null>(null);

  const [trialDays, setTrialDays] = useState<number | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const selected = ({ isActive }: any) => cn(style.link_container, { [style.active]: isActive });

  const openUpgradePlanPopup = () => {
    dispatch(actionsPopup.setShowUpgradePopup(true));
  };

  const checkAccess = (access: boolean, path: string, redirect?: boolean) => {
    toggleNavbar(true);

    if (access) {
      navigate(path);
    } else {
      if (redirect) {
        navigate('/workspace');
      }
      openUpgradePlanPopup();
    }
  };

  const checkExtension = () => {
    if (chrome && chrome.runtime) {
      const extension_id = process.env.REACT_APP_CHROME_EXTENSION_ID;
      chrome.runtime.sendMessage(extension_id, 'version', (reply: any) => {
        if (reply) {
          dispatch(actionsApp.setExtensionIsInstalled(true));
        }
      });
    }
  };

  useEffect(() => {
    if (navigator.vendor.includes('Google')) checkExtension();
  }, []);

  useEffect(() => {
    if (currentPlan?.subscription.trial_ends_at && currentPlan.subscription.stripe_status === 'trialing') {
      const result = differenceInDays(new Date(currentPlan.subscription.trial_ends_at), new Date());
      setTrialDays(result + 1);
    }

    if (currentPlan?.subscription_plan) {
      if (location.pathname === '/tracker') {
        checkAccess(
          currentPlan?.subscription_plan.plan.has_bookmark_content_changes_tracker === 'yes',
          location.pathname,
          true
        );
      }

      if (location.pathname === '/reminders') {
        checkAccess(
          currentPlan?.subscription_plan.plan.has_bookmark_reminders === 'yes',
          location.pathname,
          true
        );
      }

      if (location.pathname === '/snippets') {
        checkAccess(
          currentPlan?.subscription_plan.plan.has_snippets === 'yes',
          location.pathname,
          true
        );
      }
    }
  }, [currentPlan]);

  const toggleShowItems = () => {
    if (firstItemInList.current) {
      firstItemInList.current?.scrollIntoView({ block: 'start' });
    }
    setShowItems((state) => !state);
  };

  return (
    <Flex
      flexDirection="column"
      position={{
        base: 'fixed',
        lg: 'relative'
      }}
      zIndex={{
        base: '9',
        lg: 'unset'
      }}
      h="100%"
      minW={visibleNav ? '300px' : '74px'}
      w={{
        base: '100%',
        xs: '300px',
        lg: visibleNav ? '300px' : '74px'
      }}
      borderRight="1px solid"
      borderColor="gray.100"
      transition="0.3s"
      bg="white.50"
      transform={{
        base: visibleNav ? 'translate(0,0)' : 'translate(-400px, 0)',
        lg: 'none'
      }}
    >
      <Flex
        direction="column"
        maxW={{
          base: '100%',
          xs: '300px'
        }}
        w="100%"
        p="16px 12px 8px"
        h="calc(100vh - 66px)"
        maxH="calc(100vh - 66px)"
        gap="16px"
        overflow="hidden"
      >
        <UserBar visibleNav={visibleNav} toggleNavbar={() => toggleNavbar(true)} />

        <WorkspacesList toggleNavbar={() => toggleNavbar(true)} visibleNav={visibleNav} />

        <Box w="100%" position="relative" borderBottom="1px solid" borderColor="gray.100">
          <Button
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            boxSize="20px"
            minW="20px"
            p="0"
            borderRadius="50%"
            bg="purple.600"
            color="white"
            fontSize="9px"
            _hover={{ bg: 'purple.500' }}
            _active={{ bg: 'purple.500' }}
            onClick={toggleShowItems}
          >
            <Box as="i" transition="150ms" transform={showItems ? 'none' : 'rotate(180deg)'} className="fa-solid fa-angles-down" />
          </Button>
        </Box>

        <Flex
          alignItems="center"
          minH={showItems ? 'auto' : '48px'}
          maxH={showItems ? '300px' : '48px'}
          direction={showItems ? 'column' : 'row'}
          sx={!showItems && visibleNav ? {
            justifyContent: 'space-between',
            p: {
              display: 'none'
            },
            a: {
              height: '42px',
              width: '42px',
              padding: '4px'
            },
            button: {
              height: '42px',
              width: '42px',
              padding: '4px'
            }
          } : undefined}
          gap="4px"
          width="100%"
          className={cn(style.navigation_container, { [style.noscroll]: !showItems })}
          transition="none"
        >
          <NavLink ref={firstItemInList} onClick={() => toggleNavbar(true)} className={selected} to="/shared">
            <i className={cn('fa-solid fa-share-nodes', style.icon_utils)} />
            <Text noOfLines={1} maxW="210px">
              Shared
            </Text>
          </NavLink>

          <Button
            variant="navbar"
            h="48px"
            isActive={location.pathname === '/snippets'}
            onClick={() => checkAccess(currentPlan?.subscription_plan.plan.has_bookmark_reminders === 'yes', '/snippets')}
          >
            <i className={cn('fa-solid fa-code', style.icon_utils)} />
            <Text noOfLines={1} maxW="210px">
              Snippets
            </Text>
            {currentPlan?.subscription_plan.plan.slug === 'basic' && showItems && <UpgradeTag title="Pro" />}
          </Button>

          {(
            currentPlan?.subscription_plan.plan.has_users_for_teams
            && currentEnvironment.type !== 'personal'
            && currentEnvironment.is_own
          )
            && (
              <NavLink onClick={() => toggleNavbar(true)} className={selected} to="/team">
                <i className={cn('fa-solid fa-users-gear', style.icon_utils)} />
                <Text noOfLines={1} maxW="210px">
                  Members
                </Text>
              </NavLink>
            )}

          <Button
            variant="navbar"
            h="48px"
            isActive={location.pathname === '/tracker'}
            onClick={() => checkAccess(currentPlan?.subscription_plan.plan.has_bookmark_content_changes_tracker === 'yes', '/tracker')}
          >
            <i className={cn('fa-solid fa-bolt', style.icon_utils)} />
            <Text noOfLines={1} maxW="210px">
              Tracker
            </Text>

            {currentPlan?.subscription_plan.plan.slug === 'basic' && showItems && <UpgradeTag title="Pro" />}
          </Button>

          <Button
            variant="navbar"
            h="48px"
            isActive={location.pathname === '/reminders'}
            onClick={() => checkAccess(currentPlan?.subscription_plan.plan.has_bookmark_reminders === 'yes', '/reminders')}
          >
            <i className={cn('fa-solid fa-clock', style.icon_utils)} />
            <Text noOfLines={1} maxW="210px">
              Reminder
            </Text>

            {currentPlan?.subscription_plan.plan.slug === 'basic' && showItems && <UpgradeTag title="Pro" />}
          </Button>

          <NavLink target="_blank" onClick={() => toggleNavbar(true)} className={selected} to="wiki/home">
            <i className={cn('fa-brands fa-markdown', style.icon_utils)} />
            <Text noOfLines={1} maxW="210px">
              Wiki
            </Text>
          </NavLink>
        </Flex>
      </Flex>

      <Box w="100%">
        {!!trialDays && (
          <Center
            w="100%"
            h="32px"
            textAlign="center"
            bg={trialDays < 4 ? 'red.50' : 'light_yellow.50'}
            color={trialDays < 4 ? 'red.500' : 'yellow.500'}
            fontWeight="500"
            fontSize={visibleNav ? '12px' : '10px'}
            lineHeight="15px"
          >
            <Box as="span" fontSize={visibleNav ? '14px' : '10px'} mr={visibleNav ? '8px' : '4px'}>
              <i className="fa-solid fa-clock-rotate-left" />
            </Box>
            <Box
              as="span"
              _hover={{
                textDecoration: 'underline'
              }}
              cursor="pointer"
              onClick={openUpgradePlanPopup}
            >
              {trialDays > 1
                ? `${trialDays} Day${trialDays > 1 ? 's' : ''} ${visibleNav ? ' Free Trial' : ''}`
                : `${visibleNav ? 'Trial ends' : ''} today`}
            </Box>
          </Center>
        )}

        <HStack
          justifyContent="center"
          h="66px"
          w="100%"
          bgImage={visibleNav ? bg_footer : bg_install_app}
          bgSize="cover"
          p="16px"
          spacing="10px"
        >
          {visibleNav ? (
            <FooterButtons
              onOpenUpgradePlan={() => openUpgradePlanPopup()}
              isStandalone={standalone}
              toggleNavbar={() => toggleNavbar(true)}
            />
          ) : (
            <IconButton
              m="auto"
              aria-label="open navbar"
              colorScheme="green"
              onClick={() => toggleNavbar()}
              minH="36px"
              minW="36px"
              filter="drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.25))"
              _hover={{
                filter: 'drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5))'
              }}
              icon={<i className="fa-solid fa-download" />}
            />
          )}
        </HStack>
      </Box>
    </Flex>
  );
};
