import { checkParam, EditType, GetItemsType, requestInstance, ResponseType } from 'api/api';
import { WikiFolderType } from 'types/wiki';

export const wikiFolderAPI = {
  async getFolderList(
    teamId?: number | null
  ) {
    const params = {
      team_id: checkParam(teamId)
    };

    const res = await requestInstance().get<GetItemsType<Array<WikiFolderType>>>('v1/wiki/folders', { params });

    return res.data;
  },

  async setNewFolder(folderName: string, teamId: number | null) {
    const res = await requestInstance().post<ResponseType<WikiFolderType>>(
      'v1/wiki/folders',
      { name: folderName, ...(teamId ? { team_id: teamId } : {}) }
    );

    return res.data;
  },

  async deleteFolder(folderId: number) {
    const res = await requestInstance().delete<EditType>(`v1/wiki/folders/${folderId}`);

    return res.data;
  },

  async renameFolder(folderId: number, newFolderName: string) {
    const res = await requestInstance().patch<ResponseType<WikiFolderType>>(`v1/wiki/folders/${folderId}`, { name: newFolderName });

    return res.data;
  }
};
