import { useEffect, useState } from 'react';
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  HStack,
  Image,
  Spinner,
  Text,
  VStack,
  Wrap
} from '@chakra-ui/react';
import cn from 'classnames';
import { differenceInDays, format, formatDistanceStrict } from 'date-fns';
import { ShowMoreButton } from 'components/common/buttons/ShowMoreButton';
import {
  actionsNotifications,
  deleteNotification,
  getNotificationsList,
  setNotificationIsRead
} from 'store/notifications-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import { NotificationType } from 'types/types';
import { NoNotifications } from './NoNotifications';

import style from './Notifications.module.scss';
import page_lost from '../../../../../assets/image/page_lost.svg';

export const NotificationsList = () => {
  const dispatch = useAppDispatch();
  const { notificationsList: notifications, pagination, unreadCount } = useAppSelector((state) => state.notifications);

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    dispatch(getNotificationsList(page)).then(() => {
      setLoading(false);
    });

    return () => {
      dispatch(actionsNotifications.setInitialNotificationsList([], null));
    };
  }, []);

  const getNewPortion = () => {
    setIsFetching(true);
    setPage((prev) => prev + 1);
    dispatch(getNotificationsList(page + 1)).then(() => {
      setIsFetching(false);
    });
  };

  const deleteNotifications = (id: string, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(deleteNotification(id));
  };

  const setIsRead = (id: string) => {
    dispatch(setNotificationIsRead(id));
  };

  const goTo = (url: string, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.stopPropagation();
    window.open(url, url.startsWith(window.location.origin) ? '_self' : '_blank');
  };

  const onNoteClick = (note: NotificationType) => {
    if (note.data.url) {
      goTo(note.data.url);
    }
    setIsRead(note.id);
  };

  if (loading) {
    return (
      <VStack justifyContent="center" alignItems="center" pb="70px" w="100%" flex="1 1 auto">
        <Spinner emptyColor="green.50" color="green.500" />
      </VStack>
    );
  }

  if (!notifications?.length) {
    return (
      <VStack tabIndex={-1} className={style.notifications_container} overflowY="auto" flex="1 1 auto" spacing="8px">
        <NoNotifications />
      </VStack>
    );
  }

  return (
    <VStack
      tabIndex={-1}
      className={style.notifications_container}
      overflowY="auto"
      overflowX="hidden"
      flex="1 1 auto"
      spacing="8px"
    >
      {notifications.map((note) => (
        <Box className={style.notification} key={note.id} w="100%" px={{ base: '16px', md: '8px' }}>
          <HStack
            minW="100%"
            p="8px"
            pl="12px"
            alignItems="flex-start"
            spacing="0"
            mb="8px"
            onClick={() => onNoteClick(note)}
          >
            <Avatar
              boxSize={{ base: '40px', xs: '48px', md: '34px' }}
              bg="yellow.50"
              mr="12px"
              src={note.data.user?.avatar?.url}
            >
              {unreadCount > 0 && !note.read_at && (
                <AvatarBadge
                  boxSize="6px"
                  bg="light_green.50"
                  border="none"
                  top={{ base: '0', md: '-2px' }}
                  left={{ base: '-6px', md: '-6px' }}
                />
              )}
            </Avatar>

            <VStack
              alignItems="flex-start"
              maxW={{ base: 'calc(100% - 80px)', md: 'calc(100% - 63px)' }}
              minW="unset"
              fontSize="14px"
              spacing="8px"
            >
              <Flex
                w="100%"
                lineHeight="110%"
                alignItems={{ base: 'flex-start', xs: 'center' }}
                flexWrap="wrap"
                rowGap="8px"
              >
                <Flex alignItems="center" overflow="hidden">
                  <Text fontWeight="600" color="black" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                    {note.data.name}
                  </Text>
                  <Circle mx="8px" size="3px" bg="dark_gray.50" />
                </Flex>
                <Text minW="80px" noOfLines={1} color="light_gray" fontSize="12px">
                  {differenceInDays(new Date(), new Date(note.created_at)) > 1
                    ? format(new Date(note.created_at), 'MMM dd, yyyy')
                    : `${formatDistanceStrict(new Date(), new Date(note.created_at))} ago`}
                </Text>
              </Flex>
              <Text wordBreak="break-word" maxW="100%" fontWeight="400" color="gray.700" lineHeight="20px">
                {note.data.text}
              </Text>
              {note.data.buttons && (
                <Wrap w="100%">
                  {note.data.buttons.map((btn) => (
                    <Button
                      key={btn.text}
                      colorScheme="green"
                      fontSize="12px"
                      lineHeight="15px"
                      maxW="100%"
                      overflow="hidden"
                      onClick={(e) => goTo(btn.url, e)}
                    >
                      <Box
                        as="span"
                        display="block"
                        textOverflow="ellipsis"
                        noOfLines={1}
                        whiteSpace="normal"
                        wordBreak="break-all"
                      >
                        {btn.text}
                      </Box>
                    </Button>
                  ))}
                </Wrap>
              )}
            </VStack>

            <i
              className={cn('fa-solid fa-xmark', style.cancel)}
              onClick={(e) => deleteNotifications(note.id, e)}
              role="none"
            />
          </HStack>
          <Divider p="0" w={{ base: '100%', md: '330px' }} m="0 auto" borderColor="gray.100" />
        </Box>
      ))}

      {Number(pagination?.total_pages) > Number(pagination?.current_page) ? (
        <Box zIndex="3" p="24px" pt="8px">
          <ShowMoreButton onClick={getNewPortion} isDisabled={isFetching} />
        </Box>
      ) : (
        <VStack w="100%" spacing={{ base: '32px', md: '24px' }} mt="auto!important">
          <Flex w="100%" alignItems="center" gap="8px">
            <Divider />
            <Text fontWeight="400" textAlign="center" fontSize="12px" color="light_gray" whiteSpace="nowrap">
              THAT’S YOUR ALL NOTIFICATIONS
            </Text>
            <Divider />
          </Flex>
          <Image w={{ base: '392px', md: '262px' }} src={page_lost} />
        </VStack>
      )}
    </VStack>
  );
};
