import { Box, Flex, Text } from '@chakra-ui/react';
import { plansList, PlansListType } from 'plans';

type PropsType = {
  isInPopup?: boolean;
  planType: keyof PlansListType;
}
export const PlanBenefitsList = ({ isInPopup, planType }: PropsType): JSX.Element => (
  <Flex
    flexDirection="column"
    h="100%"
    w="100%"
    flexWrap="wrap"
    gap="10px"
  >
    {plansList[planType]?.map((item) => (
      <Flex maxW={{ base: '100%', md: '50%', lg: '100%' }} alignItems="center" key={item}>
        <Box fontSize="17px" color="light_green.300">
          <i className="fa-solid fa-check" />
        </Box>
        <Text ml="10px" fontWeight="500" fontSize={isInPopup ? { base: '14px', lg: '16px' } : '14px'} lineHeight="150%">
          {item}
        </Text>
      </Flex>
    ))}
  </Flex>
);
