import { BookmarkType, FolderType, InviteFolderMembers, TeamMember, UsersSuggestions } from 'types/types';
import { EditType, GetItemsType, requestInstance, ResponseType } from './api';

export const shareAPI = {
  async getUsersWithShare(page = 1) {
    const res = await requestInstance().get<ResponseType<Array<UsersSuggestions>>>(`v1/share/suggestions?page=${page}`);

    return res.data;
  },
  async shareBookmark(bookmarkId: number, emails: Array<string>) {
    const res = await requestInstance().post<EditType>(`v1/bookmark/${bookmarkId}/share`, { emails });

    return res.data;
  },
  async shareFolder(folderId: number, shares: InviteFolderMembers | null) {
    const res = await requestInstance().post<EditType>(`v1/folder/${folderId}/share`, { shares });

    return res.data;
  },
  async getFolderMembers(folderId: number) {
    const res = await requestInstance().get<ResponseType<Array<TeamMember>>>(`v1/folder/shared/${folderId}/users`);

    return res.data;
  },
  async getSharedBookmarks(folderId: number | string = '', page = 1, keyword?: string) {
    const res = await requestInstance()
      .get<GetItemsType<Array<BookmarkType>>>(`v1/bookmark/share?folder_id=${folderId}&keyword=${keyword}&page=${page}`);

    return res.data;
  },
  async getSharedFolders(folderId: number | string = '', page = 1, keyword?: string) {
    const res = await requestInstance()
      .get<GetItemsType<Array<FolderType>>>(`v1/folder/shared/search?folder_id=${folderId}&keyword=${keyword}&page=${page}`);

    return res.data;
  },
  async deleteSharedBookmark(bookmarkId: number | string) {
    const res = await requestInstance().delete<EditType>(`v1/bookmark/${bookmarkId}/share/delete`);

    return res.data;
  },
  async deleteSharedFolder(folderId: number) {
    const res = await requestInstance().delete<EditType>(`v1/folder/${folderId}/shared/delete`);

    return res.data;
  },
  async getSharedFolder(folderId: number | string) {
    const res = await requestInstance().get<ResponseType<FolderType>>(`v1/folder/shared/${folderId}`);

    return res.data;
  },
  async getSharedFolderBySlug(slug: string) {
    const res = await requestInstance().get<ResponseType<FolderType>>(`v1/folder/shared/slug/${slug}`);

    return res.data;
  }
};
