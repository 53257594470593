import { Box, Button, Flex, Image, Link, Text, useDisclosure, VStack } from '@chakra-ui/react';
import welcome from 'assets/image/welcome.webp';
import Popup from 'components/common/popup/Popup';
import { useEffect } from 'react';
import Chapter from './Chapter';

const WelcomeToLinker = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  const features = [
    {
      feature: 'More convenient organizing of bookmarks',
      sub_feature: ['Workspaces', 'Folders', 'Sub-Folders']
    },
    {
      feature: 'Share Folder'
    },
    {
      feature: 'Bookmark Content Changes Tracker'
    },
    {
      feature: 'Reminders'
    },
    {
      feature: 'Snippets'
    },
    {
      feature: 'Progressive Web App (PWA)'
    },
    {
      feature: 'Integrations with other Apps'
    },
    {
      feature: 'Creating Teams and and much more'
    }
  ];
  const changed = [
    {
      feature: 'Updated Interface design (UI)'
    },
    {
      feature: 'Changed all App content'
    },
    {
      feature: 'Implement Plans',
      sub_feature: ['Basic (Free)', 'Pro', 'Team']
    }
  ];

  useEffect(() => {
    if (!localStorage.getItem('sawWelcome')) {
      onOpen();
    }
  }, [localStorage]);

  const handleClose = () => {
    localStorage.setItem('sawWelcome', 'true');
    onClose();
  };

  return (
    <Popup width="600px" padding="0" hasCloseButton isOpen={isOpen} onClose={handleClose}>
      <Flex overflowY="auto" maxH="95vh" flexDirection="column" w="100%">
        <Image src={welcome} objectFit="cover" w="100%" h="176px" />

        <Flex alignItems="center" flexDirection="column" p={{ base: '40px 16px', md: '40px 76px' }}>
          <Text textAlign="center" fontSize="24px" fontWeight="700" lineHeight="34px" color="#1C1E23">
            <Box as="span" mr="10px" fontSize="24px">&#128075;</Box>
            Welcome to Linker V2.0
          </Text>
          <Text mb="24px" maxW="368px" textAlign="center" fontSize="14px" lineHeight="24px" color="gray.600">
            We have made great improvements to our app, check them out and enjoy it
          </Text>

          <VStack mb="32px" w="100%" h="100%" spacing="16px">
            <Chapter
              title="Whats New?"
              titleIcon="&#127881;"
              description="Thanks to the changes, the application has become even more convenient, pleasant and useful.
              What we have now, after upgrading:"
              list={features}
            />
            <Chapter
              title="Whats New?"
              titleIcon="&#9999;&#65039;"
              description="With the improvements, changes were also made to adapt the application to the new functionality:"
              list={changed}
            >
              <Flex flexDirection="column" p="10px 16px" borderRadius="4px" bg="rgba(202, 227, 238, 0.4)" fontSize="14px">
                <Text color="gray.600" fontStyle="italic">
                  You can try “Pro” or “Team” plans on
                  {' '}
                  <Box fontWeight="700" as="span">30 days free trial</Box>
                  . For more information about Plans, please follow by this link:
                  <br />
                </Text>
                <Link href="https://staging.getlinker.io/plans" mt="8px" color="light_blue.100">https://staging.getlinker.io/plans</Link>
              </Flex>
            </Chapter>
          </VStack>

          <Box
            bottom="0"
            left="0"
            position="absolute"
            h="100px"
            w="100%"
            borderRadius="0 0 12px 12px"
            bg="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)"
          />

          <Button onClick={handleClose} size="sm" colorScheme="green" filter="drop-shadow(0px 1px 4px rgba(14, 87, 72, 0.25))">
            Start using Linker
          </Button>
        </Flex>
      </Flex>
    </Popup>
  );
};

export default WelcomeToLinker;
