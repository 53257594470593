import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';

type PropsType = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  iconPlacement?: 'left' | 'right';
  size?: 'sm' | 'md';
  name?: string
};

const searchInputSizes = {
  md: {
    fontSize: '14px',
    lineHeight: '17px',
    iconSize: '13px',
    iconWidth: '20px',
    inputPaddings: {
      left: '4px 10px 4px 34px',
      right: '4px 30px 4px 10px'
    }
  },
  sm: {
    fontSize: '12px',
    lineHeight: '15px',
    iconSize: '11px',
    iconWidth: '16px',
    inputPaddings: {
      left: '6px 10px 6px 30px',
      right: '6px 30px 6px 10px'
    }
  }
};

const SearchInput = ({
  iconPlacement = 'right',
  value,
  onChange,
  placeholder = 'Search...',
  size = 'md',
  name
}: PropsType): JSX.Element => (
  <InputGroup w="100%" h="100%" maxH="40px" minH="24px">
    {iconPlacement === 'left' && (
      <InputLeftElement
        width={searchInputSizes[size].iconWidth}
        height="100%"
        ml="8px"
        color="light_gray"
        fontSize={searchInputSizes[size].iconSize}
        zIndex={1}
      >
        <i className="fa-solid fa-magnifying-glass" />
      </InputLeftElement>
    )}
    <Input
      variant="formInput"
      h="100%"
      bg="white.500"
      name={name}
      fontSize={searchInputSizes[size].fontSize}
      lineHeight={searchInputSizes[size].lineHeight}
      p={searchInputSizes[size].inputPaddings[iconPlacement]}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      value={value}
    />
    {iconPlacement === 'right' && (
      <InputRightElement
        width={searchInputSizes[size].iconWidth}
        height="100%"
        mr="10px"
        color="light_gray"
        fontSize={searchInputSizes[size].iconSize}
      >
        <i className="fa-solid fa-magnifying-glass" />
      </InputRightElement>
    )}
  </InputGroup>
);

export default SearchInput;
