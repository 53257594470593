import { useEffect, useState } from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';
import AuthorizedRoutes from 'routes/AuthorizedRoutes';
import UnauthorizedRoutes from 'routes/UnauthorizedRoutes';
import { actionsPopup } from 'store/popup-reducer';
import { autoLogin } from 'store/profile-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import Cookies from 'universal-cookie';

export const standalone = window.matchMedia('(display-mode: standalone)').matches;

TopBarProgress.config({
  barColors: {
    0: '#55BCA7',
    1.0: '#8fd7c9'
  },
  shadowBlur: 3
});

const App = (): JSX.Element => {
  const cookies = new Cookies();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.profile);

  const [accessToken, setAccessToken] = useState(cookies.get('token'));

  const addPwaListener = () => {
    window.addEventListener('beforeinstallprompt', (e: any) => {
      e.preventDefault();

      cookies.remove('isInstalled', { path: '/' });
      dispatch(actionsPopup.setDeferredPromptEvent(e));
    });

    window.addEventListener('appinstalled', () => {
      cookies.set('isInstalled', 'installed', { path: '/' });
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') addPwaListener();

    const tokenInCookie = cookies.get('token');
    if (!tokenInCookie) {
      setAccessToken('');
    } else if (accessToken && !user) {
      dispatch(autoLogin()).catch(() => {
        setAccessToken('');
      });
    }
  }, [accessToken, user]);

  if (!accessToken && !user) return <UnauthorizedRoutes />;

  if (!user) return <TopBarProgress />;

  return <AuthorizedRoutes user={user} />;
};

export default App;
