import { Flex } from '@chakra-ui/react';
import UpdateApp from 'components/Alerts/UpdateApp';
import { OpenNavbarButton } from 'components/common/buttons/ToggleNavbarButtons';
import NavbarOverlay from 'components/common/Overlay';
import Header from 'components/Wiki/Layout/Header';
import WikiNavbar from 'components/Wiki/Navbar/WikiNavbar';
import { ScrollToTop } from 'hoc/scrollToTop';
import { useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';

const WikiLayout = (): JSX.Element => {
  const [isOpenNavbar, setIsOpenNavbar] = useState(false);
  const wikiWrapper = useRef<HTMLDivElement | null>(null);
  const toggleNavbar = () => {
    setIsOpenNavbar(!isOpenNavbar);
  };

  return (
    <Flex
      alignItems="flex-start"
      margin="auto"
      h="100vh"
      maxW="1920px"
      overflow="hidden"
      background="white.50"
      position="relative"
      fontSize="14px"
    >
      <WikiNavbar isOpenNavbar={isOpenNavbar} toggleNavbar={toggleNavbar} />
      <Flex width={{ base: '100%', lg: 'calc(100% - 240px)' }} transition="width 0.3s" h="100%">
        <UpdateApp />
        <Header>
          <OpenNavbarButton toggleNavbar={toggleNavbar} />
        </Header>
        <Flex ref={wikiWrapper} direction="column" mt={{ base: '48px', md: '56px' }} overflowY="auto" w="100%">
          <NavbarOverlay display={{ base: isOpenNavbar ? 'block' : 'none', lg: 'none' }} handleClick={toggleNavbar} />
          <Outlet />
          <ScrollToTop element={wikiWrapper.current} />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default WikiLayout;
