import { Center, Text } from '@chakra-ui/react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { LocationState } from 'types/types';
import style from './Layout.module.scss';

type Props = {
  icon?: JSX.Element;
  name: string;
  navigateTo: string;
  locationState: LocationState;
  theFirst?: boolean;
}

export const BreadcrumbButton = ({ icon, name, navigateTo, locationState, theFirst }: Props): JSX.Element => {
  const selected = ({ isActive }: any) => cn(style.breadcrumb, { [style.active]: isActive });

  return (
    <>
      {!theFirst && (
        <Center boxSize="20px" fontSize="11px" color="light_gray">
          <i className="fa-solid fa-angle-right" />
        </Center>
      )}

      <NavLink end className={selected} to={navigateTo} state={{ ...locationState }}>
        <Center
          fontSize="16px"
          color="#9D9EB5"
        >
          {icon}
        </Center>
        <Text color="gray.600" fontWeight="600" whiteSpace="nowrap">{name}</Text>
      </NavLink>
    </>
  );
};
