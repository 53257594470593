import { useEffect, useRef, useState } from 'react';
import { Box, Center, Divider, Flex, IconButton, Image, Spacer, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react';
import folder from 'assets/image/folder.svg';
import { BlurLoading } from 'components/common/BlurLoading';
import SearchInput from 'components/common/inputs/SearchInput';
import { ButtonGroupPopup } from 'components/common/buttons/ButtonGroupPopup';
import AddFolderButton from 'components/common/buttons/AddFolderButton';
import { getBookmarks } from 'store/bookmark-reducer';
import { useAppDispatch, useAppSelector } from 'store/store';
import { BookmarkType, FolderType, WorkspaceType } from 'types/types';
import { errorHandler } from 'utils/errorHandler';
import { folderAPI } from 'api/folderApi';
import Popup from 'components/common/popup/Popup';
import WorkspacesList from './WorkspacesList';
import BookmarksList from './BookmarksList';
import { AddFolder } from '../AddFolder';

type WorkspaceItemPickerProps = {
  loading: boolean;
  hasBookmarks?: boolean;
  onClose?: () => void;
  handlePick?: (bookmark: BookmarkType) => void;
  handlePickFolder?: (workspaceId: number, folderId: number | null) => void;
  applyLabel?: string;
  emptyFoldersLabel?: string;
};

const WorkspaceItemPicker = ({
  handlePick,
  handlePickFolder,
  loading,
  hasBookmarks = true,
  onClose,
  applyLabel = 'Sync',
  emptyFoldersLabel
}: WorkspaceItemPickerProps) => {
  const dispatch = useAppDispatch();
  const { isOpen: addFolderIsOpen, onOpen: addFolderOnOpen, onClose: addFolderOnClose } = useDisclosure();

  const { workspacesList: workspaces, currentWorkspaceId } = useAppSelector((state) => state.workspaces);
  const [folders, setFolders] = useState<FolderType[]>([]);
  const [foldersLoading, setFoldersLoading] = useState(false);

  const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceType>({} as WorkspaceType);
  const [selectedFolder, setSelectedFolder] = useState<number | null>(null);
  const [bookmarkKeyword, setBookmarkKeyword] = useState('');
  const [folderKeyword, setFolderKeyword] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const typingTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const typingTimeoutFolder = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleGetFolders = (workspaceId: string | number, keyword?: string | null) => {
    setFoldersLoading(true);
    folderAPI
      .getFolderList(workspaceId.toString(), null, null, keyword ?? null)
      .then((resp) => {
        setFolders(resp.data);
      })
      .catch((error) => {
        errorHandler(error, dispatch);
      })
      .finally(() => {
        setFoldersLoading(false);
      });
  };

  const handleGetBookmarks = (
    workspaceId: string | number,
    page = 1,
    keyword: string | null = null,
    folderId: string | null = null
  ) => {
    dispatch(getBookmarks(workspaceId.toString(), folderId, page, keyword));
  };

  const handleSelectWorkspace = async (workspace: WorkspaceType) => {
    if (workspace.id !== selectedWorkspace.id) {
      const selectedId = String(workspace.id);
      setSelectedWorkspace(workspace);
      setSelectedFolder(null);
      handleGetFolders(selectedId);

      if (hasBookmarks) {
        setBookmarkKeyword('');
        setCurrentPage(1);
        handleGetBookmarks(selectedId);
      }
    }
  };

  const handleSelectFolder = async (id: number) => {
    if (hasBookmarks) {
      setCurrentPage(1);
      if (id === selectedFolder) {
        setSelectedFolder(null);
        handleGetBookmarks(selectedWorkspace.id.toString(), 1, bookmarkKeyword, null);
      } else {
        setSelectedFolder(id);
        handleGetBookmarks(selectedWorkspace.id.toString(), 1, bookmarkKeyword, id.toString());
      }
    } else {
      setSelectedFolder(id === selectedFolder ? null : id);
    }
  };

  useEffect(() => {
    handleGetFolders(currentWorkspaceId);
    if (hasBookmarks) {
      handleGetBookmarks(currentWorkspaceId);
    }
    const workspace = workspaces.find((item) => String(item.id) === currentWorkspaceId);
    if (workspace) {
      setSelectedWorkspace(workspace);
    }

    return () => {
      if (typingTimeout.current) clearTimeout(typingTimeout.current);
      if (typingTimeoutFolder.current) clearTimeout(typingTimeoutFolder.current);
    };
  }, []);

  useEffect(() => {
    if (currentPage > 1) {
      handleGetBookmarks(selectedWorkspace.id.toString(), currentPage, bookmarkKeyword);
    }
  }, [currentPage]);

  const handleAddFolder = (newFolder: FolderType) => {
    if (newFolder) {
      setFolders((foldersState) => [...foldersState, newFolder]);
      handleSelectFolder(newFolder.id);
    }
  };

  const handleSearch = (value: string) => {
    setBookmarkKeyword(value);
    if (typingTimeout.current) clearTimeout(typingTimeout.current);

    typingTimeout.current = setTimeout(() => {
      handleGetBookmarks(selectedWorkspace.id, 1, value, selectedFolder ? selectedFolder.toString() : null);
      setCurrentPage(1);
    }, 500);
  };

  const handleSearchFolder = (value: string) => {
    setFolderKeyword(value);
    if (typingTimeoutFolder.current) clearTimeout(typingTimeoutFolder.current);

    typingTimeoutFolder.current = setTimeout(() => {
      handleGetFolders(selectedWorkspace.id, value);
    }, 500);
  };

  const handlePickBookmark = (bookmark: BookmarkType) => {
    if (handlePick) {
      handlePick(bookmark);
    }
  };

  const pickFolder = () => {
    if (handlePickFolder && selectedWorkspace) {
      handlePickFolder(+selectedWorkspace.id, selectedFolder);
    }
  };

  return (
    <>
      <Flex w="100%" maxH="600px" h="100vh" minH="200px" position="relative">
        {loading && hasBookmarks && <BlurLoading />}
        <Flex
          flexDirection={hasBookmarks ? { base: 'column', ml: 'row' } : 'row'}
          flex={hasBookmarks ? { base: '1', ml: 'unset' } : 'unset'}
          maxW={hasBookmarks ? { base: '144px', sm: '250px', ml: 'unset' } : 'unset'}
          w={hasBookmarks ? 'auto' : '100%'}
        >
          <WorkspacesList
            handleSelectWorkspace={handleSelectWorkspace}
            workspaces={workspaces}
            selectedWorkspace={selectedWorkspace}
            hasBookmarks={hasBookmarks}
          />

          <Flex
            flex="1 1 auto"
            flexDir="column"
            w={hasBookmarks ? { base: '100%', ml: '250px' } : 'auto'}
            p={{ base: `16px 16px ${!hasBookmarks ? '170px' : '16px'}`, lg: '16px' }}
            minW="120px"
            borderRight={hasBookmarks ? '1px solid #EEEEEE' : 'none'}
            h={{ base: hasBookmarks ? '50%' : '100%', ml: '100%' }}
            bg={{ base: hasBookmarks ? 'gray.900' : 'white', ml: 'white' }}
            borderTop={{ base: hasBookmarks ? '1px solid #EEEEEE' : 'none', ml: 'none' }}
          >
            <Flex>
              <Text
                fontWeight="600"
                minH="24px"
                fontSize={hasBookmarks ? { base: '12px', sm: '14px' } : '14px'}
                lineHeight="150%"
                wordBreak="break-all"
                whiteSpace="nowrap"
                overflow="hidden"
                color="black"
                mb="8px"
                noOfLines={1}
                display="block"
              >
                {selectedWorkspace.name}
                {' '}
                <Box color="light_gray" as="span" fontWeight="400">
                  / Folders
                </Box>
              </Text>

              <Spacer />

              {!!folders.length && (
                <IconButton
                  aria-label="add"
                  boxSize="20px"
                  minW="20px"
                  fontSize="20px"
                  mr="4px"
                  color="dark_gray.100"
                  colorScheme="transparent"
                  _hover={{
                    color: 'dark_gray.300'
                  }}
                  onClick={addFolderOnOpen}
                >
                  <i className="fa-solid fa-plus" />
                </IconButton>
              )}
            </Flex>

            <Divider borderColor="gray.100" />
            <Box w="100%" minH="32px" h="32px" my="16px">
              <SearchInput iconPlacement="left" value={folderKeyword} onChange={handleSearchFolder} />
            </Box>

            {!folders.length && !hasBookmarks && !foldersLoading && !folderKeyword ? (
              <Center h="100%" flexDirection="column" gap="16px">
                <AddFolderButton onOpen={addFolderOnOpen} />

                <Text maxW="209px" fontWeight="400" fontSize="12px" color="light_gray" textAlign="center">
                  {emptyFoldersLabel}
                </Text>
              </Center>
            ) : (
              <Stack
                w="100%"
                h="100%"
                mt={{ base: hasBookmarks ? '0' : '16px', ml: '16px' }}
                spacing="4px"
                overflowY="auto"
                pb={{ base: !hasBookmarks ? '12px' : '0', lg: '0' }}
              >
                {foldersLoading && (
                  <Center>
                    <Spinner color="green" />
                  </Center>
                )}

                {folders.map((item) => (
                  <Flex
                    key={item.id}
                    color="black"
                    w="100%"
                    gap="8px"
                    cursor="pointer"
                    alignItems="center"
                    p="8px 10px"
                    h="40px"
                    bg={selectedFolder === item.id ? 'white.600' : 'transparent'}
                    _hover={{ background: 'white.600' }}
                    borderRadius="8px"
                    fontWeight="500"
                    fontSize={{ base: '12px', sm: '14px' }}
                    lineHeight="150%"
                    onClick={() => handleSelectFolder(item.id)}
                  >
                    <Image
                      minWidth={{ base: '17px', sm: '20px' }}
                      src={folder}
                      boxSize={{ base: '17px', sm: '20px' }}
                      objectFit="contain"
                    />

                    <Text noOfLines={1}>{item.name}</Text>

                    <Spacer />

                    <Box fontSize="12px" color="gray.500" as="span">
                      {item.bookmarks_count}
                    </Box>
                  </Flex>
                ))}
              </Stack>
            )}

            {!hasBookmarks && (
              <>
                <Spacer />

                <Box
                  p={{ base: '0 32px 32px', lg: '16px' }}
                  position={{ base: 'absolute', lg: 'initial' }}
                  w="100%"
                  bg="white"
                  bottom={{ base: '0', lg: 'unset' }}
                  left={{ base: '0', lg: 'unset' }}
                >
                  <ButtonGroupPopup
                    handleCancelClick={onClose}
                    label={applyLabel}
                    mt="0"
                    isLoading={loading}
                    handleClick={pickFolder}
                  />
                </Box>
              </>
            )}
          </Flex>
        </Flex>

        {hasBookmarks && (
          <BookmarksList
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            handlePickBookmark={handlePickBookmark}
            handleSearch={handleSearch}
            keyword={bookmarkKeyword}
          />
        )}
      </Flex>

      <Popup isOpen={addFolderIsOpen} onClose={addFolderOnClose}>
        <AddFolder
          workspaceId={selectedWorkspace?.id ? +selectedWorkspace.id : undefined}
          handleAddFolder={handleAddFolder}
          onClose={addFolderOnClose}
        />
      </Popup>
    </>
  );
};

export default WorkspaceItemPicker;
