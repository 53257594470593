import { pocketAPI } from 'api/pocketApi';
import { slackAPI } from 'api/slackApi';
import { InferActionsTypes, SlackChannelType, SlackTeamType, UserType } from 'types/types';
import { errorHandler } from 'utils/errorHandler';
import { actionsWorkspace } from './workspace-reducer';
import { showAlert } from './app-reducer';
import { actionsProfile } from './profile-reducer';
import { AppThunkType } from './store';
import { actionsTeam, getCurrentTeam } from './team-reducer';

const initialState = {
    slackTeam: null as SlackTeamType | null,
    slackChannels: null as Array<SlackChannelType> | null
};

export const actionsIntegrations = {
    setSlackTeam: (data: SlackTeamType | null) => ({ type: 'SET_SLACK_TEAM', data }) as const,
    setSlackChannels: (data: SlackChannelType[]) => ({ type: 'SET_SLACK_CHANNELS', data }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsIntegrations>

export const integrationsReducer = (state = initialState, action: ActionType): InitialStateType => {
    switch (action.type) {
        case 'SET_SLACK_TEAM':
            return {
                ...state,
                slackTeam: action.data
            };

        case 'SET_SLACK_CHANNELS':
            return {
                ...state,
                slackChannels: action.data
            };

        default:
            return state;
    }
};

export const getSlackTeam = (): AppThunkType => async (dispatch, getState) => {
    try {
        const teamId = getState().profile.currentEnvironment.team_id as number;
        const response = await slackAPI.getSlackTeam(teamId);

        const channels = await slackAPI.getSlackChannels(teamId);

        dispatch(actionsIntegrations.setSlackTeam(response.data));
        dispatch(actionsIntegrations.setSlackChannels(channels.data));

        return Promise.resolve(response.data);
    } catch (error: any) {
        errorHandler(error, dispatch);

        return Promise.reject(error);
    }
};

export const addSlackWorkspace = (code: string): AppThunkType => async (dispatch, getState) => {
    try {
        const teamId = getState().profile.currentEnvironment.team_id as number;

        const response = await slackAPI.setTokenForTeam(teamId, code);
        await dispatch(getCurrentTeam(teamId));
        await dispatch(getSlackTeam());

        dispatch(showAlert(response.data.message, true));

        return Promise.resolve();
    } catch (error: any) {
        errorHandler(error, dispatch);

        return Promise.reject(error);
    }
};

export const getSlackChannels = (): AppThunkType => async (dispatch, getState) => {
    try {
        const teamId = getState().profile.currentEnvironment.team_id as number;
        const channels = await slackAPI.getSlackChannels(teamId);

        dispatch(actionsIntegrations.setSlackChannels(channels.data));

        return Promise.resolve(channels);
    } catch (error: any) {
        errorHandler(error, dispatch);

        return Promise.reject(error);
    }
};

export const setChannelForTeam = (channelId: string): AppThunkType => async (dispatch, getState) => {
    try {
        const teamId = getState().profile.currentEnvironment.team_id as number;
        const { currentTeam } = getState().team;
        const response = await slackAPI.setChannelForTeam(teamId, channelId);
        if (currentTeam) {
            dispatch(actionsTeam.setCurrentTeam({ ...currentTeam, slack_channel_id: channelId }));
        }
        dispatch(showAlert(response.data.message, true));

        return Promise.resolve(response.data);
    } catch (error: any) {
        errorHandler(error, dispatch);

        return Promise.reject(error);
    }
};

export const deleteSlackToken = (): AppThunkType => async (dispatch, getState) => {
    try {
        const teamId = getState().profile.currentEnvironment.team_id as number;
        const { currentTeam } = getState().team;
        const response = await slackAPI.deleteTokenForTeam(teamId);
        if (currentTeam) {
            dispatch(actionsTeam.setCurrentTeam({ ...currentTeam, slack_channel_id: null, has_slack: false }));
        }
        dispatch(showAlert(response.data.message, true));

        return Promise.resolve(response.data);
    } catch (error: any) {
        errorHandler(error, dispatch);

        return Promise.reject(error);
    }
};

export const setChannelForWorkspace = (workspaceId: number, channelId: string): AppThunkType => async (dispatch, getState) => {
    try {
        const { workspacesList } = getState().workspaces;
        const response = await slackAPI.setChannelForWorkspace(workspaceId, channelId);
        dispatch(actionsWorkspace.setWorkspacesList(
            workspacesList.map((item) => (item.id === workspaceId ? ({ ...item, slack_channel_id: channelId }) : item))
        ));
        dispatch(showAlert(response.data.message, true));

        return Promise.resolve(response.data);
    } catch (error: any) {
        errorHandler(error, dispatch);

        return Promise.reject(error);
    }
};

export const deleteChannelForWorkspace = (workspaceId: number): AppThunkType => async (dispatch, getState) => {
    const { workspacesList } = getState().workspaces;
    try {
        const response = await slackAPI.deleteChannelForWorkspace(workspaceId);
        dispatch(actionsWorkspace.setWorkspacesList(
            workspacesList.map((item) => (item.id === workspaceId ? ({ ...item, slack_channel_id: null }) : item))
        ));
        dispatch(showAlert(response.data.message, true));

        return Promise.resolve(response.data);
    } catch (error: any) {
        errorHandler(error, dispatch);

        return Promise.reject(error);
    }
};

export const deleteChannelForTeam = (teamId: string | number): AppThunkType => async (dispatch, getState) => {
    try {
        const { currentTeam } = getState().team;
        const response = await slackAPI.deleteChannelForTeam(teamId);
        if (currentTeam) {
            dispatch(actionsTeam.setCurrentTeam({ ...currentTeam, slack_channel_id: null }));
        }
        dispatch(showAlert(response.data.message, true));

        return Promise.resolve(response.data);
    } catch (error: any) {
        errorHandler(error, dispatch);

        return Promise.reject(error);
    }
};

export const getPocketUrl = (): AppThunkType => async (dispatch, getState) => {
    try {
        const { currentWorkspaceId } = getState().workspaces;
        const response = await pocketAPI.getUrl(currentWorkspaceId);
        window.open(response.data.redirect_url, '_self');

        return Promise.resolve(response.data);
    } catch (error: any) {
        errorHandler(error, dispatch);

        return Promise.reject(error);
    }
};

export const storePocketToken = (requestToken: string): AppThunkType => async (dispatch, getState) => {
    const user = getState().profile.user as UserType;
    try {
        const response = await pocketAPI.storeAccessToken(requestToken);
        dispatch(actionsProfile.setUserProfile({ ...user, has_pocket: true }));

        return Promise.resolve(response.data);
    } catch (error: any) {
        errorHandler(error, dispatch);

        return Promise.reject(error);
    }
};

export const syncWithPocket = (workspaceId: number | string, folderId?: number | null): AppThunkType => async (dispatch) => {
    try {
        const response = await pocketAPI.syncWithPocket(workspaceId, folderId);
        dispatch(showAlert(response.data.message, true));

        return Promise.resolve(response.data);
    } catch (error: any) {
        errorHandler(error, dispatch);

        return Promise.reject(error);
    }
};

export const deletePocketToken = (): AppThunkType => async (dispatch, getState) => {
    const user = getState().profile.user as UserType;

    try {
        const response = await pocketAPI.deleteAccessToken();
        dispatch(actionsProfile.setUserProfile({ ...user, has_pocket: false }));

        return Promise.resolve(response.data);
    } catch (error: any) {
        errorHandler(error, dispatch);

        return Promise.reject(error);
    }
};
