import { Flex, Stack } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';

import AuthLayout from 'components/BookmarksManager/Layouts/AuthLayout';
import PasswordInput from 'components/common/inputs/PasswordInput';
import SubmitButton from 'components/common/buttons/SubmitButton';
import KeyIcon from 'components/Auth/KeyIcon';
import { useAppDispatch, useAppSelector } from 'store/store';
import { resetPassword } from 'store/auth-reducer';

import bgimage from 'assets/image/login.webp';

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.popup.isLoading);
  const [searchParams] = useSearchParams('');
  const email = searchParams.get('email') || '';
  const token = searchParams.get('token') || '';

  const formik = useFormik({
    initialValues: { password: '', password_confirmation: '' },
    onSubmit: ({ password, password_confirmation }) => {
      dispatch(resetPassword(email, password, password_confirmation, token)).catch((error) => {
        formik.setStatus(error);
        window.scrollTo(0, 0);
      });
    }
  });
  const { password, password_confirmation } = formik.values;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    formik.handleChange(e);
    if (formik.status?.[key]) formik.setStatus({ ...formik.status, [key]: undefined });
  };

  return (
    <AuthLayout
      image={bgimage}
      title="Create password"
      icon={<KeyIcon background="gradient.160" />}
      topSpace={{ base: '40px', md: '118px' }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Flex mt={{ base: '32px', lg: '48px' }} w="100%" flexDir="column">
          <Stack spacing={{ base: '16px', lg: '24px' }} mb="32px" fontWeight="400" fontSize="16px">
            <PasswordInput
              value={password}
              handleChange={onChange}
              name="password"
              label="New password"
              placeholder="Enter new password..."
              error={formik.status?.password}
              hideLabelOnMobile
            />
            <PasswordInput
              value={password_confirmation}
              handleChange={onChange}
              name="password_confirmation"
              label="Confirm password"
              placeholder="Confirm new password"
              error={formik.status?.password_confirmation}
              hideLabelOnMobile
            />
          </Stack>
          <SubmitButton isLoading={isLoading}>Reset password</SubmitButton>
        </Flex>
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;
