import { Flex, Box, Image, Text } from '@chakra-ui/react';
import { BookmarkType } from 'types/types';

import imgBookmarkDefault from 'assets/image/img_bookmark_default.webp';

interface BookmarkPreviewProps {
  bookmark: BookmarkType;
  handleClick: () => void;
}

const ReminderBookmarkItem = ({ bookmark, handleClick }: BookmarkPreviewProps) => (
  <Flex
    w="100%"
    onClick={handleClick}
    cursor="pointer"
    bg="white.200"
    borderRadius={{ base: '6px', ml: '12px' }}
    p="10px"
    _hover={{
      bg: 'white.600'
    }}
  >
    <Image
      boxSize={{ base: '28px', sm: '44px', ml: '75px' }}
      objectFit="cover"
      borderRadius="4px"
      src={bookmark.link.file?.url || imgBookmarkDefault}
      alt=""
      mr={{ base: '12px', sm: '16px', ml: '17px' }}
      mt={{ base: '4px', sm: '0' }}
    />
    <Box flex={1} overflow="hidden">
      <Text
        w="100%"
        fontWeight="500"
        fontSize="14px"
        lineHeight="150%"
        noOfLines={{ base: 1, ml: 2 }}
        wordBreak="break-word"
        mb={{ base: '0', ml: '8px' }}
      >
        {bookmark.name}
      </Text>

      <Text
        w="100%"
        fontWeight="400"
        fontSize="12px"
        lineHeight="150%"
        color="light_gray"
        noOfLines={1}
        wordBreak="break-all"
      >
        {bookmark.link?.url}
      </Text>
    </Box>
  </Flex>
);

export default ReminderBookmarkItem;
