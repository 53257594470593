import { workspaceAPI } from 'api/workspaceApi';
import { errorHandler } from 'utils/errorHandler';
import {
  InferActionsTypes,
  NewWorkspace,
  UpdateWorkspace, WorkspaceType
} from '../types/types';
import { showAlert } from './app-reducer';
import { actionsPopup } from './popup-reducer';
import { AppThunkType } from './store';

const initialState = {
  workspacesList: [] as Array<WorkspaceType>,
  currentWorkspaceId: '' as string
};

export const actionsWorkspace = {
  setWorkspacesList: (data: Array<WorkspaceType>) => ({ type: 'SET_WORKSPACES_LIST', data }) as const,
  setCurrentWorkspaceId: (id: string) => ({ type: 'SET_CURRENT_WORKSPACE_ID', id }) as const,
  cleanUp: () => ({ type: 'CLEAN_UP_WORKSPACE_DATA' }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsWorkspace>

const workspaceReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_WORKSPACES_LIST':
      return {
        ...state,
        workspacesList: action.data
      };
    case 'SET_CURRENT_WORKSPACE_ID':
      return {
        ...state,
        currentWorkspaceId: action.id
      };
    case 'CLEAN_UP_WORKSPACE_DATA':
      return {
        ...state,
        workspacesList: [],
        currentWorkspaceId: ''
      };

    default:
      return state;
  }
};

export const getWorkspaces = (type: string, teamId?: number | null): AppThunkType => async (dispatch) => {
  try {
    const response = await workspaceAPI.getWorkspacesList(type, teamId ?? null);
    dispatch(actionsWorkspace.setWorkspacesList(response.data));
    if (response.data[0]?.id) {
      dispatch(actionsWorkspace.setCurrentWorkspaceId(String(response.data[0].id)));
    }

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const getOneWorkspace = (workspaceId: string | number): AppThunkType => async (dispatch, getState) => {
  const { workspacesList } = getState().workspaces;
  try {
    const clone = [...workspacesList];
    const response = await workspaceAPI.getWorkspace(workspaceId);
    const updatedWorkspaceIndex = clone.map((item) => Number(item.id)).indexOf(Number(workspaceId));

    if (updatedWorkspaceIndex !== -1) {
      clone.splice(updatedWorkspaceIndex, 1, response.data);
      dispatch(actionsWorkspace.setWorkspacesList(clone));
    }

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export const getWorkspacesWithFoldersTree = (workspaceType = 'personal', teamId: number | null): AppThunkType => async (dispatch) => {
  try {
    const response = await workspaceAPI.getWorkspaceListWithFoldersTree(workspaceType, teamId);

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export const createNewWorkspace = (data: NewWorkspace): AppThunkType => async (dispatch: any, getState) => {
  try {
    dispatch(actionsPopup.setIsLoading(true));

    const workspace = await workspaceAPI.createWorkspace(data);
    const createdWorkspace = await workspaceAPI.getWorkspace(workspace.data.id);
    const workspaces = getState().workspaces.workspacesList;
    dispatch(showAlert('Workspace successfully created!', true));
    dispatch(actionsWorkspace.setWorkspacesList([...workspaces, createdWorkspace.data]));

    return Promise.resolve();
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  } finally {
    dispatch(actionsPopup.setIsLoading(false));
  }
};

export const updateWorkspace = (id: number, data: UpdateWorkspace): AppThunkType => async (dispatch) => {
  try {
    await workspaceAPI.updateWorkspace(id, data);
    dispatch(showAlert('Workspace successfully updated', true));
    dispatch(getOneWorkspace(id));

    return Promise.resolve();
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const deleteWorkspaceImage = (id: number): AppThunkType => async (dispatch, getState) => {
  const { workspacesList } = getState().workspaces;
  try {
    const response = await workspaceAPI.deleteWorkspaceImage(id);
    const newWorkspacesList = workspacesList.map((ws) => (ws.id === response.data.id ? { ...ws, image: response.data.image } : ws));
    dispatch(actionsWorkspace.setWorkspacesList(newWorkspacesList));
    dispatch(showAlert('Workspace image successfully deleted', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const deleteWorkspace = (id: number | string): AppThunkType => async (dispatch, getState) => {
  const { workspacesList } = getState().workspaces;
  try {
    dispatch(actionsPopup.setIsLoading(true));

    const response = await workspaceAPI.deleteWorkspace(id);
    const workspaces = workspacesList.filter((item) => item.id !== id);
    dispatch(actionsWorkspace.setWorkspacesList(workspaces));
    dispatch(showAlert(response.data.message, response.success));

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  } finally {
    dispatch(actionsPopup.setIsLoading(false));
  }
};

export const deleteWorkspaceMember = (workspaceId: number | string, memberId: number): AppThunkType => async (dispatch) => {
  try {
    const response = await workspaceAPI.deleteWorkspaceMember(workspaceId, memberId);
    dispatch(showAlert(response.data.message, true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const orderWorkspaces = (
  firstIndex: number,
  secondIndex: number
): AppThunkType => async (dispatch, getState) => {
  const { workspacesList } = getState().workspaces;

  try {
    const workspacesClone = [...workspacesList];
    const replaceable = workspacesClone.splice(firstIndex, 1);
    workspacesClone.splice(secondIndex, 0, replaceable[0]);
    dispatch(actionsWorkspace.setWorkspacesList(workspacesClone));
    await workspaceAPI.orderWorkspaces(workspacesClone.map((item) => +item.id));

    return Promise.resolve();
  } catch (error: any) {
    errorHandler(error, dispatch);
  }
};

export default workspaceReducer;
