import { snippetsAPI } from 'api/snippetsApi';
import { errorHandler } from 'utils/errorHandler';
import { SnippetType, InferActionsTypes } from '../types/types';
import { showAlert } from './app-reducer';
import { AppThunkType } from './store';

const initialState = {
  data: [] as Array<SnippetType>,
  isFetching: false
};

export const actionsSnippets = {
  setSnippetsList: (data: Array<SnippetType>) => ({ type: 'SET_SNIPPETS_LIST', data }) as const,
  addNewSnippet: (data: SnippetType) => ({ type: 'ADD_NEW_SNIPPET', data }) as const,
  setIsSnippetsFetching: (isFetching: boolean) => ({ type: 'SET_IS_SNIPPETS_FETCHING', isFetching }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsSnippets>

export const snippetsReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_SNIPPETS_LIST':
      return {
        ...state,
        data: action.data
      };

    case 'ADD_NEW_SNIPPET':
      return {
        ...state,
        data: [action.data, ...state.data]
      };

    case 'SET_IS_SNIPPETS_FETCHING':
      return {
        ...state,
        isFetching: action.isFetching
      };

    default:
      return state;
  }
};

export const getSnippets = (
  teamId: number | null,
  collectionId: number | null,
  keyword: string | null = null,
  tags: string | null
): AppThunkType => async (dispatch) => {
  try {
    dispatch(actionsSnippets.setIsSnippetsFetching(true));

    const response = await snippetsAPI.getSnippetsList(teamId, collectionId, keyword, tags);
    dispatch(actionsSnippets.setSnippetsList(response.data));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  } finally {
    dispatch(actionsSnippets.setIsSnippetsFetching(false));
  }
};

export const createSnippet = (
  collectionId: string | number | null,
  title: string,
  content: string,
  tags: string[] = []
): AppThunkType => async (dispatch, getState) => {
  const environment = getState().profile.currentEnvironment;

  try {
    const response = await snippetsAPI.createSnippet(collectionId, title, content, environment.team_id, tags);
    dispatch(actionsSnippets.addNewSnippet(response.data));
    dispatch(showAlert('Snippet successfully created!', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const editSnippet = (
  collectionId: string | number,
  title: string,
  content: string,
  tags: string[]
): AppThunkType => async (dispatch, getState) => {
  const snippets = getState().snippets.data;
  try {
    const response = await snippetsAPI.editSnippet(collectionId, title, content, tags);
    dispatch(actionsSnippets.setSnippetsList(
      snippets.map((item) => ({
        ...item,
        ...(+item.id === +collectionId
          ? { title, content, tags: response.data.tags }
          : {})
      }))
    ));
    dispatch(showAlert('Snippet successfully updated!', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    return errorHandler(error, dispatch, (res) => Promise.reject(res.validationErrors));
  }
};

export const deleteSnippet = (
  snippetId: string | number
): AppThunkType => async (dispatch, getState) => {
  const snippets = getState().snippets.data;
  try {
    const response = await snippetsAPI.deleteSnippet(snippetId);
    dispatch(actionsSnippets.setSnippetsList(snippets.filter((item) => +item.id !== +snippetId)));
    dispatch(showAlert('Snippet successfully deleted!', true));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const bulkDeleteSnippets = (
  snippets: Array<string | number>
): AppThunkType => async (dispatch, getState) => {
  const snippetsList = getState().snippets.data;

  try {
    const response = await snippetsAPI.bulkDeleteSnippets(snippets);
    dispatch(showAlert('Snippets successfully deleted!', true));
    dispatch(actionsSnippets.setSnippetsList(snippetsList.filter((item) => !snippets.includes(item.id))));

    return Promise.resolve(response.data);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export default snippetsReducer;

