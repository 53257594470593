import { Flex, Square, Stack, Text } from '@chakra-ui/react';
import { useFormik } from 'formik';

import { ButtonGroupPopup } from 'components/common/buttons/ButtonGroupPopup';
import FormInput from 'components/common/inputs/FormInput';
import { useAppDispatch } from 'store/store';
import { createCollection } from 'store/collections-reducer';

type AddCollectionProps = {
  onClose: () => void;
};

export const AddCollection = ({ onClose }: AddCollectionProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      name: ''
    },
    onSubmit: (value) => {
      dispatch(createCollection(value.name))
        .then(() => {
          onClose();
        })
        .catch((errors) => {
          formik.setStatus(errors);
        })
        .finally(() => {
          formik.setSubmitting(false);
        });
    }
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    if (formik.status?.name) formik.setStatus({ ...formik.status, name: undefined });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Flex direction="column">
        <Stack spacing="24px">
          <Flex alignItems="center">
            <Square background="light_blue.800" borderRadius="4px" size="28px" color="primary_blue.800">
              <i className="fa-solid fa-layer-group" />
            </Square>
            <Text mx="12px" fontWeight="600" fontSize="16px" lineHeight="19px">
              Create Collection
            </Text>
          </Flex>

          <FormInput
            label=""
            name="name"
            autoComplete="off"
            placeholder="Collection name"
            handleChange={onChange}
            value={formik.values.name}
            error={formik.status?.name}
            height="40px"
          />
        </Stack>

        <ButtonGroupPopup mt="72px" isLoading={formik.isSubmitting} label="Save" handleCancelClick={onClose} />
      </Flex>
    </form>
  );
};
