export const options = {
    date_asc: { sort: 'created_at', order: 'asc' },
    date_desc: { sort: 'created_at', order: 'desc' },
    name_asc: { sort: 'name', order: 'asc' },
    name_desc: { sort: 'name', order: 'desc' }
};

export type BookmarkSortOptions = typeof options;

export type BookmarkSortOption = keyof BookmarkSortOptions;

// eslint-disable-next-line max-len
export const isBookmarkSortOption = (value: string): value is BookmarkSortOption => Object.keys(options).includes(value as BookmarkSortOption);

