import { Button, MenuItem } from '@chakra-ui/react';
import { ReactNode } from 'react';

type PropsType = {
  handleClick?: () => void;
  children: ReactNode;
  hoverColor?: string;
};

export const ContextMenuButton = ({ handleClick, children, hoverColor = 'white.700' }: PropsType) => (
  <MenuItem
    as={Button}
    onClick={handleClick}
    _hover={{ bg: hoverColor }}
    size="lg"
    variant="menuItem"
    p="8px"
    m="0 8px 0 8px"
    minW="calc(100% - 16px)"
    w="calc(100% - 16px)"
  >
    {children}
  </MenuItem>
);
