import { getEnvironments } from 'store/profile-reducer';
import { subscriptionAPI } from 'api/subscriptionApi';
import { errorHandler } from 'utils/errorHandler';
import {
  InferActionsTypes, PlanType, SubscriptionDetailsType
} from '../types/types';
import { showAlert } from './app-reducer';
import { actionsPopup } from './popup-reducer';
import { AppThunkType } from './store';

const initialState = {
  currentPlan: null as SubscriptionDetailsType | null,
  subscriptionsPlanList: [] as Array<PlanType>,
  congratulationIsOpen: false
};

export const actionsSubscription = {
  setCurrentPlan: (plan: SubscriptionDetailsType) => ({ type: 'SET_CURRENT_SUBSCRIPTION_PLAN', plan }) as const,
  setSubscriptionPlansList: (list: Array<PlanType>) => ({ type: 'SET_SUBSCRIPTIONS_PLANS_LIST', list }) as const,
  setCongratulationIsOpen: (isOpen: boolean) => ({ type: 'SET_CONGRATULATION_IS_OPEN', isOpen }) as const,
  cleanUp: () => ({ type: 'CLEAN_UP_CURRENT_PLAN' }) as const
};

type InitialStateType = typeof initialState
type ActionType = InferActionsTypes<typeof actionsSubscription>

const subscriptionReducer = (state = initialState, action: ActionType): InitialStateType => {
  switch (action.type) {
    case 'SET_CURRENT_SUBSCRIPTION_PLAN':
      return {
        ...state,
        currentPlan: action.plan
      };

    case 'SET_SUBSCRIPTIONS_PLANS_LIST':
      return {
        ...state,
        subscriptionsPlanList: action.list
      };

    case 'CLEAN_UP_CURRENT_PLAN':
      return {
        ...state,
        currentPlan: null,
        subscriptionsPlanList: []
      };

    case 'SET_CONGRATULATION_IS_OPEN':
      return {
        ...state,
        congratulationIsOpen: action.isOpen
      };

    default:
      return state;
  }
};

export const getCurrentPlan = (): AppThunkType => async (dispatch) => {
  try {
    const response = await subscriptionAPI.getDetails();

    if (response.success) {
      dispatch(actionsSubscription.setCurrentPlan(response.data));
    }

    return Promise.resolve(response);
  } catch (error: any) {
    errorHandler(error, dispatch);

    return Promise.reject(error);
  }
};

export const upgradePlan = (targetPlan: string, period: string): AppThunkType => async (dispatch, getState) => {
  const { subscriptionsPlanList } = getState().subscription;

  dispatch(actionsPopup.setIsLoading(true));
  try {
    const stripePlansPrices = subscriptionsPlanList.find((item) => item.slug === targetPlan)?.prices;
    const stripePlan = stripePlansPrices?.find(
      (price) => (targetPlan === 'basic' ? price.interval === null : price.interval === period)
    )?.stripe_price;

    if (stripePlan) {
      const upgradeData = await subscriptionAPI.upgradePlan(stripePlan);

      if (upgradeData.data.url) {
        window.open(upgradeData.data.url, '_self');
      }
      dispatch(getCurrentPlan());
      dispatch(getEnvironments());

      return Promise.resolve(upgradeData.data);
    }

    dispatch(showAlert('Something went wrong!', false));
  } catch (error) {
    errorHandler(error, dispatch);
  } finally {
    dispatch(actionsPopup.setIsLoading(false));
  }
};

export const getSubscriptionsPlansList = (period?: string): AppThunkType => async (dispatch) => {
  try {
    const listPlansData = await subscriptionAPI.getListPlans(period);

    dispatch(actionsSubscription.setSubscriptionPlansList(listPlansData.data));
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export const getBillingPortalUrl = (): AppThunkType => async (dispatch) => {
  try {
    const data = await subscriptionAPI.getBillingPortalUrl();

    return Promise.resolve(data.data.url);
  } catch (error) {
    errorHandler(error, dispatch);
  }
};

export default subscriptionReducer;
