import { Button, Center, Flex, Image, Text } from '@chakra-ui/react';
import documentIcon from 'assets/image/wiki/document.svg';
import cn from 'classnames';
import EditItem from 'components/Wiki/contextMenu/EditItem';
import { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { deleteDocument, editWikiDocument } from 'store/wiki/document-reducer';
import { deleteFolder, renameFolder } from 'store/wiki/folder-reducer';
import style from '../WikiNavbar.module.scss';

type Props = {
  item: {
    id: number;
    title: string;
    wikis?: Props['item'][];
  }
  icon: string;
  link: string;
  parent?: {
    id: number,
    name: string
  };
  toggleNavbar: () => void;
}

const ItemList = ({ item, icon, link, parent, toggleNavbar }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [showSubList, setShowSubList] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const selected = ({ isActive }: any) => cn(style.link_container, { [style.active]: isActive });

  const toggleSubList = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowSubList(!showSubList);
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onFolderDelete = async () => dispatch(deleteFolder(item.id))
    .then(() => {
      if (Number(location.pathname.split('/').pop()) === Number(item.id)) {
        navigate('.');
      }
    })
    .catch((err) => Promise.reject(err));

  const onFolderEdit = async (newName: string) => dispatch(renameFolder(item.id, newName));

  const onDocumentDelete = async () => dispatch(deleteDocument(item.id, parent?.id))
    .then(() => {
      if (Number(location.pathname.split('/').pop()) === Number(item.id)) {
        navigate(`${parent?.id}`);
      }
    })
    .catch((err) => Promise.reject(err));

  const onDocumentEdit = async (newName: string) => dispatch(editWikiDocument(item.id, newName, undefined, parent?.id));

  return (
    <>
      <NavLink
        onClick={toggleNavbar}
        data-group
        className={selected}
        to={link}
        state={parent
          ? { folder: parent.name, document: item.title }
          : { folder: item.title, document: '' }}
      >
        {!parent && (
          <Button
            colorScheme="transparent"
            size="icon"
            boxSize="12px"
            onClick={toggleSubList}
            transform={showSubList ? 'rotate(90deg)' : 'none'}
            transition="150ms"
            fontSize="12px"
            color="#9D9EB5"
          >
            <i className="fa-solid fa-angle-right" />
          </Button>
        )}
        <Image src={icon} boxSize="16px" />
        <Text noOfLines={1} wordBreak="break-all">
          {item.title}
        </Text>

        <Center
          onClick={handleClick}
          ml="auto"
        >
          <EditItem
            itemName={item.title}
            onEdit={parent?.id ? onDocumentEdit : onFolderEdit}
            onDelete={parent?.id ? onDocumentDelete : onFolderDelete}
          />
        </Center>

        <Button
          onClick={handleClick}
          className={style.feature_icon}
          size="icon"
          variant="wikiIconButton"
          color="light_gray"
          cursor="grab"
        >
          <i className="fa-solid fa-grip-vertical" />
        </Button>
      </NavLink>

      {showSubList && !!item.wikis?.length && (
        <Flex flexDirection="column" pl="16px" gap="1px">
          {item.wikis.map((wiki) => (
            <ItemList
              key={wiki.id}
              icon={documentIcon}
              item={wiki}
              link={`${item.id}/${wiki.id}`}
              parent={{
                id: item.id,
                name: item.title
              }}
              toggleNavbar={toggleNavbar}
            />
          ))}
        </Flex>
      )}
    </>
  );
};

export default ItemList;
