import { Center, HStack, Text, VStack } from '@chakra-ui/react';
import { useAppSelector } from 'store/store';
import style from './Alerts.module.scss';

export const Alerts = (): JSX.Element => {
  const errorInState = useAppSelector((state) => state.app.alerts);

  return (
    <div className={style.error_container}>
      {
        (errorInState.length > 0) && (
          errorInState.map((arr) => (
            <HStack
              className={style.error}
              key={arr.id}
              spacing="16px"
              p="20px 24px"
              justifyContent="flex-start"
              borderRadius="4px"
              boxShadow="0px 0px 6px rgba(101, 101, 101, 0.25)"
              borderLeft="4px solid"
              borderColor={arr.success ? '#55BC59' : '#E24A4A'}
              bg="white.100"
              w={{ base: '100%', md: 'fit-content' }}
              minW="300px"
              maxW={{ base: 'calc(100% - 32px)', md: '400px' }}
            >
              {arr.success && (
                <Center fontSize="18px" minW="24px" h="24px" borderRadius="50%" bg="#EAF5E5" color="#55BC59">
                  <i className="fa-solid fa-circle-check" />
                </Center>
              )}

              {!arr.success && (
                <Center position="relative" fontSize="24px" minW="24px" h="24px" color="#F9DBDB">
                  <i className="fa-solid fa-triangle-exclamation" />
                  <Center position="absolute" top="3px" left="3px" fontSize="18px" color="#E24A4A">
                    <i className="fa-solid fa-triangle-exclamation" />
                  </Center>
                </Center>
              )}

              <VStack alignItems="flex-start" spacing="8px">
                <Text color={arr.success ? '#55BC59' : '#E24A4A'} lineHeight="16px">
                  {arr.success ? 'Success' : 'Failed'}
                </Text>

                <Text fontSize="14px" fontWeight="400" color="#999999" lineHeight="16px">
                  {arr.message}
                </Text>
              </VStack>
            </HStack>
          ))
        )
      }
    </div>
  );
};
