import { Center, Divider, Flex, HStack, Image, Text } from '@chakra-ui/react';
import cn from 'classnames';
import Popup from 'components/common/popup/Popup';
import { EditWorkspace } from 'components/modals/Popups/EditManagement/EditWorkspace';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { WorkspaceType } from 'types/types';
import { useAppDispatch, useAppSelector } from 'store/store';
import { orderWorkspaces } from 'store/workspace-reducer';
import { useDragAndDrop } from 'hooks/useDragAndDrop';
import { CreateWorkspaceButton } from '../CreateWorkspaceButton/CreateWorkspaceButton';
import style from '../Navbar.module.scss';

type PropsType = {
  visibleNav: boolean;
  toggleNavbar: () => void;
};

export const WorkspacesList = ({ visibleNav, toggleNavbar }: PropsType): JSX.Element => {
  const dispatch = useAppDispatch();
  const workspaces = useAppSelector((state) => state.workspaces.workspacesList);
  const { currentEnvironment } = useAppSelector((state) => state.profile);

  const [currentWorkspace, setCurrentWorkspace] = useState<WorkspaceType | null>(null);

  const { onDrag, onDrop, removeDragElement } = useDragAndDrop({
    className: style.workspace_drag_element,
    offsetX: 260,
    offsetY: 30
  });

  const selected = ({ isActive }: any) => cn(style.link_container, { [style.active]: isActive });

  const editWorkspace = (e: React.MouseEvent<HTMLElement, MouseEvent>, workspace: WorkspaceType) => {
    setCurrentWorkspace(workspace);
    e.preventDefault();
  };

  const handleClose = () => {
    setCurrentWorkspace(null);
  };

  const handleDrop = (e: React.DragEvent<HTMLAnchorElement>, index: number) => {
    onDrop(e, index, () => {
      const firstIndex = e.dataTransfer?.getData('index');
      dispatch(orderWorkspaces(+firstIndex, index));
    });
  };

  return (
    <>
      <Flex w="100%" justifyContent={visibleNav ? 'space-between' : 'center'}>
        <Text fontWeight="500px" fontSize="12px" lineHeight="24px" color="light_gray" whiteSpace="nowrap" mb="-8px">
          {visibleNav && 'WORKSPACES'}
          {!visibleNav && !currentEnvironment.is_own && 'SPACES'}
        </Text>

        {currentEnvironment.is_own && <CreateWorkspaceButton />}
      </Flex>

      <Divider />

      <Flex
        my="-16px"
        py="16px"
        flex="1 1 auto"
        overflow={visibleNav ? 'auto' : 'hidden'}
        direction="column"
        gap="4px"
        width="100%"
        minH={window.innerHeight < 700 ? '70px' : '140px'}
      >
        <Flex direction="column" alignItems="flex-start" gap="4px">
          {workspaces?.map((workspace, index) => (
            <NavLink
              end
              to={`workspace/${workspace.id}`}
              onClick={toggleNavbar}
              className={selected}
              key={workspace.id}
              draggable
              onDragStart={(e) => onDrag(e, { id: workspace.id, index })}
              onDragOver={(e) => e.preventDefault()}
              onDragEnd={removeDragElement}
              onDrop={(e) => handleDrop(e, index)}
            >
              {workspace.image ? (
                <Image src={workspace.image} minW="32px" boxSize="32px" borderRadius="50px" />
              ) : (
                <i className={cn('fa-solid fa-briefcase', style.icon_workspaces)} />
              )}
              <Text noOfLines={1} wordBreak="break-all" maxW="140px">
                {workspace.name}
              </Text>

              {visibleNav ? (
                <Flex gap="8px" ml="auto">
                  <HStack className={style.items_count} spacing="6px" fontSize="12px">
                    <HStack spacing="2px">
                      <Text>{workspace.folders_count}</Text>
                      <Center boxSize="14px" fontSize="10px">
                        <i className="fa-solid fa-folder" />
                      </Center>
                    </HStack>

                    <HStack spacing="2px">
                      <Text>{workspace.bookmarks_count}</Text>
                      <Center boxSize="14px" fontSize="10px">
                        <i className="fa-solid fa-bookmark" />
                      </Center>
                    </HStack>
                  </HStack>

                  <i
                    id={String(workspace.id)}
                    className={cn('fa-solid fa-sliders', style.kebab_menu_icon)}
                    onClick={(e) => editWorkspace(e, workspace)}
                    role="button"
                    tabIndex={0}
                  />
                  <i className={cn('fa-solid fa-grip', style.grip_icon)} />
                </Flex>
              ) : null}
            </NavLink>
          ))}
        </Flex>

        {currentWorkspace && (
          <Popup isOpen={!!currentWorkspace} onClose={handleClose} hasCloseButton width="643px">
            <EditWorkspace workspace={currentWorkspace} onClose={handleClose} />
          </Popup>
        )}
      </Flex>
    </>
  );
};
