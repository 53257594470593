import { Center } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface HotKeyProps {
  children: ReactNode;
  background?: string;
  color?: string;
  borderRadius?: string;
}

const HotKey = ({ children, color, background = 'rgba(99, 85, 188, 0.2)', borderRadius = '7px' }: HotKeyProps) => (
  <Center
    h="24px"
    minW="24px"
    borderRadius={borderRadius}
    background={background}
    color={color}
    fontSize="14px"
    fontWeight="600"
  >
    {children}
  </Center>
);

export default HotKey;
