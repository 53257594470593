import axios from 'axios';
import Cookies from 'universal-cookie';
import { PaginationType } from '../types/types';

const cookies = new Cookies();

export const checkParam = (param: any) => (!param ? '' : param);

export const requestInstance = (isAuthorized: boolean = true) => axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: isAuthorized
    ? {
      Authorization: `Bearer ${cookies.get('token')}`
    }
    : {}
});

export interface ResponseType<D> {
  data: D;
  success: boolean;
  error?: {
    message: string;
  };
}

export type EditType = {
  success: boolean,
  data: {
    message: string,
  }
}

export interface GetItemsType<D> extends ResponseType<D> {
  pagination: PaginationType;
}

